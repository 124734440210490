/*
************
*************************

Template Name: Eduan - School University & Online Education Template
Description: School University & Online Education HTML Template Description
Author: author name
Version: 1.0

-----------------------------------------------------------------------------------

CSS INDEX
=================== 
01. common
02. header
03. sidebar
04. banner
05. section
06. brand
07. category
08. course
09. course-details
10. button
11. about
12. event
13. event-details
14. testimonial
15. counter
16. blog
17. blog-details
18. cta
19. teacher
20. price
21. breadcrumb
22. gallery
23. account
24. team details
25. contact
26. 404
27. admission
28. education
29. faq
30. feature
31. video
32. footer

******************************************************* 
*************************************************************** */
/*
************
*************************
01. common
******************************************************* 
*************************************************************** */
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700;900&family=Sansita+Swashed:wght@400;500;600;700;800&display=swap");
body {
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-style: normal;
  color: var(--clr-body-text);
  font-size: 16px;
  line-height: 28px;
}

.img, img {
  max-width: 100%;
  -webkit-transition: all 0.3s linear 0s;
  -moz-transition: all 0.3s linear 0s;
  -ms-transition: all 0.3s linear 0s;
  -o-transition: all 0.3s linear 0s;
  transition: all 0.3s linear 0s;
}

a,
.button, button {
  -webkit-transition: all 0.3s linear 0s;
  -moz-transition: all 0.3s linear 0s;
  -ms-transition: all 0.3s linear 0s;
  -o-transition: all 0.3s linear 0s;
  transition: all 0.3s linear 0s;
}

a:focus,
.button:focus {
  text-decoration: none;
  outline: none;
}

a:focus,
a:hover {
  color: inherit;
  text-decoration: none;
}

a,
button {
  color: inherit;
  outline: medium none;
  text-decoration: none;
}

button:focus, input:focus, input:focus, textarea, textarea:focus {
  outline: 0;
}

.uppercase {
  text-transform: uppercase;
}

.capitalize {
  text-transform: capitalize;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Roboto", sans-serif;
  color: var(--clr-body-heading);
  margin-top: 0px;
  line-height: 1.2;
  font-weight: 700;
  margin-bottom: 15px;
}

h1 a,
h2 a,
h3 a,
h4 a,
h5 a,
h6 a {
  color: inherit;
}

h1 {
  font-size: 70px;
}

h2 {
  font-size: 46px;
}

h3 {
  font-size: 40px;
}

h4 {
  font-size: 28px;
}

h5 {
  font-size: 20px;
}

h6 {
  font-size: 16px;
}

ul {
  margin: 0px;
  padding: 0px;
  list-style: none;
}

p {
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;
  color: var(--clr-body-text);
}

*::-moz-selection {
  background-color: var(--clr-theme-primary);
  color: var(--clr-common-white);
  text-shadow: none;
}

::-moz-selection {
  background-color: var(--clr-theme-primary);
  color: var(--clr-common-white);
  text-shadow: none;
}

::selection {
  background-color: var(--clr-theme-primary);
  color: var(--clr-common-white);
  text-shadow: none;
}

/* <<<<<<<    Input Placeholder   >>>>>>>>> */
input::-webkit-input-placeholder {
  color: var(--clr-body-text);
  font-size: 16px;
  opacity: 1;
  -webkit-transition: all 0.3s linear 0s;
  -moz-transition: all 0.3s linear 0s;
  -ms-transition: all 0.3s linear 0s;
  -o-transition: all 0.3s linear 0s;
  transition: all 0.3s linear 0s;
}
input:-moz-placeholder {
  color: var(--clr-body-text);
  font-size: 16px;
  opacity: 1;
  -webkit-transition: all 0.3s linear 0s;
  -moz-transition: all 0.3s linear 0s;
  -ms-transition: all 0.3s linear 0s;
  -o-transition: all 0.3s linear 0s;
  transition: all 0.3s linear 0s;
}
input::-moz-placeholder {
  color: var(--clr-body-text);
  font-size: 16px;
  opacity: 1;
  -webkit-transition: all 0.3s linear 0s;
  -moz-transition: all 0.3s linear 0s;
  -ms-transition: all 0.3s linear 0s;
  -o-transition: all 0.3s linear 0s;
  transition: all 0.3s linear 0s;
}
input:-ms-input-placeholder {
  color: var(--clr-body-text);
  font-size: 16px;
  opacity: 1;
  -webkit-transition: all 0.3s linear 0s;
  -moz-transition: all 0.3s linear 0s;
  -ms-transition: all 0.3s linear 0s;
  -o-transition: all 0.3s linear 0s;
  transition: all 0.3s linear 0s;
}

textarea::-webkit-input-placeholder {
  color: var(--clr-body-text);
  font-size: 16px;
  opacity: 1;
  -webkit-transition: all 0.3s linear 0s;
  -moz-transition: all 0.3s linear 0s;
  -ms-transition: all 0.3s linear 0s;
  -o-transition: all 0.3s linear 0s;
  transition: all 0.3s linear 0s;
}
textarea:-moz-placeholder {
  color: var(--clr-body-text);
  font-size: 16px;
  opacity: 1;
  -webkit-transition: all 0.3s linear 0s;
  -moz-transition: all 0.3s linear 0s;
  -ms-transition: all 0.3s linear 0s;
  -o-transition: all 0.3s linear 0s;
  transition: all 0.3s linear 0s;
}
textarea::-moz-placeholder {
  color: var(--clr-body-text);
  font-size: 16px;
  opacity: 1;
  -webkit-transition: all 0.3s linear 0s;
  -moz-transition: all 0.3s linear 0s;
  -ms-transition: all 0.3s linear 0s;
  -o-transition: all 0.3s linear 0s;
  transition: all 0.3s linear 0s;
}
textarea:-ms-input-placeholder {
  color: var(--clr-body-text);
  font-size: 16px;
  opacity: 1;
  -webkit-transition: all 0.3s linear 0s;
  -moz-transition: all 0.3s linear 0s;
  -ms-transition: all 0.3s linear 0s;
  -o-transition: all 0.3s linear 0s;
  transition: all 0.3s linear 0s;
}

input:focus::placeholder, textarea:focus::placeholder {
  opacity: 0;
}

/* <<<<<<<    Common Classes   >>>>>>>>> */
.w_img img {
  width: 100%;
}

.fix {
  overflow: hidden;
}

/* <<<<<<<    Overlay   >>>>>>>>> */
[data-overlay] {
  position: relative;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  z-index: 1;
}
[data-overlay]::before {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  content: "";
}

/* <<<<<<<    Margin & Padding     >>>>>>>>> */
.mt-5 {
  margin-top: 5px;
}

.mt-10 {
  margin-top: 10px;
}

.mt-15 {
  margin-top: 15px;
}

.mt-20 {
  margin-top: 20px;
}

.mt-25 {
  margin-top: 25px;
}

.mt-30 {
  margin-top: 30px;
}

.mt-35 {
  margin-top: 35px;
}

.mt-40 {
  margin-top: 40px;
}

.mt-45 {
  margin-top: 45px;
}

.mt-50 {
  margin-top: 50px;
}

.mt-55 {
  margin-top: 55px;
}

.mt-60 {
  margin-top: 60px;
}

.mt-65 {
  margin-top: 65px;
}

.mt-70 {
  margin-top: 70px;
}

.mt-75 {
  margin-top: 75px;
}

.mt-80 {
  margin-top: 80px;
}

.mt-85 {
  margin-top: 85px;
}

.mt-90 {
  margin-top: 90px;
}

.mt-95 {
  margin-top: 95px;
}

.mt-100 {
  margin-top: 100px;
}

.mt-105 {
  margin-top: 105px;
}

.mt-110 {
  margin-top: 110px;
}

.mt-115 {
  margin-top: 115px;
}

.mt-120 {
  margin-top: 120px;
}

.mt-125 {
  margin-top: 125px;
}

.mt-130 {
  margin-top: 130px;
}

.mt-135 {
  margin-top: 135px;
}

.mt-140 {
  margin-top: 140px;
}

.mt-145 {
  margin-top: 145px;
}

.mt-150 {
  margin-top: 150px;
}

.mt-155 {
  margin-top: 155px;
}

.mt-160 {
  margin-top: 160px;
}

.mt-165 {
  margin-top: 165px;
}

.mt-170 {
  margin-top: 170px;
}

.mt-175 {
  margin-top: 175px;
}

.mt-180 {
  margin-top: 180px;
}

.mt-185 {
  margin-top: 185px;
}

.mt-190 {
  margin-top: 190px;
}

.mt-195 {
  margin-top: 195px;
}

.mt-200 {
  margin-top: 200px;
}

.mb-5 {
  margin-bottom: 5px;
}

.mb-10 {
  margin-bottom: 10px;
}

.mb-15 {
  margin-bottom: 15px;
}

.mb-20 {
  margin-bottom: 20px;
}

.mb-25 {
  margin-bottom: 25px;
}

.mb-30 {
  margin-bottom: 30px;
}

.mb-35 {
  margin-bottom: 35px;
}

.mb-40 {
  margin-bottom: 40px;
}

.mb-45 {
  margin-bottom: 45px;
}

.mb-50 {
  margin-bottom: 50px;
}

.mb-55 {
  margin-bottom: 55px;
}

.mb-60 {
  margin-bottom: 60px;
}

.mb-65 {
  margin-bottom: 65px;
}

.mb-70 {
  margin-bottom: 70px;
}

.mb-75 {
  margin-bottom: 75px;
}

.mb-80 {
  margin-bottom: 80px;
}

.mb-85 {
  margin-bottom: 85px;
}

.mb-90 {
  margin-bottom: 90px;
}

.mb-95 {
  margin-bottom: 95px;
}

.mb-100 {
  margin-bottom: 100px;
}

.mb-105 {
  margin-bottom: 105px;
}

.mb-110 {
  margin-bottom: 110px;
}

.mb-115 {
  margin-bottom: 115px;
}

.mb-120 {
  margin-bottom: 120px;
}

.mb-125 {
  margin-bottom: 125px;
}

.mb-130 {
  margin-bottom: 130px;
}

.mb-135 {
  margin-bottom: 135px;
}

.mb-140 {
  margin-bottom: 140px;
}

.mb-145 {
  margin-bottom: 145px;
}

.mb-150 {
  margin-bottom: 150px;
}

.mb-155 {
  margin-bottom: 155px;
}

.mb-160 {
  margin-bottom: 160px;
}

.mb-165 {
  margin-bottom: 165px;
}

.mb-170 {
  margin-bottom: 170px;
}

.mb-175 {
  margin-bottom: 175px;
}

.mb-180 {
  margin-bottom: 180px;
}

.mb-185 {
  margin-bottom: 185px;
}

.mb-190 {
  margin-bottom: 190px;
}

.mb-195 {
  margin-bottom: 195px;
}

.mb-200 {
  margin-bottom: 200px;
}

.ml-5 {
  margin-left: 5px;
}

.ml-10 {
  margin-left: 10px;
}

.ml-15 {
  margin-left: 15px;
}

.ml-20 {
  margin-left: 20px;
}

.ml-25 {
  margin-left: 25px;
}

.ml-30 {
  margin-left: 30px;
}

.ml-35 {
  margin-left: 35px;
}

.ml-40 {
  margin-left: 40px;
}

.ml-45 {
  margin-left: 45px;
}

.ml-50 {
  margin-left: 50px;
}

.ml-55 {
  margin-left: 55px;
}

.ml-60 {
  margin-left: 60px;
}

.ml-65 {
  margin-left: 65px;
}

.ml-70 {
  margin-left: 70px;
}

.ml-75 {
  margin-left: 75px;
}

.ml-80 {
  margin-left: 80px;
}

.ml-85 {
  margin-left: 85px;
}

.ml-90 {
  margin-left: 90px;
}

.ml-95 {
  margin-left: 95px;
}

.ml-100 {
  margin-left: 100px;
}

.ml-105 {
  margin-left: 105px;
}

.ml-110 {
  margin-left: 110px;
}

.ml-115 {
  margin-left: 115px;
}

.ml-120 {
  margin-left: 120px;
}

.ml-125 {
  margin-left: 125px;
}

.ml-130 {
  margin-left: 130px;
}

.ml-135 {
  margin-left: 135px;
}

.ml-140 {
  margin-left: 140px;
}

.ml-145 {
  margin-left: 145px;
}

.ml-150 {
  margin-left: 150px;
}

.ml-155 {
  margin-left: 155px;
}

.ml-160 {
  margin-left: 160px;
}

.ml-165 {
  margin-left: 165px;
}

.ml-170 {
  margin-left: 170px;
}

.ml-175 {
  margin-left: 175px;
}

.ml-180 {
  margin-left: 180px;
}

.ml-185 {
  margin-left: 185px;
}

.ml-190 {
  margin-left: 190px;
}

.ml-195 {
  margin-left: 195px;
}

.ml-200 {
  margin-left: 200px;
}

.mr-5 {
  margin-right: 5px;
}

.mr-10 {
  margin-right: 10px;
}

.mr-15 {
  margin-right: 15px;
}

.mr-20 {
  margin-right: 20px;
}

.mr-25 {
  margin-right: 25px;
}

.mr-30 {
  margin-right: 30px;
}

.mr-35 {
  margin-right: 35px;
}

.mr-40 {
  margin-right: 40px;
}

.mr-45 {
  margin-right: 45px;
}

.mr-50 {
  margin-right: 50px;
}

.mr-55 {
  margin-right: 55px;
}

.mr-60 {
  margin-right: 60px;
}

.mr-65 {
  margin-right: 65px;
}

.mr-70 {
  margin-right: 70px;
}

.mr-75 {
  margin-right: 75px;
}

.mr-80 {
  margin-right: 80px;
}

.mr-85 {
  margin-right: 85px;
}

.mr-90 {
  margin-right: 90px;
}

.mr-95 {
  margin-right: 95px;
}

.mr-100 {
  margin-right: 100px;
}

.mr-105 {
  margin-right: 105px;
}

.mr-110 {
  margin-right: 110px;
}

.mr-115 {
  margin-right: 115px;
}

.mr-120 {
  margin-right: 120px;
}

.mr-125 {
  margin-right: 125px;
}

.mr-130 {
  margin-right: 130px;
}

.mr-135 {
  margin-right: 135px;
}

.mr-140 {
  margin-right: 140px;
}

.mr-145 {
  margin-right: 145px;
}

.mr-150 {
  margin-right: 150px;
}

.mr-155 {
  margin-right: 155px;
}

.mr-160 {
  margin-right: 160px;
}

.mr-165 {
  margin-right: 165px;
}

.mr-170 {
  margin-right: 170px;
}

.mr-175 {
  margin-right: 175px;
}

.mr-180 {
  margin-right: 180px;
}

.mr-185 {
  margin-right: 185px;
}

.mr-190 {
  margin-right: 190px;
}

.mr-195 {
  margin-right: 195px;
}

.mr-200 {
  margin-right: 200px;
}

.pt-5 {
  padding-top: 5px;
}

.pt-10 {
  padding-top: 10px;
}

.pt-15 {
  padding-top: 15px;
}

.pt-20 {
  padding-top: 20px;
}

.pt-25 {
  padding-top: 25px;
}

.pt-30 {
  padding-top: 30px;
}

.pt-35 {
  padding-top: 35px;
}

.pt-40 {
  padding-top: 40px;
}

.pt-45 {
  padding-top: 45px;
}

.pt-50 {
  padding-top: 50px;
}

.pt-55 {
  padding-top: 55px;
}

.pt-60 {
  padding-top: 60px;
}

.pt-65 {
  padding-top: 65px;
}

.pt-70 {
  padding-top: 70px;
}

.pt-75 {
  padding-top: 75px;
}

.pt-80 {
  padding-top: 80px;
}

.pt-85 {
  padding-top: 85px;
}

.pt-90 {
  padding-top: 90px;
}

.pt-95 {
  padding-top: 95px;
}

.pt-100 {
  padding-top: 100px;
}

.pt-105 {
  padding-top: 105px;
}

.pt-110 {
  padding-top: 110px;
}

.pt-115 {
  padding-top: 115px;
}

.pt-120 {
  padding-top: 120px;
}

.pt-125 {
  padding-top: 125px;
}

.pt-130 {
  padding-top: 130px;
}

.pt-135 {
  padding-top: 135px;
}

.pt-140 {
  padding-top: 140px;
}

.pt-145 {
  padding-top: 145px;
}

.pt-150 {
  padding-top: 150px;
}

.pt-155 {
  padding-top: 155px;
}

.pt-160 {
  padding-top: 160px;
}

.pt-165 {
  padding-top: 165px;
}

.pt-170 {
  padding-top: 170px;
}

.pt-175 {
  padding-top: 175px;
}

.pt-180 {
  padding-top: 180px;
}

.pt-185 {
  padding-top: 185px;
}

.pt-190 {
  padding-top: 190px;
}

.pt-195 {
  padding-top: 195px;
}

.pt-200 {
  padding-top: 200px;
}

.pt-205 {
  padding-top: 205px;
}

.pt-210 {
  padding-top: 210px;
}

.pt-215 {
  padding-top: 215px;
}

.pt-220 {
  padding-top: 220px;
}

.pt-225 {
  padding-top: 225px;
}

.pt-230 {
  padding-top: 230px;
}

.pt-235 {
  padding-top: 235px;
}

.pt-240 {
  padding-top: 240px;
}

.pt-245 {
  padding-top: 245px;
}

.pt-250 {
  padding-top: 250px;
}

.pt-255 {
  padding-top: 255px;
}

.pt-260 {
  padding-top: 260px;
}

.pt-265 {
  padding-top: 265px;
}

.pt-270 {
  padding-top: 270px;
}

.pt-275 {
  padding-top: 275px;
}

.pt-280 {
  padding-top: 280px;
}

.pt-285 {
  padding-top: 285px;
}

.pt-290 {
  padding-top: 290px;
}

.pt-295 {
  padding-top: 295px;
}

.pt-300 {
  padding-top: 300px;
}

.pb-5 {
  padding-bottom: 5px;
}

.pb-10 {
  padding-bottom: 10px;
}

.pb-15 {
  padding-bottom: 15px;
}

.pb-20 {
  padding-bottom: 20px;
}

.pb-25 {
  padding-bottom: 25px;
}

.pb-30 {
  padding-bottom: 30px;
}

.pb-35 {
  padding-bottom: 35px;
}

.pb-40 {
  padding-bottom: 40px;
}

.pb-45 {
  padding-bottom: 45px;
}

.pb-50 {
  padding-bottom: 50px;
}

.pb-55 {
  padding-bottom: 55px;
}

.pb-60 {
  padding-bottom: 60px;
}

.pb-65 {
  padding-bottom: 65px;
}

.pb-70 {
  padding-bottom: 70px;
}

.pb-75 {
  padding-bottom: 75px;
}

.pb-80 {
  padding-bottom: 80px;
}

.pb-85 {
  padding-bottom: 85px;
}

.pb-90 {
  padding-bottom: 90px;
}

.pb-95 {
  padding-bottom: 95px;
}

.pb-100 {
  padding-bottom: 100px;
}

.pb-105 {
  padding-bottom: 105px;
}

.pb-110 {
  padding-bottom: 110px;
}

.pb-115 {
  padding-bottom: 115px;
}

.pb-120 {
  padding-bottom: 120px;
}

.pb-125 {
  padding-bottom: 125px;
}

.pb-130 {
  padding-bottom: 130px;
}

.pb-135 {
  padding-bottom: 135px;
}

.pb-140 {
  padding-bottom: 140px;
}

.pb-145 {
  padding-bottom: 145px;
}

.pb-150 {
  padding-bottom: 150px;
}

.pb-155 {
  padding-bottom: 155px;
}

.pb-160 {
  padding-bottom: 160px;
}

.pb-165 {
  padding-bottom: 165px;
}

.pb-170 {
  padding-bottom: 170px;
}

.pb-175 {
  padding-bottom: 175px;
}

.pb-180 {
  padding-bottom: 180px;
}

.pb-185 {
  padding-bottom: 185px;
}

.pb-190 {
  padding-bottom: 190px;
}

.pb-195 {
  padding-bottom: 195px;
}

.pb-200 {
  padding-bottom: 200px;
}

.pb-205 {
  padding-bottom: 205px;
}

.pb-210 {
  padding-bottom: 210px;
}

.pb-215 {
  padding-bottom: 215px;
}

.pb-220 {
  padding-bottom: 220px;
}

.pb-225 {
  padding-bottom: 225px;
}

.pb-230 {
  padding-bottom: 230px;
}

.pb-235 {
  padding-bottom: 235px;
}

.pb-240 {
  padding-bottom: 240px;
}

.pb-245 {
  padding-bottom: 245px;
}

.pb-250 {
  padding-bottom: 250px;
}

.pb-255 {
  padding-bottom: 255px;
}

.pb-260 {
  padding-bottom: 260px;
}

.pb-265 {
  padding-bottom: 265px;
}

.pb-270 {
  padding-bottom: 270px;
}

.pb-275 {
  padding-bottom: 275px;
}

.pb-280 {
  padding-bottom: 280px;
}

.pb-285 {
  padding-bottom: 285px;
}

.pb-290 {
  padding-bottom: 290px;
}

.pb-295 {
  padding-bottom: 295px;
}

.pb-300 {
  padding-bottom: 300px;
}

.pl-5 {
  padding-left: 5px;
}

.pl-10 {
  padding-left: 10px;
}

.pl-15 {
  padding-left: 15px;
}

.pl-20 {
  padding-left: 20px;
}

.pl-25 {
  padding-left: 25px;
}

.pl-30 {
  padding-left: 30px;
}

.pl-35 {
  padding-left: 35px;
}

.pl-40 {
  padding-left: 40px;
}

.pl-45 {
  padding-left: 45px;
}

.pl-50 {
  padding-left: 50px;
}

.pl-55 {
  padding-left: 55px;
}

.pl-60 {
  padding-left: 60px;
}

.pl-65 {
  padding-left: 65px;
}

.pl-70 {
  padding-left: 70px;
}

.pl-75 {
  padding-left: 75px;
}

.pl-80 {
  padding-left: 80px;
}

.pl-85 {
  padding-left: 85px;
}

.pl-90 {
  padding-left: 90px;
}

.pl-95 {
  padding-left: 95px;
}

.pl-100 {
  padding-left: 100px;
}

.pl-105 {
  padding-left: 105px;
}

.pl-110 {
  padding-left: 110px;
}

.pl-115 {
  padding-left: 115px;
}

.pl-120 {
  padding-left: 120px;
}

.pl-125 {
  padding-left: 125px;
}

.pl-130 {
  padding-left: 130px;
}

.pl-135 {
  padding-left: 135px;
}

.pl-140 {
  padding-left: 140px;
}

.pl-145 {
  padding-left: 145px;
}

.pl-150 {
  padding-left: 150px;
}

.pl-155 {
  padding-left: 155px;
}

.pl-160 {
  padding-left: 160px;
}

.pl-165 {
  padding-left: 165px;
}

.pl-170 {
  padding-left: 170px;
}

.pl-175 {
  padding-left: 175px;
}

.pl-180 {
  padding-left: 180px;
}

.pl-185 {
  padding-left: 185px;
}

.pl-190 {
  padding-left: 190px;
}

.pl-195 {
  padding-left: 195px;
}

.pl-200 {
  padding-left: 200px;
}

.pr-5 {
  padding-right: 5px;
}

.pr-10 {
  padding-right: 10px;
}

.pr-15 {
  padding-right: 15px;
}

.pr-20 {
  padding-right: 20px;
}

.pr-25 {
  padding-right: 25px;
}

.pr-30 {
  padding-right: 30px;
}

.pr-35 {
  padding-right: 35px;
}

.pr-40 {
  padding-right: 40px;
}

.pr-45 {
  padding-right: 45px;
}

.pr-50 {
  padding-right: 50px;
}

.pr-55 {
  padding-right: 55px;
}

.pr-60 {
  padding-right: 60px;
}

.pr-65 {
  padding-right: 65px;
}

.pr-70 {
  padding-right: 70px;
}

.pr-75 {
  padding-right: 75px;
}

.pr-80 {
  padding-right: 80px;
}

.pr-85 {
  padding-right: 85px;
}

.pr-90 {
  padding-right: 90px;
}

.pr-95 {
  padding-right: 95px;
}

.pr-100 {
  padding-right: 100px;
}

.pr-105 {
  padding-right: 105px;
}

.pr-110 {
  padding-right: 110px;
}

.pr-115 {
  padding-right: 115px;
}

.pr-120 {
  padding-right: 120px;
}

.pr-125 {
  padding-right: 125px;
}

.pr-130 {
  padding-right: 130px;
}

.pr-135 {
  padding-right: 135px;
}

.pr-140 {
  padding-right: 140px;
}

.pr-145 {
  padding-right: 145px;
}

.pr-150 {
  padding-right: 150px;
}

.pr-155 {
  padding-right: 155px;
}

.pr-160 {
  padding-right: 160px;
}

.pr-165 {
  padding-right: 165px;
}

.pr-170 {
  padding-right: 170px;
}

.pr-175 {
  padding-right: 175px;
}

.pr-180 {
  padding-right: 180px;
}

.pr-185 {
  padding-right: 185px;
}

.pr-190 {
  padding-right: 190px;
}

.pr-195 {
  padding-right: 195px;
}

.pr-200 {
  padding-right: 200px;
}

.container {
  padding-right: 15px;
  padding-left: 15px;
}

.row {
  --bs-gutter-x: 30px;
}
.row.g-0 {
  --bs-gutter-x: 0;
}
.row.g-15 {
  --bs-gutter-x: 15px;
}

@media (min-width: 1400px) {
  .container.container-custom-1 {
    max-width: 1600px;
    margin-left: auto;
    margin-right: auto;
  }
}
.container.container-custom-2 {
  max-width: calc(100% - 50px);
  margin-left: auto;
  margin-right: auto;
}
@media only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .container.container-custom-2 {
    max-width: 100%;
  }
}

@media (min-width: 1700px) {
  .container.container-custom-2 {
    max-width: 1660px;
    margin-left: auto;
    margin-right: auto;
  }
}
@media (min-width: 1700px) {
  .container.container-custom-3 {
    max-width: calc(100% - 320px);
    margin-left: auto;
    margin-right: auto;
  }
}
@media (min-width: 1600px) and (max-width: 1699px) {
  .container.container-custom-3 {
    max-width: calc(100% - 220px);
    margin-left: auto;
    margin-right: auto;
  }
}
@media (min-width: 1600px) {
  .container-fluid.container-custom-1 {
    max-width: 1600px;
    margin-left: auto;
    margin-right: auto;
  }
}
/* <<<<<<<    Declaration   >>>>>>>>> */
:root {
  /**
  @color declaration
  */
  --clr-common-white: #fff;
  --clr-common-black: #222222;
  --clr-common-lightBlack: #38383A;
  --clr-common-darkBlack: #000000;
  --clr-body-heading: #1E1E1E;
  --clr-body-text: rgba(30, 30, 30, 0.6);
  --clr-color-lightBlue: #BBE8EB;
  --clr-color-darkPink: #D2093C;
  --clr-color-lightPink: #F7ECF9;
  --clr-color-lightPurple: #F3F4FD;
  --clr-theme-primary: #1268EB;
  --clr-theme-primaryDark: #08449E;
  --clr-theme-primary-3: #F5530D;
  --clr-theme-primary-4: #F6BA2A;
  --clr-theme-primary-5: #F3EB1E;
  --clr-theme-primary-6: #B1040E;
  --clr-theme-primary-8: #395BDF;
  --clr-theme-primary-9: #1865F2;
  --clr-theme-primary-10: #4846AB;
  --clr-bg-gray: #F6F6F6;
  --clr-bg-gray-2: #F1F1F1;
}

.bg-default {
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}

.light-bg {
  background-color: #F8F8F8;
}

.no-after::after {
  display: none;
}

.p-relative {
  position: relative;
}

.theme-bg {
  background-color: var(--clr-theme-primary);
}

.swiper-wrapper.roll-slider {
  transition-timing-function: linear;
}

.pt-295 {
  padding-top: 295px;
}
@media (max-width: 767px) {
  .pt-295 {
    padding-top: 250px;
  }
}

/*
************
*************************
02. header
******************************************************* 
*************************************************************** */
.header-area {
  padding: 22px 0;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .header-area {
    padding: 17px 0;
  }
}
@media (max-width: 767px) {
  .header-area {
    padding: 12px 0;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .header-area {
    padding: 15px 0;
  }
}
.header-left {
  display: flex;
  align-items: center;
}
.header-right {
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content: end;
}
.header-logo {
  position: relative;
  z-index: 1;
  padding-right: 21px;
  margin-right: 20px;
}
.header-logo::after {
  position: absolute;
  content: "";
  top: 0;
  right: 0;
  background-color: rgba(30, 30, 30, 0.1);
  width: 1px;
  height: 100%;
}
@media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .header-logo {
    padding-right: 0;
  }
  .header-logo::after {
    display: none;
  }
}

.main-menu {
  width: auto;
}
.main-menu ul .menu-has-child {
  position: relative;
  z-index: 99;
  text-align: start;
}
.main-menu ul li {
  display: inline-block;
  margin-right: 45px;
}
.main-menu ul li:last-child {
  margin-right: 0;
}
.main-menu ul li a {
  font-size: 16px;
  color: var(--clr-body-heading);
  padding: 10px 0;
  font-weight: 500;
  display: block;
}
.main-menu ul li .submenu {
  position: absolute;
  left: 0;
  top: 100%;
  background: #fff;
  width: 240px;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: all 0.3s linear 0s;
  -moz-transition: all 0.3s linear 0s;
  -ms-transition: all 0.3s linear 0s;
  -o-transition: all 0.3s linear 0s;
  transition: all 0.3s linear 0s;
  box-shadow: 0 9px 19px rgba(0, 0, 0, 0.1);
}
.main-menu ul li .submenu li {
  display: block;
  margin-right: 0;
  position: relative;
  z-index: 99;
}
.main-menu ul li .submenu li a {
  padding: 10px 25px;
  color: var(--clr-body-heading);
  -webkit-transition: all 0.3s linear 0s;
  -moz-transition: all 0.3s linear 0s;
  -ms-transition: all 0.3s linear 0s;
  -o-transition: all 0.3s linear 0s;
  transition: all 0.3s linear 0s;
}
.main-menu ul li .submenu li .submenu {
  left: 100%;
  top: 0;
}
.main-menu ul li .submenu li:hover > a {
  background-color: var(--clr-theme-primary);
  color: #fff;
}
.main-menu ul li .submenu li:not(:last-child) {
  border-bottom: 1px solid #f2f2f2;
}
.main-menu ul li:hover > .submenu {
  opacity: 1;
  visibility: visible;
}
.main-menu ul li:hover > a {
  color: var(--clr-theme-primary);
}

.header-search form {
  position: relative;
  z-index: 1;
}
.header-search form input {
  min-width: 300px;
  width: 100%;
  height: 45px;
  line-height: 45px;
  background-color: #F5F5F5;
  border: none;
  border-radius: 6px;
  padding: 0 20px;
  padding-right: 55px;
  font-size: 15px;
  color: rgba(30, 30, 30, 0.6);
}
.header-search form input::-webkit-input-placeholder {
  color: rgba(30, 30, 30, 0.6);
  font-size: 15px;
  opacity: 1;
  -webkit-transition: all 0.5s linear 0s;
  -moz-transition: all 0.5s linear 0s;
  -ms-transition: all 0.5s linear 0s;
  -o-transition: all 0.5s linear 0s;
  transition: all 0.5s linear 0s;
}
.header-search form input:-moz-placeholder {
  color: rgba(30, 30, 30, 0.6);
  font-size: 15px;
  opacity: 1;
  -webkit-transition: all 0.5s linear 0s;
  -moz-transition: all 0.5s linear 0s;
  -ms-transition: all 0.5s linear 0s;
  -o-transition: all 0.5s linear 0s;
  transition: all 0.5s linear 0s;
}
.header-search form input::-moz-placeholder {
  color: rgba(30, 30, 30, 0.6);
  font-size: 15px;
  opacity: 1;
  -webkit-transition: all 0.5s linear 0s;
  -moz-transition: all 0.5s linear 0s;
  -ms-transition: all 0.5s linear 0s;
  -o-transition: all 0.5s linear 0s;
  transition: all 0.5s linear 0s;
}
.header-search form input:-ms-input-placeholder {
  color: rgba(30, 30, 30, 0.6);
  font-size: 15px;
  opacity: 1;
  -webkit-transition: all 0.5s linear 0s;
  -moz-transition: all 0.5s linear 0s;
  -ms-transition: all 0.5s linear 0s;
  -o-transition: all 0.5s linear 0s;
  transition: all 0.5s linear 0s;
}
.header-search form button {
  position: absolute;
  right: 20px;
  top: 0;
  height: 100%;
  font-size: 15px;
  color: #1E1E1E;
  border: none;
  background: transparent;
  padding: 0;
}
.header-search form button i {
  font-weight: 300;
}

.header-menu-bar-icon {
  font-size: 25px;
  display: block;
  color: var(--clr-body-heading);
  cursor: pointer;
  -webkit-transition: all 0.3s linear 0s;
  -moz-transition: all 0.3s linear 0s;
  -ms-transition: all 0.3s linear 0s;
  -o-transition: all 0.3s linear 0s;
  transition: all 0.3s linear 0s;
}
.header-menu-bar-icon:hover {
  color: var(--clr-theme-primary);
}

.h2_header-area {
  padding: 22px 0;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .h2_header-area {
    padding: 17px 0;
  }
}
@media (max-width: 767px) {
  .h2_header-area {
    padding: 12px 0;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .h2_header-area {
    padding: 15px 0;
  }
}
.h2_header-left {
  display: flex;
  align-items: center;
}
.h2_header-middle {
  text-align: center;
}
.h2_header-right {
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content: end;
}
.h2_header-logo {
  position: relative;
  z-index: 1;
  padding-right: 21px;
  margin-right: 20px;
}
.h2_header-logo::after {
  position: absolute;
  content: "";
  top: 0;
  right: 0;
  background-color: rgba(30, 30, 30, 0.1);
  width: 1px;
  height: 100%;
}
@media (max-width: 767px) {
  .h2_header-logo {
    margin-right: 10px;
    padding-right: 11px;
  }
  .h2_header-logo::after {
    display: none;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .h2_header-logo {
    margin-right: 15px;
    padding-right: 16px;
  }
  .h2_header-logo::after {
    display: block;
  }
}
.h2_header-category {
  position: relative;
  z-index: 9;
}
.h2_header-category a {
  color: var(--clr-body-heading);
  font-size: 16px;
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 15px 0;
}
.h2_header-category a span {
  display: block;
  font-size: 16px;
  line-height: 1;
  font-weight: 500;
  color: var(--clr-body-heading);
  -webkit-transition: all 0.3s linear 0s;
  -moz-transition: all 0.3s linear 0s;
  -ms-transition: all 0.3s linear 0s;
  -o-transition: all 0.3s linear 0s;
  transition: all 0.3s linear 0s;
}
.h2_header-category-submenu {
  position: absolute;
  left: 0;
  top: 100%;
  background: #fff;
  width: 240px;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: all 0.3s linear 0s;
  -moz-transition: all 0.3s linear 0s;
  -ms-transition: all 0.3s linear 0s;
  -o-transition: all 0.3s linear 0s;
  transition: all 0.3s linear 0s;
  box-shadow: 0 9px 19px rgba(0, 0, 0, 0.1);
  z-index: 9999;
}
.h2_header-category-submenu li {
  display: block;
  margin-right: 0;
  position: relative;
  z-index: 99;
}
.h2_header-category-submenu li a {
  padding: 15px 25px;
  display: block;
  color: var(--clr-body-heading);
  -webkit-transition: all 0.3s linear 0s;
  -moz-transition: all 0.3s linear 0s;
  -ms-transition: all 0.3s linear 0s;
  -o-transition: all 0.3s linear 0s;
  transition: all 0.3s linear 0s;
}
.h2_header-category-submenu li .submenu {
  left: 100%;
  top: 0;
}
.h2_header-category-submenu li:hover > a {
  background-color: var(--clr-theme-primary);
  color: #fff;
}
.h2_header-category-submenu li:not(:last-child) {
  border-bottom: 1px solid #f2f2f2;
}
.h2_header-category:hover > .h2_header-category-submenu {
  opacity: 1;
  visibility: visible;
}
.h2_header-category:hover > a {
  color: var(--clr-theme-primary);
}
.h2_header-category:hover > a span {
  color: var(--clr-theme-primary);
}

.h2_header-area.sticky {
  box-shadow: 0px 6px 50px rgba(30, 30, 30, 0.1);
}

.h2_main-menu ul .menu-has-child {
  position: relative;
  z-index: 99;
  text-align: start;
}
.h2_main-menu ul li {
  display: inline-block;
  margin-right: 45px;
}
.h2_main-menu ul li:last-child {
  margin-right: 0;
}
.h2_main-menu ul li:is(.menu-has-child) > a::after {
  display: inline-block;
  font-family: "Font Awesome 6 Pro";
  content: "\f107";
  font-size: 12px;
  font-weight: 500;
  margin-left: 5px;
}
.h2_main-menu ul li a {
  font-size: 16px;
  color: var(--clr-body-heading);
  padding: 10px 0;
  font-weight: 500;
  display: block;
}
.h2_main-menu ul li .submenu {
  position: absolute;
  left: 0;
  top: 100%;
  background: #fff;
  width: 240px;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: all 0.3s linear 0s;
  -moz-transition: all 0.3s linear 0s;
  -ms-transition: all 0.3s linear 0s;
  -o-transition: all 0.3s linear 0s;
  transition: all 0.3s linear 0s;
  box-shadow: 0 9px 19px rgba(0, 0, 0, 0.1);
}
.h2_main-menu ul li .submenu li {
  display: block;
  margin-right: 0;
  position: relative;
  z-index: 99;
}
.h2_main-menu ul li .submenu li a {
  padding: 10px 25px;
  color: var(--clr-body-heading);
  -webkit-transition: all 0.4s linear 0s;
  -moz-transition: all 0.4s linear 0s;
  -ms-transition: all 0.4s linear 0s;
  -o-transition: all 0.4s linear 0s;
  transition: all 0.4s linear 0s;
}
.h2_main-menu ul li .submenu li .submenu {
  left: 100%;
  top: 0;
}
.h2_main-menu ul li .submenu li:hover > a {
  background-color: var(--clr-theme-primary);
  color: #fff;
}
.h2_main-menu ul li .submenu li:not(:last-child) {
  border-bottom: 1px solid #f2f2f2;
}
.h2_main-menu ul li:hover > .submenu {
  opacity: 1;
  visibility: visible;
}
.h2_main-menu ul li:hover > a {
  color: var(--clr-theme-primary);
}

.h3_header-area {
  padding: 16px 0;
}
.h3_header-middle {
  text-align: center;
}
.h3_header-right {
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content: end;
}
.h3_header-logo {
  position: relative;
  z-index: 1;
  padding-right: 21px;
  margin-right: 20px;
}
@media (max-width: 767px) {
  .h3_header-logo {
    margin-right: 10px;
    padding-right: 11px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .h3_header-logo {
    margin-right: 15px;
    padding-right: 16px;
  }
}

.h3_header-area.sticky {
  box-shadow: 0px 6px 50px rgba(30, 30, 30, 0.1);
}

.h3_main-menu ul .menu-has-child {
  position: relative;
  z-index: 99;
  text-align: start;
}
.h3_main-menu ul li {
  display: inline-block;
  margin-right: 45px;
}
.h3_main-menu ul li:last-child {
  margin-right: 0;
}
.h3_main-menu ul li:is(.menu-has-child) > a::after {
  display: inline-block;
  font-family: "Font Awesome 6 Pro";
  content: "\f107";
  font-size: 12px;
  font-weight: 500;
  margin-left: 5px;
}
.h3_main-menu ul li a {
  font-size: 16px;
  color: var(--clr-body-heading);
  padding: 10px 0;
  font-weight: 500;
  display: block;
}
.h3_main-menu ul li .submenu {
  position: absolute;
  left: 0;
  top: 100%;
  background: #fff;
  width: 240px;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: all 0.3s linear 0s;
  -moz-transition: all 0.3s linear 0s;
  -ms-transition: all 0.3s linear 0s;
  -o-transition: all 0.3s linear 0s;
  transition: all 0.3s linear 0s;
  box-shadow: 0 9px 19px rgba(0, 0, 0, 0.1);
}
.h3_main-menu ul li .submenu li {
  display: block;
  margin-right: 0;
  position: relative;
  z-index: 99;
}
.h3_main-menu ul li .submenu li a {
  padding: 10px 25px;
  color: var(--clr-body-heading);
  -webkit-transition: all 0.4s linear 0s;
  -moz-transition: all 0.4s linear 0s;
  -ms-transition: all 0.4s linear 0s;
  -o-transition: all 0.4s linear 0s;
  transition: all 0.4s linear 0s;
}
.h3_main-menu ul li .submenu li .submenu {
  left: 100%;
  top: 0;
}
.h3_main-menu ul li .submenu li:hover > a {
  background-color: var(--clr-theme-primary-3);
  color: #fff;
}
.h3_main-menu ul li .submenu li:not(:last-child) {
  border-bottom: 1px solid #f2f2f2;
}
.h3_main-menu ul li:hover > .submenu {
  opacity: 1;
  visibility: visible;
}
.h3_main-menu ul li:hover > a {
  color: var(--clr-theme-primary-3);
}

.h4_main-menu ul li .submenu li:hover > a {
  background-color: var(--clr-theme-primary-4);
  color: var(--clr-body-heading);
}
.h4_main-menu ul li:hover > a {
  color: var(--clr-body-heading);
}

.h4_header-category-submenu li:hover > a {
  background-color: var(--clr-theme-primary-4);
  color: var(--clr-body-heading);
}
.h4_header-category:hover > a {
  color: var(--clr-body-heading);
}
.h4_header-category:hover > a span {
  color: var(--clr-body-heading);
}

.h5_main-menu ul li .submenu li:hover > a {
  background-color: var(--clr-theme-primary-5);
  color: var(--clr-body-heading);
}
.h5_main-menu ul li:hover > a {
  color: var(--clr-body-heading);
}

.header-sticky.sticky {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background: #ffffff;
  z-index: 99;
  animation: 300ms ease-in-out 0s normal none 1 running stickySlideDown;
  display: block;
  box-shadow: 0px 6px 50px rgba(30, 30, 30, 0.1);
}

@keyframes stickySlideDown {
  0% {
    transform: translateY(-100%);
  }
  to {
    transform: translateY(0);
  }
}
.h6_header-area {
  padding: 16px 0;
  background: linear-gradient(180deg, rgba(217, 217, 217, 0.05) 0%, rgba(217, 217, 217, 0.05) 100%);
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 9;
}
.h6_header-middle {
  text-align: center;
}
.h6_header-right {
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content: end;
}
.h6_header-logo {
  position: relative;
  z-index: 1;
  padding-right: 21px;
  margin-right: 20px;
}
@media (max-width: 767px) {
  .h6_header-logo {
    margin-right: 10px;
    padding-right: 11px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .h6_header-logo {
    margin-right: 15px;
    padding-right: 16px;
  }
}

.h6_header-area.sticky {
  background-color: var(--clr-body-heading);
}

.h6_main-menu ul .menu-has-child {
  position: relative;
  z-index: 99;
  text-align: start;
}
.h6_main-menu ul li {
  display: inline-block;
  margin-right: 45px;
}
.h6_main-menu ul li:last-child {
  margin-right: 0;
}
.h6_main-menu ul li:is(.menu-has-child) > a::after {
  display: inline-block;
  font-family: "Font Awesome 6 Pro";
  content: "\f107";
  font-size: 12px;
  font-weight: 500;
  margin-left: 5px;
}
.h6_main-menu ul li a {
  font-size: 16px;
  color: var(--clr-common-white);
  padding: 10px 0;
  font-weight: 500;
  display: block;
}
.h6_main-menu ul li .submenu {
  position: absolute;
  left: 0;
  top: 100%;
  background: #fff;
  width: 240px;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: all 0.3s linear 0s;
  -moz-transition: all 0.3s linear 0s;
  -ms-transition: all 0.3s linear 0s;
  -o-transition: all 0.3s linear 0s;
  transition: all 0.3s linear 0s;
  box-shadow: 0 9px 19px rgba(0, 0, 0, 0.1);
}
.h6_main-menu ul li .submenu li {
  display: block;
  margin-right: 0;
  position: relative;
  z-index: 99;
}
.h6_main-menu ul li .submenu li a {
  padding: 10px 25px;
  color: var(--clr-body-heading);
  -webkit-transition: all 0.4s linear 0s;
  -moz-transition: all 0.4s linear 0s;
  -ms-transition: all 0.4s linear 0s;
  -o-transition: all 0.4s linear 0s;
  transition: all 0.4s linear 0s;
}
.h6_main-menu ul li .submenu li .submenu {
  left: 100%;
  top: 0;
}
.h6_main-menu ul li .submenu li:hover > a {
  background-color: var(--clr-theme-primary-6);
  color: #fff;
}
.h6_main-menu ul li .submenu li:not(:last-child) {
  border-bottom: 1px solid #f2f2f2;
}
.h6_main-menu ul li:hover > .submenu {
  opacity: 1;
  visibility: visible;
}
.h6_main-menu ul li:hover > a {
  color: var(--clr-theme-primary-3);
}

.h6_menu-bar .header-menu-bar-icon {
  color: #fff;
}

.h7_header-search form {
  position: relative;
  z-index: 1;
}
.h7_header-search form input {
  min-width: 300px;
  width: 100%;
  height: 45px;
  line-height: 45px;
  border: none;
  border-radius: 6px;
  padding: 0 20px;
  padding-right: 55px;
  font-size: 15px;
  color: rgba(30, 30, 30, 0.6);
  border-radius: 4px;
  background: rgba(44, 44, 44, 0.06);
}
.h7_header-search form input::-webkit-input-placeholder {
  color: rgba(30, 30, 30, 0.6);
  font-size: 15px;
  opacity: 1;
  -webkit-transition: all 0.5s linear 0s;
  -moz-transition: all 0.5s linear 0s;
  -ms-transition: all 0.5s linear 0s;
  -o-transition: all 0.5s linear 0s;
  transition: all 0.5s linear 0s;
}
.h7_header-search form input:-moz-placeholder {
  color: rgba(30, 30, 30, 0.6);
  font-size: 15px;
  opacity: 1;
  -webkit-transition: all 0.5s linear 0s;
  -moz-transition: all 0.5s linear 0s;
  -ms-transition: all 0.5s linear 0s;
  -o-transition: all 0.5s linear 0s;
  transition: all 0.5s linear 0s;
}
.h7_header-search form input::-moz-placeholder {
  color: rgba(30, 30, 30, 0.6);
  font-size: 15px;
  opacity: 1;
  -webkit-transition: all 0.5s linear 0s;
  -moz-transition: all 0.5s linear 0s;
  -ms-transition: all 0.5s linear 0s;
  -o-transition: all 0.5s linear 0s;
  transition: all 0.5s linear 0s;
}
.h7_header-search form input:-ms-input-placeholder {
  color: rgba(30, 30, 30, 0.6);
  font-size: 15px;
  opacity: 1;
  -webkit-transition: all 0.5s linear 0s;
  -moz-transition: all 0.5s linear 0s;
  -ms-transition: all 0.5s linear 0s;
  -o-transition: all 0.5s linear 0s;
  transition: all 0.5s linear 0s;
}
.h7_header-search form button {
  position: absolute;
  right: 20px;
  top: 0;
  height: 100%;
  font-size: 15px;
  color: #2C2C2C;
  border: none;
  background: transparent;
  padding: 0;
}
.h7_header-search form button i {
  font-weight: 300;
}
.h7_header-left {
  display: flex;
  align-items: center;
  gap: 90px;
  height: 80px;
}
.h7_header-right {
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content: end;
}
.h7_header-logo {
  position: relative;
  z-index: 1;
}
.h7_header-btn {
  display: flex;
  align-items: center;
  gap: 8px;
  border-radius: 4px;
  padding: 0 30px;
  color: #fff;
  font-size: 16px;
  height: 45px;
  font-weight: 600;
  background-color: var(--clr-theme-primary-6);
}
.h7_header-btn svg {
  color: #fff;
}

.h7_main-menu ul .menu-has-child {
  position: relative;
  z-index: 99;
  text-align: start;
}
.h7_main-menu ul li {
  display: inline-block;
  margin-right: 40px;
}
.h7_main-menu ul li:last-child {
  margin-right: 0;
}
.h7_main-menu ul li:is(.menu-has-child) > a::after {
  display: inline-block;
  font-family: "Font Awesome 6 Pro";
  content: "\f107";
  font-size: 12px;
  font-weight: 500;
  margin-left: 5px;
}
.h7_main-menu ul li a {
  font-size: 16px;
  color: #2C2C2C;
  padding: 10px 0;
  font-weight: 500;
  display: block;
}
.h7_main-menu ul li .submenu {
  position: absolute;
  left: 0;
  top: 100%;
  background: #fff;
  width: 240px;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: all 0.3s linear 0s;
  -moz-transition: all 0.3s linear 0s;
  -ms-transition: all 0.3s linear 0s;
  -o-transition: all 0.3s linear 0s;
  transition: all 0.3s linear 0s;
  box-shadow: 0 9px 19px rgba(0, 0, 0, 0.1);
}
.h7_main-menu ul li .submenu li {
  display: block;
  margin-right: 0;
  position: relative;
  z-index: 99;
}
.h7_main-menu ul li .submenu li a {
  padding: 10px 25px;
  color: var(--clr-body-heading);
  -webkit-transition: all 0.4s linear 0s;
  -moz-transition: all 0.4s linear 0s;
  -ms-transition: all 0.4s linear 0s;
  -o-transition: all 0.4s linear 0s;
  transition: all 0.4s linear 0s;
}
.h7_main-menu ul li .submenu li .submenu {
  left: 100%;
  top: 0;
}
.h7_main-menu ul li .submenu li:hover > a {
  background-color: var(--clr-theme-primary-6);
  color: #fff;
}
.h7_main-menu ul li .submenu li:not(:last-child) {
  border-bottom: 1px solid #f2f2f2;
}
.h7_main-menu ul li:hover > .submenu {
  opacity: 1;
  visibility: visible;
}
.h7_main-menu ul li:hover > a {
  color: var(--clr-theme-primary-3);
}

.h7_menu-bar .header-menu-bar-icon {
  color: var(--clr-body-heading);
}

.h7_header-top {
  background: var(--clr-theme-primary-6);
  height: 35px;
  line-height: 35px;
}
.h7_header-top-list {
  display: flex;
  align-items: center;
  gap: 10px;
}
.h7_header-top-list li {
  position: relative;
  z-index: 1;
}
.h7_header-top-list li:not(:last-child) {
  margin-right: 15px;
  padding-right: 25px;
}
.h7_header-top-list li:not(:last-child)::before {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 1px;
  background: rgba(255, 255, 255, 0.2);
  height: 16px;
  content: "";
  z-index: 1;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .h7_header-top-list li:not(:last-child) {
    margin-right: 0;
    padding-right: 10px;
  }
}
.h7_header-top-list li a {
  display: flex;
  align-items: center;
  gap: 10px;
  color: #fff;
  font-size: 14px;
  font-weight: 500;
}
.h7_header-top-social ul {
  display: flex;
  align-items: center;
  gap: 20px;
  justify-content: end;
}
.h7_header-top-social ul li a {
  color: rgba(255, 255, 255, 0.3);
  font-size: 15px;
}
.h7_header-top-social ul li a:hover {
  color: #fff;
}

.inner_page_header-area {
  position: relative;
  z-index: 9;
  box-shadow: 0px 6px 50px rgba(30, 30, 30, 0.1);
}

.h8_header-top {
  background: #021936;
  height: 40px;
  line-height: 40px;
}
.h8_header-top-text {
  margin-bottom: 0;
  color: hsla(0, 0%, 100%, 0.8);
}
.h8_header-top-text span {
  color: #F14D5D;
}
.h8_header-top-social ul {
  display: flex;
  align-items: center;
  justify-content: end;
}
.h8_header-top-social ul li a {
  color: #fff;
  font-size: 15px;
  position: relative;
  z-index: 1;
  padding-left: 9px;
  margin-left: 9px;
}
.h8_header-top-social ul li a::before {
  position: absolute;
  left: 0;
  top: 50%;
  content: "";
  transform: translateY(-50%);
  background-color: rgba(255, 255, 255, 0.2);
  width: 1px;
  height: 16px;
}
.h8_header-top-social ul li a:hover {
  color: #fff;
}
.h8_header-top-social ul li:first-child a::before {
  display: none;
}

.h8_header-search form {
  position: relative;
  z-index: 1;
}
.h8_header-search form input {
  min-width: 300px;
  width: 100%;
  height: 45px;
  line-height: 45px;
  border: none;
  border-radius: 6px;
  padding: 0 20px;
  padding-right: 55px;
  font-size: 15px;
  color: rgba(30, 30, 30, 0.6);
  border-radius: 4px;
  background: rgba(44, 44, 44, 0.06);
}
.h8_header-search form input::-webkit-input-placeholder {
  color: rgba(30, 30, 30, 0.6);
  font-size: 15px;
  opacity: 1;
  -webkit-transition: all 0.5s linear 0s;
  -moz-transition: all 0.5s linear 0s;
  -ms-transition: all 0.5s linear 0s;
  -o-transition: all 0.5s linear 0s;
  transition: all 0.5s linear 0s;
}
.h8_header-search form input:-moz-placeholder {
  color: rgba(30, 30, 30, 0.6);
  font-size: 15px;
  opacity: 1;
  -webkit-transition: all 0.5s linear 0s;
  -moz-transition: all 0.5s linear 0s;
  -ms-transition: all 0.5s linear 0s;
  -o-transition: all 0.5s linear 0s;
  transition: all 0.5s linear 0s;
}
.h8_header-search form input::-moz-placeholder {
  color: rgba(30, 30, 30, 0.6);
  font-size: 15px;
  opacity: 1;
  -webkit-transition: all 0.5s linear 0s;
  -moz-transition: all 0.5s linear 0s;
  -ms-transition: all 0.5s linear 0s;
  -o-transition: all 0.5s linear 0s;
  transition: all 0.5s linear 0s;
}
.h8_header-search form input:-ms-input-placeholder {
  color: rgba(30, 30, 30, 0.6);
  font-size: 15px;
  opacity: 1;
  -webkit-transition: all 0.5s linear 0s;
  -moz-transition: all 0.5s linear 0s;
  -ms-transition: all 0.5s linear 0s;
  -o-transition: all 0.5s linear 0s;
  transition: all 0.5s linear 0s;
}
.h8_header-search form button {
  position: absolute;
  right: 20px;
  top: 0;
  height: 100%;
  font-size: 15px;
  color: #2C2C2C;
  border: none;
  background: transparent;
  padding: 0;
}
.h8_header-search form button i {
  font-weight: 300;
}
.h8_header-left {
  display: flex;
  align-items: center;
  gap: 75px;
  height: 80px;
}
.h8_header-right {
  display: flex;
  align-items: center;
  gap: 20px;
  justify-content: end;
}
.h8_header-action {
  display: flex;
  align-items: center;
  gap: 20px;
}
.h8_header-login {
  color: #021936;
  font-size: 14px;
  font-weight: 500;
  display: flex;
  align-items: center;
  gap: 6px;
}
.h8_header-login svg {
  color: #021936;
}
.h8_header-search {
  color: #021936;
}
.h8_header-logo {
  position: relative;
  z-index: 1;
}
.h8_header-btn {
  height: 45px !important;
}

.h8_main-menu ul .menu-has-child {
  position: relative;
  z-index: 99;
  text-align: start;
}
.h8_main-menu ul li {
  display: inline-block;
  margin-right: 40px;
}
.h8_main-menu ul li:last-child {
  margin-right: 0;
}
.h8_main-menu ul li:is(.menu-has-child) > a::after {
  display: inline-block;
  font-family: "Font Awesome 6 Pro";
  content: "+";
  font-size: 12px;
  font-weight: 500;
  margin-left: 5px;
}
.h8_main-menu ul li a {
  font-size: 16px;
  color: #2C2C2C;
  padding: 10px 0;
  font-weight: 500;
  display: block;
}
.h8_main-menu ul li .submenu {
  position: absolute;
  left: 0;
  top: 100%;
  background: #fff;
  width: 240px;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: all 0.3s linear 0s;
  -moz-transition: all 0.3s linear 0s;
  -ms-transition: all 0.3s linear 0s;
  -o-transition: all 0.3s linear 0s;
  transition: all 0.3s linear 0s;
  box-shadow: 0 9px 19px rgba(0, 0, 0, 0.1);
}
.h8_main-menu ul li .submenu li {
  display: block;
  margin-right: 0;
  position: relative;
  z-index: 99;
}
.h8_main-menu ul li .submenu li a {
  padding: 10px 25px;
  color: var(--clr-body-heading);
  -webkit-transition: all 0.4s linear 0s;
  -moz-transition: all 0.4s linear 0s;
  -ms-transition: all 0.4s linear 0s;
  -o-transition: all 0.4s linear 0s;
  transition: all 0.4s linear 0s;
}
.h8_main-menu ul li .submenu li .submenu {
  left: 100%;
  top: 0;
}
.h8_main-menu ul li .submenu li:hover > a {
  background-color: var(--clr-theme-primary-6);
  color: #fff;
}
.h8_main-menu ul li .submenu li:not(:last-child) {
  border-bottom: 1px solid #f2f2f2;
}
.h8_main-menu ul li:hover > .submenu {
  opacity: 1;
  visibility: visible;
}
.h8_main-menu ul li:hover > a {
  color: var(--clr-theme-primary-3);
}

.h8_menu-bar .header-menu-bar-icon {
  color: var(--clr-body-heading);
}

.h9_header-button-text {
  color: #21242C;
  font-size: 16px;
  font-weight: 500;
  display: flex;
  align-items: center;
  gap: 10px;
}
.h9_header-button:hover > a {
  color: var(--clr-theme-primary-9);
}
.h9_header-button .h2_header-category-submenu li:hover > a {
  background-color: var(--clr-theme-primary-9);
  color: #fff;
}

.h9_header-search form {
  position: relative;
  z-index: 1;
}
.h9_header-search form input {
  min-width: 300px;
  width: 100%;
  height: 45px;
  line-height: 45px;
  padding: 0 15px;
  padding-right: 45px;
  color: rgba(33, 36, 44, 0.6);
  font-size: 13px;
  font-weight: 400;
  border-radius: 6px;
  border: 1.5px solid #1865F2;
  background: #F5F5F5;
}
.h9_header-search form input::-webkit-input-placeholder {
  color: rgba(33, 36, 44, 0.6);
  font-size: 13px;
  opacity: 1;
}
.h9_header-search form input:-moz-placeholder {
  color: rgba(33, 36, 44, 0.6);
  font-size: 13px;
  opacity: 1;
}
.h9_header-search form input::-moz-placeholder {
  color: rgba(33, 36, 44, 0.6);
  font-size: 13px;
  opacity: 1;
}
.h9_header-search form input:-ms-input-placeholder {
  color: rgba(33, 36, 44, 0.6);
  font-size: 13px;
  opacity: 1;
}
.h9_header-search form button {
  position: absolute;
  right: 15px;
  top: 0;
  height: 100%;
  font-size: 14px;
  border: none;
  background: transparent;
  padding: 0;
}
.h9_header-search form button svg {
  font-weight: 300;
  color: #21242C;
}
.h9_header-left {
  display: flex;
  align-items: center;
  gap: 20px;
  height: 90px;
}
.h9_header-right {
  display: flex;
  align-items: center;
  gap: 30px;
  justify-content: end;
}
.h9_header-logo {
  position: relative;
  z-index: 1;
}
@media (max-width: 767px) {
  .h9_header-logo {
    height: 70px;
    line-height: 70px;
  }
}
.h9_header-btn {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 0 30px !important;
  height: 45px !important;
}
.h9_header-btn svg {
  color: #fff;
}

.h9_header-right-text ul {
  display: flex;
  align-items: center;
  gap: 55px;
}

.h9_header-right-text ul li a {
  color: #21242C;
  font-size: 16px;
  font-weight: 500;
  display: flex;
  align-items: center;
  gap: 10px;
}

/*
************
*************************
03. sidebar
******************************************************* 
*************************************************************** */
.side-info {
  width: 100%;
  max-width: 400px;
  background-color: #000;
  box-shadow: 0 13px 29px rgba(4, 0, 23, 0.1);
  height: 100%;
  position: fixed;
  z-index: 999;
  top: 0;
  right: -400px;
  -webkit-transition: all 0.4s linear 0s;
  -moz-transition: all 0.4s linear 0s;
  -ms-transition: all 0.4s linear 0s;
  -o-transition: all 0.4s linear 0s;
  transition: all 0.4s linear 0s;
  padding: 30px 40px 40px 40px;
}

.side-info:is(.info-open) {
  right: 0;
  overflow-y: scroll;
  scrollbar-width: thin;
}

.offcanvas-overlay {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  content: "";
  background-color: rgba(4, 0, 23, 0.5);
  z-index: 9;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: all 0.4s linear 0s;
  -moz-transition: all 0.4s linear 0s;
  -ms-transition: all 0.4s linear 0s;
  -o-transition: all 0.4s linear 0s;
  transition: all 0.4s linear 0s;
  cursor: url(../../assets/img/bg/close.png), auto;
}

.offcanvas-overlay:is(.overlay-open) {
  left: 0;
  opacity: 1;
  visibility: visible;
}

.mean-container .mean-nav {
  background: transparent;
  margin-top: 0;
}

.mean-container .mean-nav ul li a {
  border: none;
  font-size: 16px;
  padding-left: 0;
  padding-right: 0;
  color: #fff;
}

.mean-container .mean-nav ul li:hover > a {
  background: transparent;
  color: var(--clr-theme-primary);
}

.pl-sidebar-close.side-info-close {
  background: transparent;
  border: none;
  font-size: 25px;
  color: #fff;
}

.mean-container .mean-nav ul li a.mean-expand {
  padding: 8px 0;
  border: none;
  background-color: transparent;
  color: #fff;
  width: auto;
}

.mean-container .mean-nav ul li a.mean-expand:hover {
  background: transparent;
}

.sidebar-close {
  border: 0;
  background-color: transparent;
  font-size: 25px;
  color: #fff;
  padding-left: 0;
  padding-right: 0;
}

.sidebar-close:hover {
  color: #fff;
}

/*
************
*************************
04. banner
******************************************************* 
*************************************************************** */
.single-banner {
  min-height: 670px;
  display: flex;
  align-items: end;
  position: relative;
  z-index: 1;
  background-color: var(--clr-color-lightBlue);
  border-radius: 20px;
  overflow: hidden;
}
@media only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .single-banner {
    align-items: center;
  }
}

.banner-img {
  margin-right: -75px;
  margin-left: 30px;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .banner-img {
    margin-right: -40px;
    margin-left: 0;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .banner-img {
    margin-left: 0;
    margin-right: -20px;
  }
}

.banner-content {
  padding-bottom: 45px;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .banner-content {
    padding-bottom: 135px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .banner-content {
    padding-bottom: 160px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .banner-content {
    padding-bottom: 0;
  }
}

.banner-top-shape-1 {
  position: absolute;
  left: 85px;
  top: 60px;
  animation: animation-leftRight-1 3s linear 0s infinite alternate;
}
@media (max-width: 767px) {
  .banner-top-shape-1 {
    left: 30px;
  }
}
.banner-top-shape-2 {
  right: 50px;
  bottom: 150px;
  position: absolute;
  z-index: 1;
  animation: animation-popup-2 1.2s linear 0s infinite alternate;
}
@media only screen and (min-width: 1400px) and (max-width: 1599px) {
  .banner-top-shape-2 {
    right: 20px;
    bottom: 140px;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .banner-top-shape-2 {
    right: 20px;
    bottom: 110px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .banner-top-shape-2 {
    right: 20px;
    bottom: 100px;
  }
}
@media (max-width: 767px) {
  .banner-top-shape-2 {
    right: 30px;
    bottom: 70px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .banner-top-shape-2 {
    right: 50px;
    bottom: 100px;
  }
}
.banner-top-shape-3 {
  position: absolute;
  top: 125px;
  right: 33%;
  z-index: 1;
  animation: hero-circle-1 6s linear 0s infinite;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .banner-top-shape-3 {
    top: 110px;
    right: 140px;
  }
}
@media (max-width: 767px) {
  .banner-top-shape-3 {
    right: 15%;
    top: 110px;
  }
}

.banner-right {
  position: relative;
  z-index: 1;
}
.banner-inner-meta {
  position: absolute;
}
.banner-inner-info {
  display: flex;
  gap: 20px;
}
.banner-meta-info {
  background-color: #fff;
  padding: 25px 30px 25px;
  bottom: 120px;
  left: -30px;
  border-radius: 6px;
  box-shadow: 0px 10px 50px rgba(30, 30, 30, 0.1);
}
.banner-meta-info img.inner-img {
  position: absolute;
  left: -35px;
  top: -35px;
  z-index: 1;
  animation: animation-popup-5 3s linear 0s infinite alternate;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .banner-meta-info {
    bottom: 90px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .banner-meta-info {
    display: none;
  }
}
.banner-info-img {
  width: 45px;
  height: 45px;
  flex-shrink: 0;
}
.banner-info-img img {
  width: 45px;
  height: 45px;
}
.banner-info-icon {
  position: absolute;
  width: 25px;
  height: 25px;
  background: #FFA121;
  font-size: 12px;
  display: grid;
  place-items: center;
  border-radius: 50%;
  color: #fff;
  top: 50px;
  left: 60px;
}
.banner-info-text h5 {
  font-size: 18px;
  margin-bottom: 5px;
  font-weight: 500;
}
.banner-info-text p {
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 0;
}
.banner-meta-rating {
  position: absolute;
  right: -40px;
  top: 200px;
  background: #fff;
  border-radius: 10px;
  padding: 25px 30px 25px;
  box-shadow: 0px 10px 50px rgba(30, 30, 30, 0.1);
  animation: hero-upDown-1 3s linear 0s infinite alternate;
}
.banner-meta-rating span {
  font-size: 15px;
  display: flex;
  font-weight: 500;
  align-items: center;
  gap: 5px;
  margin-bottom: 5px;
}
.banner-meta-rating span i {
  color: #FFA121;
  font-size: 15px;
}
.banner-meta-rating h5 {
  font-size: 18px;
  margin-bottom: 0;
  font-weight: 500;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .banner-meta-rating {
    right: -30px;
    top: 160px;
  }
}

.banner-right-shape img {
  position: absolute;
  z-index: -1;
}
.banner-right-shape .banner-shape-4 {
  top: 35px;
  right: 70px;
  animation: animation-popup-2 1.2s linear 0s infinite alternate;
}
.banner-right-shape .banner-shape-1 {
  right: -80px;
  top: 165px;
  animation: hero-circle-1 6s linear 0s infinite;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .banner-right-shape .banner-shape-1 {
    right: -20px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .banner-right-shape .banner-shape-1 {
    right: 0;
    top: 150px;
  }
}
.banner-right-shape .banner-shape-2 {
  bottom: 80px;
  left: 55px;
  z-index: 1;
  animation: animation-upDown-2 3s linear 0s infinite alternate;
}

@keyframes hero-circle-1 {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes hero-popup-1 {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(0.5);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes animation-popup-5 {
  0% {
    transform: scale(0.2) rotate(0deg);
  }
  40% {
    transform: scale(0.5) rotate(180deg);
  }
  80% {
    transform: scale(1) rotate(360deg);
  }
  100% {
    transform: scale(1) rotate(360deg);
  }
}
@keyframes hero-upDown-1 {
  0% {
    transform: translateY(-20px);
  }
  100% {
    transform: translateY(20px);
  }
}
@keyframes hero-leftRight-1 {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(40px);
  }
}
@keyframes animation-upDown-2 {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(30px);
  }
}
.h2_single-banner {
  background-color: var(--clr-color-lightPurple);
  min-height: 730px;
  display: flex;
  align-items: center;
}

.h2_banner-form {
  max-width: 550px;
  position: relative;
  z-index: 1;
  margin-bottom: 15px;
}
.h2_banner-form input {
  width: 100%;
  height: 55px;
  line-height: 55px;
  padding: 0 20px;
  padding-right: 55px;
  font-size: 15px;
  background: #fff;
  border: none;
  border-radius: 6px;
  position: relative;
  z-index: 1;
  border-left: 2px solid var(--clr-theme-primary);
  box-shadow: 0px 6px 30px rgba(30, 30, 30, 0.1), inset 2px 0px 0px #1E40EC;
}
.h2_banner-form button {
  position: absolute;
  top: 0;
  color: var(--clr-body-heading);
  font-size: 16px;
  z-index: 99;
  background: transparent;
  height: 100%;
  padding: 0;
  border: 0;
  right: 20px;
}
.h2_banner-form button i {
  font-weight: 300;
}
.h2_banner-content-text {
  display: block;
  font-size: 14px;
  color: var(--clr-body-heading);
  margin-bottom: 25px;
  line-height: 22px;
}
.h2_banner-content-text a {
  color: var(--clr-theme-primary);
}
.h2_banner-right {
  position: relative;
  z-index: 1;
}
@media only screen and (min-width: 1400px) and (max-width: 1599px) {
  .h2_banner-right {
    padding-left: 60px;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .h2_banner-right {
    padding-left: 30px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .h2_banner-right {
    padding-left: 0;
  }
}
.h2_banner-inner-img {
  position: relative;
  z-index: 1;
  margin-left: -15px;
  width: 45px;
  height: 45px;
  border-radius: 50%;
  overflow: hidden;
}
.h2_banner-inner-img:first-child {
  margin-left: 0;
}
.h2_banner-inner-img:last-child::before {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(30, 30, 30, 0.6);
  content: "";
  border-radius: 50%;
}
.h2_banner-inner-img span {
  position: absolute;
  left: 0;
  top: 0;
  display: grid;
  place-items: center;
  width: 100%;
  height: 100%;
  color: #fff;
  font-weight: 600;
  font-size: 14px;
}
.h2_banner-tutor {
  display: flex;
  align-items: center;
  gap: 10px;
}
.h2_banner-tutor-img {
  display: flex;
}
.h2_banner-tutor-text span {
  display: block;
  font-size: 15px;
  color: var(--clr-body-heading);
  font-weight: 600;
  line-height: 20px;
}
.h2_banner-meta-rating {
  position: absolute;
  left: 0;
  bottom: 0;
  background: #fff;
  border-radius: 10px;
  padding: 25px 30px 25px;
  box-shadow: 0px 16px 70px rgba(30, 30, 30, 0.14);
  animation: h2_hero-leftRight-1 4.5s linear 0s infinite alternate;
}
.h2_banner-meta-rating span {
  font-size: 15px;
  display: flex;
  font-weight: 500;
  align-items: center;
  gap: 5px;
  margin-bottom: 5px;
}
.h2_banner-meta-rating span i {
  color: #FFA121;
  font-size: 15px;
}
.h2_banner-meta-rating h5 {
  font-size: 18px;
  margin-bottom: 0;
  font-weight: 500;
}
.h2_banner-meta-info {
  position: absolute;
  right: -90px;
  top: 155px;
  background: #fff;
  border-radius: 10px;
  padding: 20px 25px 20px;
  box-shadow: 0px 16px 70px rgba(30, 30, 30, 0.14);
  animation: h2_hero-upDown-1 4s linear 0s infinite alternate;
}
.h2_banner-meta-info span {
  font-size: 14px;
  font-weight: 500;
  display: block;
  line-height: 1;
  margin-bottom: 5px;
}
.h2_banner-meta-info h5 {
  font-size: 17px;
  font-weight: 500;
  margin-bottom: 0;
  line-height: 1;
}
@media only screen and (min-width: 1600px) and (max-width: 1799px) {
  .h2_banner-meta-info {
    right: -70px;
  }
}
@media only screen and (min-width: 1400px) and (max-width: 1599px) {
  .h2_banner-meta-info {
    right: -40px;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .h2_banner-meta-info {
    right: -20px;
    top: 140px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px) {
  .h2_banner-meta-info {
    right: 0;
    top: 95px;
  }
}
.h2_banner-shape-1 {
  position: absolute;
  left: 80px;
  top: 0;
  animation: animation-popup-1 4s linear 0s infinite alternate;
}
@media only screen and (min-width: 1400px) and (max-width: 1599px) {
  .h2_banner-shape-1 {
    left: 60px;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .h2_banner-shape-1 {
    left: 30px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .h2_banner-shape-1 {
    left: 0;
  }
}
.h2_banner-shape-2 {
  position: absolute;
  left: -55px;
  bottom: 180px;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .h2_banner-shape-2 {
    left: -30px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .h2_banner-shape-2 {
    left: -15px;
  }
}

@keyframes h2_hero-upDown-1 {
  0% {
    transform: translateY(-20px);
  }
  100% {
    transform: translateY(20px);
  }
}
@keyframes h2_hero-leftRight-1 {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(80px);
  }
}
.h3_single-banner {
  background-color: var(--clr-color-lightPink);
  min-height: 870px;
  display: flex;
  align-items: end;
  position: relative;
  z-index: 1;
  overflow: hidden;
}
.h3_single-banner::before {
  position: absolute;
  bottom: -615px;
  left: -540px;
  content: "";
  width: 949px;
  height: 949px;
  border-radius: 949px;
  background: #E2C3E8;
  z-index: -1;
  animation: banner_before 2s linear 0s;
}
.h3_single-banner::after {
  position: absolute;
  top: -252px;
  right: -210px;
  content: "";
  width: 949px;
  height: 949px;
  border-radius: 949px;
  background: #E2C3E8;
  z-index: -1;
  animation: banner_after 1.2s linear 0s;
}
@media only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .h3_single-banner {
    align-items: center;
  }
}
@media (max-width: 767px) {
  .h3_single-banner {
    min-height: 750px;
  }
}

.h3_banner-form {
  border-radius: 6px;
  background: #FFF;
  box-shadow: 0px 1px 2px 0px rgba(10, 10, 10, 0.14);
  display: flex;
  max-width: 570px;
  justify-content: space-between;
  padding: 10px;
}
@media (max-width: 767px) {
  .h3_banner-form {
    max-width: 100%;
    flex-wrap: wrap;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .h3_banner-form {
    flex-wrap: nowrap;
  }
}
.h3_banner-form-item {
  display: flex;
  align-items: center;
  max-width: 200px;
}
@media (max-width: 767px) {
  .h3_banner-form-item {
    max-width: 50%;
    width: 100%;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .h3_banner-form-item {
    max-width: 200px;
    width: auto;
  }
}
.h3_banner-form-item:last-child {
  margin-left: auto;
}
@media (max-width: 767px) {
  .h3_banner-form-item:last-child {
    padding-top: 10px;
    max-width: 100%;
    width: 100%;
  }
  .h3_banner-form-item:last-child .h3_banner-form-btn {
    width: 100%;
    justify-content: center;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .h3_banner-form-item:last-child {
    padding-top: 0;
    max-width: 200px;
    width: auto;
  }
  .h3_banner-form-item:last-child .h3_banner-form-btn {
    width: initial;
    justify-content: start;
  }
}
.h3_banner-form-item-search {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 0 20px;
  position: relative;
  z-index: 1;
}
.h3_banner-form-item-search::after {
  position: absolute;
  content: "";
  top: 0;
  right: 0;
  background-color: rgba(30, 30, 30, 0.1);
  width: 1px;
  height: 100%;
}
.h3_banner-form-item-search i {
  font-size: 16px;
  color: var(--clr-body-heading);
}
.h3_banner-form-item-search input {
  height: 30px;
  line-height: 30px;
  border: none;
  padding: 0;
  width: 100%;
}
@media (max-width: 490px) {
  .h3_banner-form-item-search {
    padding: 0 10px;
    width: 100%;
  }
  .h3_banner-form-item-search::after {
    display: none;
  }
}
@media (max-width: 490px) {
  .h3_banner-form-item {
    max-width: 100%;
  }
  .h3_banner-form-item:first-child {
    padding-bottom: 10px;
  }
}
.h3_banner-category {
  position: relative;
  z-index: 9;
  margin: 0 30px;
}
@media (max-width: 490px) {
  .h3_banner-category {
    margin: 0 10px;
  }
}
.h3_banner-category a {
  color: var(--clr-body-heading);
  font-size: 16px;
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 15px 0;
}
.h3_banner-category a span {
  display: block;
  font-size: 16px;
  line-height: 1;
  font-weight: 500;
  color: var(--clr-body-heading);
  -webkit-transition: all 0.3s linear 0s;
  -moz-transition: all 0.3s linear 0s;
  -ms-transition: all 0.3s linear 0s;
  -o-transition: all 0.3s linear 0s;
  transition: all 0.3s linear 0s;
}
.h3_banner-category-submenu {
  position: absolute;
  left: 0;
  top: 100%;
  background: #fff;
  width: 200px;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: all 0.3s linear 0s;
  -moz-transition: all 0.3s linear 0s;
  -ms-transition: all 0.3s linear 0s;
  -o-transition: all 0.3s linear 0s;
  transition: all 0.3s linear 0s;
  box-shadow: 0 9px 19px rgba(0, 0, 0, 0.1);
  z-index: 9999;
}
.h3_banner-category-submenu li {
  display: block;
  margin-right: 0;
  position: relative;
  z-index: 99;
}
.h3_banner-category-submenu li a {
  padding: 10px 25px;
  display: block;
  color: var(--clr-body-heading);
  -webkit-transition: all 0.3s linear 0s;
  -moz-transition: all 0.3s linear 0s;
  -ms-transition: all 0.3s linear 0s;
  -o-transition: all 0.3s linear 0s;
  transition: all 0.3s linear 0s;
}
.h3_banner-category-submenu li .submenu {
  left: 100%;
  top: 0;
}
.h3_banner-category-submenu li:hover > a {
  background-color: var(--clr-theme-primary-3);
  color: #fff;
}
.h3_banner-category-submenu li:not(:last-child) {
  border-bottom: 1px solid #f2f2f2;
}
.h3_banner-category:hover > .h3_banner-category-submenu {
  opacity: 1;
  visibility: visible;
}
.h3_banner-category:hover > a {
  color: var(--clr-theme-primary-3);
}
.h3_banner-category:hover > a span {
  color: var(--clr-theme-primary-3);
}
.h3_banner-content {
  position: relative;
  z-index: 1;
  padding-bottom: 60px;
}
@media only screen and (min-width: 1600px) and (max-width: 1799px) {
  .h3_banner-content {
    padding-bottom: 120px;
  }
}
@media only screen and (min-width: 1400px) and (max-width: 1599px) {
  .h3_banner-content {
    padding-bottom: 160px;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .h3_banner-content {
    padding-bottom: 200px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .h3_banner-content {
    padding-bottom: 200px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .h3_banner-content {
    padding-bottom: 0;
  }
}
.h3_banner-content-text {
  display: block;
  font-size: 14px;
  color: var(--clr-body-heading);
  margin-bottom: 25px;
  line-height: 22px;
}
.h3_banner-content-text a {
  color: var(--clr-theme-primary);
}
.h3_banner-content-shape-1 {
  position: absolute;
  left: -75px;
  top: -65px;
  z-index: 1;
  animation: animation-leftRight-1 2s linear 0s infinite alternate;
}
@media only screen and (min-width: 1400px) and (max-width: 1599px) {
  .h3_banner-content-shape-1 {
    left: -45px;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .h3_banner-content-shape-1 {
    left: -15px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .h3_banner-content-shape-1 {
    left: 0;
  }
}
.h3_banner-right {
  position: relative;
  z-index: 1;
  margin-right: -90px;
}
@media only screen and (min-width: 1600px) and (max-width: 1799px) {
  .h3_banner-right {
    margin-right: -30px;
  }
}
@media only screen and (min-width: 1400px) and (max-width: 1599px) {
  .h3_banner-right {
    margin-right: 0px;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .h3_banner-right {
    margin-right: 0;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .h3_banner-right {
    margin-right: 0;
  }
}
.h3_banner-meta-rating {
  position: absolute;
  left: -70px;
  bottom: 145px;
  background: #fff;
  border-radius: 10px;
  padding: 25px 30px 25px;
  box-shadow: 0px 16px 70px rgba(30, 30, 30, 0.14);
  animation: h3_hero-leftRight-1 4.5s linear 0s infinite alternate;
}
.h3_banner-meta-rating span {
  font-size: 15px;
  display: flex;
  font-weight: 500;
  align-items: center;
  gap: 5px;
  margin-bottom: 5px;
}
.h3_banner-meta-rating span i {
  color: #FFA121;
  font-size: 15px;
}
.h3_banner-meta-rating h5 {
  font-size: 18px;
  margin-bottom: 0;
  font-weight: 500;
}
.h3_banner-meta-info {
  position: absolute;
  right: -15px;
  top: 295px;
  background: #fff;
  border-radius: 10px;
  padding: 20px 25px 20px;
  box-shadow: 0px 16px 70px rgba(30, 30, 30, 0.14);
  animation: h3_hero-upDown-1 4s linear 0s infinite alternate;
}
.h3_banner-meta-info span {
  font-size: 14px;
  font-weight: 500;
  display: block;
  line-height: 1;
  margin-bottom: 5px;
}
.h3_banner-meta-info h5 {
  font-size: 17px;
  font-weight: 500;
  margin-bottom: 0;
  line-height: 1;
}
@media only screen and (min-width: 1600px) and (max-width: 1799px) {
  .h3_banner-meta-info {
    right: -70px;
  }
}
@media only screen and (min-width: 1400px) and (max-width: 1599px) {
  .h3_banner-meta-info {
    right: -40px;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .h3_banner-meta-info {
    right: -20px;
    top: 140px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px) {
  .h3_banner-meta-info {
    right: 0;
    top: 95px;
  }
}
.h3_banner-shape-1 {
  position: absolute;
  right: -60px;
  bottom: 85px;
  animation: animation-upDown-1 3s linear 0s infinite alternate;
}
@media only screen and (min-width: 1400px) and (max-width: 1599px) {
  .h3_banner-shape-1 {
    right: -50px;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .h3_banner-shape-1 {
    right: -45px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .h3_banner-shape-1 {
    display: none;
  }
}
.h3_banner-shape-2 {
  position: absolute;
  left: -75px;
  bottom: 390px;
  animation: animation-leftRight-1 3s linear 0s infinite alternate;
}
@media only screen and (min-width: 1400px) and (max-width: 1599px) {
  .h3_banner-shape-2 {
    left: -35px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .h3_banner-shape-2 {
    left: -10px;
  }
}
@keyframes h3_hero-upDown-1 {
  0% {
    transform: translateY(-20px);
  }
  100% {
    transform: translateY(20px);
  }
}
@keyframes h3_hero-leftRight-1 {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(80px);
  }
}
@keyframes banner_after {
  0% {
    transform: translateX(80%);
  }
  100% {
    transform: translateX(0);
  }
}
@keyframes banner_before {
  0% {
    transform: translateX(-400px);
  }
  100% {
    transform: translateX(0);
  }
}
.h4_single-banner {
  background-color: #36348E;
  min-height: 950px;
  display: flex;
  align-items: center;
  position: relative;
  z-index: 1;
  overflow: hidden;
}
@media (max-width: 767px) {
  .h4_single-banner {
    min-height: 800px;
  }
}

.h4_banner-area {
  position: relative;
}
.h4_banner-form {
  border-radius: 100px;
  background: #FFF;
  box-shadow: 0px 1px 2px 0px rgba(10, 10, 10, 0.14);
  display: flex;
  max-width: 550px;
  justify-content: space-between;
  padding: 7px;
  overflow: hidden;
  margin-bottom: 35px;
}
.h4_banner-form input {
  width: 100%;
  height: 40px;
  padding-left: 11px;
  padding-right: 20px;
  border: none;
  color: var(--clr-body-heading);
  font-size: 15px;
}
.h4_banner-form input::-webkit-input-placeholder {
  color: rgba(30, 30, 30, 0.5);
  font-size: 15px;
  opacity: 1;
}
.h4_banner-form input:-moz-placeholder {
  color: rgba(30, 30, 30, 0.5);
  font-size: 15px;
  opacity: 1;
}
.h4_banner-form input::-moz-placeholder {
  color: rgba(30, 30, 30, 0.5);
  font-size: 15px;
  opacity: 1;
}
.h4_banner-form input:-ms-input-placeholder {
  color: rgba(30, 30, 30, 0.5);
  font-size: 15px;
  opacity: 1;
}
.h4_banner-form button {
  width: auto;
  flex-shrink: 0;
}
.h4_banner-content {
  position: relative;
  z-index: 1;
}
@media only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .h4_banner-content {
    margin-right: 0;
  }
}
.h4_banner-content-text {
  display: block;
  font-size: 14px;
  color: var(--clr-body-heading);
  margin-bottom: 25px;
  line-height: 22px;
}
.h4_banner-content-text a {
  color: var(--clr-theme-primary);
}
.h4_banner-content-shape-1 {
  position: absolute;
  left: -75px;
  top: -65px;
  z-index: 1;
}
.h4_banner-shape {
  position: absolute;
  left: 0;
  bottom: 0;
}
.h4_banner-img {
  position: absolute;
  top: 0;
  right: 0;
  max-width: 42%;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .h4_banner-img {
    max-width: 50%;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .h4_banner-img {
    display: none;
  }
}
.h4_banner-tag {
  display: flex;
  align-items: center;
  gap: 10px;
  flex-wrap: wrap;
  max-width: 420px;
}
.h4_banner-tag a {
  background: #fff;
  color: var(--clr-body-heading);
  font-size: 13px;
  padding: 0 14px;
  border-radius: 30px;
}
.h4_banner-tag a:hover {
  background-color: var(--clr-theme-primary-4);
}

.h5_single-banner {
  background-color: #DCECFF;
  min-height: 860px;
  display: flex;
  align-items: center;
  position: relative;
  z-index: 1;
  overflow: hidden;
}

.h5_banner-bg {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  max-width: 45%;
  width: 100%;
}
.h5_banner-bg img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.h5_banner-contact {
  position: absolute;
  bottom: 40px;
  right: 50px;
  display: flex;
  gap: 40px;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .h5_banner-contact {
    right: auto;
    left: 50px;
  }
}
@media (max-width: 767px) {
  .h5_banner-contact {
    display: none;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .h5_banner-contact {
    display: flex;
    right: auto;
    left: 50px;
  }
}
.h5_banner-contact-item p {
  font-size: 16px;
  font-weight: 500;
  color: var(--clr-body-heading);
  margin-bottom: 0;
}
.h5_banner-contact-item-top span {
  color: #747474;
  font-size: 16px;
  font-weight: 500;
}
.h5_banner-contact-item-top i {
  font-size: 16px;
  font-weight: 500;
  color: #747474;
  margin-right: 10px;
}

.h5_banner-content .h2_banner-form input {
  border-radius: 0;
}

.h6_single-banner {
  min-height: 700px;
  display: flex;
  align-items: center;
  position: relative;
  z-index: 1;
  overflow: hidden;
  padding-top: 290px;
  padding-bottom: 285px;
}
.h6_single-banner::before {
  position: absolute;
  content: "";
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(90deg, rgba(15, 15, 15, 0.85) 12.58%, rgba(15, 15, 15, 0.34) 83.62%);
  z-index: -1;
}
@media (max-width: 767px) {
  .h6_single-banner {
    padding-top: 200px;
    padding-bottom: 160px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .h6_single-banner {
    padding-top: 250px;
    padding-bottom: 230px;
  }
}

.h6_banner-content-subtitle {
  color: #FFF;
  font-size: 20px;
  font-weight: 500;
  line-height: 26px;
  display: block;
  margin-bottom: 20px;
}
.h6_banner-content-subtitle i {
  color: var(--clr-theme-primary-6);
  margin-right: 5px;
}
@media (max-width: 767px) {
  .h6_banner-content-subtitle {
    font-size: 16px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .h6_banner-content-subtitle {
    font-size: 20px;
  }
}
.h6_banner-content-title {
  color: #FFF;
  font-size: 80px;
  font-style: normal;
  font-weight: 600;
  line-height: 1;
  text-transform: capitalize;
  margin-bottom: 25px;
}
.h6_banner-content-title span {
  color: #FFF;
  font-family: "Sansita Swashed", cursive;
  font-size: 80px;
  font-style: normal;
  font-weight: 400;
  line-height: 1;
  text-transform: capitalize;
}
@media (max-width: 767px) {
  .h6_banner-content-title {
    font-size: 50px;
    line-height: 1.1;
  }
  .h6_banner-content-title span {
    font-size: 40px;
    line-height: 1.1;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .h6_banner-content-title {
    font-size: 60px;
  }
  .h6_banner-content-title span {
    font-size: 55px;
  }
}
.h6_banner-content-text {
  color: #FFF;
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
  margin-bottom: 42px;
}
@media (max-width: 767px) {
  .h6_banner-content-text br {
    display: none;
  }
}

.h6_banner-navigation div {
  position: absolute;
  left: 80px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
  font-size: 90px;
  color: rgba(255, 255, 255, 0.4);
  cursor: pointer;
}
.h6_banner-navigation .banner_6-swiper-next {
  right: 80px;
  left: auto;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .h6_banner-navigation div {
    left: 50px;
    font-size: 80px;
  }
  .h6_banner-navigation .banner_6-swiper-next {
    right: 50px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .h6_banner-navigation div {
    left: 20px;
    font-size: 60px;
  }
  .h6_banner-navigation .banner_6-swiper-next {
    right: 20px;
  }
}
@media (max-width: 767px) {
  .h6_banner-navigation div {
    display: none;
  }
}

.h7_single-banner {
  min-height: 600px;
  display: flex;
  align-items: center;
  position: relative;
  z-index: 1;
  overflow: hidden;
  padding-top: 245px;
  padding-bottom: 260px;
}
.h7_single-banner::before {
  position: absolute;
  content: "";
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(90deg, #0A0A0A 0%, rgba(10, 10, 10, 0) 81.54%);
  z-index: -1;
}
@media (max-width: 767px) {
  .h7_single-banner {
    padding-top: 175px;
    padding-bottom: 180px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .h7_single-banner {
    padding-top: 210px;
    padding-bottom: 220px;
  }
}

.h7_banner-content-title {
  color: #FFF;
  font-size: 80px;
  font-weight: 800;
  line-height: 1.1;
  text-transform: capitalize;
  margin-bottom: 20px;
}
@media (max-width: 767px) {
  .h7_banner-content-title {
    font-size: 38px;
  }
  .h7_banner-content-title br {
    display: none;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .h7_banner-content-title {
    font-size: 60px;
  }
  .h7_banner-content-title span {
    font-size: 55px;
  }
}
.h7_banner-content-text {
  color: rgba(255, 255, 255, 0.74);
  font-size: 20px;
  font-weight: 400;
  line-height: 30px;
  margin-bottom: 37px;
}
@media (max-width: 767px) {
  .h7_banner-content-text {
    font-size: 16px;
    line-height: 26px;
  }
  .h7_banner-content-text br {
    display: none;
  }
}
.h7_banner-content-btn {
  display: flex;
  gap: 10px;
}
@media (max-width: 767px) {
  .h7_banner-content-btn {
    flex-wrap: wrap;
  }
}

.h7_slider-active-nav .swiper-wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
}

.h7_slider-thumb-item {
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  gap: 25px;
  padding: 0 10px;
  height: 140px;
  justify-content: center;
  cursor: pointer;
}
@media (max-width: 767px) {
  .h7_slider-thumb-item {
    gap: 15px;
  }
}

.h7_slider-thumbs-info h4 {
  color: #fff;
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 5px;
}
@media (max-width: 767px) {
  .h7_slider-thumbs-info h4 {
    font-size: 22px;
  }
}

.h7_slider-thumbs-info a {
  color: #fff;
  font-size: 16px;
  font-weight: 500;
  display: flex;
  align-items: center;
  gap: 10px;
}

.h7_slider-thumbs-icon svg {
  color: #fff;
}

.h7_slider-thumbs-info a i {
  font-size: 15px;
}

.h7_slider-navigation {
  position: absolute;
  right: 6%;
  top: 50%;
  transform: translateY(-50%);
  z-index: 2;
  display: grid;
  grid-gap: 10px;
}
.h7_slider-navigation div {
  width: 60px;
  height: 60px;
  background: #fff;
  border-radius: 50%;
  display: grid;
  place-items: center;
  font-size: 22px;
  color: var(--clr-theme-primary-6);
  gap: 10px;
  -webkit-transition: all 0.3s linear 0s;
  -moz-transition: all 0.3s linear 0s;
  -ms-transition: all 0.3s linear 0s;
  -o-transition: all 0.3s linear 0s;
  transition: all 0.3s linear 0s;
}
.h7_slider-navigation div:hover {
  background-color: var(--clr-theme-primary-6);
  color: #fff;
}

.h8_banner-area {
  background-color: #F4F7FF;
  min-height: 600px;
  display: flex;
  align-items: center;
  position: relative;
  z-index: 1;
  overflow: hidden;
  padding-top: 90px;
  padding-bottom: 110px;
}

.h8_banner-content-subtitle {
  color: #F14D5D;
  font-size: 16px;
  font-weight: 700;
  text-transform: uppercase;
  display: block;
}
@media (max-width: 767px) {
  .h8_banner-content-subtitle {
    font-weight: 500;
  }
}

.h8_banner-content-title {
  color: rgba(2, 25, 54, 0.95);
  font-size: 56px;
  font-weight: 700;
  line-height: 1.2;
  margin-bottom: 20px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .h8_banner-content-title {
    font-size: 45px;
  }
}
@media (max-width: 767px) {
  .h8_banner-content-title {
    font-size: 33px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .h8_banner-content-title {
    font-size: 45px;
  }
}

.h8_banner-content-title span {
  color: #395BDF;
}

.h8_banner-content-text {
  color: rgba(2, 25, 54, 0.7);
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  margin-bottom: 50px;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px), only screen and (min-width: 992px) and (max-width: 1199px), (max-width: 767px) {
  .h8_banner-content-text br {
    display: none;
  }
}

.h8_banner-play {
  display: flex;
  align-items: center;
  gap: 12px;
  color: #021936;
  font-size: 15px;
  font-weight: 400;
}

.h8_banner-play span {
  height: 40px;
  width: 40px;
  border: 1px solid rgb(241, 77, 93);
  color: rgb(241, 77, 93);
  display: grid;
  place-items: center;
  border-radius: 50%;
  font-size: 14px;
}

.h8_banner-content-btn {
  display: flex;
  gap: 20px;
  align-items: center;
  flex-wrap: wrap;
}

.h8_banner-img {
  margin-left: -20px;
}
@media only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .h8_banner-img {
    margin-left: 0;
    text-align: center;
  }
}

.h9_single-banner {
  min-height: 600px;
  display: flex;
  align-items: center;
  position: relative;
  z-index: 1;
  overflow: hidden;
  padding-top: 205px;
  padding-bottom: 165px;
  background-size: cover;
  background-repeat: no-repeat;
}
.h9_single-banner::before {
  position: absolute;
  content: "";
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(92deg, #21242C 8.67%, #21242C 8.68%, rgba(33, 36, 44, 0.3) 98.22%);
  z-index: -1;
}
@media (max-width: 767px) {
  .h9_single-banner {
    padding-top: 175px;
    padding-bottom: 180px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .h9_single-banner {
    padding-top: 210px;
    padding-bottom: 220px;
  }
}

.h9_banner-content-subtitle {
  color: #FFF;
  font-size: 16px;
  font-weight: 500;
  text-transform: uppercase;
  display: block;
  margin-bottom: 10px;
}
@media (max-width: 767px) {
  .h9_banner-content-subtitle {
    font-size: 15px;
  }
}
.h9_banner-content-title {
  color: #FFF;
  font-size: 74px;
  font-weight: 700;
  line-height: 1.1;
  margin-bottom: 20px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px) {
  .h9_banner-content-title {
    font-size: 65px;
  }
}
@media (max-width: 767px) {
  .h9_banner-content-title {
    font-size: 40px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .h9_banner-content-title {
    font-size: 52px;
  }
  .h9_banner-content-title span {
    font-size: 55px;
  }
}
.h9_banner-content-text {
  color: rgba(255, 255, 255, 0.7);
  font-size: 17px;
  font-weight: 400;
  line-height: 26px;
  margin-bottom: 38px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px) {
  .h9_banner-content-text br {
    display: none;
  }
}
@media (max-width: 767px) {
  .h9_banner-content-text {
    font-size: 16px;
    line-height: 26px;
  }
  .h9_banner-content-text br {
    display: none;
  }
}
.h9_banner-content-btn a {
  padding: 0 35px;
}

.h9_slider-active-nav .swiper-wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
}

.h9_slider-thumb-item {
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 15px;
  padding-left: 20px;
  padding-right: 20px;
  height: 140px;
  cursor: pointer;
}
@media (max-width: 767px) {
  .h9_slider-thumb-item {
    gap: 15px;
  }
}

.h9_slider-thumbs-info h4 {
  color: #fff;
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 0px;
}
@media (max-width: 767px) {
  .h9_slider-thumbs-info h4 {
    font-size: 22px;
  }
}

.h9_slider-thumbs-info a {
  color: #fff;
  font-size: 14px;
  font-weight: 500;
  display: flex;
  align-items: center;
  gap: 10px;
}

.h9_slider-thumbs-icon svg {
  color: #fff;
}

.h9_slider-thumbs-info a i {
  font-size: 15px;
}

.h9_slider-green {
  background-color: #10C45C;
}

.h9_slider-skyBlue {
  background-color: #1EC1D9;
}

.h9_slider-orange {
  background-color: #E1B12F;
}

.h9_slider-blue {
  background-color: #307AD5;
}

.h9_slider-pink {
  background-color: #D94DA6;
}

.h10_single-banner {
  min-height: 600px;
  display: flex;
  align-items: center;
  position: relative;
  z-index: 1;
  overflow: hidden;
  padding-top: 180px;
  padding-bottom: 180px;
  background-color: var(--clr-theme-primary-10);
}
@media (max-width: 767px) {
  .h10_single-banner {
    padding-top: 110px;
    padding-bottom: 115px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .h10_single-banner {
    padding-top: 110px;
    padding-bottom: 120px;
  }
}

.h10_banner-content-title {
  color: #fff;
  font-size: 64px;
  font-weight: 700;
  line-height: 1.1;
  text-transform: capitalize;
  margin-bottom: 20px;
}
.h10_banner-content-title span {
  position: relative;
  z-index: 1;
}
.h10_banner-content-title span img {
  position: absolute;
  left: 0;
  bottom: -5px;
  width: calc(100% - 25px);
  display: inline-block;
  margin-left: 10px;
  animation: section-animation 5s linear 0s infinite;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .h10_banner-content-title {
    font-size: 55px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .h10_banner-content-title {
    font-size: 45px;
  }
}
@media (max-width: 767px) {
  .h10_banner-content-title {
    font-size: 45px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .h10_banner-content-title {
    font-size: 52px;
  }
}
.h10_banner-content-text {
  color: rgba(255, 255, 255, 0.8);
  font-size: 18px;
  font-weight: 400;
  line-height: 26px;
  margin-bottom: 37px;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px), only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .h10_banner-content-text br {
    display: none;
  }
}
@media (max-width: 767px) {
  .h10_banner-content-text {
    font-size: 16px;
    line-height: 26px;
  }
  .h10_banner-content-text br {
    display: none;
  }
}
.h10_banner-content-btn {
  display: flex;
  gap: 15px;
}
@media (max-width: 767px) {
  .h10_banner-content-btn {
    flex-wrap: wrap;
  }
}

.h10_banner-bottom-info {
  display: flex;
  align-items: center;
  row-gap: 15px;
  column-gap: 40px;
  flex-wrap: wrap;
}

.h10_banner-bottom-info span {
  color: #FFF;
  font-size: 15px;
  font-weight: 500;
  display: flex;
  align-items: center;
  gap: 8px;
  position: relative;
  z-index: 1;
}

.h10_banner-bottom-info span i {
  font-size: 22px;
}

.h10_banner-bottom-info span:not(:last-child)::before {
  position: absolute;
  right: -20px;
  top: 50%;
  transform: translateY(-50%);
  content: "";
  background: #DDB586;
  width: 1px;
  height: 100%;
}

.h10_banner-right {
  margin-right: -165px;
  position: relative;
  z-index: 1;
}
@media only screen and (min-width: 1600px) and (max-width: 1799px) {
  .h10_banner-right.pl-110 {
    padding-left: 55px;
  }
}
@media only screen and (min-width: 1400px) and (max-width: 1599px), only screen and (min-width: 1200px) and (max-width: 1399px), only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .h10_banner-right.pl-110 {
    padding-left: 0;
  }
}
@media only screen and (min-width: 1600px) and (max-width: 1799px) {
  .h10_banner-right {
    margin-right: -110px;
  }
}
@media only screen and (min-width: 1400px) and (max-width: 1599px) {
  .h10_banner-right {
    margin-right: -30px;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1399px), only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .h10_banner-right {
    margin-right: 0;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .h10_banner-img {
    text-align: center;
  }
}
.h10_banner-img img {
  animation: hero-border 5s linear infinite alternate;
}

@keyframes hero-border {
  0% {
    -webkit-border-radius: 51% 49% 77% 23%/65% 50% 50% 35%;
    -moz-border-radius: 51% 49% 77% 23%/65% 50% 50% 35%;
    border-radius: 51% 49% 77% 23%/65% 50% 50% 35%;
  }
  100% {
    -webkit-border-radius: 30% 70% 28% 72%/53% 69% 31% 47%;
    -moz-border-radius: 30% 70% 28% 72%/53% 69% 31% 47%;
    border-radius: 30% 70% 28% 72%/53% 69% 31% 47%;
  }
}
.waviy {
  position: relative;
  -webkit-box-reflect: below -20px linear-gradient(transparent, rgba(0, 0, 0, 0.2));
}

.waviy span {
  position: relative;
  display: inline-block;
  animation: waviy 1s infinite;
  animation-delay: calc(0.1s * var(--i));
}

@keyframes waviy {
  0%, 40%, 100% {
    transform: translateY(0);
  }
  20% {
    transform: translateY(-20px);
  }
}
.h10_banner-shape-1 {
  position: absolute;
  left: 5%;
  bottom: 31%;
  animation: animation-popup-2 1.2s linear 0s infinite alternate;
}
@media only screen and (min-width: 1400px) and (max-width: 1599px) {
  .h10_banner-shape-1 {
    left: 1%;
  }
}
.h10_banner-shape-2 {
  position: absolute;
  left: 11%;
  top: 12%;
  animation: animation-popup-1 3s linear 0s infinite alternate;
}
@media only screen and (min-width: 1400px) and (max-width: 1599px) {
  .h10_banner-shape-2 {
    left: 5%;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .h10_banner-shape-2 {
    left: 4%;
  }
}
.h10_banner-shape-3 {
  position: absolute;
  top: 6%;
  left: 32%;
  animation: animation-leftRight-1 3s linear 0s infinite alternate;
}
.h10_banner-shape-4 {
  position: absolute;
  left: 10px;
  top: -30px;
  animation: animation-upDown-2 3s linear 0s infinite alternate;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .h10_banner-shape-4 {
    width: 40px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .h10_banner-shape-4 {
    width: 50px;
    top: 0;
    left: 50px;
  }
}
.h10_banner-shape-5 {
  position: absolute;
  left: 30px;
  bottom: 25px;
  animation: animation-upDown-2 3s linear 0s infinite alternate;
}
@media only screen and (min-width: 1600px) and (max-width: 1799px) {
  .h10_banner-shape-5 {
    left: 0;
  }
}
@media only screen and (min-width: 1400px) and (max-width: 1599px) {
  .h10_banner-shape-5 {
    left: -40px;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .h10_banner-shape-5 {
    left: -10px;
    width: 50px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .h10_banner-shape-5 {
    left: -10px;
    width: 50px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .h10_banner-shape-5 {
    width: 50px;
  }
}
.h10_banner-shape-6 {
  position: absolute;
  right: 10px;
  bottom: -15px;
  animation: hero-circle-1 6s linear 0s infinite;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .h10_banner-shape-6 {
    width: 50px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .h10_banner-shape-6 {
    width: 50px;
    bottom: 0;
    right: 60px;
  }
}

/*
************
*************************
05. section
******************************************************* 
*************************************************************** */
.section-area .section-subtitle {
  color: #D2093C;
  font-size: 15px;
  font-weight: 500;
  display: inline-block;
  background-color: rgba(210, 9, 59, 0.04);
  padding: 10px 25px;
  line-height: 1;
  position: relative;
  z-index: 1;
  margin-bottom: 10px;
}
@media (max-width: 767px) {
  .section-area .section-subtitle {
    font-size: 14px;
    padding: 10px 20px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .section-area .section-subtitle {
    font-size: 15px;
    padding: 10px 25px;
  }
}
.section-area .section-subtitle::before {
  position: absolute;
  left: 0;
  top: 0;
  width: 2px;
  height: 100%;
  background-color: #D2093C;
  content: "";
}
.section-area .section-title {
  font-size: 46px;
  font-weight: 700;
  line-height: 1.2;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .section-area .section-title {
    font-size: 40px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-area .section-title {
    font-size: 40px;
  }
}
@media (max-width: 767px) {
  .section-area .section-title {
    font-size: 32px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .section-area .section-title {
    font-size: 40px;
  }
}
.section-area .section-text {
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 0;
  color: rgba(30, 30, 30, 0.7);
}

.section-area-top .section-subtitle::before {
  left: 0;
  top: 0;
  width: 100%;
  height: 2px;
}

.banner-content .section-subtitle {
  background-color: #fff;
  margin-bottom: 15px;
}
.banner-content .section-title {
  font-size: 70px;
  font-weight: 600;
  line-height: 1.1;
  margin-bottom: 15px;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .banner-content .section-title {
    font-size: 60px;
    margin-bottom: 20px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .banner-content .section-title {
    font-size: 45px;
    margin-bottom: 20px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .banner-content .section-title {
    font-size: 45px;
    margin-bottom: 20px;
  }
}
@media (max-width: 767px) {
  .banner-content .section-title {
    font-size: 35px;
    margin-bottom: 20px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .banner-content .section-title {
    font-size: 45px;
  }
}
.banner-content .section-title span {
  display: inline-block;
  position: relative;
  z-index: 1;
}
.banner-content .section-title span img {
  position: absolute;
  left: 0;
  bottom: -5px;
  width: calc(100% - 25px);
  display: inline-block;
  margin-left: 10px;
  animation: section-animation 5s linear 0s infinite;
}
.banner-content .section-text {
  margin-bottom: 43px;
  color: rgba(30, 30, 30, 0.6);
}
@media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .banner-content .section-text br {
    display: none;
  }
}

.section-area-2 .section-title {
  font-size: 48px;
  font-weight: 700;
  line-height: 1.2;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .section-area-2 .section-title {
    font-size: 40px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-area-2 .section-title {
    font-size: 40px;
  }
}
@media (max-width: 767px) {
  .section-area-2 .section-title {
    font-size: 32px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .section-area-2 .section-title {
    font-size: 40px;
  }
}
.section-area-2 .section-title span {
  display: inline-block;
  position: relative;
  z-index: 1;
  color: var(--clr-theme-primary);
}
.section-area-2 .section-title span img {
  position: absolute;
  left: 0;
  bottom: -7px;
  width: calc(100% - 25px);
  display: inline-block;
  margin-left: 10px;
  animation: section-animation 5s linear 0s infinite;
}
.section-area-2 .section-text {
  font-size: 16px;
  line-height: 28px;
  margin-bottom: 0;
  color: rgba(30, 30, 30, 0.7);
}

.h2_banner-content .section-title {
  font-size: 70px;
  font-weight: 600;
  line-height: 1.2;
  margin-bottom: 15px;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .h2_banner-content .section-title {
    font-size: 60px;
    margin-bottom: 20px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .h2_banner-content .section-title {
    font-size: 50px;
    margin-bottom: 20px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .h2_banner-content .section-title {
    font-size: 45px;
    margin-bottom: 20px;
  }
}
@media (max-width: 767px) {
  .h2_banner-content .section-title {
    font-size: 33px;
    margin-bottom: 20px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .h2_banner-content .section-title {
    font-size: 45px;
  }
}
.h2_banner-content .section-title span {
  display: inline-block;
  position: relative;
  z-index: 1;
  color: var(--clr-theme-primary);
}
.h2_banner-content .section-title span img {
  position: absolute;
  left: 0;
  bottom: -5px;
  width: calc(100% - 25px);
  display: inline-block;
  margin-left: 10px;
}
.h2_banner-content .section-text {
  margin-bottom: 43px;
  color: rgba(30, 30, 30, 0.6);
}

.section-area-3 .section-subtitle {
  color: var(--clr-theme-primary-3);
  font-size: 16px;
  display: block;
  margin-bottom: 15px;
}
.section-area-3 .section-title {
  font-size: 46px;
  font-weight: 700;
  line-height: 1.2;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .section-area-3 .section-title {
    font-size: 40px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-area-3 .section-title {
    font-size: 40px;
  }
}
@media (max-width: 767px) {
  .section-area-3 .section-title {
    font-size: 32px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .section-area-3 .section-title {
    font-size: 40px;
  }
}
.section-area-3 .section-text {
  font-size: 15px;
  line-height: 24px;
  margin-bottom: 0;
  color: rgba(30, 30, 30, 0.7);
  letter-spacing: 0.15px;
}

.h3_banner-content .section-subtitle {
  color: var(--clr-body-heading);
  font-size: 16px;
  font-weight: 500;
  display: inline-block;
  position: relative;
  z-index: 1;
  margin-bottom: 10px;
}
.h3_banner-content .section-title {
  font-size: 64px;
  font-weight: 600;
  line-height: 1.2;
  margin-bottom: 15px;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .h3_banner-content .section-title {
    font-size: 60px;
    margin-bottom: 20px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .h3_banner-content .section-title {
    font-size: 45px;
    margin-bottom: 20px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .h3_banner-content .section-title {
    font-size: 45px;
    margin-bottom: 20px;
  }
}
@media (max-width: 767px) {
  .h3_banner-content .section-title {
    font-size: 35px;
    margin-bottom: 20px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .h3_banner-content .section-title {
    font-size: 45px;
  }
}
.h3_banner-content .section-title span {
  display: inline-block;
  position: relative;
  z-index: 1;
  color: var(--clr-theme-primary-3);
}
.h3_banner-content .section-text {
  font-size: 17px;
  margin-bottom: 43px;
  color: rgba(30, 30, 30, 0.7);
}

.section-area-4 .section-subtitle {
  color: var(--clr-theme-primary-3);
  font-size: 16px;
  display: block;
  margin-bottom: 15px;
}
.section-area-4 .section-title {
  font-size: 46px;
  font-weight: 700;
  line-height: 1.2;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .section-area-4 .section-title {
    font-size: 40px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-area-4 .section-title {
    font-size: 40px;
  }
}
@media (max-width: 767px) {
  .section-area-4 .section-title {
    font-size: 32px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .section-area-4 .section-title {
    font-size: 40px;
  }
}
.section-area-4 .section-text {
  font-size: 16px;
  line-height: 26px;
  margin-bottom: 0;
  color: rgba(30, 30, 30, 0.7);
}

.section-white-4 .section-title {
  color: #fff;
}
.section-white-4 .section-text {
  color: rgba(255, 255, 255, 0.7);
}

.h4_banner-content .section-title {
  font-size: 90px;
  font-weight: 400;
  line-height: 1.2;
  margin-bottom: 20px;
  color: #fff;
}
@media only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .h4_banner-content .section-title br {
    display: none;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .h4_banner-content .section-title {
    font-size: 80px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .h4_banner-content .section-title {
    font-size: 75px;
  }
}
@media (max-width: 767px) {
  .h4_banner-content .section-title {
    font-size: 45px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .h4_banner-content .section-title {
    font-size: 60px;
  }
}
.h4_banner-content .section-title span {
  display: inline-block;
  font-weight: 900;
  color: var(--clr-theme-primary-4);
}
.h4_banner-content .section-text {
  font-size: 16px;
  margin-bottom: 40px;
  color: rgba(255, 255, 255, 0.7);
}

.section-area-5 .section-subtitle {
  color: var(--clr-body-heading);
  font-size: 16px;
  display: block;
}
.section-area-5 .section-title {
  font-size: 46px;
  font-weight: 700;
  line-height: 1.2;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .section-area-5 .section-title {
    font-size: 40px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-area-5 .section-title {
    font-size: 40px;
  }
}
@media (max-width: 767px) {
  .section-area-5 .section-title {
    font-size: 32px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .section-area-5 .section-title {
    font-size: 40px;
  }
}
.section-area-5 .section-text {
  font-size: 16px;
  line-height: 26px;
  margin-bottom: 0;
  color: rgba(30, 30, 30, 0.7);
}

.section-area-6 .section-subtitle {
  color: var(--clr-theme-primary-6);
  font-size: 16px;
  display: block;
}
.section-area-6 .section-title {
  font-size: 44px;
  font-weight: 600;
  line-height: 1.2;
}
@media (max-width: 767px) {
  .section-area-6 .section-title {
    font-size: 33px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .section-area-6 .section-title {
    font-size: 44px;
  }
}
.section-area-6 .section-text {
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 0;
  color: #666;
}
@media (max-width: 767px) {
  .section-area-6 .section-text br {
    display: none;
  }
}

.section-area-6-admin {
  position: relative;
  z-index: 1;
  display: block;
  color: #0A0A0A;
  font-size: 16px;
  font-weight: 500;
  padding-left: 55px;
  line-height: 21px;
  margin-top: 30px;
}

.section-area-6-admin::before {
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  content: "";
  width: 45px;
  height: 1px;
  background: #0A0A0A;
}

.section-area-8 .section-subtitle {
  color: #F14D5D;
  font-size: 16px;
  display: block;
  margin-bottom: 8px;
  font-weight: 700;
  text-transform: uppercase;
}
@media (max-width: 767px) {
  .section-area-8 .section-subtitle {
    font-weight: 500;
  }
}
.section-area-8 .section-title {
  color: #021936;
  font-size: 40px;
  font-weight: 600;
  line-height: 1.2;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .section-area-8 .section-title {
    font-size: 40px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-area-8 .section-title {
    font-size: 40px;
  }
}
@media (max-width: 767px) {
  .section-area-8 .section-title {
    font-size: 32px;
  }
  .section-area-8 .section-title br {
    display: none;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .section-area-8 .section-title {
    font-size: 40px;
  }
}
.section-area-8 .section-text {
  margin-bottom: 0;
  color: rgba(2, 25, 54, 0.7);
  font-size: 15px;
  font-weight: 400;
  line-height: 24px;
}

.section-area-9 .section-subtitle {
  color: #1865F2;
  font-size: 16px;
  font-weight: 500;
  display: block;
  line-height: 24px;
  margin-bottom: 5px;
}
.section-area-9 .section-title {
  color: #0A0A0A;
  font-size: 46px;
  font-weight: 700;
  line-height: 1.2;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .section-area-9 .section-title {
    font-size: 40px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-area-9 .section-title {
    font-size: 40px;
  }
}
@media (max-width: 767px) {
  .section-area-9 .section-title {
    font-size: 32px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .section-area-9 .section-title {
    font-size: 40px;
  }
}

.section-area-10 .section-title {
  color: #0A0A0A;
  font-size: 46px;
  font-weight: 600;
  line-height: 1.1;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .section-area-10 .section-title {
    font-size: 40px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-area-10 .section-title {
    font-size: 40px;
  }
}
@media (max-width: 767px) {
  .section-area-10 .section-title {
    font-size: 32px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .section-area-10 .section-title {
    font-size: 40px;
  }
}
.section-area-10 .section-text {
  margin-bottom: 0;
  color: #666;
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
}

.small-section-area-2 .section-title {
  font-size: 40px;
}
.small-section-area-2 .section-title span img {
  bottom: -10px;
}
@media (max-width: 767px) {
  .small-section-area-2 .section-title {
    font-size: 32px;
  }
  .small-section-area-2 .section-title br {
    display: none;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .small-section-area-2 .section-title {
    font-size: 35px;
  }
  .small-section-area-2 .section-title br {
    display: block;
  }
}

.small-section-area-3 .section-title {
  font-size: 40px;
  line-height: 1.25;
}
@media (max-width: 767px) {
  .small-section-area-3 .section-title {
    font-size: 32px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .small-section-area-3 .section-title {
    font-size: 35px;
  }
}

.h2_blog-section-area .section-title span {
  color: var(--clr-body-heading);
}

.h2_cta-content .section-area-2 .section-title span {
  color: var(--clr-body-heading);
}

.h2_teacher-section .section-area-2 .section-title span {
  color: var(--clr-body-heading);
}

@keyframes section-animation {
  0% {
    width: 0;
  }
  15% {
    width: 100%;
  }
  85% {
    opacity: 1;
  }
  90% {
    width: 100%;
    opacity: 0;
  }
  to {
    width: 0;
    opacity: 0;
  }
}
/*
************
*************************
06. brand
******************************************************* 
*************************************************************** */
.brand-wrap {
  border: 1px solid rgba(30, 30, 30, 0.1);
  padding-top: 35px;
  padding-bottom: 35px;
  padding-left: 20px;
  padding-right: 20px;
}
.brand-item {
  text-align: center;
}
.brand-content h5 {
  font-size: 18px;
  font-weight: 500;
  text-align: center;
  margin-bottom: 35px;
  color: rgba(30, 30, 30, 0.8);
}

.brand-item .brand-hover-img {
  position: absolute;
  left: 0;
  top: 0;
  display: block;
  transform: translateY(-100%);
  opacity: 0;
  visibility: hidden;
  margin: auto;
  right: 0;
}

.brand-item {
  position: relative;
  z-index: 1;
  overflow: hidden;
}
.brand-item img {
  -webkit-transition: all 0.3s linear 0s;
  -moz-transition: all 0.3s linear 0s;
  -ms-transition: all 0.3s linear 0s;
  -o-transition: all 0.3s linear 0s;
  transition: all 0.3s linear 0s;
}
.brand-item .brand-hover-img {
  left: 50%;
  transform: translate(-50%, -100%);
}
.brand-item:hover .brand-hover-img {
  transform: translateY(0);
  opacity: 1;
  visibility: visible;
  left: 50%;
  transform: translateX(-50%);
}
.brand-item:hover .brand-main-img {
  transform: translateY(100%);
  opacity: 0;
  visibility: hidden;
}

.h6_brand-area .brand-wrap {
  border: none;
  padding: 0;
}
.h6_brand-area .brand-item {
  text-align: center;
}
.h6_brand-area .brand-item .brand-hover-img {
  margin: 0;
}

/*
************
*************************
07. category
******************************************************* 
*************************************************************** */
.category-wrap {
  position: relative;
  z-index: 1;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .category-wrap {
    margin-left: 30px;
  }
}
.category-content {
  padding: 21px 25px 22px;
}
.category-content h5 {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 10px;
}
.category-content h5:hover a {
  color: var(--clr-theme-primary);
}
.category-content p {
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 0;
}
.category-content p a {
  line-height: 1;
  position: relative;
  font-size: 15px;
  font-weight: 500;
  padding-right: 15px;
  display: inline-block;
}
.category-content p a i {
  font-weight: 300;
  font-size: 12px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  transition: all 0.3s ease-in-out;
}
.category-content p a i:first-child {
  right: 10%;
  visibility: hidden;
  opacity: 0;
}
.category-content p a i:last-child {
  right: 0;
}
.category-content p a:hover i:last-child {
  right: -10%;
  visibility: hidden;
  opacity: 0;
}
.category-content p a:hover i:first-child {
  right: 0;
  visibility: visible;
  opacity: 1;
}
.category-content p:hover a {
  color: var(--clr-theme-primary);
}
.category-item {
  background-color: #fff;
  box-shadow: 0px 10px 40px rgba(30, 30, 30, 0.1);
  border-radius: 10px;
  overflow: hidden;
}

.category-navigation {
  display: flex;
  gap: 10px;
}
.category-navigation i {
  font-weight: 300;
}
.category-navigation div {
  width: 50px;
  height: 50px;
  background: #FFFFFF;
  box-shadow: 0px 2px 0px #E1E2E6;
  border-radius: 100px;
  display: grid;
  place-items: center;
  font-size: 14px;
  color: #1E1E1E;
  -webkit-transition: all 0.4s linear 0s;
  -moz-transition: all 0.4s linear 0s;
  -ms-transition: all 0.4s linear 0s;
  -o-transition: all 0.4s linear 0s;
  transition: all 0.4s linear 0s;
}
.category-navigation div:hover {
  background-color: var(--clr-theme-primary);
  color: #fff;
}

.category-shape img {
  position: absolute;
}
.category-shape-1 {
  top: -75px;
  left: -25px;
  animation: animation-popup-1 4s linear 0s infinite alternate;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .category-shape-1 {
    left: 150px;
  }
}
@media (max-width: 767px) {
  .category-shape-1 {
    top: -65px;
    left: 150px;
  }
}
.category-shape-2 {
  top: -40px;
  right: 190px;
  animation: animation-leftRight-1 3s linear 0s infinite alternate;
}
@media (max-width: 767px) {
  .category-shape-2 {
    right: 15px;
  }
}
.category-shape-3 {
  left: 49%;
  transform: translateX(-50%);
  top: 80px;
  animation: animation-popup-2 1.2s linear 0s infinite alternate;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .category-shape-3 {
    left: calc(32% - 4px);
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .category-shape-3 {
    left: 31%;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .category-shape-3 {
    left: 31%;
  }
}
@media (max-width: 767px) {
  .category-shape-3 {
    display: none;
  }
}
.category-shape-4 {
  right: -40px;
  top: 40px;
  animation: animation-upDown-1 3s linear 0s infinite alternate;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .category-shape-4 {
    right: -25px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .category-shape-4 {
    right: -30px;
  }
}
@media (max-width: 767px) {
  .category-shape-4 {
    display: none;
  }
}
.category-img img {
  width: 100%;
}

@keyframes animation-popup-1 {
  0% {
    transform: scale(0.2) rotate(0deg);
  }
  50% {
    transform: scale(0.5) rotate(180deg);
  }
  90% {
    transform: scale(1) rotate(360deg);
  }
  100% {
    transform: scale(1) rotate(360deg);
  }
}
@keyframes animation-popup-2 {
  0% {
    transform: scale(0.2);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes animation-upDown-1 {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-40px);
  }
}
@keyframes animation-leftRight-1 {
  0% {
    transform: translateX(15px);
  }
  100% {
    transform: translateX(-15px);
  }
}
.h2_category-item {
  border: 1px solid rgba(30, 30, 30, 0.1);
  padding: 10px;
  background-color: #fff;
  border-radius: 6px;
}
.h2_category-content h5 {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 2px;
}
.h2_category-content h5:hover a {
  color: var(--clr-theme-primary);
}
.h2_category-content p {
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 0;
}
.h2_category-item {
  display: flex;
  align-items: center;
  gap: 20px;
}
.h2_category-img {
  width: 90px;
  flex-shrink: 0;
  border-radius: 6px 0 0 6px;
  overflow: hidden;
}

.h3_category-area {
  background: var(--clr-color-lightPink);
}
.h3_category-item {
  background: #FFF;
  box-shadow: 0px 2px 4px rgba(23, 22, 28, 0.1);
  padding: 40px;
  display: flex;
  gap: 25px;
  align-items: center;
  border-radius: 10px;
  position: relative;
  z-index: 1;
}
@media (max-width: 767px) {
  .h3_category-item {
    padding: 40px 25px;
    align-items: flex-start;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .h3_category-item {
    padding: 40px 40px;
    align-items: center;
  }
}
.h3_category-item-icon {
  width: 70px;
  height: 70px;
  background: linear-gradient(135deg, #E2C3E8 0%, rgba(226, 195, 232, 0) 100%);
  border-radius: 50%;
  display: grid;
  place-items: center;
}
.h3_category-item-icon i {
  color: var(--clr-body-heading);
  font-size: 30px;
}
.h3_category-item-content h5 {
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 5px;
}
.h3_category-item-content h5:hover a {
  color: var(--clr-theme-primary-3);
}
.h3_category-item-content p {
  margin-bottom: 0;
}
.h3_category-btn {
  position: absolute;
  right: 35px;
  top: 50%;
  transform: translateY(-50%);
  opacity: 0;
  visibility: hidden;
  -webkit-transition: all 0.3s linear 0s;
  -moz-transition: all 0.3s linear 0s;
  -ms-transition: all 0.3s linear 0s;
  -o-transition: all 0.3s linear 0s;
  transition: all 0.3s linear 0s;
}
.h3_category-btn a {
  width: 40px;
  height: 40px;
  background: var(--clr-theme-primary-3);
  display: grid;
  place-items: center;
  color: #fff;
  font-size: 14px;
  border-radius: 6px;
  box-shadow: 0px 0px 5px 0px rgba(32, 42, 61, 0.4);
}
@media (max-width: 575px) {
  .h3_category-btn {
    position: static;
    transform: unset;
    right: unset;
    top: unset;
    opacity: 1;
    visibility: visible;
    margin-top: 12px;
  }
}

.h3_category-item:hover .h3_category-btn {
  opacity: 1;
  visibility: visible;
}

.h4_category-area {
  background-color: #ECECF9;
}
.h4_category-item {
  display: grid;
  place-items: center;
  text-align: center;
  padding: 40px 0 35px;
  border: 1px solid rgba(30, 30, 30, 0.09);
  -webkit-transition: all 0.3s linear 0s;
  -moz-transition: all 0.3s linear 0s;
  -ms-transition: all 0.3s linear 0s;
  -o-transition: all 0.3s linear 0s;
  transition: all 0.3s linear 0s;
}
.h4_category-item-icon {
  width: 80px;
  height: 80px;
  background: #fff;
  display: grid;
  place-items: center;
  border-radius: 5px;
  box-shadow: 0px 6px 30px 0px rgba(10, 10, 10, 0.1);
  margin-bottom: 25px;
  -webkit-transition: all 0.3s linear 0s;
  -moz-transition: all 0.3s linear 0s;
  -ms-transition: all 0.3s linear 0s;
  -o-transition: all 0.3s linear 0s;
  transition: all 0.3s linear 0s;
  border: 1px solid transparent;
}
.h4_category-item-icon i {
  font-size: 40px;
  color: var(--clr-theme-primary-4);
}
.h4_category-item-content h5 {
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 5px;
}
.h4_category-item-content h5:hover a {
  color: var(--clr-theme-primary-4);
}
.h4_category-item-content p {
  margin-bottom: 0;
}
.h4_category-item:hover {
  border-radius: 10px;
  background: #FFF;
  box-shadow: 0px 14px 50px 0px rgba(10, 10, 10, 0.14);
}
.h4_category-item:hover .h4_category-item-icon {
  border-color: rgba(10, 10, 10, 0.1);
  box-shadow: none;
}

.h4_category-area .col-xl-3:first-child .h4_category-item {
  border-width: 0;
}
@media (max-width: 767px) {
  .h4_category-area .col-xl-3:first-child .h4_category-item {
    border-width: 0 0 1px 0;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .h4_category-area .col-xl-3:first-child .h4_category-item {
    border-width: 0;
  }
}
.h4_category-area .col-xl-3:nth-child(2) .h4_category-item {
  border-width: 0 0 0 1px;
}
@media (max-width: 767px) {
  .h4_category-area .col-xl-3:nth-child(2) .h4_category-item {
    border-width: 0 0 1px 0;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .h4_category-area .col-xl-3:nth-child(2) .h4_category-item {
    border-width: 0 0 0 1px;
  }
}
.h4_category-area .col-xl-3:nth-child(3) .h4_category-item {
  border-width: 0 1px 0 1px;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .h4_category-area .col-xl-3:nth-child(3) .h4_category-item {
    border-width: 1px 0 0 0;
  }
}
@media (max-width: 767px) {
  .h4_category-area .col-xl-3:nth-child(3) .h4_category-item {
    border-width: 0 0 1px 0;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .h4_category-area .col-xl-3:nth-child(3) .h4_category-item {
    border-width: 1px 0 0 0;
  }
}
.h4_category-area .col-xl-3:nth-child(4) .h4_category-item {
  border-width: 0;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .h4_category-area .col-xl-3:nth-child(4) .h4_category-item {
    border-width: 1px 0 0 1px;
  }
}
@media (max-width: 767px) {
  .h4_category-area .col-xl-3:nth-child(4) .h4_category-item {
    border-width: 0 0 1px 0;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .h4_category-area .col-xl-3:nth-child(4) .h4_category-item {
    border-width: 1px 0 0 1px;
  }
}
.h4_category-area .col-xl-3:nth-child(5) .h4_category-item {
  border-width: 1px 0 0 0;
}
@media (max-width: 767px) {
  .h4_category-area .col-xl-3:nth-child(5) .h4_category-item {
    border-width: 0 0 1px 0;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .h4_category-area .col-xl-3:nth-child(5) .h4_category-item {
    border-width: 1px 0 0 0;
  }
}
.h4_category-area .col-xl-3:nth-child(6) .h4_category-item {
  border-width: 1px 0 0 1px;
}
@media (max-width: 767px) {
  .h4_category-area .col-xl-3:nth-child(6) .h4_category-item {
    border-width: 0 0 1px 0;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .h4_category-area .col-xl-3:nth-child(6) .h4_category-item {
    border-width: 1px 0 0 1px;
  }
}
.h4_category-area .col-xl-3:nth-child(7) .h4_category-item {
  border-width: 1px 1px 0 1px;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .h4_category-area .col-xl-3:nth-child(7) .h4_category-item {
    border-width: 1px 0 0 0;
  }
}
@media (max-width: 767px) {
  .h4_category-area .col-xl-3:nth-child(7) .h4_category-item {
    border-width: 0 0 1px 0;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .h4_category-area .col-xl-3:nth-child(7) .h4_category-item {
    border-width: 1px 0 0 0;
  }
}
.h4_category-area .col-xl-3:last-child .h4_category-item {
  border-width: 1px 0 0 0;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .h4_category-area .col-xl-3:last-child .h4_category-item {
    border-width: 1px 0 0 1px;
  }
}
@media (max-width: 767px) {
  .h4_category-area .col-xl-3:last-child .h4_category-item {
    border-width: 0 0 0 0;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .h4_category-area .col-xl-3:last-child .h4_category-item {
    border-width: 1px 0 0 1px;
  }
}

.h5_category-item {
  border: 1px solid rgba(10, 10, 10, 0.14);
  padding: 41px 45px 41px;
  -webkit-transition: all 0.3s linear 0s;
  -moz-transition: all 0.3s linear 0s;
  -ms-transition: all 0.3s linear 0s;
  -o-transition: all 0.3s linear 0s;
  transition: all 0.3s linear 0s;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .h5_category-item {
    padding: 41px 30px 41px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .h5_category-item {
    padding: 41px 30px 41px;
  }
}
.h5_category-item-icon {
  margin-bottom: 85px;
}
.h5_category-item-icon i {
  font-size: 50px;
  color: var(--clr-body-heading);
  -webkit-transition: all 0.3s linear 0s;
  -moz-transition: all 0.3s linear 0s;
  -ms-transition: all 0.3s linear 0s;
  -o-transition: all 0.3s linear 0s;
  transition: all 0.3s linear 0s;
}
.h5_category-item-content h5 {
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 4px;
  -webkit-transition: all 0.3s linear 0s;
  -moz-transition: all 0.3s linear 0s;
  -ms-transition: all 0.3s linear 0s;
  -o-transition: all 0.3s linear 0s;
  transition: all 0.3s linear 0s;
}
.h5_category-item-content h5:hover a {
  color: var(--clr-theme-primary-5);
}
.h5_category-item-content p {
  font-size: 16px;
  margin-bottom: 0;
  color: var(--clr-body-heading);
  -webkit-transition: all 0.3s linear 0s;
  -moz-transition: all 0.3s linear 0s;
  -ms-transition: all 0.3s linear 0s;
  -o-transition: all 0.3s linear 0s;
  transition: all 0.3s linear 0s;
}
.h5_category-item:hover {
  background-color: var(--clr-body-heading);
  border-color: transparent;
}
.h5_category-item:hover .h5_category-item-icon i {
  color: #fff;
}
.h5_category-item:hover .h5_category-item-content h5 {
  color: #fff;
}
.h5_category-item:hover .h5_category-item-content p {
  color: #fff;
}

.h8_category-item {
  border-radius: 10px;
  background: #FFF;
  box-shadow: 0px 5px 25px 0px rgba(8, 5, 33, 0.08);
  padding: 40px 35px 32px;
  text-align: center;
}

.h8_category-icon i {
  color: rgb(57, 91, 223);
  font-size: 35px;
  display: inline-block;
  margin-bottom: 20px;
}

.h8_category-title {
  color: #021936;
  text-align: center;
  font-size: 18px;
  font-weight: 600;
  line-height: 1.33;
  margin-bottom: 5px;
}

.h8_category-text {
  color: rgba(2, 25, 54, 0.7);
  text-align: center;
  font-size: 15px;
  font-weight: 400;
  margin-bottom: 0;
}

.h8_category-wrap {
  position: relative;
  z-index: 1;
}

.h8_category-navigation div {
  border-radius: 40px;
  border: 1px solid rgba(2, 25, 54, 0.1);
  width: 40px;
  height: 40px;
  display: grid;
  place-items: center;
  position: absolute;
  left: -70px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 9;
  -webkit-transition: all 0.3s linear 0s;
  -moz-transition: all 0.3s linear 0s;
  -ms-transition: all 0.3s linear 0s;
  -o-transition: all 0.3s linear 0s;
  transition: all 0.3s linear 0s;
}
@media only screen and (min-width: 1400px) and (max-width: 1599px) {
  .h8_category-navigation div {
    left: -50px;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .h8_category-navigation div {
    left: -40px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .h8_category-navigation div {
    left: -25px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .h8_category-navigation div {
    left: -25px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .h8_category-navigation div {
    left: -25px;
  }
}
.h8_category-navigation div.h8_category-next {
  left: auto;
  right: -70px;
}
@media only screen and (min-width: 1400px) and (max-width: 1599px) {
  .h8_category-navigation div.h8_category-next {
    right: -50px;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .h8_category-navigation div.h8_category-next {
    right: -40px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .h8_category-navigation div.h8_category-next {
    right: -25px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .h8_category-navigation div.h8_category-next {
    right: -25px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .h8_category-navigation div.h8_category-next {
    right: -25px;
  }
}
.h8_category-navigation div svg {
  color: #021936;
  -webkit-transition: all 0.3s linear 0s;
  -moz-transition: all 0.3s linear 0s;
  -ms-transition: all 0.3s linear 0s;
  -o-transition: all 0.3s linear 0s;
  transition: all 0.3s linear 0s;
}
.h8_category-navigation div:hover {
  background-color: #395BDF;
}
.h8_category-navigation div:hover svg {
  color: #fff;
}

.h8_category-item .h8_category-icon > i {
  transition: all 0.3s linear 0s;
}

.h8_category-item:hover .h8_category-icon > i {
  transform: scale(1.1) translateY(-5px);
}

.h10_category-area {
  background: #E1EEDA;
}

.h10_category-item {
  background: #F2F4F8;
  padding: 40px 40px 30px;
  border: 1px dashed transparent;
  -webkit-transition: all 0.3s linear 0s;
  -moz-transition: all 0.3s linear 0s;
  -ms-transition: all 0.3s linear 0s;
  -o-transition: all 0.3s linear 0s;
  transition: all 0.3s linear 0s;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .h10_category-item {
    padding: 40px 25px 30px;
  }
}
.h10_category-item-icon {
  margin-bottom: 20px;
}
.h10_category-item-icon svg {
  width: 50px;
  color: #2C2C2C;
}
.h10_category-item-title {
  color: #2C2C2C;
  font-size: 20px;
  font-weight: 500;
  line-height: 1.2;
  margin-bottom: 5px;
}
.h10_category-item p {
  color: #2C2C2C;
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 0;
}
.h10_category-item:hover {
  border-color: #4846AB;
}

.h10_category_bg-2 {
  background-color: #F8F2F2;
}

.h10_category_bg-3 {
  background-color: #E8F6EB;
}

.h10_category_bg-4 {
  background-color: #F8F6F2;
}

.h10_category_bg-5 {
  background-color: #F4F8F2;
}

.h10_category_bg-6 {
  background-color: #E8F6F5;
}

.h10_category_bg-7 {
  background-color: #F7F2F8;
}

.h10_category_bg-8 {
  background-color: #E8F6F5;
}

.h10_category-item-btn {
  display: flex;
  justify-content: center;
}
.h10_category-item-btn a {
  color: #4846AB;
  font-size: 16px;
  font-weight: 500;
  text-transform: capitalize;
  display: flex;
  align-items: center;
  padding: 10px 20px;
  gap: 8px;
  border: 1px solid #5F5DB4;
  border-radius: 4px;
  line-height: 1;
}
.h10_category-item-btn a:hover {
  background-color: var(--clr-theme-primary-10);
  color: #fff;
}

.h10_category-item .h10_category-item-icon > svg {
  transition: all 0.3s linear 0s;
}

.h10_category-item:hover .h10_category-item-icon > svg {
  transform: scale(1.1) translateY(-5px);
}

/*
************
*************************
08. course
******************************************************* 
*************************************************************** */
.course-wrap {
  background-color: var(--clr-color-lightPurple);
}
.course-item {
  background-color: #fff;
  padding: 10px;
  border-radius: 10px;
  box-shadow: 0px 20px 80px rgba(30, 30, 30, 0.1);
  position: relative;
  z-index: auto;
}
.course-img {
  border-radius: 10px 10px 0 0;
  overflow: hidden;
}
.course-img img {
  width: 100%;
}
.course-content {
  padding-left: 15px;
  padding-right: 15px;
}
.course-content-top {
  display: flex;
  align-items: center;
  gap: 10px;
  padding-top: 25px;
  padding-bottom: 20px;
}
.course-content-title {
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
  margin-bottom: 25px;
}
.course-content-title:hover a {
  color: var(--clr-theme-primary);
}
.course-content-bottom {
  display: flex;
  justify-content: space-between;
  border-top: 1px solid rgba(30, 30, 30, 0.1);
  padding: 15px 0 6px;
  align-items: center;
}
.course-top-icon {
  width: 30px;
  height: 30px;
  border: 1px solid rgba(30, 30, 30, 0.1);
  border-radius: 4px;
  display: grid;
  place-items: center;
}
.course-top-icon img {
  width: 20px;
}
.course-top-title h6 {
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 0;
  color: rgba(30, 30, 30, 0.6);
}
.course-bottom-info {
  display: flex;
  align-items: center;
  gap: 20px;
}
.course-bottom-info span {
  display: flex;
  align-items: center;
  gap: 7px;
  color: rgba(30, 30, 30, 0.6);
}
.course-bottom-info span i {
  font-weight: 300;
}
.course-bottom-price span {
  display: flex;
  align-items: center;
  gap: 10px;
  color: var(--clr-theme-primary);
  font-weight: 700;
}
.course-bottom-price span del {
  color: #787878;
}

.course-hover-content {
  padding: 25px 25px 25px;
  position: absolute;
  left: 0;
  top: -100%;
  top: 0;
  background-color: #fff;
  border-radius: 10px;
  opacity: 0;
  z-index: 9;
  visibility: hidden;
  -webkit-transition: all 0.3s linear 0s;
  -moz-transition: all 0.3s linear 0s;
  -ms-transition: all 0.3s linear 0s;
  -o-transition: all 0.3s linear 0s;
  transition: all 0.3s linear 0s;
  transform: scale(0.6);
  min-height: 100%;
}
.course-hover-content-top {
  display: flex;
  align-items: center;
  gap: 10px;
  padding-bottom: 20px;
}
.course-hover-content-title {
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
  margin-bottom: 10px;
}
.course-hover-content-title:hover a {
  color: var(--clr-theme-primary);
}
.course-hover-content-text {
  font-size: 14px;
  line-height: 22px;
  color: rgba(30, 30, 30, 0.6);
  margin-bottom: 20px;
}
.course-hover-content-list {
  margin-bottom: 30px;
}
.course-hover-content-list li {
  font-size: 14px;
  line-height: 22px;
  color: rgba(30, 30, 30, 0.7);
  display: flex;
  line-height: 22px;
  gap: 10px;
  margin-bottom: 10px;
}
.course-hover-content-list li i {
  color: #1E1E1E;
  margin-top: 5px;
  font-weight: 300;
}
.course-hover-content-list li:last-child {
  margin-bottom: 0;
}
.course-hover-content-btn {
  display: flex;
  align-items: center;
  gap: 10px;
  width: 100%;
}
.course-hover-top-icon {
  width: 30px;
  height: 30px;
  border: 1px solid rgba(30, 30, 30, 0.1);
  border-radius: 4px;
  display: grid;
  place-items: center;
}
.course-hover-top-icon img {
  width: 20px;
}
.course-hover-top-title h6 {
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 0;
  color: rgba(30, 30, 30, 0.6);
}

.course-hover-fav-btn a {
  width: 40px;
  height: 40px;
  border: 1.5px solid rgba(30, 30, 30, 0.14);
  border-radius: 50%;
  display: grid;
  place-items: center;
  flex-shrink: 0;
  font-size: 15px;
  color: #1E1E1E;
  background-color: rgba(10, 10, 10, 0.08);
}
.course-hover-fav-btn a i {
  font-weight: 300;
}
.course-hover-fav-btn a:hover {
  background-color: var(--clr-theme-primary);
  border-color: transparent;
  color: #fff;
}
.course-hover-cart-btn {
  width: calc(100% - 40px);
}
.course-hover-content::before {
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  content: "";
  background-color: #fff;
  clip-path: polygon(0 50%, 100% 100%, 100% 0);
  width: 20px;
  height: 24px;
  z-index: -1;
  -webkit-transition: all 0.3s linear 0s;
  -moz-transition: all 0.3s linear 0s;
  -ms-transition: all 0.3s linear 0s;
  -o-transition: all 0.3s linear 0s;
  transition: all 0.3s linear 0s;
}

.course-btn.theme-btn {
  width: 100%;
  justify-content: center;
}

.course-item:hover .course-hover-content {
  opacity: 1;
  visibility: visible;
  top: 0;
  transform: scale(1);
}
.course-item:hover .course-hover-content::before {
  left: -15px;
}

.course-tab {
  margin-bottom: 25px;
  display: flex;
  justify-content: center;
}
.course-tab .nav {
  background-color: #fff;
  padding: 5px 5px;
  border-radius: 100px;
  display: flex;
  gap: 15px;
  box-shadow: 0px 2px 0px #E1E2E6;
}
.course-tab .nav .nav-item .nav-link {
  padding: 8px 30px;
  border-radius: 100px;
  font-size: 15px;
  font-weight: 500;
  line-height: 20px;
  -webkit-transition: all 0.3s linear 0s;
  -moz-transition: all 0.3s linear 0s;
  -ms-transition: all 0.3s linear 0s;
  -o-transition: all 0.3s linear 0s;
  transition: all 0.3s linear 0s;
  color: rgba(30, 30, 30, 0.6);
}
@media (max-width: 767px) {
  .course-tab .nav {
    justify-content: center;
    padding: 30px 30px;
  }
}

.course-tab .nav-pills .nav-link:hover,
.course-tab .nav-pills .nav-link.active,
.course-tab .nav-pills .show > .nav-link {
  color: #fff;
  background-color: var(--clr-theme-primary);
}

.h2_course-area {
  background-color: #F5F5F5;
}
.h2_course-item {
  background: #fff;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0px 20px 80px rgba(30, 30, 30, 0.1);
}
.h2_course-item-img {
  overflow: hidden;
}
.h2_course-item-img img {
  width: 100%;
  -webkit-transition: all 0.6s linear 0s;
  -moz-transition: all 0.6s linear 0s;
  -ms-transition: all 0.6s linear 0s;
  -o-transition: all 0.6s linear 0s;
  transition: all 0.6s linear 0s;
}
.h2_course-content {
  padding: 20px 30px 30px;
}
@media (max-width: 767px) {
  .h2_course-content {
    padding: 20px 25px 30px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .h2_course-content {
    padding: 20px 30px 30px;
  }
}
.h2_course-content-title {
  font-weight: 600;
  font-size: 20px;
  margin-bottom: 20px;
}
.h2_course-content-title:hover a {
  color: var(--clr-theme-primary);
}
.h2_course-content-top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 11px;
  gap: 10px;
}
.h2_course-content-info {
  display: flex;
  align-items: center;
  width: max-content;
  background: #F1F1F1;
  margin-bottom: 20px;
}
.h2_course-content-info span {
  display: flex;
  padding: 2px 15px;
  align-items: center;
  gap: 7px;
  font-size: 14px;
}
.h2_course-content-info span:first-child {
  border-right: 1px solid rgba(30, 30, 30, 0.14);
}
.h2_course-content-info span i {
  font-weight: 300;
}
.h2_course-content-text {
  font-size: 15px;
  line-height: 24px;
  margin-bottom: 24px;
}
.h2_course-content-author {
  display: flex;
  align-items: center;
  gap: 10px;
}
.h2_course-rating {
  display: flex;
  gap: 10px;
  align-items: center;
}
.h2_course-rating span {
  font-size: 15px;
  font-weight: 500;
  color: rgba(30, 30, 30, 0.7);
  display: block;
}
.h2_course-rating ul {
  display: flex;
  gap: 5px;
  align-items: center;
}
.h2_course-rating ul li {
  color: #FFA121;
  font-size: 14px;
}
@media (max-width: 767px) {
  .h2_course-rating {
    gap: 5px;
  }
  .h2_course-rating span {
    font-size: 14px;
  }
  .h2_course-rating ul li {
    font-size: 12px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .h2_course-rating {
    gap: 10px;
  }
  .h2_course-rating span {
    font-size: 15px;
  }
  .h2_course-rating ul li {
    font-size: 14px;
  }
}

.h2_course-save a {
  width: 40px;
  height: 40px;
  flex-shrink: 0;
  border: 1px solid rgba(30, 30, 30, 0.1);
  border-radius: 50%;
  display: grid;
  place-items: center;
  font-size: 14px;
  color: var(--clr-body-heading);
  background-color: rgba(10, 10, 10, 0.08);
}
.h2_course-save a i {
  font-weight: 300;
}
.h2_course-save a:hover {
  background-color: var(--clr-theme-primary);
  color: #fff;
  border-color: var(--clr-theme-primary);
}
.h2_course-author-info span {
  color: #A3A9B1;
  font-size: 14px;
  font-weight: 500;
}
.h2_course-author-info span a {
  color: var(--clr-body-heading);
}
.h2_course-author-info span a:hover {
  color: var(--clr-theme-primary);
}
.h2_course-author-img {
  width: 45px;
  height: 45px;
  border: 1px solid rgba(30, 30, 30, 0.14);
  border-radius: 50%;
  display: grid;
  place-items: center;
  flex-shrink: 0;
}
.h2_course-author-img img {
  width: 40px;
  height: 40px;
}

.h2_course-content-bottom {
  border-top: 1px solid rgba(30, 30, 30, 0.1);
  padding: 15px 30px 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.h2_course-bottom-price span {
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 18px;
  font-weight: 700;
  color: var(--clr-color-darkPink);
}
.h2_course-bottom-price span del {
  color: #787878;
  font-weight: 500;
}
.h2_course-bottom-btn a {
  line-height: 1;
  position: relative;
  font-size: 15px;
  font-weight: 500;
  padding-right: 15px;
  display: inline-block;
}
.h2_course-bottom-btn a i {
  font-size: 12px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  transition: all 0.3s ease-in-out;
  font-weight: 400;
}
.h2_course-bottom-btn a i:first-child {
  right: 10%;
  visibility: hidden;
  opacity: 0;
}
.h2_course-bottom-btn a i:last-child {
  right: 0;
}
.h2_course-bottom-btn a:hover i:last-child {
  right: -10%;
  visibility: hidden;
  opacity: 0;
}
.h2_course-bottom-btn a:hover i:first-child {
  right: 0;
  visibility: visible;
  opacity: 1;
}
.h2_course-bottom-btn a:hover {
  color: var(--clr-theme-primary);
}

.h2_course-tab .nav {
  justify-content: end;
}
@media only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .h2_course-tab .nav {
    justify-content: flex-start;
  }
}
.h2_course-tab .nav .nav-item {
  margin-bottom: 10px;
}
.h2_course-tab .nav .nav-item .nav-link {
  padding: 0;
  background: transparent;
  color: var(--clr-body-heading);
  font-size: 15px;
  margin-right: 25px;
  display: flex;
  position: relative;
  z-index: 1;
}
.h2_course-tab .nav .nav-item .nav-link span {
  position: absolute;
  left: 0;
  top: -25px;
  background-color: var(--clr-theme-primary);
  box-shadow: 0px 3px 3px rgba(18, 104, 235, 0.24);
  color: #fff;
  display: block;
  font-size: 12px;
  padding: 4px 8px;
  line-height: 1;
}
.h2_course-tab .nav .nav-item .nav-link span::before {
  position: absolute;
  left: 3px;
  bottom: -6px;
  content: "";
  width: 9px;
  height: 8px;
  background: var(--clr-theme-primary);
  clip-path: polygon(0 0, 50% 100%, 100% 0);
}
.h2_course-tab .nav .nav-item:last-child .nav-link {
  margin-right: 0;
}
.h2_course-tab .nav-pills .nav-link.active, .h2_course-tab .nav-pills .show > .nav-link {
  color: var(--clr-theme-primary);
}

.h2_course-item:hover .h2_course-item-img img {
  transform: scale(1.2);
}

.h3_course-tab {
  display: flex;
  justify-content: center;
}
.h3_course-tab .nav {
  background-color: #fff;
  padding: 5px 5px;
  border-radius: 6px;
  display: flex;
  gap: 15px;
  border: 1px solid rgba(30, 30, 30, 0.14);
}
.h3_course-tab .nav .nav-item .nav-link {
  padding: 8px 30px;
  border-radius: 6px;
  font-size: 15px;
  font-weight: 500;
  line-height: 20px;
  -webkit-transition: all 0.3s linear 0s;
  -moz-transition: all 0.3s linear 0s;
  -ms-transition: all 0.3s linear 0s;
  -o-transition: all 0.3s linear 0s;
  transition: all 0.3s linear 0s;
  color: rgba(30, 30, 30, 0.6);
}
@media (max-width: 767px) {
  .h3_course-tab .nav {
    justify-content: center;
    padding: 30px 30px;
  }
}
.h3_course-tab .nav-pills .nav-link:hover,
.h3_course-tab .nav-pills .nav-link.active,
.h3_course-tab .nav-pills .show > .nav-link {
  color: #fff;
  background-color: var(--clr-theme-primary-3);
}
.h3_course-item {
  background: #fff;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0px 2px 20px 0px rgba(30, 30, 30, 0.1);
}
.h3_course-item-top {
  padding: 25px 25px;
}
.h3_course-item-img {
  overflow: hidden;
  position: relative;
  z-index: 1;
  margin-bottom: 25px;
  border-radius: 6px;
}
.h3_course-item-img img {
  width: 100%;
  -webkit-transition: all 0.6s linear 0s;
  -moz-transition: all 0.6s linear 0s;
  -ms-transition: all 0.6s linear 0s;
  -o-transition: all 0.6s linear 0s;
  transition: all 0.6s linear 0s;
}
.h3_course-item-tag a {
  position: absolute;
  left: 15px;
  top: 15px;
  border-radius: 6px;
  background: var(--clr-theme-primary-3);
  box-shadow: 0px 2px 5px 0px rgba(32, 42, 61, 0.4);
  color: #fff;
  padding: 2px 13px;
  display: inline-block;
  font-size: 13px;
  font-weight: 500;
}
.h3_course-item-save a {
  position: absolute;
  right: 15px;
  top: 15px;
  z-index: 1;
  width: 40px;
  height: 40px;
  display: inline-grid;
  background: rgba(30, 30, 30, 0.48);
  place-items: center;
  color: #fff;
  font-size: 16px;
  opacity: 1;
  border-radius: 4px;
}
.h3_course-item-save a:hover {
  background: var(--clr-theme-primary-3);
}
.h3_course-content-info {
  margin-bottom: 15px;
}
.h3_course-content-info ul li {
  display: inline-block;
  margin-right: 20px;
  font-size: 14px;
  color: rgba(30, 30, 30, 0.6);
}
.h3_course-content-info ul li:last-child {
  margin-right: 0;
}
.h3_course-content-info ul li i {
  margin-right: 6px;
}
.h3_course-content-title {
  font-weight: 500;
  font-size: 20px;
  margin-bottom: 25px;
}
.h3_course-content-title:hover a {
  color: var(--clr-theme-primary-3);
}
.h3_course-content-price {
  display: flex;
  align-items: center;
  gap: 30px;
  margin-bottom: 5px;
}
.h3_course-content-price span {
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 20px;
  font-weight: 600;
  color: var(--clr-theme-primary-3);
}
.h3_course-content-price span del {
  color: rgba(30, 30, 30, 0.5);
  font-weight: 500;
  font-size: 18px;
}
.h3_course-content-price h5 {
  color: #FF002C;
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
  margin-bottom: 0;
}
.h3_course-item-bottom {
  border-top: 1px solid rgba(30, 30, 30, 0.1);
  padding: 15px 25px 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
@media (max-width: 767px) {
  .h3_course-item-bottom {
    flex-direction: column;
    gap: 10px;
    align-items: flex-start;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .h3_course-item-bottom {
    flex-direction: row;
    gap: 0;
    align-items: center;
  }
}
.h3_course-item-author {
  display: flex;
  align-items: center;
  gap: 10px;
}
.h3_course-item-author-img {
  width: 35px;
  height: 35px;
  border-radius: 50%;
}
.h3_course-item-author-info span {
  color: #79818D;
  font-size: 14px;
  font-weight: 500;
}
.h3_course-item-author-info span a {
  color: var(--clr-body-heading);
}
.h3_course-item-author-info span a:hover {
  color: var(--clr-theme-primary-3);
}
.h3_course-item-rating {
  display: flex;
  gap: 10px;
  align-items: center;
}
.h3_course-item-rating ul {
  display: flex;
  gap: 5px;
  align-items: center;
}
.h3_course-item-rating ul li {
  color: #FFA121;
  font-size: 14px;
}
.h3_course-item-rating span {
  font-size: 15px;
  font-weight: 500;
  color: rgba(30, 30, 30, 0.7);
  display: block;
}

.h3_course-item:hover .h3_course-item-img img {
  transform: scale(1.2);
}

.h4_course-tab .nav {
  display: flex;
  gap: 10px;
}
.h4_course-tab .nav .nav-item .nav-link {
  padding: 10px 30px;
  line-height: 1;
  font-size: 15px;
  font-weight: 500;
  -webkit-transition: all 0.3s linear 0s;
  -moz-transition: all 0.3s linear 0s;
  -ms-transition: all 0.3s linear 0s;
  -o-transition: all 0.3s linear 0s;
  transition: all 0.3s linear 0s;
  border: 1px solid rgba(10, 10, 10, 0.1);
  border-radius: 30px;
  color: rgba(30, 30, 30, 0.6);
}
.h4_course-tab .nav-pills .nav-link:hover,
.h4_course-tab .nav-pills .nav-link.active,
.h4_course-tab .nav-pills .show > .nav-link {
  color: #fff;
  background-color: var(--clr-theme-primary-4);
  border-color: transparent;
}
.h4_course-item {
  background: #fff;
  border-radius: 10px;
  overflow: hidden;
  border: 1px solid rgba(10, 10, 10, 0.1);
  padding: 30px 30px 23px;
}
.h4_course-item-img {
  overflow: hidden;
  border-radius: 6px;
}
.h4_course-item-img img {
  width: 100%;
  -webkit-transition: all 0.6s linear 0s;
  -moz-transition: all 0.6s linear 0s;
  -ms-transition: all 0.6s linear 0s;
  -o-transition: all 0.6s linear 0s;
  transition: all 0.6s linear 0s;
}
.h4_course-content {
  position: relative;
  z-index: 1;
  padding-top: 25px;
}
.h4_course-content-price {
  position: absolute;
  right: 30px;
  top: -12px;
  z-index: 9;
}
.h4_course-content-price span {
  display: block;
  font-size: 14px;
  font-weight: 600;
  color: var(--clr-body-heading);
  background-color: var(--clr-theme-primary-4);
  padding: 0 13px;
  height: 24px;
  line-height: 25px;
  border-radius: 4px;
}
.h4_course-content-tag a {
  border-radius: 4px;
  border: 1px solid rgba(10, 10, 10, 0.1);
  color: rgba(30, 30, 30, 0.6);
  padding: 7px 13px;
  display: inline-block;
  font-size: 15px;
  font-weight: 400;
  line-height: 1;
  margin-bottom: 12px;
}
.h4_course-content-tag a:hover {
  background-color: var(--clr-theme-primary-4);
  color: var(--clr-body-heading);
  border-color: transparent;
  opacity: 1;
}
.h4_course-content-info {
  margin-bottom: 15px;
}
.h4_course-content-info ul li {
  display: inline-block;
  margin-right: 20px;
  font-size: 14px;
  color: rgba(30, 30, 30, 0.6);
}
.h4_course-content-info ul li:last-child {
  margin-right: 0;
}
.h4_course-content-info ul li i {
  margin-right: 6px;
}
.h4_course-content-title {
  font-weight: 500;
  font-size: 20px;
  margin-bottom: 15px;
}
.h4_course-content-title:hover a {
  color: var(--clr-theme-primary-4);
}
.h4_course-content-rating {
  display: flex;
  gap: 10px;
  align-items: center;
  margin-bottom: 20px;
}
.h4_course-content-rating ul {
  display: flex;
  gap: 5px;
  align-items: center;
}
.h4_course-content-rating ul li {
  color: #FFA121;
  font-size: 14px;
}
.h4_course-content-rating span {
  font-size: 15px;
  font-weight: 500;
  color: rgba(30, 30, 30, 0.7);
  display: block;
}
.h4_course-content-btn a {
  display: inline-flex;
  align-items: center;
  gap: 8px;
  font-size: 16px;
  font-weight: 500;
  color: var(--clr-body-heading);
}
.h4_course-content-btn a:hover {
  color: var(--clr-theme-primary-4);
}

.h4_course-item:hover .h4_course-item-img img {
  transform: scale(1.2);
}

.h5_course-tab .nav {
  border-radius: 0;
}
.h5_course-tab .nav .nav-item .nav-link {
  border-radius: 0;
}
.h5_course-tab .nav-pills .nav-link:hover,
.h5_course-tab .nav-pills .nav-link.active,
.h5_course-tab .nav-pills .show > .nav-link {
  color: var(--clr-body-heading);
  background-color: var(--clr-theme-primary-5);
}
.h5_course-area .course-hover-fav-btn a:hover {
  background-color: var(--clr-theme-primary-5);
  border-color: transparent;
  color: var(--clr-body-heading);
}
.h5_course-area .course-hover-content-title:hover a {
  color: var(--clr-theme-primary);
}

.h8_course-area {
  position: relative;
  z-index: 1;
}
.h8_course-shape {
  position: absolute;
  left: 47%;
  transform: translateX(-50%);
  top: 130px;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .h8_course-shape {
    left: 50%;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .h8_course-shape {
    left: 57%;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .h8_course-shape {
    left: 70%;
  }
}
@media (max-width: 767px) {
  .h8_course-shape {
    display: none;
  }
}
.h8_course-active {
  overflow-x: scroll;
}
.h8_course-item {
  display: flex;
  border-radius: 10px;
  overflow: hidden;
  background: #FFF;
  position: relative;
  min-height: 100%;
  
}
.h8_course-item.h8_course_common .h8_course-content-3 {
  opacity: 0;
  visibility: hidden;
  transform: translateX(-80px);
  -webkit-transition: all 0.3s linear 0s;
  -moz-transition: all 0.3s linear 0s;
  -ms-transition: all 0.3s linear 0s;
  -o-transition: all 0.3s linear 0s;
  transition: all 0.3s linear 0s;
}
.h8_course-item.small {
  width: 80px;
  flex-shrink: 0;
}
.h8_course-item.expand {
  width: 690px;
  flex-shrink: 0;
  box-shadow: 0px 6px 60px 0px rgba(8, 5, 33, 0.1);
}
.h8_course-item.expand .h8_course-content-3 {
  opacity: 1;
  visibility: visible;
  transform: translateX(0);
}
.h8_course-item.expand .h8_course-m-title {
  background-color: #021936;
}
.h8_course-item.expand .h8_course-m-count svg {
  color: #fff;
}
.h8_course-item.expand .h8_course-m-title-vertical {
  color: #fff;
}
.h8_course-m-title {
  width: 80px;
  z-index: 50;
  cursor: pointer;
  background-color: #F4F7FF;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 55px 0;
}
.h8_course-m-title-vertical {
  display: block;
  color: #021936;
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
  writing-mode: vertical-rl;
  text-orientation: mixed;
}
.h8_course-content-3 {
  position: relative;
  height: 100%;
  padding: 90px 85px 90px;
  background-color: #fff;
}
@media only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .h8_course-content-3 {
    padding: 90px 60px 90px;
  }
}

.h8_course-wrap {
  display: flex;
  width: 100%;
  padding: 0;
  gap: 50px;
}
@media (max-width: 767px) {
  .h8_course-wrap {
    gap: 30px;
  }
}
.h8_course-wrap .accordion-body-img {
  position: relative;
  z-index: 1;
  margin-bottom: 35px;
}
.h8_course-wrap .accordion-body-img img {
  width: 100%;
  z-index: -2;
  position: relative;
}
.h8_course-wrap .accordion-body-img span {
  border-radius: 10px;
  background: #395BDF;
  color: #FFF;
  text-align: center;
  font-size: 32px;
  font-weight: 600;
  line-height: 1;
  width: 135px;
  display: inline-grid;
  place-items: center;
  position: absolute;
  right: 0;
  top: 0;
  height: 85px;
}
.h8_course-wrap .accordion-body-img span::before {
  position: absolute;
  left: -15px;
  top: 0;
  width: calc(100% + 15px);
  height: calc(100% + 15px);
  content: "";
  background: #fff;
  z-index: -1;
  border-radius: 0 0 0 10px;
}
.h8_course-wrap .accordion-body-bottom {
  display: grid;
  grid-template-columns: 1fr 115px;
  grid-gap: 45px;
}
.h8_course-wrap .accordion-body-content h4 {
  color: #021936;
  font-size: 26px;
  font-weight: 600;
  line-height: 1.2;
  margin-bottom: 13px;
}
.h8_course-wrap .accordion-body-content p {
  color: #021936;
  font-size: 15px;
  font-weight: 400;
  line-height: 24px;
  margin-bottom: 10px;
}
.h8_course-wrap .accordion-body-content-rating {
  display: flex;
  align-items: end;
  gap: 10px;
  margin-top: 32px;
}
.h8_course-wrap .accordion-body-right {
  display: flex;
  flex-direction: column;
  align-items: end;
}
.h8_course-wrap .accordion_left_rating {
  display: flex;
  gap: 5px;
}
.h8_course-wrap .accordion_left_rating li {
  color: #F2A22A;
  font-size: 15px;
}
.h8_course-wrap .accordion_left_img {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}
.h8_course-wrap .accordion_left_img span {
  width: 30px;
  height: 30px;
  border-radius: 50px;
  background: #FFF;
  box-shadow: 0px 6px 30px 0px rgba(25, 35, 53, 0.15);
  display: grid;
  place-items: center;
  color: #021936;
  font-size: 12px;
  font-weight: 500;
  line-height: 1;
  margin-left: -10px;
}
.h8_course-wrap .accordion_right h2 {
  color: #021936;
  font-size: 60px;
  font-weight: 500;
  line-height: 1;
  margin-bottom: 0;
  letter-spacing: -2px;
}
.h8_course-wrap .accordion_right h2 span {
  color: rgba(2, 25, 54, 0.7);
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: normal;
}
.h8_course-wrap .inner-info {
  text-align: center;
  width: 95px;
  display: grid;
  place-items: center;
  padding: 10px 10px 15px;
  position: relative;
  z-index: 99;
}
.h8_course-wrap .inner-info::after {
  position: absolute;
  left: 0;
  top: 0;
  content: "";
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.3);
  box-shadow: 0px 6px 50px 0px rgba(25, 35, 53, 0.1);
  backdrop-filter: blur(20px);
  border: 2px solid #FFF;
  border-radius: 10px;
  z-index: -1;
}
.h8_course-wrap .inner-info::before {
  position: absolute;
  left: -20px;
  bottom: -30px;
  content: "";
  width: 70px;
  height: 80px;
  border-radius: 10px;
  opacity: 0.7;
  background: linear-gradient(143deg, #F14D5D 0%, rgba(241, 77, 93, 0) 100%);
  z-index: -2;
}
.h8_course-wrap .inner-info-2::before {
  background: linear-gradient(143deg, #2F57EF 0%, rgba(47, 87, 239, 0) 100%);
}
.h8_course-wrap .inner-info h3 {
  color: #021936;
  font-size: 50px;
  font-weight: 500;
  margin-bottom: 5px;
  line-height: 1.1;
}
.h8_course-wrap .inner-info span {
  color: #021936;
  font-size: 15px;
  font-weight: 500;
  line-height: 1;
}

.h10_course-tab .nav {
  justify-content: end;
}
@media only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .h10_course-tab .nav {
    justify-content: flex-start;
  }
}
.h10_course-tab .nav .nav-item {
  margin-bottom: 10px;
}
.h10_course-tab .nav .nav-item .nav-link {
  padding: 0;
  background: transparent;
  color: var(--clr-body-heading);
  font-size: 15px;
  margin-right: 25px;
  display: flex;
  position: relative;
  z-index: 1;
}
.h10_course-tab .nav .nav-item .nav-link span {
  position: absolute;
  left: 0;
  top: -25px;
  background-color: var(--clr-theme-primary);
  box-shadow: 0px 3px 3px rgba(18, 104, 235, 0.24);
  color: #fff;
  display: block;
  font-size: 12px;
  padding: 4px 8px;
  line-height: 1;
}
.h10_course-tab .nav .nav-item .nav-link span::before {
  position: absolute;
  left: 3px;
  bottom: -6px;
  content: "";
  width: 9px;
  height: 8px;
  background: var(--clr-theme-primary);
  clip-path: polygon(0 0, 50% 100%, 100% 0);
}
.h10_course-tab .nav .nav-item:last-child .nav-link {
  margin-right: 0;
}
.h10_course-tab .nav-pills .nav-link.active, .h10_course-tab .nav-pills .show > .nav-link {
  color: var(--clr-theme-primary);
}
.h10_course-item {
  background: #fff;
  overflow: hidden;
  border: 1px solid rgba(44, 44, 44, 0.1);
  padding: 40px 30px 33px;
  -webkit-transition: all 0.3s linear 0s;
  -moz-transition: all 0.3s linear 0s;
  -ms-transition: all 0.3s linear 0s;
  -o-transition: all 0.3s linear 0s;
  transition: all 0.3s linear 0s;
}
@media (max-width: 767px) {
  .h10_course-item {
    padding: 40px 25px 33px;
  }
}
.h10_course-item-img {
  overflow: hidden;
  border-radius: 6px;
  position: relative;
  z-index: 1;
}
.h10_course-item-img img {
  width: 100%;
  -webkit-transition: all 0.6s linear 0s;
  -moz-transition: all 0.6s linear 0s;
  -ms-transition: all 0.6s linear 0s;
  -o-transition: all 0.6s linear 0s;
  transition: all 0.6s linear 0s;
}
.h10_course-item-img-price {
  position: absolute;
  right: 20px;
  top: 20px;
  z-index: 9;
}
.h10_course-item-img-price span {
  display: block;
  font-size: 14px;
  font-weight: 600;
  color: #fff;
  background-color: var(--clr-theme-primary-10);
  padding: 0 13px;
  height: 24px;
  line-height: 25px;
  border-radius: 4px;
}
.h10_course-content {
  position: relative;
  z-index: 1;
  padding-top: 25px;
}
.h10_course-content-tag a {
  border-radius: 4px;
  border: 1px solid rgba(44, 44, 44, 0.14);
  color: rgba(44, 44, 44, 0.6);
  padding: 7px 13px;
  display: inline-block;
  font-size: 15px;
  font-weight: 400;
  line-height: 1;
}
.h10_course-content-tag a:hover {
  background-color: var(--clr-theme-primary-10);
  color: #fff;
  border-color: transparent;
  opacity: 1;
}
.h10_course-content-info {
  margin-bottom: 15px;
}
.h10_course-content-info ul li {
  display: inline-block;
  margin-right: 20px;
  font-size: 14px;
  color: rgba(44, 44, 44, 0.6);
}
.h10_course-content-info ul li:last-child {
  margin-right: 0;
}
.h10_course-content-info ul li i {
  margin-right: 6px;
}
.h10_course-content-title {
  font-weight: 600;
  font-size: 20px;
  margin-bottom: 18px;
  color: #2C2C2C;
}
.h10_course-content-title:hover a {
  color: var(--clr-theme-primary-10);
}
.h10_course-content-rating {
  display: flex;
  gap: 10px;
  align-items: center;
}
.h10_course-content-rating ul {
  display: flex;
  gap: 5px;
  align-items: center;
}
.h10_course-content-rating ul li {
  color: #FFA121;
  font-size: 14px;
}
.h10_course-content-rating span {
  font-size: 15px;
  font-weight: 400;
  color: rgba(44, 44, 44, 0.6);
  display: block;
}
.h10_course-content-btn a {
  display: inline-flex;
  align-items: center;
  gap: 8px;
  font-size: 16px;
  font-weight: 500;
  color: #2C2C2C;
}
.h10_course-content-btn a:hover {
  color: var(--clr-theme-primary-10);
}

.h10_course-item:hover {
  border-color: #4846AB;
  border-style: dashed;
}
.h10_course-item:hover .h10_course-item-img img {
  transform: scale(1.2);
}

.gap-25 {
  gap: 25px;
  row-gap: 10px;
}

.innerPage_course-left p {
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 0;
  color: rgba(30, 30, 30, 0.7);
}

.innerPage_course-search form {
  position: relative;
  z-index: 1;
}
.innerPage_course-search form input {
  width: 100%;
  height: 40px;
  line-height: 40px;
  background-color: #F5F5F5;
  border: none;
  border-radius: 6px;
  padding: 0 20px;
  font-size: 15px;
  color: rgba(30, 30, 30, 0.6);
  padding-right: 55px;
}
.innerPage_course-search form input::-webkit-input-placeholder {
  color: rgba(30, 30, 30, 0.6);
  font-size: 15px;
  opacity: 1;
  -webkit-transition: all 0.5s linear 0s;
  -moz-transition: all 0.5s linear 0s;
  -ms-transition: all 0.5s linear 0s;
  -o-transition: all 0.5s linear 0s;
  transition: all 0.5s linear 0s;
}
.innerPage_course-search form input:-moz-placeholder {
  color: rgba(30, 30, 30, 0.6);
  font-size: 15px;
  opacity: 1;
  -webkit-transition: all 0.5s linear 0s;
  -moz-transition: all 0.5s linear 0s;
  -ms-transition: all 0.5s linear 0s;
  -o-transition: all 0.5s linear 0s;
  transition: all 0.5s linear 0s;
}
.innerPage_course-search form input::-moz-placeholder {
  color: rgba(30, 30, 30, 0.6);
  font-size: 15px;
  opacity: 1;
  -webkit-transition: all 0.5s linear 0s;
  -moz-transition: all 0.5s linear 0s;
  -ms-transition: all 0.5s linear 0s;
  -o-transition: all 0.5s linear 0s;
  transition: all 0.5s linear 0s;
}
.innerPage_course-search form input:-ms-input-placeholder {
  color: rgba(30, 30, 30, 0.6);
  font-size: 15px;
  opacity: 1;
  -webkit-transition: all 0.5s linear 0s;
  -moz-transition: all 0.5s linear 0s;
  -ms-transition: all 0.5s linear 0s;
  -o-transition: all 0.5s linear 0s;
  transition: all 0.5s linear 0s;
}
@media (min-width: 992px) {
  .innerPage_course-search form input {
    min-width: 300px;
  }
}
.innerPage_course-search form button {
  position: absolute;
  right: 20px;
  top: 0;
  height: 100%;
  font-size: 15px;
  color: #1E1E1E;
  border: none;
  background: transparent;
  padding: 0;
}
.innerPage_course-search form button i {
  font-weight: 300;
}

.innerPage_course-right {
  display: flex;
  align-items: center;
  justify-content: end;
  gap: 15px;
}
@media (max-width: 767px) {
  .innerPage_course-right {
    justify-content: flex-start;
    flex-wrap: wrap;
  }
}
.innerPage_course-right .nice-select.innerPage_course-category-list.has-nice-select {
  height: 40px;
  line-height: 40px;
  padding: 0 16px;
  width: 190px;
  border-radius: 6px;
  border: 1px solid rgba(30, 30, 30, 0.1);
}
.innerPage_course-right .nice-select.innerPage_course-category-list.has-nice-select ul li {
  font-size: 15px;
  font-weight: 400;
  width: 100%;
  height: 40px;
  line-height: 40px;
  padding: 0 15px;
  min-height: 40px;
  color: rgba(30, 30, 30, 0.6);
}
.innerPage_course-right .nice-select.innerPage_course-category-list.has-nice-select.open ul {
  width: 100%;
  border-radius: 6px;
  border: none;
  margin-top: 1px;
}
.innerPage_course-right .nice-select::after {
  border-bottom: 1.5px solid rgba(30, 30, 30, 0.6);
  border-right: 1.5px solid rgba(30, 30, 30, 0.6);
  content: "";
  display: block;
  height: 7px;
  margin-top: -1px;
  pointer-events: none;
  position: absolute;
  right: 18px;
  top: 50%;
  transform-origin: 50% 50%;
  transform: rotate(45deg) translateY(-50%);
  transition: all 0.3s ease-in-out;
  width: 7px;
}

.pagination-area ul {
  display: flex;
  align-items: center;
  gap: 10px;
}
.pagination-area ul li a {
  width: 45px;
  height: 45px;
  display: grid;
  place-items: center;
  font-size: 14px;
  font-weight: 500;
  color: rgba(30, 30, 30, 0.6);
  border: 1px solid rgba(30, 30, 30, 0.1);
  border-radius: 4px;
}
.pagination-area ul li a:hover {
  background-color: var(--clr-theme-primary);
  color: #fff;
}
.pagination-area ul li a i {
  font-size: 15px;
}

/*
************
*************************
09. course-details
******************************************************* 
*************************************************************** */
.course_details-img {
  position: relative;
  margin-bottom: -40px;
  z-index: -1;
}
.course_details-title {
  font-size: 32px;
  margin-bottom: 15px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .course_details-title {
    font-size: 30px;
  }
}
@media (max-width: 767px) {
  .course_details-title {
    font-size: 25px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .course_details-title {
    font-size: 30px;
  }
}
.course_details-top {
  padding: 27px 40px 35px;
  box-shadow: 0px 20px 80px rgba(30, 30, 30, 0.1);
  border-radius: 0 10px 10px 10px;
  background-color: #fff;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .course_details-top {
    padding: 27px 30px 35px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .course_details-top {
    border-radius: 10px;
  }
}
@media (max-width: 767px) {
  .course_details-top {
    padding: 27px 25px 35px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .course_details-top {
    padding: 27px 30px 35px;
  }
}
.course_details-meta {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
}
.course_details-meta-left {
  display: flex;
  align-items: center;
  gap: 35px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .course_details-meta-left {
    gap: 25px;
  }
}
@media (max-width: 767px) {
  .course_details-meta-left {
    gap: 20px;
    flex-direction: column;
    align-items: flex-start;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .course_details-meta-left {
    gap: 25px;
    flex-direction: row;
    align-items: center;
  }
}
@media (max-width: 767px) {
  .course_details-meta {
    flex-direction: column;
    align-items: flex-start;
  }
}
.course_details-author {
  display: flex;
  align-items: center;
  gap: 10px;
}
.course_details-author-img {
  width: 50px;
  height: 50px;
  border: 1px solid rgba(4, 0, 23, 0.1);
  border-radius: 50%;
  padding: 4px;
}
.course_details-author-img img {
  width: 100%;
}
.course_details-author-info span {
  font-size: 14px;
  font-weight: 500;
  color: #848B97;
  display: block;
  line-height: 20px;
}
.course_details-author-info h5 {
  font-size: 14px;
  margin-bottom: 0;
  font-weight: 500;
  line-height: 1.4;
}
.course_details-author-info h5:hover a {
  color: var(--clr-theme-primary);
}
.course_details-rating {
  position: relative;
  z-index: 1;
  padding-left: 15px;
}
.course_details-rating::before {
  position: absolute;
  left: 0;
  top: 0;
  content: "";
  height: 100%;
  width: 1px;
  background: rgba(4, 0, 23, 0.14);
  z-index: 1;
}
@media (max-width: 767px) {
  .course_details-rating {
    padding-left: 0;
  }
  .course_details-rating::before {
    display: none;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .course_details-rating {
    padding-left: 15px;
  }
  .course_details-rating::before {
    display: block;
  }
}
.course_details-rating span {
  font-size: 14px;
  font-weight: 500;
  display: block;
  color: #848B97;
  line-height: 22px;
}
.course_details-rating ul {
  display: flex;
  gap: 5px;
}
.course_details-rating ul li {
  display: block;
  font-size: 12px;
  color: #FFA121;
  line-height: 20px;
}
.course_details-category {
  position: relative;
  z-index: 1;
  padding-left: 15px;
}
.course_details-category::before {
  position: absolute;
  left: 0;
  top: 0;
  content: "";
  height: 40px;
  width: 1px;
  background: rgba(4, 0, 23, 0.14);
  z-index: 1;
}
@media (max-width: 767px) {
  .course_details-category {
    padding-left: 0;
  }
  .course_details-category::before {
    display: none;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .course_details-category {
    padding-left: 15px;
  }
  .course_details-category::before {
    display: block;
  }
}
.course_details-category span {
  font-size: 14px;
  font-weight: 500;
  color: #848B97;
  display: block;
  line-height: 20px;
}
.course_details-category h5 {
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 0;
  line-height: 1.4;
}
.course_details-category h5:hover a {
  color: var(--clr-theme-primary);
}

.course_details-tab-button {
  margin-bottom: 30px;
}
.course_details-tab-button .nav {
  gap: 10px;
  flex-wrap: nowrap;
}
.course_details-tab-button .nav .nav-item {
  width: 25%;
}
.course_details-tab-button .nav .nav-item .nav-link {
  background: #F5F5F5;
  height: 55px;
  line-height: 55px;
  border: 1px solid rgba(30, 30, 30, 0.1);
  padding: 0;
  display: flex;
  align-items: center;
  gap: 10px;
  width: 100%;
  justify-content: center;
  color: var(--clr-body-heading);
  font-size: 15px;
  font-weight: 500;
}
@media (max-width: 767px) {
  .course_details-tab-button .nav .nav-item .nav-link span {
    display: none;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .course_details-tab-button .nav .nav-item .nav-link span {
    display: block;
  }
}
.course_details-tab-button .nav .nav-item .nav-link i {
  font-weight: 300;
}

.course_details-tab-button .nav-pills .nav-link.active, .course_details-tab-button .nav-pills .show > .nav-link {
  color: var(--clr-body-heading);
  background-color: #fff;
  border-top: 2px solid var(--clr-theme-primary);
}
.course_details-tab-button .nav-pills .nav-link.active i, .course_details-tab-button .nav-pills .show > .nav-link i {
  color: var(--clr-theme-primary);
}

.course_details-content-title {
  font-size: 24px;
  line-height: 1.4;
}
.course_details-content p {
  color: rgba(30, 30, 30, 0.7);
}
.course_details-content-list ul li {
  display: block;
  position: relative;
  z-index: 1;
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 5px;
  padding-left: 13px;
  line-height: 26px;
}
.course_details-content-list ul li:last-child {
  margin-bottom: 0;
}
.course_details-content-list ul li::before {
  position: absolute;
  left: 0;
  top: 50%;
  content: "";
  width: 4px;
  height: 4px;
  background-color: var(--clr-body-heading);
  border-radius: 50%;
  transform: translateY(-50%);
}

.course_details-sidebar {
  background: #fff;
  box-shadow: 0px 20px 80px rgba(30, 30, 30, 0.1);
  padding: 45px 0 60px;
  border-radius: 10px 0 10px 10px;
}
.course_details-sidebar-btn {
  margin-left: 40px;
  margin-right: 40px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .course_details-sidebar-btn {
    margin-left: 30px;
    margin-right: 30px;
  }
}
@media (max-width: 767px) {
  .course_details-sidebar-btn {
    margin-left: 25px;
    margin-right: 25px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .course_details-sidebar-btn {
    margin-left: 30px;
    margin-right: 30px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .course_details-sidebar {
    border-radius: 10px;
  }
}
.course_details-price {
  padding-left: 40px;
  padding-right: 40px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .course_details-price {
    padding-left: 30px;
    padding-right: 30px;
  }
}
@media (max-width: 767px) {
  .course_details-price {
    padding-left: 25px;
    padding-right: 25px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .course_details-price {
    padding-left: 30px;
    padding-right: 30px;
  }
}
.course_details-price del {
  display: block;
  font-size: 16px;
  margin-bottom: 4px;
}
.course_details-price h2 {
  font-size: 44px;
  font-weight: 600;
  margin-bottom: 20px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .course_details-price h2 {
    font-size: 38px;
  }
}
@media (max-width: 767px) {
  .course_details-price h2 {
    font-size: 35px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .course_details-price h2 {
    font-size: 40px;
  }
}
.course_details-list ul {
  margin-bottom: 40px;
}
.course_details-list ul li {
  border-bottom: 1px solid rgba(30, 30, 30, 0.1);
  font-size: 16px;
  font-weight: 500;
  padding: 10px 40px;
  color: rgba(30, 30, 30, 0.7);
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.course_details-list ul li:first-child {
  border-top: 1px solid rgba(30, 30, 30, 0.1);
}
.course_details-list ul li span i {
  margin-right: 10px;
  color: var(--clr-theme-primary);
  font-size: 16px;
  font-weight: 300;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .course_details-list ul li {
    padding-left: 30px;
    padding-right: 30px;
  }
}
@media (max-width: 767px) {
  .course_details-list ul li {
    padding-left: 25px;
    padding-right: 25px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .course_details-list ul li {
    padding-left: 30px;
    padding-right: 30px;
  }
}

.course_details-instructor {
  display: flex;
  gap: 30px;
}
@media (max-width: 360px) {
  .course_details-instructor {
    flex-direction: column;
  }
}
@media (max-width: 767px) {
  .course_details-instructor {
    gap: 20px;
  }
}
.course_details-thumbnail {
  min-width: 200px;
}
@media (max-width: 767px) {
  .course_details-thumbnail {
    min-width: 120px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .course_details-thumbnail {
    min-width: 170px;
  }
}
.course_details-author-title {
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 5px;
}
.course_details-author-subtitle {
  display: block;
  margin-bottom: 15px;
}
.course_details-author-content p {
  margin-bottom: 15px;
}
.course_details-social span {
  color: #747474;
  font-weight: 500;
  line-height: 30px;
  display: block;
  font-size: 16px;
  margin-bottom: 10px;
}
.course_details-social ul {
  display: flex;
  gap: 10px;
  align-items: center;
}
.course_details-social ul li a {
  display: grid;
  width: 40px;
  height: 40px;
  border: 1px solid rgba(30, 30, 30, 0.1);
  place-items: center;
  border-radius: 4px;
  color: rgba(30, 30, 30, 0.3);
  font-size: 15px;
}
.course_details-social ul li a:hover {
  background: var(--clr-theme-primary);
  color: #fff;
}

.course_details-review-content ul li {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.course_details-review-content ul li .review-rating {
  font-size: 14px;
  display: flex;
  align-items: center;
  gap: 10px;
  color: var(--clr-body-heading);
}

.course_details-review-content ul li .review-rating i {
  color: #f8b81f;
  font-size: 12px;
}

.course_details-review-content ul li .review-progress-bar {
  width: 100%;
  height: 5px;
  background: var(--clr-theme-primary);
  border-radius: 4px;
  margin-left: 30px;
  margin-right: 30px;
}

.course_details-review-content ul li .review-rating-count {
  color: var(--clr-body-heading);
  font-size: 14px;
}

.course_details-review-left h5 {
  font-size: 60px;
  margin-bottom: 0;
  font-weight: 600;
}

.course_details-review-left {
  display: grid;
  place-items: center;
}
@media (max-width: 767px) {
  .course_details-review-left {
    margin-bottom: 40px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .course_details-review-left {
    margin-bottom: 0px;
  }
}

.course_details-review-left ul {
  display: flex;
  align-items: center;
  gap: 5px;
}

.course_details-review-left ul li i {
  font-size: 14px;
  color: #f8b81f;
}

.course_details-review-left p {
  font-size: 14px;
  margin-bottom: 0;
}

.course_details-review-wrap {
  padding: 40px 40px;
  padding-left: 30px;
  border: 1px solid rgba(4, 0, 23, 0.1);
  border-radius: 10px;
}

.course_details-review-title {
  font-size: 22px;
  margin-bottom: 25px;
}

.course_details-curriculum .accordion .accordion-item {
  border: 1px solid rgba(4, 0, 23, 0.1);
  margin-bottom: 30px;
  border-radius: 4px;
}

.course_details-curriculum .accordion .accordion-header .accordion-button {
  border: 0;
  background: transparent;
  padding-left: 30px;
  padding-right: 50px;
  height: 70px;
  font-size: 20px;
  font-weight: 500;
  cursor: pointer;
  border: none;
  border-radius: 0;
  box-shadow: none;
  color: var(--clr-body-heading);
}
@media (max-width: 767px) {
  .course_details-curriculum .accordion .accordion-header .accordion-button {
    font-size: 16px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .course_details-curriculum .accordion .accordion-header .accordion-button {
    font-size: 18px;
  }
}
.course_details-curriculum .accordion .accordion-header .accordion-button:not(.collapsed) {
  color: var(--clr-theme-primary);
  border-bottom: 1px solid rgba(4, 0, 23, 0.1);
}

.course_details-curriculum .accordion .accordion-body {
  padding: 25px 30px;
  line-height: 1.5;
}

.course_details-curriculum .accordion-button::after {
  display: none;
}
.course_details-curriculum .accordion-button {
  position: relative;
}
.course_details-curriculum .accordion-button::before {
  position: absolute;
  right: 0;
  top: 0;
  content: "\f067";
  font-family: "Font Awesome 5 Pro";
  padding: 0 30px;
  font-size: 16px;
  -webkit-transition: all 0.3s linear 0s;
  -moz-transition: all 0.3s linear 0s;
  -ms-transition: all 0.3s linear 0s;
  -o-transition: all 0.3s linear 0s;
  transition: all 0.3s linear 0s;
  font-weight: 300;
  height: 70px;
  line-height: 70px;
}
.course_details-curriculum .accordion-button:not(.collapsed)::before {
  content: "\f068";
  transform: rotate(-180deg);
}
.course_details-curriculum .accordion-body ul li {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;
  gap: 30px;
}
.course_details-curriculum .accordion-body ul li a {
  display: flex;
  align-items: center;
  gap: 10px;
}
.course_details-curriculum .accordion-body ul li span {
  display: flex;
  align-items: center;
  gap: 20px;
  font-size: 14px;
  color: var(--clr-body-text);
}
.course_details-curriculum .accordion-body ul li a i {
  font-size: 14px;
}

/*
************
*************************
10. button
******************************************************* 
*************************************************************** */
.theme-btn {
  background: var(--clr-theme-primary);
  color: var(--clr-common-white);
  display: inline-flex;
  align-items: center;
  height: 40px;
  padding: 0 25px;
  font-size: 16px;
  font-weight: 600;
  text-transform: capitalize;
  cursor: pointer;
  margin-bottom: 0;
  text-align: center;
  touch-action: manipulation;
  transition: all 0.3s ease 0s;
  white-space: nowrap;
  border: none;
  border-radius: 6px;
  position: relative;
  overflow: hidden;
  z-index: 1;
}
.theme-btn::after {
  content: "";
  position: absolute;
  top: 10px;
  left: 0;
  width: 500px;
  height: 200px;
  background-color: #08449E;
  border-color: transparent;
  border-radius: 100px;
  transform: translate(-40px, -80px) scale(0.1);
  opacity: 0;
  z-index: -1;
  -webkit-transition: all 0.5s linear 0s;
  -moz-transition: all 0.5s linear 0s;
  -ms-transition: all 0.5s linear 0s;
  -o-transition: all 0.5s linear 0s;
  transition: all 0.5s linear 0s;
}

.theme-btn:hover {
  color: #fff;
}
.theme-btn:hover::after {
  opacity: 0.5;
  transform-origin: 100px 100px;
  transform: scale(1) translate(-10px, -70px);
}

.theme-btn-big {
  height: 55px;
  line-height: 55px;
  padding: 0 30px;
}

.theme-btn-medium {
  height: 45px !important;
  line-height: 45px !important;
  padding: 0 20px;
}

.theme-btn-full {
  width: 100%;
  justify-content: center;
}

.theme-btn-3 {
  border-radius: 6px;
  background: var(--clr-theme-primary-3);
  box-shadow: 0px 2px 5px 0px rgba(32, 42, 61, 0.2);
}
.theme-btn-3::after {
  background-color: #a73301;
}
.theme-btn-3 i {
  margin-left: 5px;
}

.theme-btn-4 {
  border-radius: 100px;
  background: var(--clr-theme-primary-4);
  color: var(--clr-body-heading);
  font-size: 15px;
  font-weight: 500;
}
.theme-btn-4::after {
  color: var(--clr-body-heading);
  background-color: #c79216;
}
.theme-btn-4:hover {
  color: var(--clr-body-heading);
}

.theme-btn-5 {
  border-radius: 0;
  background: var(--clr-theme-primary-5);
  color: var(--clr-body-heading);
  font-size: 16px;
  font-weight: 600;
}
.theme-btn-5::after {
  color: var(--clr-body-heading);
  background-color: #c7c108;
}
.theme-btn-5:hover {
  color: var(--clr-body-heading);
}

.theme-btn-6 {
  border-radius: 0;
  background: var(--clr-theme-primary-6);
}
.theme-btn-6::after {
  background-color: #8b030a;
}
.theme-btn-6 i {
  margin-left: 5px;
}

.theme-btn-8 {
  border-radius: 6px;
  background: #395BDF;
  font-weight: 500;
  font-size: 15px;
  height: 50px;
}
.theme-btn-8::after {
  background-color: #8b030a;
}
.theme-btn-8 i {
  margin-left: 5px;
}

.theme-btn-9 {
  border-radius: 6px;
  background: #1865F2;
  font-weight: 500;
  font-size: 15px;
  height: 50px;
}
.theme-btn-9::after {
  background-color: #8b030a;
}
.theme-btn-9 i {
  margin-left: 5px;
}

.theme-btn-7 {
  height: 50px;
  padding: 0 35px;
  border-radius: 0;
  background: var(--clr-theme-primary-6);
}
.theme-btn-7::after {
  background-color: #8b030a;
}
.theme-btn-7 i {
  margin-left: 5px;
}
.theme-btn-7-yellow {
  background-color: #F4B826;
  color: #17161C;
}

.h7_header-btn {
  display: flex;
  align-items: center;
  gap: 8px;
  border-radius: 4px;
  padding: 0 30px;
  color: #fff;
  font-size: 16px;
  height: 45px;
  font-weight: 600;
  background-color: var(--clr-theme-primary-6);
}
.h7_header-btn svg {
  color: #fff;
}

.theme-btn-10 {
  height: 50px;
  padding: 0 25px;
  border-radius: 0;
  background-color: var(--clr-theme-primary-10);
  border-radius: 4px;
  gap: 7px;
}
.theme-btn-10::after {
  background-color: #4240A6;
}
.theme-btn-10 i {
  margin-left: 5px;
}
.theme-btn-10-transparent {
  background-color: transparent;
  border: 1px solid #fff;
}
.theme-btn-10-transparent:hover {
  background-color: #fff;
  color: var(--clr-theme-primary-10);
  border-color: #fff;
}
.theme-btn-10-transparent::after {
  display: none;
}
.theme-btn-10-white {
  background-color: #fff;
  color: var(--clr-theme-primary-10);
}

.h3_banner-form-btn {
  padding: 0 35px;
}

.h4_about-btn,
.h4_blog-btn {
  height: 50px;
}

.t-theme-btn {
  background-color: #fff;
  border: 1px solid rgba(30, 30, 30, 0.1);
  color: var(--clr-body-heading);
  padding: 0 20px;
}
.t-theme-btn:hover {
  background-color: var(--clr-theme-primary);
  color: #fff;
}
.t-theme-btn::after {
  display: none;
}

.h5_play-btn {
  width: 150px;
  height: 150px;
  background: var(--clr-theme-primary-5);
  text-align: center;
  border-radius: 50%;
  transition: all 0.3s ease;
  display: grid;
  place-items: center;
  color: var(--clr-body-heading);
  font-size: 25px;
}
.h5_play-btn::after {
  position: absolute;
  left: 0;
  top: 0;
  content: "";
  border-radius: 50%;
  width: 100%;
  height: 100%;
  border: 1px solid rgba(255, 255, 255, 0.2);
  background: rgba(255, 255, 255, 0.3);
  backdrop-filter: blur(5px);
  animation-name: popupBtn;
  animation-duration: 1.8s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  z-index: -1;
}
.h5_play-btn:hover {
  background-color: var(--clr-theme-primary-4);
  color: var(--clr-body-heading);
}

@keyframes popupBtn {
  0% {
    transform: scale(1);
    opacity: 0;
  }
  50% {
    transform: scale(1.4);
    opacity: 0.3;
  }
  100% {
    transform: scale(1.8);
    opacity: 0;
  }
}
/*
************
*************************
11. about
******************************************************* 
*************************************************************** */
.about-img {
  margin-right: 40px;
  position: relative;
  z-index: 1;
}
.about-img img {
  width: 100%;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .about-img {
    margin-right: 10px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .about-img {
    margin-right: 0;
  }
}
.about-img-meta {
  position: absolute;
  left: 235px;
  bottom: 45px;
  background: #fff;
  border-radius: 6px;
  padding: 25px 30px 25px;
  box-shadow: 0px 10px 40px rgba(30, 30, 30, 0.1);
  animation: about-upDown-1 3s linear 0s infinite alternate;
  z-index: 1;
}
.about-img-meta span {
  font-size: 15px;
  display: flex;
  font-weight: 500;
  align-items: center;
  gap: 5px;
  margin-bottom: 5px;
}
.about-img-meta span i {
  color: #FFA121;
  font-size: 15px;
}
.about-img-meta h5 {
  font-size: 18px;
  margin-bottom: 0;
  font-weight: 500;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .about-img-meta {
    left: 150px;
  }
}
@media (max-width: 767px) {
  .about-img-meta {
    padding: 15px 20px;
    left: 130px;
  }
  .about-img-meta span {
    font-size: 12px;
    margin-bottom: 0;
  }
  .about-img-meta span i {
    font-size: 12px;
  }
  .about-img-meta h5 {
    font-size: 15px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .about-img-meta {
    padding: 25px 30px 25px;
    left: 230px;
  }
  .about-img-meta span {
    font-size: 15px;
    margin-bottom: 5px;
  }
  .about-img-meta span i {
    font-size: 15px;
  }
  .about-img-meta h5 {
    font-size: 18px;
  }
}

.about-content-list {
  margin-bottom: 41px;
}
.about-content-list ul li {
  color: var(--clr-body-heading);
  font-size: 15px;
  font-weight: 500;
  position: relative;
  z-index: 1;
  padding-left: 15px;
  margin-bottom: 5px;
  line-height: 28px;
}
.about-content-list ul li::before {
  position: absolute;
  left: 0;
  top: 12px;
  content: "";
  width: 4px;
  height: 4px;
  background-color: var(--clr-body-heading);
  border-radius: 50%;
}
.about-content-list ul li:last-child {
  margin-bottom: 0;
}

@keyframes about-upDown-1 {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(40px);
  }
}
.h2_about-area {
  position: relative;
  z-index: 1;
}
.h2_about-top-shape {
  position: absolute;
  z-index: 1;
  top: 135px;
  right: 145px;
  animation: animation-upDown-2 3s linear 0s infinite alternate;
}
.h2_about-img {
  position: relative;
  z-index: 1;
  padding-bottom: 120px;
}
.h2_about-img-button a {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  right: 0;
  text-align: center;
  width: 90px;
  height: 90px;
  background-color: #fff;
  border-radius: 50%;
  line-height: 90px;
  font-size: 25px;
  color: var(--clr-body-heading);
  box-shadow: 0px 2px 0px #A5A6A9;
}
@media (max-width: 767px) {
  .h2_about-img-button a {
    width: 50px;
    height: 50px;
    line-height: 50px;
    font-size: 16px;
  }
}
.h2_about-img-button a:hover {
  background-color: var(--clr-theme-primary);
  box-shadow: 0px 2px 0px var(--clr-theme-primary);
  color: #fff;
}
.h2_about-inner-img {
  position: relative;
  z-index: 1;
}
.h2_about-inner-img .h2_inner-img {
  border-radius: 10px;
}
.h2_about-inner-img2 {
  position: absolute;
  right: 50px;
  top: 120px;
  z-index: 9;
  border-radius: 10px;
  overflow: hidden;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .h2_about-inner-img2 {
    right: 0;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .h2_about-inner-img2 {
    right: 100px;
  }
}
@media (max-width: 767px) {
  .h2_about-inner-img2 {
    right: 0;
    top: auto;
    bottom: 0;
    margin-left: 20%;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .h2_about-inner-img2 {
    right: 0;
    top: 120px;
  }
}
.h2_about-shape-1 {
  position: absolute;
  right: -10px;
  top: 0;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .h2_about-shape-1 {
    width: 40%;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .h2_about-shape-1 {
    right: -20px;
    z-index: 1;
    width: 26%;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .h2_about-shape-1 {
    right: 100px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .h2_about-shape-1 {
    right: 0;
    width: 30%;
  }
}
.h2_about-shape-2 {
  position: absolute;
  left: -65px;
  top: -25px;
  z-index: 1;
  animation: animation-leftRight-1 3s linear 0s infinite alternate;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px), only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px) {
  .h2_about-shape-2 {
    left: 0;
  }
}
.h2_about-shape-3 {
  position: absolute;
  left: 145px;
  bottom: 20px;
  animation: animation-popup-1 3s linear 0s infinite alternate;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .h2_about-shape-3 {
    left: 120px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .h2_about-shape-3 {
    left: 30px;
  }
}
.h2_about-rating {
  position: absolute;
  left: 105px;
  bottom: 65px;
  background: #fff;
  border-radius: 10px;
  padding: 25px 30px 25px;
  box-shadow: 0px 16px 70px rgba(30, 30, 30, 0.14);
  z-index: 9;
  animation: animation-upDown-1 3s linear 0s infinite alternate;
}
.h2_about-rating span {
  font-size: 15px;
  display: flex;
  font-weight: 500;
  align-items: center;
  gap: 5px;
  margin-bottom: 5px;
}
.h2_about-rating span i {
  color: #FFA121;
  font-size: 15px;
}
.h2_about-rating h5 {
  font-size: 18px;
  margin-bottom: 0;
  font-weight: 500;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .h2_about-rating {
    left: 50px;
  }
}

.h2_inner-img-shape {
  position: absolute;
  left: -30px;
  bottom: -30px;
  z-index: 1;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .h2_inner-img-shape {
    left: -20px;
    bottom: -20px;
  }
}
@media (max-width: 767px) {
  .h2_inner-img-shape {
    left: -10px;
    bottom: -10px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .h2_inner-img-shape {
    left: -15px;
    bottom: -15px;
  }
}

.h3_about-area {
  position: relative;
  z-index: 1;
}
.h3_about-top-shape {
  position: absolute;
  top: 280px;
  right: 80px;
  animation: animation-upDown-1 2s linear 0s infinite alternate;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .h3_about-top-shape {
    top: 230px;
    right: 40px;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1399px), only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .h3_about-top-shape {
    display: none;
  }
}
.h3_about-img {
  position: relative;
  z-index: 1;
}
.h3_about-img-shape-1 {
  position: absolute;
  top: 65px;
  left: 0;
  animation: animation-upDown-1 2s linear 0s infinite alternate;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .h3_about-img-shape-1 {
    top: 10px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .h3_about-img-shape-1 {
    top: 0;
  }
}
.h3_about-img-shape-2 {
  position: absolute;
  top: 5px;
  right: -50px;
  animation: animation-leftRight-1 2s linear 0s infinite alternate;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .h3_about-img-shape-2 {
    right: 0;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .h3_about-img-shape-2 {
    right: 0;
  }
}
.h3_about-img-shape-3 {
  position: absolute;
  bottom: 20px;
  left: -140px;
  animation: animation-leftRight-1 2s linear 0s infinite alternate;
}
@media only screen and (min-width: 1600px) and (max-width: 1799px) {
  .h3_about-img-shape-3 {
    left: -100px;
  }
}
@media only screen and (min-width: 1400px) and (max-width: 1599px) {
  .h3_about-img-shape-3 {
    left: -40px;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .h3_about-img-shape-3 {
    left: -20px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .h3_about-img-shape-3 {
    left: 0;
    bottom: -35px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .h3_about-img-shape-3 {
    left: 0;
    bottom: 0;
  }
}
.h3_about-wrap {
  position: relative;
  z-index: 1;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px), only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .h3_about-wrap {
    margin-right: 0;
  }
}
.h3_about-wrap-shape {
  position: absolute;
  right: 0;
  bottom: 25px;
  animation: animation-popup-1 2s linear 0s infinite alternate;
}
.h3_about-content {
  max-width: 500px;
}
@media only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .h3_about-content {
    max-width: 100%;
  }
}
.h3_about-content span {
  display: flex;
  align-items: center;
  gap: 10px;
  color: var(--clr-body-heading);
  font-size: 15px;
  font-weight: 500;
  margin-bottom: 15px;
  line-height: 1;
}
.h3_about-content span i {
  background: linear-gradient(#20E448, #139E30);
  display: inline-grid;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  color: #fff;
  place-items: center;
  font-size: 10px;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .h3_about-inner-img {
    margin-right: 30px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .h3_about-inner-img {
    margin-right: 10px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .h3_about-inner-img {
    margin-right: 0;
  }
}

.h4_about-img {
  margin-right: -30px;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .h4_about-img {
    margin-right: -20px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .h4_about-img {
    margin-right: 0;
  }
}
.h4_about-wrap {
  margin-left: 80px;
}
@media only screen and (min-width: 1400px) and (max-width: 1599px) {
  .h4_about-wrap {
    margin-left: 60px;
    margin-right: 50;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .h4_about-wrap {
    margin-left: 30px;
    margin-right: 0;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .h4_about-wrap {
    margin-left: 0;
    margin-right: 0;
  }
}
.h4_about-content ul li {
  border-radius: 30px;
  background: linear-gradient(90deg, rgba(246, 124, 55, 0.14) 0%, rgba(246, 124, 55, 0) 100%);
  height: 40px;
  line-height: 40px;
  padding: 0 10px;
  display: flex;
  align-items: center;
  gap: 5px;
  margin-bottom: 15px;
  color: #F67C37;
  width: max-content;
}
.h4_about-content ul li i {
  width: 24px;
  height: 24px;
  background-color: #F67C37;
  border-radius: 50%;
  color: #fff;
  display: grid;
  place-items: center;
  flex-shrink: 0;
  font-size: 12px;
}
.h4_about-content ul li:nth-child(2) {
  background: linear-gradient(90deg, rgba(21, 105, 253, 0.14) 0%, rgba(21, 105, 253, 0) 100%);
  color: #1569FD;
}
.h4_about-content ul li:nth-child(2) i {
  background-color: #1569FD;
}
.h4_about-content ul li:last-child {
  background: linear-gradient(90deg, rgba(246, 55, 88, 0.14) 0%, rgba(246, 55, 88, 0) 100%);
  color: #F63758;
}
.h4_about-content ul li:last-child i {
  background-color: #F63758;
}
.h4_about-button {
  display: flex;
  align-items: center;
  gap: 30px;
}
@media (max-width: 767px) {
  .h4_about-button {
    flex-direction: column;
    gap: 20px;
    align-items: flex-start;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .h4_about-button {
    flex-direction: row;
    gap: 30px;
    align-items: center;
  }
}
.h4_about-button-call {
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 15px;
  color: var(--clr-body-heading);
  font-weight: 500;
}
.h4_about-button-call i {
  width: 36px;
  height: 36px;
  flex-shrink: 0;
  border-radius: 100px;
  background: #F6BA2A;
  display: grid;
  place-items: center;
  color: var(--clr-body-heading);
  font-size: 14px;
}

.h5_about-button-call i {
  background: var(--clr-theme-primary-5);
}

.h6_about-img {
  position: relative;
  z-index: 1;
  padding-bottom: 40px;
  transition: all 0.8s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  clip-path: inset(0 0 0 0);
}
.h6_about-img img {
  transition: all 0.8s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  transform: scale(1);
  width: 100%;
}
.h6_about-img:hover {
  clip-path: inset(8px 8px 8px 8px);
}
.h6_about-img:hover img {
  transform: scale(1.05);
}
.h6_about-img-content {
  position: absolute;
  left: 0;
  bottom: 0;
  background: #fff;
  padding: 22px 35px 26px 0;
}
@media (max-width: 767px) {
  .h6_about-img-content {
    padding: 22px 25px 26px 0;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .h6_about-img-content {
    padding: 22px 35px 26px 0;
  }
}
.h6_about-img-content h2 {
  -webkit-text-fill-color: transparent;
  -webkit-text-stroke: 1px #5C5C5C;
  font-size: 100px;
  font-weight: 800;
  line-height: 1;
  letter-spacing: -5px;
  margin-bottom: 10px;
}
@media (max-width: 767px) {
  .h6_about-img-content h2 {
    font-size: 45px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .h6_about-img-content h2 {
    font-size: 100px;
  }
}
.h6_about-img-content span {
  color: #525252;
  font-size: 24px;
  font-weight: 400;
  position: relative;
  z-index: 1;
  padding-left: 150px;
}
.h6_about-img-content span::before {
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  content: "";
  background: #D9D9D9;
  width: 130px;
  height: 1px;
  z-index: 1;
}
@media (max-width: 767px) {
  .h6_about-img-content span {
    font-size: 16px;
    padding-left: 50px;
  }
  .h6_about-img-content span::before {
    width: 40px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .h6_about-img-content span {
    font-size: 24px;
    padding-left: 150px;
  }
  .h6_about-img-content span::before {
    width: 130px;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .h6_about-content.ml-30 {
    margin-left: 10px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .h6_about-content.ml-30 {
    margin-left: 0;
  }
}
.h6_about-content-title {
  font-size: 36px;
  font-weight: 500;
  line-height: 1.25;
  margin-bottom: 22px;
}
@media (max-width: 767px) {
  .h6_about-content-title {
    font-size: 30px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .h6_about-content-title {
    font-size: 36px;
  }
}
.h6_about-content p {
  font-size: 16px;
  color: #666;
  margin-bottom: 14px;
}
.h6_about-btn {
  margin-top: 28px;
}
.h6_about-bottom-title {
  -webkit-text-fill-color: transparent;
  -webkit-text-stroke: 1px #C5C5C5;
  font-size: 120px;
  font-weight: 600;
  line-height: 1.12;
  text-align: center;
  margin-right: 100px;
  width: max-content !important;
}
@media (max-width: 767px) {
  .h6_about-bottom-title {
    font-size: 80px;
  }
}

.h7_about-img {
  position: relative;
  z-index: 1;
}
.h7_about-img-content {
  position: absolute;
  left: 0;
  bottom: 0;
  background: #fff;
  padding: 22px 35px 26px 0;
}
@media (max-width: 767px) {
  .h7_about-img-content {
    padding: 22px 25px 26px 0;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .h7_about-img-content {
    padding: 22px 35px 26px 0;
  }
}
.h7_about-img-content h2 {
  -webkit-text-fill-color: transparent;
  -webkit-text-stroke: 1px #5C5C5C;
  font-size: 100px;
  font-weight: 800;
  line-height: 1;
  letter-spacing: -5px;
  margin-bottom: 10px;
}
@media (max-width: 767px) {
  .h7_about-img-content h2 {
    font-size: 45px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .h7_about-img-content h2 {
    font-size: 100px;
  }
}
.h7_about-img-content span {
  color: #525252;
  font-size: 24px;
  font-weight: 400;
  position: relative;
  z-index: 1;
  padding-left: 150px;
}
.h7_about-img-content span::before {
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  content: "";
  background: #D9D9D9;
  width: 130px;
  height: 1px;
  z-index: 1;
}
@media (max-width: 767px) {
  .h7_about-img-content span {
    font-size: 16px;
    padding-left: 50px;
  }
  .h7_about-img-content span::before {
    width: 40px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .h7_about-img-content span {
    font-size: 24px;
    padding-left: 150px;
  }
  .h7_about-img-content span::before {
    width: 130px;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .h7_about-content.ml-30 {
    margin-left: 10px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .h7_about-content.ml-30 {
    margin-left: 0;
  }
}
.h7_about-content-title {
  font-size: 36px;
  font-weight: 500;
  line-height: 1.25;
  margin-bottom: 22px;
}
@media (max-width: 767px) {
  .h7_about-content-title {
    font-size: 30px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .h7_about-content-title {
    font-size: 36px;
  }
}
.h7_about-content p {
  font-size: 16px;
  color: #666;
  margin-bottom: 14px;
}
.h7_about-btn {
  margin-top: 28px;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .h7_about-wrap.mr-70 {
    margin-right: 40px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .h7_about-wrap.mr-70 {
    margin-right: 0;
  }
}

.h7_about-img a {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.h7_about-img a svg {
  -webkit-transition: all 0.3s linear 0s;
  -moz-transition: all 0.3s linear 0s;
  -ms-transition: all 0.3s linear 0s;
  -o-transition: all 0.3s linear 0s;
  transition: all 0.3s linear 0s;
}
@media (max-width: 767px) {
  .h7_about-img a svg {
    width: 80px;
    height: 80px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .h7_about-img a svg {
    width: 100px;
    height: 100px;
  }
}
.h7_about-img a i {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  font-size: 22px;
  color: var(--clr-theme-primary-6);
}
.h7_about-img a:hover svg {
  transform: rotate(180deg);
}

.h7_about-content {
  display: flex;
  align-items: center;
  gap: 90px;
}
@media (max-width: 767px) {
  .h7_about-content {
    flex-wrap: wrap;
    gap: 20px;
  }
}

.h7_about-admin {
  display: flex;
  gap: 20px;
  align-items: center;
}

.h7_about-admin-info h5 {
  color: #17161C;
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 0;
  line-height: 1;
}

.h7_about-admin-img {
  width: 55px;
  height: 55px;
  flex-shrink: 0;
}

.h7_about-admin-info span {
  color: #17161C;
  font-size: 14px;
  font-weight: 500;
  display: inline-block;
}

.h8_about-img {
  display: flex;
  align-items: start;
  grid-gap: 30px;
}
@media (max-width: 767px) {
  .h8_about-img {
    flex-wrap: wrap;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .h8_about-img {
    flex-wrap: nowrap;
  }
}
@media only screen and (min-width: 1400px) and (max-width: 1599px) {
  .h8_about-img.mr-35 {
    margin-right: 20px;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .h8_about-img.mr-35 {
    margin-right: 10px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .h8_about-img.mr-35 {
    margin-right: 0;
  }
}
.h8_about-img-left {
  position: relative;
  z-index: 1;
  padding-top: 150px;
  width: 54%;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px), only screen and (min-width: 992px) and (max-width: 1199px) {
  .h8_about-img-left {
    width: 50%;
  }
}
@media (max-width: 767px) {
  .h8_about-img-left {
    width: 100%;
  }
}
.h8_about-img-left img {
  border-radius: 10px;
}
.h8_about-img-right {
  position: relative;
  z-index: 1;
  width: 46%;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px), only screen and (min-width: 992px) and (max-width: 1199px) {
  .h8_about-img-right {
    width: 50%;
  }
}
@media (max-width: 767px) {
  .h8_about-img-right {
    width: 100%;
  }
}
.h8_about-img-right img {
  border-radius: 10px;
  margin-bottom: 30px;
}
.h8_about-img-year {
  position: absolute;
  right: 0;
  top: 0;
  background: #F14D5D;
  padding: 30px 55px;
  border-radius: 10px;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .h8_about-img-year {
    padding: 30px 30px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px), (max-width: 767px) {
  .h8_about-img-year {
    padding: 30px 20px;
    width: 100%;
  }
}
.h8_about-img-year h3 {
  color: #FFF;
  text-align: center;
  font-size: 30px;
  font-weight: 700;
  line-height: 1;
  margin-bottom: 0;
}
.h8_about-img-year h3 span {
  color: rgba(255, 255, 255, 0.9);
  font-size: 20px;
  font-weight: 400;
  display: block;
  line-height: 1.2;
  margin-top: 6px;
}
.h8_about-img-count {
  border-radius: 10px;
  background: #FFF;
  box-shadow: 0px 6px 100px 0px rgba(8, 5, 33, 0.1);
  padding: 20px 20px;
  display: flex;
  align-items: center;
  gap: 15px;
  width: 100%;
}
.h8_about-img-count img {
  flex-shrink: 0;
  margin-bottom: 0;
}
.h8_about-img-count-info h3 {
  color: #021936;
  font-size: 36px;
  font-weight: 600;
  line-height: 1.3;
  margin-bottom: 0;
}
.h8_about-img-count-info p {
  color: #021936;
  font-size: 15px;
  font-weight: 400;
  line-height: 24px;
  margin-bottom: 0;
}

@media only screen and (min-width: 1400px) and (max-width: 1599px) {
  .h8_about-wrap.ml-30 {
    margin-left: 20px;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .h8_about-wrap.ml-30 {
    margin-left: 10px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .h8_about-wrap.ml-30 {
    margin-left: 0;
  }
}

.h8_about-content {
  display: flex;
  gap: 35px;
  margin-bottom: 50px;
}
@media (max-width: 767px) {
  .h8_about-content {
    flex-wrap: wrap;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .h8_about-content {
    flex-wrap: nowrap;
  }
}

.h8_about-content-item {
  border-radius: 10px;
  background: #FFF;
  box-shadow: 0px 6px 100px 0px rgba(8, 5, 33, 0.1);
  padding: 25px 25px 20px;
  position: relative;
  z-index: 1;
}

.h8_about-icon {
  position: absolute;
  right: 35px;
  top: 25px;
}

.h8_about-content-item-icon svg {
  color: #395BDF;
}

.h8_about-content-item-icon {
  margin-bottom: 20px;
}

.h8_about-content-item-title {
  color: #021936;
  font-size: 18px;
  font-weight: 600;
  line-height: 1.3;
  margin-bottom: 10px;
}

.h8_about-content-item-text {
  color: rgba(2, 25, 54, 0.7);
  font-size: 15px;
  font-weight: 400;
  line-height: 24px;
  margin-bottom: 0;
}

.h8_about-icon svg {
  color: #395BDF;
}

.h8_about-content-item .h8_about-content-item-icon > svg {
  transition: all 0.3s linear 0s;
}

.h8_about-content-item:hover .h8_about-content-item-icon > svg {
  transform: scale(1.1) translateY(-5px);
}

.h10_about-area {
  overflow-x: hidden;
}

.h10_about-img {
  position: relative;
  z-index: 1;
}
@media only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .h10_about-img {
    text-align: center;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .h10_about-img.mr-25 {
    margin-right: 0;
  }
}

.h10_about-img-shape {
  position: absolute;
  top: 20px;
  left: -45px;
  animation: animation-leftRight-1 3s linear 0s infinite alternate;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .h10_about-img-shape {
    left: 0;
  }
}

.h10_about-img-shape-1 {
  position: absolute;
  left: -90px;
  top: 150px;
  animation: animation-upDown-2 3s linear 0s infinite alternate;
}
@media only screen and (min-width: 1400px) and (max-width: 1599px) {
  .h10_about-img-shape-1 {
    left: -45px;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .h10_about-img-shape-1 {
    left: -35px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .h10_about-img-shape-1 {
    left: -25px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .h10_about-img-shape-1 {
    left: 0;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px), only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .h10_about-content.ml-35 {
    margin-left: 0;
  }
}

.h10_about-content-title {
  color: #111720;
  font-size: 44px;
  font-weight: 700;
  line-height: 1.2;
  margin-bottom: 25px;
}
@media (max-width: 767px) {
  .h10_about-content-title {
    font-size: 34px;
  }
}

.h10_about-content-inner {
  margin-bottom: 27px;
}
.h10_about-content-inner h5 {
  color: #111720;
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
  margin-bottom: 10px;
}
.h10_about-content-inner p {
  color: rgba(17, 23, 32, 0.7);
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
  margin-bottom: 0;
}

.h10_about-count {
  border: 1px dashed #FED3D5;
  padding: 15px 40px 15px;
  display: flex;
  gap: 20px;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: 50px;
}

.h10_about-count-item h3 {
  color: #111720;
  font-size: 32px;
  font-weight: 700;
  line-height: 1.2;
  margin-bottom: 0;
}

.h10_about-count-item span {
  color: #111720;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  display: block;
}

.h10_about-count-item {
  position: relative;
  z-index: 1;
}

.h10_about-count-item:not(:last-child)::before {
  position: absolute;
  right: -52px;
  top: 50%;
  transform: translateY(-50%);
  content: "";
  width: 1px;
  height: 100%;
  border: 1px dashed #FED3D5;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .h10_about-count-item:not(:last-child)::before {
    right: -38px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .h10_about-count-item:not(:last-child)::before {
    right: -15px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .h10_about-count-item:not(:last-child)::before {
    right: -76px;
  }
}
@media (max-width: 767px) {
  .h10_about-count-item:not(:last-child)::before {
    display: none;
  }
}

.h10_about-bottom {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 90px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .h10_about-bottom {
    gap: 40px;
  }
}
@media (max-width: 767px) {
  .h10_about-bottom {
    gap: 30px;
  }
}

.h10_about-admin {
  display: flex;
  gap: 20px;
  align-items: center;
}
.h10_about-admin img {
  width: 55px;
  height: 55px;
  flex-shrink: 0;
}

.h10_about-admin-info h5 {
  color: #17161C;
  font-size: 18px;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 5px;
}

.h10_about-admin-info span {
  color: rgba(23, 22, 29, 0.7);
  font-size: 14px;
  font-weight: 500;
  line-height: 1;
  display: block;
}

.h10_about-bottom-btn a {
  display: flex;
  align-items: center;
  gap: 12px;
}
.h10_about-bottom-btn a .inner-text {
  color: #18222F;
  font-size: 16px;
  font-weight: 600;
  line-height: 1;
  text-decoration: underline;
}

.h10_about-bottom-btn a .inner-btn {
  border-radius: 40px;
  background: var(--clr-theme-primary-10);
  backdrop-filter: blur(15px);
  width: 44px;
  height: 44px;
  display: grid;
  place-items: center;
  color: #fff;
  transform: rotate(45deg);
  font-size: 16px;
}

.h10_about-shape-2 {
  width: 65px;
  height: 37px;
  transform: rotate(-20deg);
  position: relative;
  right: 75px;
  animation: animation-leftRight-1 3s linear 0s infinite alternate;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .h10_about-shape-2 {
    right: 50px;
  }
}

/*
************
*************************
12. event
******************************************************* 
*************************************************************** */
.event-section-area {
  margin-left: 125px;
}
@media (max-width: 1799px) {
  .event-section-area {
    margin-left: 0;
  }
}
.event-area {
  background-color: var(--clr-color-lightPurple);
  position: relative;
  z-index: 1;
}
.event-bg-img {
  position: absolute;
  left: 0;
  top: 0;
  z-index: -1;
  max-width: 400px;
  height: 100%;
}
@media (max-width: 1799px) {
  .event-bg-img {
    display: none;
  }
}
.event-item {
  padding: 20px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0px 20px 40px rgba(30, 30, 30, 0.1);
}
.event-img {
  border-radius: 10px 10px 0 0;
  overflow: hidden;
}
.event-img img {
  width: 100%;
  -webkit-transition: all 0.6s linear 0s;
  -moz-transition: all 0.6s linear 0s;
  -ms-transition: all 0.6s linear 0s;
  -o-transition: all 0.6s linear 0s;
  transition: all 0.6s linear 0s;
}
.event-content-title {
  font-size: 20px;
  font-weight: 600;
  line-height: 1.5;
  margin-bottom: 27px;
}
.event-content-title:hover a {
  color: var(--clr-theme-primary);
}
.event-content-meta {
  display: flex;
  align-items: center;
  gap: 20px;
  margin-bottom: 6px;
}
.event-content-meta span {
  display: inline-block;
  font-size: 14px;
  font-weight: 500;
}
.event-content-meta span i {
  font-size: 14px;
  margin-right: 7px;
  font-weight: 300;
  color: #D2093C;
}

.event-content {
  padding: 25px 0 10px;
}
.event-navigation {
  display: flex;
  gap: 10px;
  justify-content: end;
}
.event-navigation div {
  width: 50px;
  height: 50px;
  background: #FFFFFF;
  box-shadow: 0px 2px 0px #E1E2E6;
  border-radius: 100px;
  display: grid;
  place-items: center;
  font-size: 14px;
  color: #1E1E1E;
  -webkit-transition: all 0.4s linear 0s;
  -moz-transition: all 0.4s linear 0s;
  -ms-transition: all 0.4s linear 0s;
  -o-transition: all 0.4s linear 0s;
  transition: all 0.4s linear 0s;
}
.event-navigation div:hover {
  background-color: var(--clr-theme-primary);
  color: #fff;
}
@media (max-width: 767px) {
  .event-navigation {
    justify-content: flex-start;
  }
}

.event-item:hover .event-img img {
  transform: scale(1.2);
}

.h5_event-area {
  background-color: #F3F4FD;
}
.h5_event-item {
  display: flex;
  align-items: center;
  height: 33.3333333333%;
}
@media (max-width: 767px) {
  .h5_event-item {
    flex-direction: column;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .h5_event-item {
    flex-direction: row;
    align-items: center;
  }
}
.h5_event-item-date {
  height: 100%;
  background: #F3EB1E;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 190px;
  flex-shrink: 0;
  text-align: center;
}
@media (max-width: 767px) {
  .h5_event-item-date {
    width: 100%;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .h5_event-item-date {
    width: 170px;
  }
}
.h5_event-item-date h1 {
  font-size: 70px;
  margin-bottom: 0;
  line-height: 1;
}
.h5_event-item-date p {
  margin-bottom: 0;
  font-size: 17px;
  color: var(--clr-body-heading);
}
.h5_event-item-content {
  height: 100%;
  background: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: calc(100% - 190px);
  padding: 0 60px;
}
@media (max-width: 767px) {
  .h5_event-item-content {
    width: 100%;
    padding: 30px 20px;
    height: auto;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .h5_event-item-content {
    width: calc(100% - 170px);
    padding: 0 30px;
    height: 160px;
  }
}
.h5_event-item-content ul {
  margin-bottom: 7px;
}
.h5_event-item-content ul li {
  display: inline-block;
  margin-right: 15px;
}
.h5_event-item-content ul li:last-child {
  margin-right: 0;
}
.h5_event-item-content ul li a {
  font-size: 15px;
}
.h5_event-item-content ul li a i {
  margin-right: 6px;
  color: var(--clr-body-heading);
}
.h5_event-item-content h5 {
  font-size: 18px;
  margin-bottom: 0;
  line-height: 1.4;
}
.h5_event-item-content h5:hover a {
  color: var(--clr-theme-primary);
}
.h5_event-item:nth-child(even) .h5_event-item-date {
  background-color: #FEF523;
}
.h5_event-item:nth-child(even) .h5_event-item-content {
  background-color: rgba(254, 247, 35, 0.1);
}

.h6_event-area {
  background-color: #fff;
}
.h6_event-area .h5_event-item {
  background: #FFF;
  box-shadow: 0px 20px 80px 0px rgba(30, 30, 30, 0.1);
  height: 33.3333333333%;
}
.h6_event-area .h5_event-item-date {
  background: #4846AB;
}
.h6_event-area .h5_event-item-date h1 {
  color: #fff;
}
.h6_event-area .h5_event-item-date p {
  color: #fff;
}
.h6_event-area .h5_event-item:nth-child(2n) .h5_event-item-date {
  background-color: #403E9D;
}
.h6_event-area .h5_event-item-content h5:hover a {
  color: var(--clr-theme-primary-6);
}

.h5_event-wrap {
  height: 100%;
  min-height: 420px;
}

.h7_event-area {
  background-color: #F6F6F6;
}
@media (max-width: 1799px) {
  .h7_event-area .event-wrap.pb-40 {
    padding-bottom: 35px;
  }
}
.h7_event-area .event-content-meta span i {
  color: #F63758;
}
.h7_event-area .section-area .section-subtitle {
  background: rgba(246, 55, 88, 0.06);
  color: #F63758;
}
.h7_event-area .section-area .section-subtitle::before {
  display: none;
}
.h7_event-area .event-navigation div {
  color: #0A0A0A;
}
.h7_event-area .event-navigation div:hover {
  background: var(--clr-theme-primary-6);
  color: #fff;
}
.h7_event-area .event-item {
  border-radius: 0;
}
.h7_event-area .event-content-title:hover a {
  color: var(--clr-theme-primary-6);
}
.h7_event-area .t-theme-btn.theme-btn.event-btn {
  border-radius: 0;
}
.h7_event-area .t-theme-btn.theme-btn.event-btn:hover {
  background-color: var(--clr-theme-primary-6);
}

.h10_event-area {
  background-color: #E1EEDA;
}
.h10_event-item {
  background-color: #fff;
}
.h10_event-img {
  position: relative;
  z-index: 1;
  overflow: hidden;
}
.h10_event-img img {
  -webkit-transition: all 0.3s linear 0s;
  -moz-transition: all 0.3s linear 0s;
  -ms-transition: all 0.3s linear 0s;
  -o-transition: all 0.3s linear 0s;
  transition: all 0.3s linear 0s;
}
.h10_event-date {
  position: absolute;
  right: 0;
  top: 30px;
  color: #FFF;
  font-size: 14px;
  font-weight: 700;
  line-height: 1;
  background-color: var(--clr-theme-primary-10);
  padding: 10px 15px;
}
.h10_event-content {
  padding: 35px 30px 40px;
}
.h10_event-content-meta {
  display: flex;
  align-items: center;
  gap: 10px;
  color: rgba(44, 44, 44, 0.7);
  font-size: 15px;
  font-weight: 500;
  margin-bottom: 8px;
}
.h10_event-content-meta i {
  color: var(--clr-theme-primary-10);
}
.h10_event-content-title {
  color: #2C2C2C;
  font-size: 20px;
  font-weight: 600;
  line-height: 1.5;
  margin-bottom: 22px;
}
.h10_event-content .h10_event-btn {
  border-radius: 0;
}

.h10_event-item:hover .h10_event-img img {
  transform: scale(1.1);
}

/*
************
*************************
13. event-details
******************************************************* 
*************************************************************** */
.event_details-wrap {
  margin-top: 40px;
}
.event_details-img {
  border-radius: 10px;
  overflow: hidden;
}
.event_details-content {
  margin-right: 80px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .event_details-content {
    margin-right: 50px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .event_details-content {
    margin-right: 30px;
  }
}
@media (max-width: 767px) {
  .event_details-content {
    margin-right: 0;
  }
}
.event_details-content-title {
  font-size: 32px;
  font-weight: 700;
  margin-bottom: 16px;
  line-height: 1.43;
}
@media (max-width: 767px) {
  .event_details-content-title {
    font-size: 26px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .event_details-content-title {
    font-size: 30px;
  }
}
.event_details-content p {
  color: rgba(30, 30, 30, 0.7);
}
.event_details-content-list ul li {
  display: block;
  position: relative;
  z-index: 1;
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 5px;
  padding-left: 13px;
  line-height: 26px;
}
.event_details-content-list ul li::before {
  position: absolute;
  left: 0;
  top: 50%;
  content: "";
  width: 4px;
  height: 4px;
  background-color: var(--clr-body-heading);
  border-radius: 50%;
  transform: translateY(-50%);
}
.event_details-content-list ul li:last-child {
  margin-bottom: 0;
}
.event_details-inner-img {
  display: flex;
  gap: 30px;
  margin-bottom: 40px;
}
.event_details-inner-img img {
  width: 100%;
}
@media (max-width: 767px) {
  .event_details-inner-img {
    flex-direction: column;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .event_details-inner-img {
    flex-direction: row;
  }
}

.event_details-sidebar {
  position: relative;
  z-index: 1;
  margin-top: -120px;
}
@media only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .event_details-sidebar {
    margin-top: 0;
  }
}
.event_details-sidebar-content {
  background: #fff;
  box-shadow: 0px 20px 80px rgba(30, 30, 30, 0.1);
  padding: 39px 0 45px;
  border-radius: 10px 0 10px 10px;
}
.event_details-sidebar-content-title {
  font-size: 28px;
  font-weight: 700;
  margin-bottom: 22px;
  margin-left: 40px;
  margin-right: 40px;
}
@media (max-width: 767px) {
  .event_details-sidebar-content-title {
    margin-left: 30px;
    margin-right: 30px;
    font-size: 22px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .event_details-sidebar-content-title {
    font-size: 24px;
  }
}
.event_details-sidebar-content ul {
  margin-bottom: 30px;
}
.event_details-sidebar-content ul li {
  border-bottom: 1px solid rgba(30, 30, 30, 0.1);
  font-size: 16px;
  font-weight: 500;
  padding: 10px 40px;
  color: rgba(30, 30, 30, 0.7);
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.event_details-sidebar-content ul li span i {
  margin-right: 10px;
  color: var(--clr-theme-primary);
  font-size: 16px;
}
.event_details-sidebar-content ul li:first-child {
  border-top: 1px solid rgba(30, 30, 30, 0.1);
}
@media (max-width: 767px) {
  .event_details-sidebar-content ul li {
    padding-left: 30px;
    padding-right: 30px;
  }
}
.event_details-sidebar-btn {
  margin-left: 40px;
  margin-right: 40px;
}
@media (max-width: 767px) {
  .event_details-sidebar-btn {
    margin-left: 30px;
    margin-right: 30px;
  }
}
.event_details-sidebar-map {
  background: #fff;
  box-shadow: 0px 20px 80px rgba(30, 30, 30, 0.1);
  padding: 32px 0 32px;
  border-radius: 10px;
}
.event_details-sidebar-map .inner-map {
  margin-left: 40px;
  margin-right: 40px;
}
@media (max-width: 767px) {
  .event_details-sidebar-map .inner-map {
    margin-left: 30px;
    margin-right: 30px;
  }
}
.event_details-sidebar-map .inner-map iframe {
  height: 400px;
  width: 100%;
}

/*
************
*************************
14. testimonial
******************************************************* 
*************************************************************** */
.testimonial-item {
  padding: 50px 35px 45px;
  background-color: #F5F5F5;
  border-radius: 10px;
}
.testimonial-top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 15px;
  margin-bottom: 25px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 576px) and (max-width: 767px) {
  .testimonial-top {
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
  }
}
.testimonial-admin {
  display: flex;
  gap: 10px;
  align-items: center;
}
.testimonial-admin-img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  overflow: hidden;
}
.testimonial-admin-img img {
  width: 100%;
}
.testimonial-admin-info h5 {
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 0;
}
.testimonial-admin-info span {
  font-size: 14px;
  font-weight: 500;
  display: block;
}

.testimonial-rating ul li {
  display: inline-block;
  font-size: 14px;
  color: #FFA121;
}

.testimonial-content p {
  color: #1E1E1E;
  margin-bottom: 0;
  font-size: 16px;
  line-height: 26px;
}

.testimonial-scrollbar-wrap {
  position: relative;
}
.testimonial-scrollbar-wrap .swiper-scrollbar.testimonial-scrollbar {
  height: 3px;
  border-radius: 0;
  background: rgba(30, 30, 30, 0.1);
}
.testimonial-scrollbar-wrap .swiper-scrollbar-drag {
  border-radius: 0;
  background-color: var(--clr-theme-primary);
}

.h3_testimonial-area {
  background-color: var(--clr-color-lightPink);
  overflow: hidden;
  position: relative;
  z-index: 1;
}
.h3_testimonial-shape-1 {
  position: absolute;
  left: 65px;
  top: 120px;
  animation: animation-upDown-2 3s linear 0s infinite alternate;
}
@media only screen and (min-width: 1400px) and (max-width: 1599px) {
  .h3_testimonial-shape-1 {
    left: 30px;
    top: 70px;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .h3_testimonial-shape-1 {
    left: 30px;
    top: 70px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .h3_testimonial-shape-1 {
    left: 30px;
    top: 70px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .h3_testimonial-shape-1 {
    left: 30px;
    top: 70px;
  }
}
@media (max-width: 767px) {
  .h3_testimonial-shape-1 {
    display: none;
  }
}
.h3_testimonial-shape-2 {
  position: absolute;
  left: 180px;
  bottom: 180px;
  animation: animation-leftRight-1 3s linear 0s infinite alternate;
}
@media only screen and (min-width: 1600px) and (max-width: 1799px) {
  .h3_testimonial-shape-2 {
    left: 80px;
  }
}
@media only screen and (min-width: 1400px) and (max-width: 1599px) {
  .h3_testimonial-shape-2 {
    left: 10px;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .h3_testimonial-shape-2 {
    left: 10px;
    bottom: 40px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .h3_testimonial-shape-2 {
    display: none;
  }
}
.h3_testimonial-shape-3 {
  position: absolute;
  top: 70px;
  right: 690px;
  animation: hero-circle-1 6s linear 0s infinite;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .h3_testimonial-shape-3 {
    right: 390px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .h3_testimonial-shape-3 {
    right: 300px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .h3_testimonial-shape-3 {
    right: 200px;
  }
}
@media (max-width: 767px) {
  .h3_testimonial-shape-3 {
    display: none;
  }
}
.h3_testimonial-shape-4 {
  position: absolute;
  bottom: 40px;
  right: 365px;
  animation: animation-leftRight-1 3s linear 0s infinite alternate;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .h3_testimonial-shape-4 {
    right: 300px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .h3_testimonial-shape-4 {
    right: 50%;
  }
}
@media (max-width: 767px) {
  .h3_testimonial-shape-4 {
    display: none;
  }
}
.h3_testimonial-item {
  background: #fff;
  border-radius: 20px;
  padding: 105px 80px 90px;
  position: relative;
  z-index: 1;
}
@media (max-width: 767px) {
  .h3_testimonial-item {
    padding: 70px 30px 80px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .h3_testimonial-item {
    padding: 70px 50px 80px;
  }
}
.h3_testimonial-item-author {
  display: flex;
  align-items: center;
  gap: 15px;
  flex-shrink: 0;
}
.h3_testimonial-item-author img {
  width: 65px;
  height: 65px;
  border-radius: 50%;
}
.h3_testimonial-item-author-info h5 {
  font-size: 18px;
  margin-bottom: 0;
  font-weight: 600;
}
.h3_testimonial-item-author-info span {
  font-size: 16px;
  display: block;
}
.h3_testimonial-item-content .quote {
  position: absolute;
  left: 80px;
  top: 50px;
  z-index: -1;
}
@media (max-width: 767px) {
  .h3_testimonial-item-content .quote {
    left: 50px;
    top: 40px;
  }
}
.h3_testimonial-item-content p {
  margin-bottom: 50px;
  font-size: 20px;
  line-height: 40px;
  color: rgba(30, 30, 30, 0.7);
}
.h3_testimonial-active {
  margin-right: -680px;
}
@media (max-width: 767px) {
  .h3_testimonial-active {
    margin-right: 0;
  }
}
.h3_testimonial-navigation {
  display: flex;
  gap: 10px;
  justify-content: end;
}
.h3_testimonial-navigation div {
  width: 50px;
  height: 50px;
  border-radius: 6px;
  display: grid;
  place-items: center;
  font-size: 14px;
  color: #1E1E1E;
  border: 1.5px solid rgba(30, 30, 30, 0.15);
  -webkit-transition: all 0.3s linear 0s;
  -moz-transition: all 0.3s linear 0s;
  -ms-transition: all 0.3s linear 0s;
  -o-transition: all 0.3s linear 0s;
  transition: all 0.3s linear 0s;
}
.h3_testimonial-navigation div:hover {
  background-color: var(--clr-theme-primary-3);
  border-color: transparent;
  color: #fff;
}
@media (max-width: 767px) {
  .h3_testimonial-navigation {
    justify-content: flex-start;
  }
}

.h4_testimonial-area {
  background-color: #ECECF9;
}

.h6_testimonial-area {
  position: relative;
  z-index: 1;
  overflow: hidden;
}
.h6_testimonial-area::before {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  content: "";
  background: rgba(10, 10, 10, 0.4);
  z-index: -1;
}
@media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .h6_testimonial-wrap.mr-60 {
    margin-right: 0;
  }
}
.h6_testimonial-title {
  font-size: 128px;
  font-weight: 700;
  -webkit-text-fill-color: transparent;
  -webkit-text-stroke: 1px #fff;
  line-height: 1.25;
  margin-bottom: 0;
  margin-right: 150px;
  width: max-content !important;
  opacity: 0.2;
}
@media (max-width: 767px) {
  .h6_testimonial-title {
    font-size: 80px;
  }
}
.h6_testimonial-navigation {
  display: flex;
  gap: 10px;
  justify-content: end;
}
.h6_testimonial-navigation div {
  width: 50px;
  height: 50px;
  display: grid;
  place-items: center;
  font-size: 14px;
  background-color: #fff;
  -webkit-transition: all 0.3s linear 0s;
  -moz-transition: all 0.3s linear 0s;
  -ms-transition: all 0.3s linear 0s;
  -o-transition: all 0.3s linear 0s;
  transition: all 0.3s linear 0s;
}
.h6_testimonial-navigation div svg {
  -webkit-transition: all 0.3s linear 0s;
  -moz-transition: all 0.3s linear 0s;
  -ms-transition: all 0.3s linear 0s;
  -o-transition: all 0.3s linear 0s;
  transition: all 0.3s linear 0s;
  color: rgba(0, 0, 0, 0.8);
}
.h6_testimonial-navigation div:hover {
  background-color: var(--clr-theme-primary-6);
}
.h6_testimonial-navigation div:hover svg {
  color: #fff;
}
@media (max-width: 767px) {
  .h6_testimonial-navigation {
    justify-content: flex-start;
  }
}

.testimonial-text-wrap {
  position: absolute;
  top: 40px;
}

.h6_testimonial-item blockquote {
  background: #fff;
  padding: 50px 60px 50px 145px;
  position: relative;
  z-index: 1;
  margin-bottom: 0;
}
.h6_testimonial-item blockquote::before {
  content: "\f10d";
  font-weight: 700;
  position: absolute;
  font-family: "Font Awesome 5 Pro";
  font-size: 52px;
  color: #0A0A0A;
  line-height: 1;
  display: inline-block;
  left: 70px;
  top: 60px;
}
.h6_testimonial-item blockquote p {
  color: #666;
  font-size: 20px;
  font-weight: 400;
  line-height: 30px;
}
@media (max-width: 767px) {
  .h6_testimonial-item blockquote p {
    font-size: 16px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .h6_testimonial-item blockquote p {
    font-size: 18px;
  }
}
@media (max-width: 767px) {
  .h6_testimonial-item blockquote {
    padding: 125px 30px 50px 30px;
  }
  .h6_testimonial-item blockquote::before {
    left: 30px;
    top: 40px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .h6_testimonial-item blockquote {
    padding: 125px 50px 50px 50px;
  }
  .h6_testimonial-item blockquote::before {
    left: 50px;
    top: 40px;
  }
}
.h6_testimonial-item .quote-admin {
  display: flex;
  justify-content: end;
}
.h6_testimonial-item .quote-admin-inner h5 {
  color: #0A0A0A;
  font-size: 20px;
  font-weight: 500;
  line-height: 30px;
  margin-bottom: 0;
  position: relative;
  z-index: 1;
}
.h6_testimonial-item .quote-admin-inner h5::before {
  position: absolute;
  left: -65px;
  top: 50%;
  transform: translateY(-50%);
  content: "";
  background: #0A0A0A;
  width: 50px;
  height: 2px;
}
.h6_testimonial-item .quote-admin-inner span {
  color: #666;
  font-size: 14px;
  font-weight: 400;
  line-height: 30px;
  display: inline-block;
}

.h8_testimonial-area {
  background-color: #F4F7FF;
}
.h8_testimonial-content {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  row-gap: 50px;
  column-gap: 30px;
}
.h8_testimonial-item {
  border-radius: 10px;
  background: #FFF;
  box-shadow: 0px 6px 50px 0px rgba(8, 5, 33, 0.06);
  padding: 35px 40px 35px;
  position: relative;
  z-index: 1;
  -webkit-transition: all 0.3s linear 0s;
  -moz-transition: all 0.3s linear 0s;
  -ms-transition: all 0.3s linear 0s;
  -o-transition: all 0.3s linear 0s;
  transition: all 0.3s linear 0s;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .h8_testimonial-item {
    padding: 35px 24px 35px;
  }
}
@media (max-width: 767px) {
  .h8_testimonial-item {
    padding: 35px 30px 35px;
  }
}
.h8_testimonial-item:hover {
  box-shadow: 0px 20px 100px 0px rgba(25, 35, 53, 0.3);
}
.h8_testimonial-item p {
  color: rgba(2, 25, 54, 0.7);
  font-size: 15px;
  font-weight: 400;
  line-height: 24px;
  margin-bottom: 28px;
}
@media (max-width: 767px) {
  .h8_testimonial-item p br {
    display: none;
  }
}
.h8_testimonial-item-admin {
  display: flex;
  gap: 20px;
  align-items: center;
  margin-bottom: 20px;
}
.h8_testimonial-item-img {
  width: 65px;
  height: 65px;
  flex-shrink: 0;
  border-radius: 50%;
}
.h8_testimonial-item-info h4 {
  color: #021936;
  font-size: 20px;
  font-weight: 600;
  line-height: 1.2;
  margin-bottom: 5px;
}
@media (max-width: 767px) {
  .h8_testimonial-item-info h4 {
    font-size: 16px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .h8_testimonial-item-info h4 {
    font-size: 20px;
  }
}
.h8_testimonial-item-info h4:hover a {
  color: #395BDF;
}
.h8_testimonial-item-info span {
  color: rgba(2, 25, 54, 0.7);
  font-size: 15px;
  font-weight: 400;
  line-height: 24px;
  display: block;
}
.h8_testimonial-item-rating ul {
  display: flex;
  gap: 5px;
}
.h8_testimonial-item-rating ul li {
  font-size: 18px;
  color: #F2A22A;
}
.h8_testimonial-quote {
  position: absolute;
  right: 40px;
  bottom: 40px;
}

.h9_testimonial-area {
  background: #F5F5F5;
  position: relative;
  z-index: 1;
  overflow-x: hidden;
}
.h9_testimonial-title {
  color: #21242C;
  font-size: 40px;
  font-weight: 600;
  line-height: 1.35;
  text-decoration-line: underline;
  margin-bottom: 67px;
}
@media (max-width: 767px) {
  .h9_testimonial-title {
    font-size: 32px;
  }
}
.h9_testimonial-item p {
  color: #21242C;
  font-size: 30px;
  font-weight: 400;
  line-height: 46px;
  margin-bottom: 45px;
}
@media (max-width: 767px) {
  .h9_testimonial-item p {
    font-size: 20px;
    line-height: 32px;
  }
}
.h9_testimonial-item h6 {
  color: #21242C;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.2;
  display: flex;
  align-items: center;
  gap: 34px;
  margin-bottom: 0;
}
.h9_testimonial-item h6 span {
  color: rgba(11, 23, 40, 0.7);
  font-size: 14px;
  font-weight: 500;
  line-height: 1;
  display: block;
  position: relative;
  z-index: 1;
}
.h9_testimonial-item h6 span::before {
  position: absolute;
  left: -19px;
  top: 50%;
  transform: translateY(-50%);
  content: "";
  width: 4px;
  height: 4px;
  background: rgba(11, 23, 40, 0.2);
  -webkit-transition: all 0.3s linear 0s;
  -moz-transition: all 0.3s linear 0s;
  -ms-transition: all 0.3s linear 0s;
  -o-transition: all 0.3s linear 0s;
  transition: all 0.3s linear 0s;
  border-radius: 50%;
}

.h9_testimonial-img img {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  cursor: pointer;
}

.h9_testimonial-thumb-wrap {
  width: 300px;
}
@media (max-width: 767px) {
  .h9_testimonial-thumb-wrap {
    width: 280px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .h9_testimonial-thumb-wrap {
    width: 300px;
  }
}
.h9_testimonial-thumb-wrap .swiper-slide-thumb-active .h9_testimonial-img img {
  border: 1px solid #1865F2;
  padding: 9px;
}

.h9_testimonial-wrap {
  border-radius: 500px 0px 0px 500px;
  background: #FFF;
  position: relative;
  z-index: 1;
}
.h9_testimonial-wrap::after {
  position: absolute;
  top: 0;
  right: -100%;
  content: "";
  height: 100%;
  width: 100%;
  background-color: #fff;
  z-index: -1;
}

.h9_testimonial-pagination.swiper-pagination-bullets {
  position: absolute;
  right: 120px;
  top: 50%;
  transform: translateY(-50%);
  bottom: auto;
  left: auto;
  width: auto;
  text-align: center;
  line-height: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
  z-index: 9;
}
.h9_testimonial-pagination .swiper-pagination-bullet {
  width: 8px;
  height: 8px;
  cursor: pointer;
  display: inline-block;
  border-radius: 50%;
  background: transparent;
  opacity: 1;
  -webkit-transition: all 0.3s linear 0s;
  -moz-transition: all 0.3s linear 0s;
  -ms-transition: all 0.3s linear 0s;
  -o-transition: all 0.3s linear 0s;
  transition: all 0.3s linear 0s;
  background-color: rgba(33, 36, 44, 0.14);
  position: relative;
  z-index: 1;
}
.h9_testimonial-pagination .swiper-pagination-bullet::before {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  content: "";
  width: 8px;
  height: 8px;
  width: 24px;
  height: 24px;
  border: 1px solid #1865F2;
  border-radius: 50%;
  opacity: 0;
  visibility: hidden;
}
.h9_testimonial-pagination.swiper-pagination-bullets .swiper-pagination-bullet {
  margin: 0;
}
.h9_testimonial-pagination.swiper-pagination-bullets .swiper-pagination-bullet.swiper-pagination-bullet-active {
  margin: 8px 0;
}
.h9_testimonial-pagination.swiper-pagination-bullets .swiper-pagination-bullet:first-child.swiper-pagination-bullet-active {
  margin-bottom: 8px;
  margin-top: 0;
}
.h9_testimonial-pagination.swiper-pagination-bullets .swiper-pagination-bullet:last-child.swiper-pagination-bullet-active {
  margin-top: 8px;
  margin-bottom: 0;
}
.h9_testimonial-pagination .swiper-pagination-bullet-active {
  background-color: #1865F2;
}
.h9_testimonial-pagination .swiper-pagination-bullet-active::before {
  opacity: 1;
  visibility: visible;
}
.h9_testimonial-navigation {
  position: absolute;
  left: 120px;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  flex-direction: column;
  gap: 10px;
  z-index: 99;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .h9_testimonial-navigation {
    top: auto;
    transform: unset;
    bottom: 40px;
    flex-direction: row;
    left: 40px;
  }
}
@media (max-width: 767px) {
  .h9_testimonial-navigation {
    top: auto;
    transform: unset;
    bottom: 30px;
    flex-direction: row;
    left: 15px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .h9_testimonial-navigation {
    left: 35px;
  }
}
.h9_testimonial-navigation div {
  width: 55px;
  height: 55px;
  border-radius: 50%;
  background: #FFF;
  box-shadow: 0px 1px 2px 0px rgba(33, 36, 44, 0.14);
  color: rgb(17, 7, 24);
  display: grid;
  place-items: center;
  font-size: 14px;
  -webkit-transition: all 0.3s linear 0s;
  -moz-transition: all 0.3s linear 0s;
  -ms-transition: all 0.3s linear 0s;
  -o-transition: all 0.3s linear 0s;
  transition: all 0.3s linear 0s;
}
.h9_testimonial-navigation div:hover {
  background: #1865F2;
  color: #fff;
}
@media only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .h9_testimonial-navigation div {
    transform: rotate(-90deg);
  }
}

.h10_testimonial-area {
  position: relative;
  z-index: 1;
  overflow: hidden;
  background-color: #fff;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px), only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .h10_testimonial-wrap.mr-45 {
    margin-right: 0;
  }
}
.h10_testimonial-text-wrap {
  position: absolute;
  top: 90px;
}
.h10_testimonial-title {
  font-size: 128px;
  font-weight: 700;
  -webkit-text-fill-color: transparent;
  -webkit-text-stroke: 1px #B7B7B7;
  line-height: 1.25;
  letter-spacing: -15px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .h10_testimonial-title {
    font-size: 90px;
    letter-spacing: -10px;
  }
}
@media (max-width: 767px) {
  .h10_testimonial-title {
    font-size: 45px;
    letter-spacing: -3px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .h10_testimonial-title {
    font-size: 80px;
  }
}
.h10_testimonial-title span {
  position: relative;
}
.h10_testimonial-title span img {
  position: absolute;
  top: -15px;
  left: -30px;
  transform: rotate(-15deg);
}
@media (max-width: 767px) {
  .h10_testimonial-title span img {
    top: -10px;
    left: -10px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .h10_testimonial-title span img {
    top: -10px;
    left: -15px;
  }
}
.h10_testimonial-navigation {
  display: flex;
  gap: 10px;
  position: absolute;
  right: 50px;
  bottom: 10px;
  z-index: 99;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .h10_testimonial-navigation {
    right: 0;
  }
}
@media (max-width: 767px) {
  .h10_testimonial-navigation {
    right: 0;
    bottom: 50px;
  }
}
.h10_testimonial-navigation div {
  width: 40px;
  height: 40px;
  display: grid;
  place-items: center;
  font-size: 14px;
  background-color: #F6F6F6;
  -webkit-transition: all 0.3s linear 0s;
  -moz-transition: all 0.3s linear 0s;
  -ms-transition: all 0.3s linear 0s;
  -o-transition: all 0.3s linear 0s;
  transition: all 0.3s linear 0s;
}
.h10_testimonial-navigation div svg {
  -webkit-transition: all 0.3s linear 0s;
  -moz-transition: all 0.3s linear 0s;
  -ms-transition: all 0.3s linear 0s;
  -o-transition: all 0.3s linear 0s;
  transition: all 0.3s linear 0s;
  color: rgba(0, 0, 0, 0.8);
}
.h10_testimonial-navigation div:hover {
  background-color: var(--clr-theme-primary-10);
}
.h10_testimonial-navigation div:hover svg {
  color: #fff;
}
@media (max-width: 767px) {
  .h10_testimonial-navigation {
    justify-content: flex-start;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .h10_testimonial-img.ml-10 {
    margin-left: 0;
  }
}

.h10_testimonial-item blockquote {
  background: #fff;
  position: relative;
  z-index: 1;
  margin-bottom: 0;
}
.h10_testimonial-item blockquote::before {
  content: "\f10d";
  font-weight: 700;
  font-family: "Font Awesome 5 Pro";
  font-size: 90px;
  color: #EDEDED;
  line-height: 56px;
  display: inline-block;
  margin-bottom: 35px;
}
.h10_testimonial-item blockquote p {
  color: #666;
  font-size: 22px;
  font-weight: 400;
  line-height: 32px;
  margin-bottom: 55px;
}
@media (max-width: 767px) {
  .h10_testimonial-item blockquote p {
    font-size: 16px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .h10_testimonial-item blockquote p {
    font-size: 18px;
  }
}
.h10_testimonial-item .quote-admin-inner {
  margin-left: 132px;
}
.h10_testimonial-item .quote-admin-inner h5 {
  color: #0A0A0A;
  font-size: 20px;
  font-weight: 500;
  line-height: 30px;
  margin-bottom: 0;
}
.h10_testimonial-item .quote-admin-inner span {
  color: #666;
  font-size: 14px;
  font-weight: 400;
  line-height: 30px;
  display: inline-block;
}

.h10_testimonial-admin {
  width: 60px;
  height: 60px;
}
.h10_testimonial-admin img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  object-fit: cover;
  border: 5px solid transparent;
}

.h10_testimonial-thumb-wrap {
  width: 112px;
  background-color: #fff;
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: 9;
}
@media (max-width: 767px) {
  .h10_testimonial-thumb-wrap {
    bottom: 110px;
  }
}
.h10_testimonial-thumb-wrap::after {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  content: "";
  width: 2px;
  height: 45px;
  background-color: #D9D9D9;
}
.h10_testimonial-thumbs .swiper-slide {
  z-index: 1;
}
.h10_testimonial-thumbs .swiper-slide .h10_testimonial-admin {
  margin-left: 35px;
}
.h10_testimonial-thumbs .swiper-slide.swiper-slide-thumb-active {
  z-index: 9;
}
.h10_testimonial-thumbs .swiper-slide.swiper-slide-thumb-active .h10_testimonial-admin {
  margin-left: 0;
}
.h10_testimonial-thumbs .swiper-slide.swiper-slide-thumb-active .h10_testimonial-admin img {
  border-color: #fff;
}
@media (max-width: 767px) {
  .h10_testimonial-active {
    padding-bottom: 110px;
  }
}

/*
************
*************************
15. counter
******************************************************* 
*************************************************************** */
.counter-wrap {
  border: 1px solid rgba(30, 30, 30, 0.1);
}
.counter-item {
  display: flex;
  gap: 20px;
  border-right: 1px solid rgba(30, 30, 30, 0.1);
  padding-top: 25px;
  padding-bottom: 30px;
  justify-content: center;
}
@media (max-width: 767px) {
  .counter-item {
    border-right: 0;
    border-bottom: 1px solid rgba(30, 30, 30, 0.1);
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .counter-item {
    border-right: 1px solid rgba(30, 30, 30, 0.1);
    border-bottom: 1px solid rgba(30, 30, 30, 0.1);
  }
}
.counter-icon i {
  font-size: 38px;
  color: var(--clr-color-darkPink);
  flex-shrink: 0;
  display: block;
  margin-top: 10px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .counter-icon i {
    font-size: 30px;
  }
}
@media (max-width: 767px) {
  .counter-icon i {
    font-size: 30px;
  }
}
.counter-info-title {
  font-size: 55px;
  display: flex;
  font-weight: 600;
  margin-bottom: 0;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .counter-info-title {
    font-size: 50px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .counter-info-title {
    font-size: 45px;
  }
}
@media (max-width: 767px) {
  .counter-info-title {
    font-size: 45px;
  }
}
.counter-info-text {
  font-size: 14px;
  text-transform: uppercase;
  color: #767676;
  display: inline-block;
  line-height: 1;
}

.counter-wrap .col-xl-3:last-child .counter-item {
  border-right: 0;
}
@media (max-width: 767px) {
  .counter-wrap .col-xl-3:last-child .counter-item {
    border-bottom: 0;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .counter-wrap .col-xl-3:nth-child(2) .counter-item {
    border-bottom: 1px solid rgba(30, 30, 30, 0.1);
    border-right: 0;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .counter-wrap .col-xl-3:nth-child(2) .counter-item {
    border-right: 0;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .counter-wrap .col-xl-3:nth-child(3) .counter-item {
    border-bottom: 0;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .counter-wrap .col-xl-3:first-child .counter-item {
    border-bottom: 1px solid rgba(30, 30, 30, 0.1);
  }
}

.h7_counter-item {
  display: flex;
}
.h7_counter-info-title {
  font-size: 96px;
  font-weight: 700;
  text-transform: capitalize;
  display: flex;
  line-height: 1;
  align-items: center;
  margin-bottom: 0;
  -webkit-text-fill-color: transparent;
  -webkit-text-stroke: 1px rgb(82, 82, 82);
  -webkit-transition: all 0.3s linear 0s;
  -moz-transition: all 0.3s linear 0s;
  -ms-transition: all 0.3s linear 0s;
  -o-transition: all 0.3s linear 0s;
  transition: all 0.3s linear 0s;
}
.h7_counter-info-title:hover {
  -webkit-text-fill-color: #0A0A0A;
  -webkit-text-stroke: 1px #0A0A0A;
}
.h7_counter-info-text {
  color: #525252;
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
  text-transform: uppercase;
}

.h8_counter-item {
  display: flex;
}
.h8_counter-info-title {
  color: #FFF;
  font-size: 36px;
  font-weight: 600;
  line-height: 1.3;
  margin-bottom: 5px;
}
.h8_counter-info-text {
  color: #525252;
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
  text-transform: uppercase;
}

.h8_counter-info-icon {
  width: 65px;
  height: 65px;
  border-radius: 64px;
  background: rgba(255, 255, 255, 0.1);
  display: grid;
  place-items: center;
  margin-bottom: 13px;
  margin-left: auto;
  margin-right: auto;
}

.h8_counter-info-icon i {
  font-size: 23px;
  color: rgb(255, 255, 255);
}

.h8_counter-info {
  text-align: center;
}

.h8_counter-info-text {
  color: #FFF;
  font-size: 15px;
  font-weight: 400;
  line-height: 24px;
  display: block;
}

.h10_counter-wrap {
  display: flex;
  justify-content: space-between;
  gap: 30px;
  flex-wrap: wrap;
}
@media only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .h10_counter-wrap {
    justify-content: center;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .h10_counter-wrap {
    justify-content: space-between;
  }
}
.h10_counter-item {
  display: flex;
  gap: 20px;
}
.h10_counter-icon i {
  font-size: 38px;
  color: var(--clr-theme-primary-10);
  flex-shrink: 0;
  display: block;
  margin-top: 10px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .h10_counter-icon i {
    font-size: 30px;
  }
}
@media (max-width: 767px) {
  .h10_counter-icon i {
    font-size: 30px;
  }
}
.h10_counter-info-title {
  font-size: 55px;
  display: flex;
  font-weight: 600;
  margin-bottom: 0;
  color: #0A0A0A;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .h10_counter-info-title {
    font-size: 50px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .h10_counter-info-title {
    font-size: 45px;
  }
}
@media (max-width: 767px) {
  .h10_counter-info-title {
    font-size: 45px;
  }
}
.h10_counter-info-text {
  font-size: 14px;
  text-transform: uppercase;
  color: #767676;
  display: inline-block;
  line-height: 1;
}

/*
************
*************************
16. blog
******************************************************* 
*************************************************************** */
.blog-item {
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0px 10px 50px rgba(30, 30, 30, 0.1);
}
.blog-img {
  border-radius: 6px;
  overflow: hidden;
}
.blog-img img {
  width: 100%;
  -webkit-transition: all 0.6s linear 0s;
  -moz-transition: all 0.6s linear 0s;
  -ms-transition: all 0.6s linear 0s;
  -o-transition: all 0.6s linear 0s;
  transition: all 0.6s linear 0s;
}
.blog-content {
  padding: 40px 0 25px;
}
.blog-content-title {
  font-size: 20px;
  font-weight: 600;
  line-height: 1.5;
  margin-bottom: 27px;
}
.blog-content-title:hover a {
  color: var(--clr-theme-primary);
}
.blog-content-meta {
  display: flex;
  align-items: center;
  gap: 20px;
  margin-bottom: 7px;
}
.blog-content-meta span {
  display: inline-block;
  font-size: 14px;
  font-weight: 500;
}
.blog-content-meta span i {
  font-size: 14px;
  margin-right: 7px;
  font-weight: 300;
  color: var(--clr-color-darkPink);
}

.blog-item.blog-item-h {
  display: flex;
  align-items: center;
}
.blog-item.blog-item-h .blog-content {
  padding: 0 30px;
}
.blog-item.blog-item-h .blog-img {
  width: 280px;
  flex-shrink: 0;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .blog-item {
    display: flex;
    align-items: center;
  }
  .blog-item .blog-content {
    padding: 0 30px;
  }
  .blog-item .blog-img {
    width: 280px;
    flex-shrink: 0;
  }
}
@media (max-width: 767px) {
  .blog-item.blog-item-h {
    flex-direction: column;
  }
  .blog-item.blog-item-h .blog-content {
    padding: 40px 0 25px;
  }
  .blog-item.blog-item-h .blog-img {
    width: 100%;
  }
}

.blog-item:hover .blog-img img {
  transform: scale(1.2);
}

.h2_blog-img {
  overflow: hidden;
}
.h2_blog-img img {
  border-radius: 10px 10px 0 0;
  -webkit-transition: all 0.6s linear 0s;
  -moz-transition: all 0.6s linear 0s;
  -ms-transition: all 0.6s linear 0s;
  -o-transition: all 0.6s linear 0s;
  transition: all 0.6s linear 0s;
  width: 100%;
}
.h2_blog-content {
  padding: 30px 30px 35px;
  box-shadow: 0px 10px 50px rgba(30, 30, 30, 0.1);
  border-radius: 0 0 10px 10px;
}
.h2_blog-content-title {
  font-size: 20px;
  font-weight: 600;
  line-height: 1.4;
  margin-bottom: 27px;
}
.h2_blog-content-title:hover a {
  color: var(--clr-theme-primary);
}
.h2_blog-content-meta {
  display: flex;
  align-items: center;
  gap: 20px;
  margin-bottom: 7px;
}
.h2_blog-content-meta span {
  display: inline-block;
  font-size: 14px;
  font-weight: 500;
}
.h2_blog-content-meta span i {
  font-size: 14px;
  margin-right: 7px;
  font-weight: 300;
  color: var(--clr-color-darkPink);
}

.h2_blog-item:hover .h2_blog-img img {
  transform: scale(1.2);
}

.h3_blog-item {
  border-radius: 10px;
  background: #FFF;
  box-shadow: 0px 2px 20px 0px rgba(30, 30, 30, 0.14);
  padding: 20px;
}
.h3_blog-img {
  overflow: hidden;
  position: relative;
  border-radius: 6px;
  z-index: 1;
}
.h3_blog-img img {
  border-radius: 10px;
  -webkit-transition: all 0.6s linear 0s;
  -moz-transition: all 0.6s linear 0s;
  -ms-transition: all 0.6s linear 0s;
  -o-transition: all 0.6s linear 0s;
  transition: all 0.6s linear 0s;
  width: 100%;
}
.h3_blog-img-meta {
  position: absolute;
  right: 15px;
  top: 15px;
  border-radius: 6px;
  background: var(--clr-theme-primary-3);
  box-shadow: 0px 2px 5px 0px rgba(32, 42, 61, 0.4);
  font-size: 14px;
  font-weight: 500;
  color: #fff;
  padding: 10px 15px;
  line-height: 1;
}
.h3_blog-content {
  padding-top: 25px;
}
.h3_blog-content-title {
  font-size: 20px;
  font-weight: 600;
  line-height: 1.4;
  margin-bottom: 7px;
}
.h3_blog-content-title:hover a {
  color: var(--clr-theme-primary-3);
}
.h3_blog-content-meta {
  display: flex;
  align-items: center;
  gap: 20px;
  margin-bottom: 12px;
}
.h3_blog-content-meta span {
  display: inline-block;
  font-size: 14px;
  font-weight: 500;
}
.h3_blog-content-meta span i {
  font-size: 14px;
  margin-right: 7px;
  font-weight: 300;
  color: var(--clr-theme-primary-3);
}
.h3_blog-content p {
  font-size: 15px;
  margin-bottom: 20px;
}
.h3_blog-btn {
  font-size: 16px;
  font-weight: 500;
  color: var(--clr-body-heading);
}
.h3_blog-btn i {
  margin-left: 10px;
}
.h3_blog-btn:hover {
  color: var(--clr-theme-primary-3);
}

.h3_blog-item:hover .h3_blog-img img {
  transform: scale(1.2);
}

.h4_blog-item {
  border-radius: 10px;
  background: #FFF;
  border: 1px solid rgba(10, 10, 10, 0.1);
  padding: 20px;
}
.h4_blog-img {
  overflow: hidden;
  position: relative;
  border-radius: 6px;
  z-index: 1;
}
.h4_blog-img img {
  border-radius: 10px;
  -webkit-transition: all 0.6s linear 0s;
  -moz-transition: all 0.6s linear 0s;
  -ms-transition: all 0.6s linear 0s;
  -o-transition: all 0.6s linear 0s;
  transition: all 0.6s linear 0s;
  width: 100%;
}
.h4_blog-img-meta {
  position: absolute;
  right: 15px;
  top: 15px;
  border-radius: 6px;
  background: var(--clr-theme-primary-3);
  box-shadow: 0px 2px 5px 0px rgba(32, 42, 61, 0.4);
  font-size: 14px;
  font-weight: 500;
  color: #fff;
  padding: 10px 15px;
  line-height: 1;
}
.h4_blog-content {
  padding-top: 22px;
}
.h4_blog-content-title {
  font-size: 20px;
  font-weight: 500;
  line-height: 1.5;
  margin-bottom: 5px;
}
.h4_blog-content-title:hover a {
  color: var(--clr-theme-primary-3);
}
.h4_blog-content-meta {
  display: flex;
  align-items: center;
  gap: 35px;
  margin-bottom: 14px;
}
.h4_blog-content-meta span {
  display: inline-block;
  font-size: 15px;
  font-weight: 400;
  position: relative;
  z-index: 1;
}
.h4_blog-content-meta span:not(:first-child)::after {
  position: absolute;
  left: -23px;
  top: 50%;
  transform: translateY(-50%);
  width: 5px;
  height: 5px;
  background-color: var(--clr-body-heading);
  content: "";
  border-radius: 50%;
}
.h4_blog-content-meta span:not(:first-child)::before {
  position: absolute;
  left: -13px;
  top: 50%;
  transform: translateY(-50%);
  width: 5px;
  height: 5px;
  background-color: var(--clr-body-heading);
  border-radius: 50%;
  content: "";
}
.h4_blog-content p {
  font-size: 15px;
  margin-bottom: 20px;
}
.h4_blog-btn {
  font-size: 16px;
  font-weight: 500;
  color: var(--clr-body-heading);
}
.h4_blog-btn i {
  margin-left: 10px;
}
.h4_blog-btn:hover {
  color: var(--clr-theme-primary-3);
}

.h4_blog-item:hover .h4_blog-img img {
  transform: scale(1.2);
}

.h5_blog-item {
  border: 1px solid rgba(10, 10, 10, 0.1);
  padding: 100px 50px 91px;
  -webkit-transition: all 0.4s linear 0s;
  -moz-transition: all 0.4s linear 0s;
  -ms-transition: all 0.4s linear 0s;
  -o-transition: all 0.4s linear 0s;
  transition: all 0.4s linear 0s;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .h5_blog-item {
    padding: 100px 40px 91px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .h5_blog-item {
    padding: 100px 35px 91px;
  }
}
@media (max-width: 767px) {
  .h5_blog-item {
    padding: 80px 25px 71px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .h5_blog-item {
    padding: 100px 50px 91px;
  }
}
.h5_blog-content-meta {
  display: flex;
  align-items: center;
  gap: 30px;
  margin-bottom: 25px;
}
.h5_blog-content-meta-tag {
  border-radius: 0px 6px;
  background: var(--clr-theme-primary-5);
  display: inline-block;
  padding: 7px 20px;
  color: var(--clr-body-heading);
  font-size: 14px;
  font-weight: 500;
  line-height: 1;
}
.h5_blog-content-meta span {
  position: relative;
  z-index: 1;
  -webkit-transition: all 0.3s linear 0s;
  -moz-transition: all 0.3s linear 0s;
  -ms-transition: all 0.3s linear 0s;
  -o-transition: all 0.3s linear 0s;
  transition: all 0.3s linear 0s;
  font-size: 15px;
}
.h5_blog-content-meta span::before {
  position: absolute;
  left: -15px;
  top: 50%;
  transform: translateY(-50%);
  content: "";
  width: 5px;
  height: 5px;
  background: var(--clr-body-text);
  -webkit-transition: all 0.3s linear 0s;
  -moz-transition: all 0.3s linear 0s;
  -ms-transition: all 0.3s linear 0s;
  -o-transition: all 0.3s linear 0s;
  transition: all 0.3s linear 0s;
  border-radius: 50%;
}
.h5_blog-content-title {
  font-size: 20px;
  font-weight: 500;
  -webkit-transition: all 0.3s linear 0s;
  -moz-transition: all 0.3s linear 0s;
  -ms-transition: all 0.3s linear 0s;
  -o-transition: all 0.3s linear 0s;
  transition: all 0.3s linear 0s;
  margin-bottom: 20px;
}
@media (max-width: 767px) {
  .h5_blog-content-title {
    font-size: 18px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .h5_blog-content-title {
    font-size: 20px;
  }
}
.h5_blog-content p {
  margin-bottom: 21px;
  font-size: 15px;
  -webkit-transition: all 0.3s linear 0s;
  -moz-transition: all 0.3s linear 0s;
  -ms-transition: all 0.3s linear 0s;
  -o-transition: all 0.3s linear 0s;
  transition: all 0.3s linear 0s;
  line-height: 24px;
}
.h5_blog-btn {
  position: relative;
  z-index: 1;
  width: 20px;
  overflow: hidden;
  display: inline-block;
}
.h5_blog-btn svg {
  color: var(--clr-body-heading);
  -webkit-transition: all 0.3s linear 0s;
  -moz-transition: all 0.3s linear 0s;
  -ms-transition: all 0.3s linear 0s;
  -o-transition: all 0.3s linear 0s;
  transition: all 0.3s linear 0s;
  width: 24px;
  height: auto;
  margin-left: -4px;
}

.h5_blog-item:hover {
  background-color: var(--clr-body-heading);
  border-color: transparent;
}
.h5_blog-item:hover .h5_blog-content-meta span {
  color: rgba(255, 255, 255, 0.6);
}
.h5_blog-item:hover .h5_blog-content-meta span::before {
  background-color: rgba(255, 255, 255, 0.6);
}
.h5_blog-item:hover .h5_blog-content-title {
  color: #fff;
}
.h5_blog-item:hover .h5_blog-content p {
  color: rgba(255, 255, 255, 0.7);
}
.h5_blog-item:hover .h5_blog-btn {
  width: 24px;
}
.h5_blog-item:hover .h5_blog-btn svg {
  margin-left: 0;
  color: #fff;
}

.h6_blog-area .h5_blog-item {
  position: relative;
  z-index: 1;
}
.h6_blog-area .h5_blog-item::before {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  content: "";
  background-image: url("../../assets/img/blog/6/1.jpg");
  z-index: -1;
  opacity: 0;
  -webkit-transition: all 0.4s linear 0s;
  -moz-transition: all 0.4s linear 0s;
  -ms-transition: all 0.4s linear 0s;
  -o-transition: all 0.4s linear 0s;
  transition: all 0.4s linear 0s;
}
.h6_blog-area .h5_blog-item::after {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  content: "";
  background-color: rgba(10, 10, 10, 0.6);
  z-index: -1;
  opacity: 0;
  -webkit-transition: all 0.4s linear 0s;
  -moz-transition: all 0.4s linear 0s;
  -ms-transition: all 0.4s linear 0s;
  -o-transition: all 0.4s linear 0s;
  transition: all 0.4s linear 0s;
}
.h6_blog-area .h5_blog-content-meta-tag {
  background-color: var(--clr-theme-primary-6);
  color: #fff;
}
.h6_blog-area .h5_blog-item.active {
  border-color: transparent;
  background-color: transparent;
}
.h6_blog-area .h5_blog-item.active::before {
  opacity: 1;
}
.h6_blog-area .h5_blog-item.active::after {
  opacity: 1;
}
.h6_blog-area .h5_blog-item.active .h5_blog-content-meta-tag {
  background-color: #fff;
  color: var(--clr-theme-primary-6);
}
.h6_blog-area .h5_blog-item.active .h5_blog-content-meta span {
  color: rgba(255, 255, 255, 0.6);
}
.h6_blog-area .h5_blog-item.active .h5_blog-content-meta span::before {
  background-color: rgba(255, 255, 255, 0.6);
}
.h6_blog-area .h5_blog-item.active .h5_blog-content-title {
  color: #fff;
}
.h6_blog-area .h5_blog-item.active .h5_blog-content p {
  color: rgba(255, 255, 255, 0.7);
}
.h6_blog-area .h5_blog-item.active .h5_blog-btn {
  width: 24px;
}
.h6_blog-area .h5_blog-item.active .h5_blog-btn svg {
  margin-left: 0;
  color: #fff;
}
.h6_blog-area .h5_blog-item:hover {
  border-color: transparent;
  background-color: transparent;
}
.h6_blog-area .h5_blog-item:hover::before {
  opacity: 1;
}
.h6_blog-area .h5_blog-item:hover::after {
  opacity: 1;
}
.h6_blog-area .h5_blog-item:hover .h5_blog-content-meta-tag {
  background-color: #fff;
  color: var(--clr-theme-primary-6);
}
.h6_blog-area .theme-btn-5 {
  color: #fff;
  background-color: var(--clr-theme-primary-6);
}

.h8_blog-item {
  border-radius: 10px;
  background: #FFF;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0px 5px 25px 0px rgba(8, 5, 33, 0.08);
  -webkit-transition: all 0.3s linear 0s;
  -moz-transition: all 0.3s linear 0s;
  -ms-transition: all 0.3s linear 0s;
  -o-transition: all 0.3s linear 0s;
  transition: all 0.3s linear 0s;
}
.h8_blog-item:hover {
  box-shadow: 0px 10px 40px 0px rgba(25, 35, 53, 0.15);
}
.h8_blog-item-content {
  padding: 25px 30px 25px;
}
.h8_blog-item-content-title {
  color: #021936;
  font-size: 20px;
  font-weight: 700;
  line-height: 1.4;
  margin-bottom: 10px;
}
.h8_blog-item-content-title:hover a {
  color: #395BDF;
}
.h8_blog-item-content p {
  color: rgba(2, 25, 54, 0.7);
  font-size: 15px;
  font-weight: 400;
  line-height: 24px;
  margin-bottom: 20px;
}
.h8_blog-item-content-btn {
  color: #021936;
  font-size: 15px;
  font-weight: 500;
  display: flex;
  align-items: center;
  gap: 10px;
}
.h8_blog-item-img {
  overflow: hidden;
}
.h8_blog-item-img img {
  -webkit-transition: all 0.3s linear 0s;
  -moz-transition: all 0.3s linear 0s;
  -ms-transition: all 0.3s linear 0s;
  -o-transition: all 0.3s linear 0s;
  transition: all 0.3s linear 0s;
}

.h8_blog-navigation {
  display: flex;
  gap: 10px;
  justify-content: end;
}
.h8_blog-navigation div {
  border-radius: 40px;
  border: 1px solid rgba(2, 25, 54, 0.1);
  width: 40px;
  height: 40px;
  display: grid;
  place-items: center;
  -webkit-transition: all 0.3s linear 0s;
  -moz-transition: all 0.3s linear 0s;
  -ms-transition: all 0.3s linear 0s;
  -o-transition: all 0.3s linear 0s;
  transition: all 0.3s linear 0s;
}
.h8_blog-navigation div svg {
  color: #021936;
  -webkit-transition: all 0.3s linear 0s;
  -moz-transition: all 0.3s linear 0s;
  -ms-transition: all 0.3s linear 0s;
  -o-transition: all 0.3s linear 0s;
  transition: all 0.3s linear 0s;
}
.h8_blog-navigation div:hover {
  background-color: #395BDF;
}
.h8_blog-navigation div:hover svg {
  color: #fff;
}
@media (max-width: 767px) {
  .h8_blog-navigation {
    justify-content: flex-start;
  }
}

.h8_blog-item:hover .h8_blog-item-img img {
  transform: scale(1.1);
}

.h9_blog-item {
  background-color: #F5F5F5;
  border-radius: 20px;
  overflow: hidden;
  padding: 100px 50px 85px;
  -webkit-transition: all 0.3s linear 0s;
  -moz-transition: all 0.3s linear 0s;
  -ms-transition: all 0.3s linear 0s;
  -o-transition: all 0.3s linear 0s;
  transition: all 0.3s linear 0s;
  position: relative;
  z-index: 1;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .h9_blog-item {
    padding: 100px 40px 91px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .h9_blog-item {
    padding: 100px 35px 91px;
  }
}
@media (max-width: 767px) {
  .h9_blog-item {
    padding: 80px 25px 71px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .h9_blog-item {
    padding: 100px 50px 91px;
  }
}
.h9_blog-item::before {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  content: "";
  background-image: url("../../assets/img/blog/6/1.jpg");
  z-index: -1;
  opacity: 0;
  -webkit-transition: all 0.4s linear 0s;
  -moz-transition: all 0.4s linear 0s;
  -ms-transition: all 0.4s linear 0s;
  -o-transition: all 0.4s linear 0s;
  transition: all 0.4s linear 0s;
}
.h9_blog-item::after {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  content: "";
  background-color: rgba(245, 245, 245, 0.7);
  z-index: -1;
  opacity: 0;
  -webkit-transition: all 0.4s linear 0s;
  -moz-transition: all 0.4s linear 0s;
  -ms-transition: all 0.4s linear 0s;
  -o-transition: all 0.4s linear 0s;
  transition: all 0.4s linear 0s;
}
.h9_blog-content-meta {
  display: flex;
  align-items: center;
  gap: 33px;
  margin-bottom: 25px;
}
.h9_blog-content-meta-tag {
  border-radius: 4px;
  background: #1865F2;
  display: inline-block;
  padding: 7px 20px;
  color: #fff;
  font-size: 14px;
  font-weight: 500;
  line-height: 1;
}
.h9_blog-content-meta-tag:hover {
  color: #fff;
}
.h9_blog-content-meta span {
  color: rgba(10, 10, 10, 0.6);
  font-size: 15px;
  font-weight: 400;
  position: relative;
  z-index: 1;
  -webkit-transition: all 0.3s linear 0s;
  -moz-transition: all 0.3s linear 0s;
  -ms-transition: all 0.3s linear 0s;
  -o-transition: all 0.3s linear 0s;
  transition: all 0.3s linear 0s;
  font-size: 15px;
}
.h9_blog-content-meta span::before {
  position: absolute;
  left: -18px;
  top: 50%;
  transform: translateY(-50%);
  content: "";
  width: 5px;
  height: 5px;
  background: rgba(10, 10, 10, 0.6);
  -webkit-transition: all 0.3s linear 0s;
  -moz-transition: all 0.3s linear 0s;
  -ms-transition: all 0.3s linear 0s;
  -o-transition: all 0.3s linear 0s;
  transition: all 0.3s linear 0s;
  border-radius: 50%;
}
.h9_blog-content-title {
  color: #0A0A0A;
  font-size: 20px;
  font-weight: 500;
  line-height: 1.3;
  -webkit-transition: all 0.3s linear 0s;
  -moz-transition: all 0.3s linear 0s;
  -ms-transition: all 0.3s linear 0s;
  -o-transition: all 0.3s linear 0s;
  transition: all 0.3s linear 0s;
  margin-bottom: 19px;
}
.h9_blog-content-title:hover a {
  color: #1865F2;
}
@media (max-width: 767px) {
  .h9_blog-content-title {
    font-size: 18px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .h9_blog-content-title {
    font-size: 20px;
  }
}
.h9_blog-content p {
  color: rgba(10, 10, 10, 0.7);
  font-size: 15px;
  font-weight: 400;
  line-height: 24px;
  margin-bottom: 24px;
  -webkit-transition: all 0.3s linear 0s;
  -moz-transition: all 0.3s linear 0s;
  -ms-transition: all 0.3s linear 0s;
  -o-transition: all 0.3s linear 0s;
  transition: all 0.3s linear 0s;
}
.h9_blog-btn {
  position: relative;
  z-index: 1;
  width: 20px;
  overflow: hidden;
  display: inline-block;
}
.h9_blog-btn svg {
  color: var(--clr-body-heading);
  -webkit-transition: all 0.3s linear 0s;
  -moz-transition: all 0.3s linear 0s;
  -ms-transition: all 0.3s linear 0s;
  -o-transition: all 0.3s linear 0s;
  transition: all 0.3s linear 0s;
  width: 24px;
  height: auto;
  margin-left: -4px;
}

.h9_blog-item:hover {
  border-color: transparent;
  background-color: transparent;
}
.h9_blog-item:hover::before {
  opacity: 1;
}
.h9_blog-item:hover::after {
  opacity: 1;
}
.h9_blog-item:hover .h9_blog-btn {
  width: 24px;
}
.h9_blog-item:hover .h9_blog-btn svg {
  margin-left: 0;
  color: #1865F2;
}

.h9_blog-item.active {
  border-color: transparent;
  background-color: transparent;
}
.h9_blog-item.active::before {
  opacity: 1;
}
.h9_blog-item.active::after {
  opacity: 1;
}

.h10_blog-area {
  background-color: #E1EEDA;
}
.h10_blog-area .h4_blog-item {
  border-radius: 0;
}
.h10_blog-area .h4_blog-img {
  border-radius: 0;
  overflow: hidden;
}
.h10_blog-area .h4_blog-img img {
  border-radius: 0;
  -webkit-transition: all 0.5s linear 0s;
  -moz-transition: all 0.5s linear 0s;
  -ms-transition: all 0.5s linear 0s;
  -o-transition: all 0.5s linear 0s;
  transition: all 0.5s linear 0s;
}
.h10_blog-area .h4_blog-img-meta {
  position: absolute;
  right: auto;
  top: auto;
  left: 0;
  bottom: 0;
  border-radius: 0;
  background: var(--clr-theme-primary-10);
  box-shadow: none;
  font-size: 14px;
  font-weight: 500;
  color: #fff;
  padding: 8px 15px;
  line-height: 1;
}
.h10_blog-area .h4_blog-content-meta span {
  color: rgba(10, 10, 10, 0.6);
}
.h10_blog-area .h4_blog-content-meta span:not(:first-child)::after {
  background-color: #0A0A0A;
}
.h10_blog-area .h4_blog-content-meta span:not(:first-child)::before {
  background-color: #0A0A0A;
}
.h10_blog-area .h4_blog-content-title {
  color: #0A0A0A;
}
.h10_blog-area .h4_blog-content-title:hover a {
  color: var(--clr-theme-primary-10);
}
.h10_blog-area .h4_blog-content p {
  color: rgba(10, 10, 10, 0.7);
}
.h10_blog-area .h4_blog-btn {
  color: #0A0A0A;
}
.h10_blog-area .h4_blog-btn:hover {
  color: var(--clr-theme-primary-10);
}
.h10_blog .h4_blog-item:hover .h4_blog-img img {
  transform: scale(1.1);
}

/*
************
*************************
17. blog-details
******************************************************* 
*************************************************************** */
.blog_details-img {
  position: relative;
  margin-bottom: -40px;
  z-index: -1;
}
.blog_details-top {
  padding: 40px 50px 50px;
  box-shadow: 0px 20px 80px rgba(30, 30, 30, 0.1);
  border-radius: 0 10px 10px 10px;
  background-color: #fff;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .blog_details-top {
    padding: 40px 40px 50px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .blog_details-top {
    border-radius: 10px;
  }
}
@media (max-width: 767px) {
  .blog_details-top {
    padding: 40px 25px 50px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .blog_details-top {
    padding: 40px 30px 50px;
  }
}
.blog_details-title {
  font-size: 30px;
  margin-bottom: 25px;
  line-height: 1.4;
}
@media (max-width: 767px) {
  .blog_details-title {
    font-size: 25px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .blog_details-title {
    font-size: 30px;
  }
}
.blog_details-meta {
  display: flex;
  align-items: center;
  gap: 35px;
}
@media (max-width: 767px) {
  .blog_details-meta {
    gap: 20px;
    flex-direction: column;
    align-items: flex-start;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .blog_details-meta {
    gap: 30px;
    flex-direction: row;
    align-items: center;
  }
}
.blog_details-author {
  display: flex;
  align-items: center;
  gap: 10px;
}
.blog_details-author-img {
  width: 50px;
  height: 50px;
  border: 1px solid rgba(4, 0, 23, 0.1);
  border-radius: 50%;
  padding: 4px;
}
.blog_details-author-img img {
  width: 100%;
}
.blog_details-author-info span {
  font-size: 14px;
  font-weight: 500;
  color: #848B97;
  display: block;
  line-height: 20px;
}
.blog_details-author-info h5 {
  font-size: 14px;
  margin-bottom: 0;
  font-weight: 500;
  line-height: 1.4;
}
.blog_details-author-info h5:hover a {
  color: var(--clr-theme-primary);
}
.blog_details-rating {
  position: relative;
  z-index: 1;
  padding-left: 15px;
}
.blog_details-rating::before {
  position: absolute;
  left: 0;
  top: 0;
  content: "";
  height: 100%;
  width: 1px;
  background: rgba(4, 0, 23, 0.14);
  z-index: 1;
}
@media (max-width: 767px) {
  .blog_details-rating {
    padding-left: 0;
  }
  .blog_details-rating::before {
    display: none;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .blog_details-rating {
    padding-left: 15px;
  }
  .blog_details-rating::before {
    display: block;
  }
}
.blog_details-rating ul {
  display: flex;
  gap: 5px;
}
.blog_details-rating ul li {
  display: block;
  font-size: 12px;
  color: #FFA121;
  line-height: 20px;
}
.blog_details-rating span {
  font-size: 14px;
  font-weight: 500;
  display: block;
  color: #848B97;
  line-height: 22px;
}
.blog_details-category {
  position: relative;
  z-index: 1;
  padding-left: 15px;
}
.blog_details-category::before {
  position: absolute;
  left: 0;
  top: 0;
  content: "";
  height: 40px;
  width: 1px;
  background: rgba(4, 0, 23, 0.14);
  z-index: 1;
}
@media (max-width: 767px) {
  .blog_details-category {
    padding-left: 0;
  }
  .blog_details-category::before {
    display: none;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .blog_details-category {
    padding-left: 15px;
  }
  .blog_details-category::before {
    display: block;
  }
}
.blog_details-category span {
  font-size: 14px;
  font-weight: 500;
  color: #848B97;
  display: block;
  line-height: 20px;
}
.blog_details-category h5 {
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 0;
  line-height: 1.4;
}
.blog_details-category h5:hover a {
  color: var(--clr-theme-primary);
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .blog_details-inner-text {
    margin-right: 60px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .blog_details-inner-text {
    margin-right: 0;
  }
}
.blog_details-inner-text p {
  color: rgba(30, 30, 30, 0.7);
}
.blog_details-quote {
  background-color: #F5F5F5;
  padding: 35px 85px 35px 45px;
  position: relative;
  z-index: 1;
  margin: 0;
  margin-bottom: 40px;
  border-radius: 6px;
}
.blog_details-quote p {
  font-size: 20px;
  font-style: italic;
  color: var(--clr-body-heading);
  margin-bottom: 15px;
}
.blog_details-quote h6 {
  font-size: 16px;
  font-weight: 600;
  padding-left: 15px;
  margin-bottom: 0;
  position: relative;
  z-index: 1;
}
.blog_details-quote h6::before {
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  content: "";
  width: 6px;
  height: 6px;
  background-color: var(--clr-theme-primary);
}
.blog_details-quote-icon {
  position: absolute;
  right: 85px;
  bottom: 25px;
  line-height: 1;
  font-size: 45px;
  color: rgba(0, 88, 252, 0.1);
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .blog_details-quote {
    padding: 35px 65px 35px 30px;
  }
}
@media (max-width: 767px) {
  .blog_details-quote {
    padding: 35px 30px;
  }
  .blog_details-quote p {
    font-size: 18px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .blog_details-quote {
    padding: 35px 30px 35px 30px;
  }
}
.blog_details-highlight {
  display: flex;
  align-items: center;
  background-color: #F5F5F5;
  padding: 20px;
  border-radius: 10px;
  margin-bottom: 25px;
}
.blog_details-highlight-img {
  width: 310px;
  flex-shrink: 0;
  border-radius: 6px;
  overflow: hidden;
}
.blog_details-highlight-img img {
  width: 100%;
}
.blog_details-highlight-text {
  padding: 0 40px;
  padding-right: 30px;
}
.blog_details-highlight-text h3 {
  font-size: 30px;
  line-height: 1.33;
  margin-bottom: 0;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .blog_details-highlight-img {
    width: 210px;
  }
  .blog_details-highlight-text {
    padding: 0 30px;
  }
  .blog_details-highlight-text h3 {
    font-size: 28px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .blog_details-highlight-img {
    width: 300px;
  }
  .blog_details-highlight-text {
    padding-right: 20px;
    padding-left: 30px;
  }
  .blog_details-highlight-text h3 {
    font-size: 28px;
  }
}
@media (max-width: 767px) {
  .blog_details-highlight {
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
  }
  .blog_details-highlight-img {
    width: 100%;
  }
  .blog_details-highlight-text {
    padding-right: 0;
    padding-left: 0;
  }
  .blog_details-highlight-text h3 {
    font-size: 23px;
  }
}
.blog_details-content-meta {
  border: 1px solid rgba(4, 0, 23, 0.1);
  border-radius: 10px;
  padding: 34px 40px;
  display: flex;
  justify-content: space-between;
  gap: 20px;
  align-items: center;
  margin-bottom: 50px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .blog_details-content-meta {
    flex-direction: column;
    align-items: flex-start;
  }
}
@media (max-width: 767px) {
  .blog_details-content-meta {
    flex-direction: column;
    align-items: flex-start;
    padding: 34px 20px;
  }
}
.blog_details-content-tag {
  display: flex;
  align-items: center;
  gap: 10px;
}
.blog_details-content-tag h6 {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 0;
  flex-shrink: 0;
}
.blog_details-content-tag ul {
  display: flex;
  align-items: center;
  gap: 10px;
}
.blog_details-content-tag ul li a {
  font-size: 14px;
  background: #F5F5F5;
  display: block;
  height: 30px;
  line-height: 30px;
  border-radius: 4px;
  padding: 0 15px;
  color: rgba(4, 0, 23, 0.7);
}
@media (max-width: 767px) {
  .blog_details-content-tag {
    align-items: flex-start;
  }
  .blog_details-content-tag ul {
    flex-wrap: wrap;
  }
}
.blog_details-content-social {
  display: flex;
  align-items: center;
  gap: 15px;
}
.blog_details-content-social h6 {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 0;
}
.blog_details-content-social ul {
  display: flex;
  align-items: center;
  gap: 15px;
}
.blog_details-content-social ul li a {
  font-size: 14px;
  color: rgba(4, 0, 23, 0.3);
  display: block;
}
.blog_details-bottom {
  display: flex;
  align-items: center;
  gap: 30px;
}
.blog_details-bottom-item {
  width: 100%;
  background: #F5F5F5;
  display: flex;
  align-items: center;
  padding: 30px 60px 30px 30px;
  border: 1px solid transparent;
  border-radius: 6px;
  gap: 20px;
  -webkit-transition: all 0.3s linear 0s;
  -moz-transition: all 0.3s linear 0s;
  -ms-transition: all 0.3s linear 0s;
  -o-transition: all 0.3s linear 0s;
  transition: all 0.3s linear 0s;
  cursor: pointer;
}
.blog_details-bottom-item.inner-item-2 {
  padding: 30px 30px 30px 60px;
}
.blog_details-bottom-item:hover {
  background-color: #fff;
  border-color: rgba(4, 0, 23, 0.1);
}
.blog_details-bottom-item h5 {
  font-size: 18px;
  font-weight: 500;
  color: var(--clr-body-heading);
  margin-bottom: 0;
  line-height: 1.33;
}
.blog_details-bottom-item span {
  display: block;
  color: var(--clr-body-heading);
  font-size: 18px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .blog_details-bottom-item {
    padding: 30px 25px 30px 25px;
    gap: 15px;
  }
  .blog_details-bottom-item.inner-item-2 {
    padding: 30px 25px 30px 25px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .blog_details-bottom-item {
    padding: 30px 50px 30px 30px;
  }
  .blog_details-bottom-item.inner-item-2 {
    padding: 30px 30px 30px 50px;
  }
}
@media (max-width: 767px) {
  .blog_details-bottom-item {
    padding: 30px 25px 30px 25px;
    gap: 15px;
  }
  .blog_details-bottom-item.inner-item-2 {
    padding: 30px 25px 30px 25px;
  }
}
@media (max-width: 767px) {
  .blog_details-bottom {
    flex-direction: column;
  }
}
.blog_details-related-title {
  font-size: 30px;
  line-height: 1.46;
  margin-bottom: 20px;
}
@media (max-width: 767px) {
  .blog_details-related-title {
    font-size: 25px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .blog_details-related-title {
    font-size: 30px;
  }
}
.blog_details-comment-item {
  display: flex;
  gap: 20px;
  border-bottom: 1px solid rgba(4, 0, 23, 0.1);
  margin-bottom: 30px;
  padding-bottom: 40px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .blog_details-comment-item.ml-80 {
    margin-left: 60px;
  }
}
@media (max-width: 767px) {
  .blog_details-comment-item {
    flex-direction: column;
  }
  .blog_details-comment-item.ml-80 {
    margin-left: 0;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .blog_details-comment-item {
    flex-direction: row;
  }
  .blog_details-comment-item.ml-80 {
    margin-left: 50px;
  }
}
.blog_details-comment-title {
  font-size: 24px;
  margin-bottom: 30px;
}
.blog_details-comment-img {
  width: 60px;
  flex-shrink: 0;
  border-radius: 50%;
  overflow: hidden;
  height: 60px;
}
.blog_details-comment-content h6 {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 3px;
}
.blog_details-comment-content span {
  font-size: 13px;
  color: var(--clr-theme-primary);
  font-weight: 500;
  text-transform: uppercase;
  display: block;
  margin-bottom: 5px;
}
.blog_details-comment-content p {
  margin-bottom: 22px;
}
.blog_details-comment-content .comment-reply {
  font-size: 15px;
  display: inline-block;
  border: 1px solid rgba(30, 30, 30, 0.1);
  height: 30px;
  padding: 0 20px;
  font-weight: 500;
  color: var(--clr-body-heading);
  border-radius: 4px;
}
.blog_details-comment-content .comment-reply:hover {
  background-color: var(--clr-theme-primary);
  color: #fff;
}
.blog_details-form-submit {
  display: flex;
  align-items: center;
  gap: 40px;
}
@media (max-width: 767px) {
  .blog_details-form-submit {
    flex-direction: column;
    gap: 20px;
    align-items: flex-start;
  }
  .blog_details-form-btn {
    order: 1;
  }
}

.blog_details-content-social ul li a:hover {
  color: var(--clr-theme-primary);
}

.blog_details-content-tag ul li a:hover {
  background: var(--clr-theme-primary);
  color: #fff;
}

.blog_details-message-title {
  font-size: 32px;
  margin-bottom: 25px;
}
@media (max-width: 767px) {
  .blog_details-message-title {
    font-size: 25px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .blog_details-message-title {
    font-size: 30px;
  }
}
.blog_details-form-input {
  position: relative;
  z-index: auto;
}
.blog_details-form-input textarea, .blog_details-form-input input {
  width: 100%;
  height: 60px;
  line-height: 60px;
  border: none;
  border-bottom: 1px solid rgba(4, 0, 23, 0.14);
  padding: 0 30px;
  color: rgba(4, 0, 23, 0.6);
  font-size: 16px;
}
.blog_details-form-input textarea::-webkit-input-placeholder, .blog_details-form-input input::-webkit-input-placeholder {
  color: rgba(4, 0, 23, 0.6);
  font-size: 16px;
  opacity: 1;
  -webkit-transition: all 0.3s linear 0s;
  -moz-transition: all 0.3s linear 0s;
  -ms-transition: all 0.3s linear 0s;
  -o-transition: all 0.3s linear 0s;
  transition: all 0.3s linear 0s;
}
.blog_details-form-input textarea:-moz-placeholder, .blog_details-form-input input:-moz-placeholder {
  color: rgba(4, 0, 23, 0.6);
  font-size: 16px;
  opacity: 1;
  -webkit-transition: all 0.3s linear 0s;
  -moz-transition: all 0.3s linear 0s;
  -ms-transition: all 0.3s linear 0s;
  -o-transition: all 0.3s linear 0s;
  transition: all 0.3s linear 0s;
}
.blog_details-form-input textarea::-moz-placeholder, .blog_details-form-input input::-moz-placeholder {
  color: rgba(4, 0, 23, 0.6);
  font-size: 16px;
  opacity: 1;
  -webkit-transition: all 0.3s linear 0s;
  -moz-transition: all 0.3s linear 0s;
  -ms-transition: all 0.3s linear 0s;
  -o-transition: all 0.3s linear 0s;
  transition: all 0.3s linear 0s;
}
.blog_details-form-input textarea:-ms-input-placeholder, .blog_details-form-input input:-ms-input-placeholder {
  color: rgba(4, 0, 23, 0.6);
  font-size: 16px;
  opacity: 1;
  -webkit-transition: all 0.3s linear 0s;
  -moz-transition: all 0.3s linear 0s;
  -ms-transition: all 0.3s linear 0s;
  -o-transition: all 0.3s linear 0s;
  transition: all 0.3s linear 0s;
}
.blog_details-form-input textarea {
  height: 95px;
  line-height: 22px;
  padding-top: 20px;
}
.blog_details-form-input .inner-icon {
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  font-size: 14px;
  color: rgba(4, 0, 23, 0.6);
  z-index: 9;
}
.blog_details-form-input-select .inner-icon {
  top: 30px;
}
.blog_details-form-textarea .inner-icon {
  top: 17px;
  transform: translateY(0);
}

.nice-select.blog_details-form-list {
  width: 100%;
  height: 60px;
  line-height: 60px;
  color: rgba(4, 0, 23, 0.6);
  font-size: 16px;
  border: none;
  border-bottom: 1px solid rgba(4, 0, 23, 0.14);
  border-radius: 0;
  padding: 0 30px;
}
.nice-select.blog_details-form-list::after {
  border-bottom: 1.5px solid rgba(30, 30, 30, 0.6);
  border-right: 1.5px solid rgba(30, 30, 30, 0.6);
  content: "";
  display: block;
  height: 5px;
  margin-top: -1px;
  pointer-events: none;
  position: absolute;
  right: 25px;
  top: 50%;
  transform-origin: 50% 50%;
  transform: rotate(45deg) translateY(-50%);
  transition: all 0.3s ease-in-out;
  width: 5px;
}
.nice-select.blog_details-form-list ul {
  width: 100%;
  border-radius: 0;
  border: none;
  background-color: #fff;
  margin-top: 0;
}
.nice-select.blog_details-form-list ul li {
  color: rgba(4, 0, 23, 0.6);
  font-size: 15px;
  font-weight: 400;
  width: 100%;
  height: 50px;
  line-height: 50px;
  padding: 0 30px;
  min-height: 50px;
}
.nice-select.blog_details-form-list ul li.selected {
  font-weight: 400;
}

.theme-btn.blog_details-btn {
  height: 50px;
  line-height: 50px;
  padding: 0 45px;
}

.blog_details-form-condition label {
  font-size: 15px;
}

.blog_details-form-condition .condition_label {
  display: block;
  position: relative;
  padding-left: 22px;
  cursor: pointer;
  font-size: 15px;
  user-select: none;
}
.blog_details-form-condition .condition_label input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}
.blog_details-form-condition .check_mark {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  height: 14px;
  width: 14px;
  background-color: transparent;
  border: 1px solid rgba(30, 30, 30, 0.2);
  border-radius: 2px;
}
.blog_details-form-condition .check_mark:after {
  content: "";
  position: absolute;
  display: none;
}
.blog_details-form-condition .condition_label input:checked ~ .check_mark:after {
  display: block;
}
.blog_details-form-condition .condition_label .check_mark:after {
  left: 4px;
  top: 1px;
  width: 4px;
  height: 8px;
  border: solid rgba(30, 30, 30, 0.4);
  border-width: 0 1px 1px 0;
  transform: rotate(45deg);
}

.blog_details-price {
  padding-left: 40px;
  padding-right: 40px;
}
.blog_details-price del {
  display: block;
  font-size: 16px;
  margin-bottom: 4px;
}
.blog_details-price h2 {
  font-size: 44px;
  font-weight: 600;
  margin-bottom: 20px;
}
.blog_details-list ul {
  margin-bottom: 40px;
}
.blog_details-list ul li {
  border-bottom: 1px solid rgba(30, 30, 30, 0.1);
  font-size: 16px;
  font-weight: 500;
  padding: 10px 40px;
  color: rgba(30, 30, 30, 0.7);
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.blog_details-list ul li span i {
  margin-right: 10px;
  color: var(--clr-theme-primary);
  font-size: 16px;
}
.blog_details-sidebar-btn {
  margin-left: 40px;
  margin-right: 40px;
}
.blog_details-widget {
  background: #fff;
  padding: 35px 30px 40px;
  box-shadow: 0px 20px 80px rgba(30, 30, 30, 0.1);
  margin-bottom: 30px;
  border-radius: 10px;
}
@media (max-width: 767px) {
  .blog_details-widget {
    padding: 35px 25px 40px;
  }
}
.blog_details-widget.widget-category {
  padding-bottom: 25px;
}
.blog_details-widget-title {
  font-size: 22px;
  font-weight: 600;
  margin-bottom: 25px;
}
.blog_details-widget form {
  position: relative;
  z-index: 1;
}
.blog_details-widget form button {
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  border: none;
  padding: 0 15px;
  background-color: var(--clr-theme-primary);
  color: #fff;
  border-radius: 0 6px 6px 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.blog_details-widget form input {
  width: 100%;
  height: 50px;
  line-height: 50px;
  border: none;
  background: #F5F5F5;
  border-radius: 6px;
  padding: 0 20px;
  padding-right: 65px;
  font-size: 14px;
  color: rgba(30, 30, 30, 0.7);
}
.blog_details-widget-post {
  display: flex;
  gap: 20px;
  align-items: center;
  margin-bottom: 15px;
}
.blog_details-widget-post:last-child {
  margin-bottom: 0;
}
.blog_details-widget-course {
  display: flex;
  gap: 20px;
  align-items: center;
  margin-bottom: 15px;
}
.blog_details-widget-course:last-child {
  margin-bottom: 0;
}

.blog_details-list ul li:first-child {
  border-top: 1px solid rgba(30, 30, 30, 0.1);
}

.blog_details-post-img {
  width: 90px;
  height: 90px;
  flex-shrink: 0;
  border-radius: 4px;
  overflow: hidden;
}
.blog_details-post-info span {
  font-size: 14px;
  display: flex;
  align-items: center;
  gap: 6px;
  color: rgba(30, 30, 30, 0.6);
  font-weight: 500;
}
.blog_details-post-info h6 {
  font-size: 16px;
  margin-bottom: 0;
  font-weight: 500;
  line-height: 22px;
}
.blog_details-post-info h6:hover a {
  color: var(--clr-theme-primary);
}

.blog_details-course-img {
  width: 90px;
  height: 90px;
  flex-shrink: 0;
  border-radius: 4px;
  overflow: hidden;
}
.blog_details-course-info h6 {
  font-size: 16px;
  margin-bottom: 0;
  font-weight: 500;
  line-height: 22px;
}
.blog_details-course-info h6:hover a {
  color: var(--clr-theme-primary);
}
.blog_details-course-info .inner-course-rate {
  font-size: 15px;
  font-weight: 600;
  color: var(--clr-theme-primary);
  display: block;
}

.blog_details-widget-tag ul {
  display: flex;
  align-items: center;
  gap: 10px;
  flex-wrap: wrap;
}
.blog_details-widget-tag ul li a {
  font-size: 14px;
  background: #F5F5F5;
  display: block;
  height: 30px;
  line-height: 30px;
  border-radius: 4px;
  padding: 0 15px;
  color: rgba(4, 0, 23, 0.7);
}
.blog_details-widget-tag ul li a:hover {
  background-color: var(--clr-theme-primary);
  color: #fff;
}
.blog_details-widget-category ul li a {
  display: block;
  position: relative;
  z-index: 1;
  font-size: 16px;
  font-weight: 400;
  padding-left: 17px;
  line-height: 44px;
  color: var(--clr-body-heading);
  border-bottom: 1px solid rgba(30, 30, 30, 0.1);
}
.blog_details-widget-category ul li a:hover {
  color: var(--clr-theme-primary);
}
.blog_details-widget-category ul li a::before {
  position: absolute;
  left: 0;
  top: 50%;
  content: "";
  width: 6px;
  height: 6px;
  background-color: var(--clr-body-heading);
  border-radius: 50%;
  transform: translateY(-50%);
}
.blog_details-widget-category ul li:last-child a {
  border: none;
}

/*
************
*************************
18. cta
******************************************************* 
*************************************************************** */
.cta-area {
  position: relative;
  z-index: 9;
  margin-bottom: -120px;
}
.cta-wrapper {
  background-color: var(--clr-theme-primary);
  border-radius: 10px;
  padding: 50px 80px 40px;
  box-shadow: 0px 30px 80px rgba(30, 30, 30, 0.3);
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .cta-wrapper {
    padding: 50px 50px 40px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .cta-wrapper {
    padding: 50px 50px 50px;
  }
}
@media (max-width: 767px) {
  .cta-wrapper {
    padding: 40px 30px 40px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .cta-wrapper {
    padding: 50px 50px 50px;
  }
}
.cta-title {
  font-size: 40px;
  font-weight: 700;
  line-height: 1.25;
  color: #fff;
  margin-bottom: 0;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .cta-title {
    font-size: 36px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .cta-title {
    font-size: 35px;
  }
}
@media (max-width: 767px) {
  .cta-title {
    font-size: 30px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .cta-title {
    font-size: 35px;
  }
}
.cta-subtitle {
  color: #E8184D;
  font-size: 15px;
  font-weight: 500;
  display: inline-block;
  background-color: #FDF5F7;
  padding: 10px 25px;
  line-height: 1;
  position: relative;
  z-index: 1;
  margin-bottom: 10px;
}
.cta-subtitle::before {
  position: absolute;
  left: 0;
  top: 0;
  width: 2px;
  height: 100%;
  background-color: #E8184D;
  content: "";
}
@media (max-width: 767px) {
  .cta-subtitle {
    font-size: 14px;
    padding: 10px 20px;
  }
}
.cta-button {
  display: flex;
  gap: 20px;
  justify-content: end;
}
@media only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .cta-button {
    justify-content: flex-start;
  }
}
@media (max-width: 767px) {
  .cta-button {
    flex-direction: column;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .cta-button {
    flex-direction: row;
  }
}
.cta-btn {
  height: 55px;
  border: 1px solid #fff;
  color: #fff;
  padding: 0 25px;
  border-radius: 6px;
  font-size: 16px;
  font-weight: 600;
  display: inline-block;
  line-height: 55px;
}
.cta-btn i {
  font-size: 18px;
  margin-right: 10px;
}
.cta-btn:hover {
  background: #fff;
  color: var(--clr-body-heading);
}
@media (max-width: 767px) {
  .cta-btn {
    text-align: center;
  }
}

.h2_cta-content-form {
  position: relative;
  z-index: 1;
  margin: 0 40px;
}
@media (max-width: 767px) {
  .h2_cta-content-form {
    margin: 0 15px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .h2_cta-content-form {
    margin: 0 40px;
  }
}
.h2_cta-content-form input {
  width: 100%;
  height: 55px;
  line-height: 55px;
  padding-left: 25px;
  border-radius: 6px;
  border: none;
  padding-right: 195px;
  color: rgba(30, 30, 30, 0.6);
  background-color: #fff;
  font-size: 15px;
  box-shadow: 0px 6px 30px rgba(30, 30, 30, 0.1);
}
.h2_cta-content-form input::-webkit-input-placeholder {
  color: rgba(30, 30, 30, 0.6);
  font-size: 15px;
  opacity: 1;
  -webkit-transition: all 0.3s linear 0s;
  -moz-transition: all 0.3s linear 0s;
  -ms-transition: all 0.3s linear 0s;
  -o-transition: all 0.3s linear 0s;
  transition: all 0.3s linear 0s;
}
.h2_cta-content-form input:-moz-placeholder {
  color: rgba(30, 30, 30, 0.6);
  font-size: 15px;
  opacity: 1;
  -webkit-transition: all 0.3s linear 0s;
  -moz-transition: all 0.3s linear 0s;
  -ms-transition: all 0.3s linear 0s;
  -o-transition: all 0.3s linear 0s;
  transition: all 0.3s linear 0s;
}
.h2_cta-content-form input::-moz-placeholder {
  color: rgba(30, 30, 30, 0.6);
  font-size: 15px;
  opacity: 1;
  -webkit-transition: all 0.3s linear 0s;
  -moz-transition: all 0.3s linear 0s;
  -ms-transition: all 0.3s linear 0s;
  -o-transition: all 0.3s linear 0s;
  transition: all 0.3s linear 0s;
}
.h2_cta-content-form input:-ms-input-placeholder {
  color: rgba(30, 30, 30, 0.6);
  font-size: 15px;
  opacity: 1;
  -webkit-transition: all 0.3s linear 0s;
  -moz-transition: all 0.3s linear 0s;
  -ms-transition: all 0.3s linear 0s;
  -o-transition: all 0.3s linear 0s;
  transition: all 0.3s linear 0s;
}
@media (max-width: 767px) {
  .h2_cta-content-form input {
    padding-right: 25px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .h2_cta-content-form input {
    padding-right: 100px;
  }
}
.h2_cta-content-form button {
  position: absolute;
  right: 7px;
  top: 50%;
  transform: translateY(-50%);
  height: 40px;
  line-height: 40px;
  background: var(--clr-theme-primary);
  color: #fff;
  border: none;
  border-radius: 6px;
  font-size: 14px;
  font-weight: 600;
  padding: 0 20px;
}
@media (max-width: 767px) {
  .h2_cta-content-form button {
    position: relative;
    top: 15px;
    transform: none;
    right: 0;
    width: 100%;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .h2_cta-content-form button {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 7px;
    width: auto;
  }
}

.h3_cta-area .cta-wrapper {
  background-color: var(--clr-theme-primary-3);
}

.h4_cta-area .cta-wrapper {
  background-color: var(--clr-theme-primary-4);
}

.h5_cta-area .cta-wrapper {
  background-color: var(--clr-theme-primary-5);
}
.h5_cta-area .cta-title {
  color: var(--clr-body-heading);
}
.h5_cta-area .cta-btn {
  color: var(--clr-body-heading);
  border-color: var(--clr-body-heading);
}
.h5_cta-area .cta-btn:hover {
  border-color: transparent;
}

.h6_cta-area {
  position: relative;
  z-index: 9;
  margin-bottom: -120px;
}
.h6_cta-wrapper {
  background-color: var(--clr-theme-primary);
  padding: 55px 60px 55px 370px;
  box-shadow: 0px 30px 80px rgba(30, 30, 30, 0.3);
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  z-index: 1;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .h6_cta-wrapper {
    padding: 55px 60px 55px 280px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .h6_cta-wrapper {
    padding: 55px 60px 55px 280px;
  }
}
@media (max-width: 767px) {
  .h6_cta-wrapper {
    flex-wrap: wrap;
    padding: 50px 35px 50px 35px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px) {
  .h6_cta-wrapper {
    padding: 55px 50px 55px 50px;
  }
}
.h6_cta-wrapper-img {
  position: absolute;
  left: 50px;
  bottom: 0;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px), only screen and (min-width: 992px) and (max-width: 1199px) {
  .h6_cta-wrapper-img {
    left: 0;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .h6_cta-wrapper-img {
    display: none;
  }
}
.h6_cta-title {
  color: #FFF;
  font-size: 48px;
  font-weight: 700;
  line-height: 1.2;
  margin-bottom: 0;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .h6_cta-title {
    font-size: 36px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .h6_cta-title {
    font-size: 35px;
  }
}
@media (max-width: 767px) {
  .h6_cta-title {
    font-size: 30px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .h6_cta-title {
    font-size: 35px;
  }
}
.h6_cta-content {
  width: 460px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .h6_cta-content {
    width: 380px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .h6_cta-content {
    width: 65%;
  }
}
@media (max-width: 767px) {
  .h6_cta-content {
    width: 100%;
  }
}
.h6_cta-btn {
  height: 55px;
  background-color: #fff;
  color: var(--clr-theme-primary-6);
  padding: 0 30px;
  line-height: 55px;
  text-align: center;
  font-size: 15px;
  font-weight: 500;
  display: flex;
  align-items: center;
  gap: 8px;
}
.h6_cta-btn i {
  font-size: 16px;
}
.h6_cta-btn:hover {
  background: var(--clr-theme-primary-6);
  color: #fff;
}

.h8_cta-area .h6_cta-wrapper {
  border-radius: 10px;
}

.h9_cta-area .h6_cta-wrapper {
  border-radius: 10px;
}

/*
************
*************************
19. teacher
******************************************************* 
*************************************************************** */
.h2_teacher-section {
  min-height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 50px 60px 60px;
  border-radius: 10px;
  overflow: hidden;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .h2_teacher-section {
    padding: 50px 30px 60px 45px;
  }
}
@media (max-width: 767px) {
  .h2_teacher-section {
    padding: 45px 30px 50px;
  }
  .h2_teacher-section .section-title br {
    display: none;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .h2_teacher-section {
    padding: 45px 50px 50px 50px;
  }
  .h2_teacher-section .section-title br {
    display: block;
  }
}
.h2_teacher-img {
  border-radius: 10px;
  overflow: hidden;
}
.h2_teacher-img img {
  width: 100%;
  -webkit-transition: all 0.6s linear 0s;
  -moz-transition: all 0.6s linear 0s;
  -ms-transition: all 0.6s linear 0s;
  -o-transition: all 0.6s linear 0s;
  transition: all 0.6s linear 0s;
}
.h2_teacher-item {
  position: relative;
  z-index: 1;
  overflow: hidden;
}
.h2_teacher-content {
  position: absolute;
  left: 0;
  right: 0;
  width: calc(100% - 60px);
  margin-left: auto;
  margin-right: auto;
  background-color: #fff;
  padding: 15px 25px;
  text-align: center;
  bottom: 30px;
  border-radius: 6px;
  opacity: 0;
  visibility: hidden;
  bottom: -50px;
  -webkit-transition: all 0.5s linear 0s;
  -moz-transition: all 0.5s linear 0s;
  -ms-transition: all 0.5s linear 0s;
  -o-transition: all 0.5s linear 0s;
  transition: all 0.5s linear 0s;
}
.h2_teacher-content h5 {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 5px;
  line-height: 24px;
}
.h2_teacher-content h5:hover a {
  color: var(--clr-theme-primary);
}
.h2_teacher-content span {
  font-size: 14px;
  font-weight: 500;
  display: block;
  line-height: 20px;
}

.h2_teacher-item:hover .h2_teacher-content {
  opacity: 1;
  visibility: visible;
  bottom: 30px;
}
.h2_teacher-item:hover .h2_teacher-img img {
  transform: scale(1.2);
}

.h3_teacher-img {
  position: relative;
  z-index: 1;
  border-radius: 10px;
  overflow: hidden;
  margin-bottom: 25px;
}
.h3_teacher-social {
  position: absolute;
  right: 30px;
  bottom: 30px;
  display: flex;
  align-items: center;
  gap: 10px;
}
.h3_teacher-social .share {
  width: 50px;
  height: 50px;
  background: #fff;
  display: grid;
  place-items: center;
  border-radius: 4px;
  font-size: 18px;
  color: var(--clr-body-heading);
  -webkit-transition: all 0.3s linear 0s;
  -moz-transition: all 0.3s linear 0s;
  -ms-transition: all 0.3s linear 0s;
  -o-transition: all 0.3s linear 0s;
  transition: all 0.3s linear 0s;
  cursor: pointer;
}
.h3_teacher-social ul {
  display: flex;
  gap: 10px;
}
.h3_teacher-social ul li {
  opacity: 0;
  visibility: hidden;
  -webkit-transition: all 0.3s linear 0s;
  -moz-transition: all 0.3s linear 0s;
  -ms-transition: all 0.3s linear 0s;
  -o-transition: all 0.3s linear 0s;
  transition: all 0.3s linear 0s;
  margin-bottom: -10px;
}
.h3_teacher-social ul li a {
  width: 50px;
  height: 50px;
  background: var(--clr-theme-primary-3);
  display: grid;
  place-items: center;
  color: #fff;
  border-radius: 4px;
  font-size: 18px;
}
.h3_teacher-social ul li:nth-child(2) {
  -webkit-transition: all 0.4s linear 0s;
  -moz-transition: all 0.4s linear 0s;
  -ms-transition: all 0.4s linear 0s;
  -o-transition: all 0.4s linear 0s;
  transition: all 0.4s linear 0s;
}
.h3_teacher-social ul li:first-child {
  -webkit-transition: all 0.5s linear 0s;
  -moz-transition: all 0.5s linear 0s;
  -ms-transition: all 0.5s linear 0s;
  -o-transition: all 0.5s linear 0s;
  transition: all 0.5s linear 0s;
}
.h3_teacher-content {
  display: flex;
  align-items: center;
  gap: 45px;
}
@media (max-width: 767px) {
  .h3_teacher-content {
    gap: 20px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .h3_teacher-content {
    gap: 45px;
  }
}
.h3_teacher-content-title {
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 0;
}
.h3_teacher-content-title:hover {
  color: var(--clr-theme-primary-3);
}
.h3_teacher-content span {
  font-size: 16px;
  display: inline-block;
  color: rgba(30, 30, 30, 0.7);
}
.h3_teacher-img:hover .share {
  background: var(--clr-theme-primary-3);
  color: #fff;
}
.h3_teacher-img:hover ul li {
  opacity: 1;
  visibility: visible;
  margin-bottom: 0;
}

.teacher-pagination.swiper-pagination-bullets {
  right: 0;
  left: auto;
  text-align: center;
  line-height: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 14px;
}
.teacher-pagination .swiper-pagination-bullet {
  width: 7px;
  height: 7px;
  cursor: pointer;
  display: inline-block;
  border-radius: 50%;
  background: #fff;
  border: 1px solid rgba(30, 30, 30, 0.7);
  opacity: 1;
  -webkit-transition: all 0.3s linear 0s;
  -moz-transition: all 0.3s linear 0s;
  -ms-transition: all 0.3s linear 0s;
  -o-transition: all 0.3s linear 0s;
  transition: all 0.3s linear 0s;
}
.teacher-pagination.swiper-pagination-bullets .swiper-pagination-bullet {
  margin: 0;
}
.teacher-pagination.swiper-pagination-bullets .swiper-pagination-bullet:last-child {
  margin-right: 0;
}
.teacher-pagination .swiper-pagination-bullet-active {
  opacity: 1;
  width: 14px;
  height: 14px;
  background: var(--clr-body-heading);
  border-color: transparent;
}

.h5_teacher-img {
  position: relative;
  z-index: 1;
  border-radius: 0;
  overflow: hidden;
  margin-bottom: 25px;
}
.h5_teacher-img::after {
  position: absolute;
  left: 0;
  top: 0;
  content: "";
  width: 100%;
  height: 100%;
  background: linear-gradient(180deg, rgba(243, 235, 30, 0) 0%, #F3EB1E 100%);
  z-index: 1;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: all 0.3s linear 0s;
  -moz-transition: all 0.3s linear 0s;
  -ms-transition: all 0.3s linear 0s;
  -o-transition: all 0.3s linear 0s;
  transition: all 0.3s linear 0s;
}
.h5_teacher-social {
  position: absolute;
  left: 30px;
  bottom: 30px;
  display: flex;
  align-items: center;
  gap: 10px;
  z-index: 5;
}
.h5_teacher-social ul {
  display: flex;
  gap: 10px;
}
.h5_teacher-social ul li {
  opacity: 0;
  visibility: hidden;
  -webkit-transition: all 0.3s linear 0s;
  -moz-transition: all 0.3s linear 0s;
  -ms-transition: all 0.3s linear 0s;
  -o-transition: all 0.3s linear 0s;
  transition: all 0.3s linear 0s;
  margin-bottom: -10px;
}
.h5_teacher-social ul li a {
  width: 50px;
  height: 50px;
  background: var(--clr-body-heading);
  display: grid;
  place-items: center;
  color: var(--clr-theme-primary-5);
  font-size: 18px;
}
.h5_teacher-social ul li a:hover {
  background-color: #fff;
  color: var(--clr-body-heading);
}
.h5_teacher-social ul li:nth-child(2) {
  -webkit-transition: all 0.4s linear 0s;
  -moz-transition: all 0.4s linear 0s;
  -ms-transition: all 0.4s linear 0s;
  -o-transition: all 0.4s linear 0s;
  transition: all 0.4s linear 0s;
}
.h5_teacher-social ul li:first-child {
  -webkit-transition: all 0.5s linear 0s;
  -moz-transition: all 0.5s linear 0s;
  -ms-transition: all 0.5s linear 0s;
  -o-transition: all 0.5s linear 0s;
  transition: all 0.5s linear 0s;
}
.h5_teacher-content-title {
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 3px;
}
.h5_teacher-content-title:hover {
  color: var(--clr-theme-primary);
}
.h5_teacher-content span {
  font-size: 16px;
  display: inline-block;
  color: rgba(30, 30, 30, 0.7);
}
.h5_teacher-img:hover::after {
  opacity: 1;
  visibility: visible;
}
.h5_teacher-img:hover ul li {
  opacity: 1;
  visibility: visible;
  margin-bottom: 0;
}

.h6_teacher-area {
  background-color: #F6F6F6;
}
@media (max-width: 1799px) {
  .h6_teacher-area.pt-115 {
    padding-top: 0;
  }
}
.h6_teacher-area .h3_teacher-img {
  border-radius: 0;
}
.h6_teacher-area .h3_teacher-img:hover .share {
  background: var(--clr-theme-primary-6);
}
.h6_teacher-area .h3_teacher-social ul li a {
  background: var(--clr-theme-primary-6);
}

.h8_teacher-img {
  position: relative;
  z-index: 1;
  border-radius: 10px;
  overflow: hidden;
  margin-bottom: 25px;
}
.h8_teacher-social {
  position: absolute;
  right: 30px;
  top: 30px;
  display: flex;
  align-items: center;
  gap: 10px;
  flex-direction: column;
}
.h8_teacher-social .share {
  width: 36px;
  height: 36px;
  display: grid;
  place-items: center;
  border-radius: 50%;
  font-size: 16px;
  border: 1px solid rgba(255, 255, 255, 0.4);
  box-shadow: drop-shadow(0px 20px 100px rgba(25, 35, 53, 0.3));
  color: #fff;
  -webkit-transition: all 0.4s linear 0s;
  -moz-transition: all 0.4s linear 0s;
  -ms-transition: all 0.4s linear 0s;
  -o-transition: all 0.4s linear 0s;
  transition: all 0.4s linear 0s;
  cursor: pointer;
}
.h8_teacher-social ul {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.h8_teacher-social ul li {
  opacity: 0;
  visibility: hidden;
  -webkit-transition: all 0.3s linear 0s;
  -moz-transition: all 0.3s linear 0s;
  -ms-transition: all 0.3s linear 0s;
  -o-transition: all 0.3s linear 0s;
  transition: all 0.3s linear 0s;
  margin-bottom: -10px;
}
.h8_teacher-social ul li a {
  width: 36px;
  height: 36px;
  background: #fff;
  display: grid;
  place-items: center;
  color: #395BDF;
  border-radius: 50%;
  font-size: 16px;
}
.h8_teacher-social ul li:nth-child(2) {
  -webkit-transition: all 0.4s linear 0s;
  -moz-transition: all 0.4s linear 0s;
  -ms-transition: all 0.4s linear 0s;
  -o-transition: all 0.4s linear 0s;
  transition: all 0.4s linear 0s;
}
.h8_teacher-social ul li:first-child {
  -webkit-transition: all 0.5s linear 0s;
  -moz-transition: all 0.5s linear 0s;
  -ms-transition: all 0.5s linear 0s;
  -o-transition: all 0.5s linear 0s;
  transition: all 0.5s linear 0s;
}
.h8_teacher-content {
  text-align: center;
}
.h8_teacher-content-title {
  color: #021936;
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 8px;
  line-height: 1.2;
}
.h8_teacher-content-title:hover a {
  color: #395BDF;
}
.h8_teacher-content span {
  display: block;
  color: rgba(2, 25, 54, 0.7);
  font-size: 15px;
  font-weight: 400;
  line-height: 24px;
}
.h8_teacher-img:hover .share {
  background: #fff;
  color: #395BDF;
}
.h8_teacher-img:hover ul li {
  opacity: 1;
  visibility: visible;
  margin-bottom: 0;
}

.h8_teacher-navigation {
  display: flex;
  gap: 10px;
  justify-content: end;
}
.h8_teacher-navigation div {
  border-radius: 40px;
  border: 1px solid rgba(2, 25, 54, 0.1);
  width: 40px;
  height: 40px;
  display: grid;
  place-items: center;
  -webkit-transition: all 0.3s linear 0s;
  -moz-transition: all 0.3s linear 0s;
  -ms-transition: all 0.3s linear 0s;
  -o-transition: all 0.3s linear 0s;
  transition: all 0.3s linear 0s;
}
.h8_teacher-navigation div svg {
  color: #021936;
  -webkit-transition: all 0.3s linear 0s;
  -moz-transition: all 0.3s linear 0s;
  -ms-transition: all 0.3s linear 0s;
  -o-transition: all 0.3s linear 0s;
  transition: all 0.3s linear 0s;
}
.h8_teacher-navigation div:hover {
  background-color: #395BDF;
}
.h8_teacher-navigation div:hover svg {
  color: #fff;
}
@media (max-width: 767px) {
  .h8_teacher-navigation {
    justify-content: flex-start;
  }
}

.h9_teacher-item {
  position: relative;
  z-index: 1;
  overflow: hidden;
}
.h9_teacher-img {
  border-radius: 20px;
  overflow: hidden;
}
.h9_teacher-img img {
  width: 100%;
  -webkit-transition: all 0.5s linear 0s;
  -moz-transition: all 0.5s linear 0s;
  -ms-transition: all 0.5s linear 0s;
  -o-transition: all 0.5s linear 0s;
  transition: all 0.5s linear 0s;
}
.h9_teacher-content {
  position: absolute;
  left: 0;
  right: 0;
  width: calc(100% - 60px);
  margin-left: auto;
  margin-right: auto;
  background-color: #fff;
  padding: 30px 30px 24px;
  bottom: 30px;
  border-radius: 10px;
  -webkit-transition: all 0.5s linear 0s;
  -moz-transition: all 0.5s linear 0s;
  -ms-transition: all 0.5s linear 0s;
  -o-transition: all 0.5s linear 0s;
  transition: all 0.5s linear 0s;
}
.h9_teacher-content h5 {
  color: #17161C;
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 5px;
  line-height: 1;
}
.h9_teacher-content span {
  color: rgba(23, 22, 28, 0.7);
  font-size: 14px;
  font-weight: 500;
  text-transform: uppercase;
  display: block;
  line-height: 24px;
}
.h9_teacher-pagination.swiper-pagination-bullets {
  right: 0;
  left: auto;
  text-align: center;
  line-height: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}
.h9_teacher-pagination .swiper-pagination-bullet {
  width: 10px;
  height: 10px;
  cursor: pointer;
  display: inline-block;
  border-radius: 50%;
  background: rgba(23, 22, 28, 0.14);
  opacity: 1;
  -webkit-transition: all 0.3s linear 0s;
  -moz-transition: all 0.3s linear 0s;
  -ms-transition: all 0.3s linear 0s;
  -o-transition: all 0.3s linear 0s;
  transition: all 0.3s linear 0s;
}
.h9_teacher-pagination.swiper-pagination-bullets .swiper-pagination-bullet {
  margin: 0;
}
.h9_teacher-pagination.swiper-pagination-bullets .swiper-pagination-bullet:last-child {
  margin-right: 0;
}
.h9_teacher-pagination .swiper-pagination-bullet-active {
  opacity: 1;
  width: 40px;
  border-radius: 20px;
  background: #1865F2;
}

.h9_teacher-item:hover .h9_teacher-content {
  box-shadow: 5px 0px 0px 0px #1865F2 inset;
}
.h9_teacher-item:hover .h9_teacher-img img {
  transform: scale(1.1);
}

/*
************
*************************
20. price
******************************************************* 
*************************************************************** */
.h2_price-item {
  background-color: #F5F5F5;
  text-align: center;
  padding: 40px 40px 40px;
  border-radius: 10px;
  overflow: hidden;
  position: relative;
  z-index: 1;
}
.h2_price-item-title h5 {
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 15px;
}
@media (max-width: 767px) {
  .h2_price-item {
    padding: 35px 25px 35px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .h2_price-item {
    padding: 35px 40px 35px;
  }
}
.h2_price-popular-tag span {
  display: block;
  position: absolute;
  z-index: 1;
  content: "";
  right: 0;
  top: 0;
  font-size: 14px;
  font-weight: 600;
  background-color: var(--clr-color-darkPink);
  color: #fff;
  padding: 3px 20px;
}
.h2_price-amount {
  margin-bottom: 15px;
}
.h2_price-amount-info {
  display: flex;
  justify-content: center;
  gap: 5px;
  align-items: center;
}
.h2_price-amount-info h2 {
  font-size: 44px;
  font-weight: 600;
  margin-bottom: 0;
}
.h2_price-amount-info p {
  margin-bottom: 0;
}
.h2_price-amount-info p span {
  display: block;
  text-align: left;
  font-size: 14px;
  line-height: 18px;
  font-weight: 500;
  color: var(--clr-body-heading);
}
.h2_price-amount del {
  font-size: 16px;
  color: #787878;
  display: inline-block;
  margin-bottom: 10px;
}

.h2_price-middle-info {
  margin-bottom: 25px;
}
.h2_price-middle-info p {
  font-size: 14px;
  color: #787878;
  margin-bottom: 10px;
  line-height: 20px;
}
.h2_price-middle-info-2 {
  color: var(--clr-body-heading);
}
.h2_price-button {
  margin-bottom: 20px;
}
.h2_price-button a {
  display: block;
  font-size: 15px;
  font-weight: 500;
  border: 1px solid rgba(30, 30, 30, 0.1);
  height: 50px;
  line-height: 50px;
  color: var(--clr-body-heading);
  border-radius: 6px;
}
.h2_price-button a:hover {
  background-color: var(--clr-theme-primary);
  color: #fff;
}
.h2_price-content-top {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  margin-bottom: 27px;
}
@media (max-width: 767px) {
  .h2_price-content-top {
    flex-direction: column;
    gap: 15px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .h2_price-content-top {
    flex-direction: row;
    gap: 10px;
  }
}
.h2_price-content-top a {
  color: var(--clr-body-heading);
  font-size: 14px;
  font-weight: 500;
  text-decoration: underline;
  display: block;
  line-height: 1;
}
.h2_price-content-top a:hover {
  color: var(--clr-theme-primary);
}
.h2_price-content-top span {
  display: block;
  color: var(--clr-color-darkPink);
  font-size: 14px;
  font-weight: 500;
  border: 1px solid rgba(210, 9, 59, 0.3);
  border-radius: 4px;
  background: rgba(210, 9, 59, 0.04);
  line-height: 1;
  padding: 3px 10px;
}

.h2_price-content-list ul li {
  display: block;
  text-align: left;
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 5px;
  padding-left: 25px;
  position: relative;
  z-index: 1;
}
.h2_price-content-list ul li:last-child {
  margin-bottom: 0;
}
.h2_price-content-list ul li::before {
  position: absolute;
  left: 8px;
  top: 12px;
  content: "";
  width: 4px;
  height: 4px;
  background-color: var(--clr-body-heading);
  border-radius: 50%;
}

/*
************
*************************
21. breadcrumb
******************************************************* 
*************************************************************** */
.breadcrumb-area {
  min-height: 330px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 1;
}
@media (max-width: 767px) {
  .breadcrumb-area {
    min-height: 300px;
  }
}
.breadcrumb-content {
  text-align: center;
}
.breadcrumb-title {
  color: var(--clr-body-heading);
  font-size: 50px;
  font-weight: 700;
  margin-bottom: 10px;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .breadcrumb-title {
    font-size: 45px;
  }
}
@media (max-width: 767px) {
  .breadcrumb-title {
    font-size: 35px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .breadcrumb-title {
    font-size: 40px;
  }
}
.breadcrumb-list {
  display: flex;
  justify-content: center;
  gap: 10px;
}
.breadcrumb-list a {
  color: var(--clr-body-heading);
  display: block;
  position: relative;
  z-index: 1;
  font-size: 16px;
  font-weight: 500;
}
.breadcrumb-list a::after {
  display: inline-block;
  content: "/";
  font-size: 14px;
  font-weight: 400;
  margin-left: 10px;
}
.breadcrumb-list span {
  color: var(--clr-theme-primary);
  display: block;
  font-size: 16px;
  font-weight: 500;
}

.breadcrumb-shape {
  position: absolute;
  top: 70px;
  right: 31%;
  animation: animation-popup-1 4s linear 0s infinite alternate;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .breadcrumb-shape {
    right: 25%;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .breadcrumb-shape {
    top: 60px;
    right: 20%;
  }
}
@media (max-width: 767px) {
  .breadcrumb-shape {
    top: 40px;
    right: 15%;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .breadcrumb-shape {
    right: 20%;
  }
}

/*
************
*************************
22. gallery
******************************************************* 
*************************************************************** */
.innerPage_gallery-tab .nav {
  justify-content: end;
}
@media only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .innerPage_gallery-tab .nav {
    justify-content: flex-start;
  }
}
.innerPage_gallery-tab .nav .nav-item {
  margin-bottom: 10px;
}
.innerPage_gallery-tab .nav .nav-item .nav-link {
  padding: 0;
  background: transparent;
  color: var(--clr-body-heading);
  font-size: 15px;
  margin-right: 25px;
  display: flex;
  position: relative;
  z-index: 1;
}
.innerPage_gallery-tab .nav .nav-item .nav-link span {
  position: absolute;
  left: 0;
  top: -25px;
  background-color: var(--clr-theme-primary);
  color: #fff;
  display: block;
  font-size: 12px;
  padding: 4px 8px;
  line-height: 1;
}
.innerPage_gallery-tab .nav .nav-item .nav-link span::before {
  position: absolute;
  left: 3px;
  bottom: -6px;
  content: "";
  width: 9px;
  height: 8px;
  background: var(--clr-theme-primary);
  clip-path: polygon(0 0, 50% 100%, 100% 0);
}
.innerPage_gallery-tab .nav .nav-item:last-child .nav-link {
  margin-right: 0;
}

.innerPage_gallery-tab .nav-pills .nav-link.active, .innerPage_gallery-tab .nav-pills .show > .nav-link {
  color: var(--clr-theme-primary);
}

.innerPage_gallery-item {
  position: relative;
  z-index: 1;
  border-radius: 10px;
  overflow: hidden;
}
.innerPage_gallery-img img {
  width: 100%;
}
.innerPage_gallery-content {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: all 0.3s linear 0s;
  -moz-transition: all 0.3s linear 0s;
  -ms-transition: all 0.3s linear 0s;
  -o-transition: all 0.3s linear 0s;
  transition: all 0.3s linear 0s;
}
.innerPage_gallery-content::before {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  content: "";
  background: rgba(30, 30, 30, 0.6);
  z-index: 1;
}
.innerPage_gallery-content a {
  width: 90px;
  height: 90px;
  background: #fff;
  display: grid;
  place-items: center;
  border-radius: 50%;
  font-size: 20px;
  color: var(--clr-theme-primary);
  z-index: 99;
}
.innerPage_gallery-content a i {
  font-weight: 300;
}

.innerPage_gallery-item:hover .innerPage_gallery-content {
  opacity: 1;
  visibility: visible;
}

/*
************
*************************
23. account
******************************************************* 
*************************************************************** */
.account-wrap {
  background: #fff;
  box-shadow: 0px 10px 50px rgba(30, 30, 30, 0.1);
  max-width: 690px;
  margin-left: auto;
  margin-right: auto;
  border-radius: 10px;
  overflow: hidden;
}
.account-top {
  display: flex;
  align-items: center;
  gap: 10px;
}
.account-top-link {
  width: 100%;
  background: #F5F5F5;
  height: 55px;
  line-height: 55px;
  text-align: center;
  border-radius: 10px 0 10px 0;
  border: 1px solid rgba(4, 0, 23, 0.1);
  position: relative;
  z-index: 1;
}
.account-top-link a {
  color: var(--clr-body-heading);
  font-size: 16px;
  font-weight: 500;
  width: 100%;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  height: 100%;
  display: inline-block;
}
.account-top-current {
  width: 100%;
  border: 1px solid rgba(4, 0, 23, 0.1);
  border-radius: 0 10px 0 10px;
  text-align: center;
  height: 55px;
  line-height: 55px;
  border-top: 2px solid var(--clr-theme-primary);
}
.account-top-current span {
  font-size: 16px;
  font-weight: 500;
  color: var(--clr-body-heading);
  width: 100%;
  display: inline-block;
}
.account-main {
  padding: 65px 80px 70px;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .account-main {
    padding: 65px 60px 70px;
  }
}
@media (max-width: 767px) {
  .account-main {
    padding: 65px 30px 70px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .account-main {
    padding: 65px 50px 70px;
  }
}
.account-title {
  font-size: 32px;
  margin-bottom: 30px;
}
@media (max-width: 767px) {
  .account-title {
    font-size: 28px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .account-title {
    font-size: 32px;
  }
}

.sign-up .account-top-link {
  border-radius: 0 10px 0 10px;
}
.sign-up .account-top-current {
  border-radius: 10px 0 10px 0;
}

.account-form-label {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.account-form-label label {
  font-size: 14px;
  font-weight: 500;
  color: var(--clr-body-heading);
  margin-bottom: 5px;
}
.account-form-label a {
  font-size: 14px;
  color: rgba(4, 0, 23, 0.6);
  font-weight: 400;
  display: block;
  margin-bottom: 5px;
}
.account-form-input {
  position: relative;
  z-index: 1;
}
.account-form-input input {
  width: 100%;
  height: 50px;
  line-height: 50px;
  border: 1px solid rgba(4, 0, 23, 0.1);
  border-radius: 6px;
  padding: 0 20px;
  font-size: 14px;
  color: rgba(4, 0, 23, 0.6);
}
.account-form-input span {
  position: absolute;
  right: 20px;
  top: 0;
  height: 50px;
  display: inline-block;
  line-height: 50px;
  font-size: 14px;
  cursor: pointer;
}
.account-form-input-pass input {
  padding-right: 50px;
}
.account-form-button {
  margin-bottom: 40px;
}
.account-form-condition {
  margin-bottom: 35px;
}
.account-form-condition span {
  font-size: 14px;
  font-weight: 400;
}
.account-btn {
  display: block;
  width: 100%;
  height: 50px;
  line-height: 50px;
  border: none;
  font-size: 16px;
  color: #fff;
  font-weight: 500;
  background-color: var(--clr-theme-primary);
  border-radius: 6px;
}
.account-break {
  position: relative;
  z-index: 1;
}
.account-break::before {
  position: absolute;
  left: 0;
  top: 0;
  content: "";
  width: 100%;
  height: 1px;
  background: rgba(4, 0, 23, 0.14);
}
.account-break span {
  display: block;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background: #fff;
  font-size: 14px;
  line-height: 1;
  padding: 5px 11px;
  color: rgba(4, 0, 23, 0.7);
}
.account-bottom {
  padding-top: 35px;
}
.account-bottom-text {
  text-align: center;
}
.account-bottom-text p {
  margin-bottom: 0;
  font-size: 14px;
  color: var(--clr-body-heading);
  line-height: 20px;
}
.account-bottom-text p a {
  display: inline-block;
  color: var(--clr-theme-primary);
}
.account-option {
  display: flex;
  justify-content: space-between;
  gap: 10px;
  margin-bottom: 35px;
}
.account-option-account {
  display: flex;
  align-items: center;
  border: 1px solid rgba(4, 0, 23, 0.1);
  height: 50px;
  width: 100%;
  justify-content: center;
  gap: 10px;
  border-radius: 6px;
  line-height: 50px;
}
.account-option-account img {
  flex-shrink: 0;
  max-width: 20px;
}
.account-option-account span {
  display: block;
  font-size: 14px;
  font-weight: 500;
  line-height: 1;
}
@media (max-width: 767px) {
  .account-option {
    flex-wrap: wrap;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .account-option {
    flex-wrap: nowrap;
  }
}

.account-form-condition .condition_label {
  display: block;
  position: relative;
  padding-left: 22px;
  cursor: pointer;
  font-size: 15px;
  user-select: none;
}
.account-form-condition .condition_label input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}
.account-form-condition .check_mark {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  height: 14px;
  width: 14px;
  background-color: transparent;
  border: 1px solid rgba(30, 30, 30, 0.3);
  border-radius: 2px;
}
.account-form-condition .check_mark:after {
  content: "";
  position: absolute;
  display: none;
}
.account-form-condition .condition_label input:checked ~ .check_mark:after {
  display: block;
}
.account-form-condition .condition_label .check_mark:after {
  left: 4px;
  top: 1px;
  width: 4px;
  height: 8px;
  border: solid rgba(30, 30, 30, 0.4);
  border-width: 0 1px 1px 0;
  transform: rotate(45deg);
}

/*
************
*************************
24. team details
******************************************************* 
*************************************************************** */
.teacher_details-title {
  font-size: 28px;
  margin-bottom: 5px;
}
@media (max-width: 767px) {
  .teacher_details-title {
    font-size: 25px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .teacher_details-title {
    font-size: 28px;
  }
}
.teacher_detailstitle2 {
  font-size: 20px;
  margin-bottom: 20px;
  line-height: 1.1;
}
.teacher_details-admin {
  margin-bottom: 11px;
}
.teacher_details-admin span {
  font-size: 16px;
  display: block;
}
.teacher_details-rating {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 30px;
}
.teacher_details-rating ul {
  display: flex;
  align-items: center;
  gap: 5px;
}
.teacher_details-rating ul li i {
  font-size: 14px;
  color: #f8b81f;
}
.teacher_details-rating span {
  font-size: 14px;
  color: var(--clr-body-heading);
}

.teacher_details-info ul {
  margin-bottom: 17px;
}
.teacher_details-info ul li {
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 16px;
  margin-bottom: 6px;
}
.teacher_details-info ul li span {
  font-size: 16px;
  font-weight: 500;
  color: var(--clr-body-heading);
  min-width: 70px;
}
.teacher_details-info ul li a:hover {
  color: var(--clr-theme-primary);
}
.teacher_details-info ul li:last-child {
  margin-bottom: 0;
}
.teacher_details-social {
  display: flex;
  gap: 10px;
}
.teacher_details-social a {
  display: grid;
  width: 40px;
  height: 40px;
  border: 1px solid rgba(30, 30, 30, 0.15);
  place-items: center;
  border-radius: 50%;
  color: rgba(30, 30, 30, 0.3);
  font-size: 14px;
}
.teacher_details-social a:hover {
  background: var(--clr-theme-primary);
  color: #fff;
  border-color: transparent;
}

/*
************
*************************
25. contact
******************************************************* 
*************************************************************** */
.contact-wrap {
  padding: 80px 80px 60px;
  background: #FFFFFF;
  box-shadow: 0px 10px 50px rgba(30, 30, 30, 0.1);
  position: relative;
  z-index: 1;
  margin-bottom: -160px;
  border-radius: 10px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .contact-wrap {
    padding: 80px 60px 60px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .contact-wrap {
    padding: 80px 40px 60px;
  }
}
@media (max-width: 767px) {
  .contact-wrap {
    padding: 60px 20px 40px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .contact-wrap {
    padding: 60px 40px 40px;
  }
}
.contact-content {
  border-right: 1px solid rgba(4, 0, 23, 0.14);
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .contact-content {
    padding-right: 60px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .contact-content {
    padding-right: 40px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .contact-content {
    padding-right: 30px;
  }
}
@media (max-width: 767px) {
  .contact-content {
    padding-right: 0;
    border-right: 0;
  }
}
.contact-title {
  font-size: 32px;
}
@media (max-width: 767px) {
  .contact-title {
    font-size: 26px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .contact-title {
    font-size: 30px;
  }
}
.contact-form-input {
  position: relative;
  z-index: auto;
}
.contact-form-input input, .contact-form-input textarea {
  width: 100%;
  height: 60px;
  line-height: 60px;
  border: none;
  border-bottom: 1px solid rgba(4, 0, 23, 0.14);
  padding: 0 30px;
  color: rgba(4, 0, 23, 0.6);
  font-size: 16px;
}
.contact-form-input input::-webkit-input-placeholder, .contact-form-input textarea::-webkit-input-placeholder {
  color: rgba(4, 0, 23, 0.6);
  font-size: 16px;
  opacity: 1;
  -webkit-transition: all 0.3s linear 0s;
  -moz-transition: all 0.3s linear 0s;
  -ms-transition: all 0.3s linear 0s;
  -o-transition: all 0.3s linear 0s;
  transition: all 0.3s linear 0s;
}
.contact-form-input input:-moz-placeholder, .contact-form-input textarea:-moz-placeholder {
  color: rgba(4, 0, 23, 0.6);
  font-size: 16px;
  opacity: 1;
  -webkit-transition: all 0.3s linear 0s;
  -moz-transition: all 0.3s linear 0s;
  -ms-transition: all 0.3s linear 0s;
  -o-transition: all 0.3s linear 0s;
  transition: all 0.3s linear 0s;
}
.contact-form-input input::-moz-placeholder, .contact-form-input textarea::-moz-placeholder {
  color: rgba(4, 0, 23, 0.6);
  font-size: 16px;
  opacity: 1;
  -webkit-transition: all 0.3s linear 0s;
  -moz-transition: all 0.3s linear 0s;
  -ms-transition: all 0.3s linear 0s;
  -o-transition: all 0.3s linear 0s;
  transition: all 0.3s linear 0s;
}
.contact-form-input input:-ms-input-placeholder, .contact-form-input textarea:-ms-input-placeholder {
  color: rgba(4, 0, 23, 0.6);
  font-size: 16px;
  opacity: 1;
  -webkit-transition: all 0.3s linear 0s;
  -moz-transition: all 0.3s linear 0s;
  -ms-transition: all 0.3s linear 0s;
  -o-transition: all 0.3s linear 0s;
  transition: all 0.3s linear 0s;
}
.contact-form-input .inner-icon {
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  font-size: 14px;
  color: rgba(4, 0, 23, 0.6);
}
.contact-form-input .inner-icon-select {
  top: 30px;
  z-index: 99;
}
.contact-form-input textarea {
  height: 95px;
  line-height: 22px;
  padding-top: 20px;
}
.contact-form-textarea .inner-icon {
  top: 17px;
  transform: translateY(0);
}
.contact-form-submit {
  display: flex;
  align-items: center;
  gap: 40px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .contact-form-submit {
    flex-direction: column;
    gap: 20px;
    align-items: flex-start;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .contact-form-btn {
    order: 1;
  }
}
.contact-form-condition .condition_label {
  display: block;
  position: relative;
  padding-left: 22px;
  cursor: pointer;
  font-size: 15px;
  user-select: none;
}
.contact-form-condition .condition_label input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}
.contact-form-condition .check_mark {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  height: 14px;
  width: 14px;
  background-color: transparent;
  border: 1px solid rgba(30, 30, 30, 0.2);
  border-radius: 2px;
}
.contact-form-condition .check_mark:after {
  content: "";
  position: absolute;
  display: none;
}
.contact-form-condition .condition_label input:checked ~ .check_mark:after {
  display: block;
}
.contact-form-condition .condition_label .check_mark:after {
  left: 4px;
  top: 1px;
  width: 4px;
  height: 8px;
  border: solid rgba(30, 30, 30, 0.4);
  border-width: 0 1px 1px 0;
  transform: rotate(45deg);
}

.nice-select.contact-form-list {
  width: 100%;
  height: 60px;
  line-height: 60px;
  color: rgba(4, 0, 23, 0.6);
  font-size: 16px;
  border: none;
  border-bottom: 1px solid rgba(4, 0, 23, 0.14);
  border-radius: 0;
  padding: 0 30px;
}
.nice-select.contact-form-list::after {
  border-bottom: 1.5px solid rgba(30, 30, 30, 0.6);
  border-right: 1.5px solid rgba(30, 30, 30, 0.6);
  content: "";
  display: block;
  height: 7px;
  margin-top: -1px;
  pointer-events: none;
  position: absolute;
  right: 3px;
  top: 50%;
  transform-origin: 50% 50%;
  transform: rotate(45deg) translateY(-50%);
  transition: all 0.3s ease-in-out;
  width: 7px;
}
.nice-select.contact-form-list ul {
  width: 100%;
  border-radius: 0;
  border: none;
  background-color: #fff;
  margin-top: 0;
}
.nice-select.contact-form-list ul li {
  color: rgba(4, 0, 23, 0.6);
  font-size: 15px;
  font-weight: 400;
  width: 100%;
  height: 50px;
  line-height: 50px;
  padding: 0 30px;
  min-height: 50px;
}
.nice-select.contact-form-list ul li.selected {
  font-weight: 400;
}

.theme-btn.contact-btn {
  height: 50px;
  line-height: 50px;
  padding: 0 45px;
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .contact-info {
    margin-left: 30px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .contact-info {
    margin-left: 10px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .contact-info {
    margin-left: 0;
  }
}
.contact-info-item {
  margin-bottom: 20px;
}
.contact-info-item span {
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 16px;
  font-weight: 500;
  line-height: 30px;
}
.contact-info-item p {
  margin-bottom: 0;
  color: var(--clr-body-heading);
  font-size: 16px;
  font-weight: 500;
  line-height: 30px;
}
.contact-info-item a {
  font-size: 16px;
  display: block;
  color: var(--clr-body-heading);
  font-weight: 500;
}
.contact-info-item a:hover {
  color: var(--clr-theme-primary);
}
.contact-social span {
  color: #747474;
  font-weight: 500;
  line-height: 30px;
  display: block;
  font-size: 16px;
  margin-bottom: 10px;
}
.contact-social ul {
  display: flex;
  gap: 10px;
  align-items: center;
}
.contact-social ul li a {
  display: grid;
  width: 40px;
  height: 40px;
  border: 1px solid rgba(30, 30, 30, 0.1);
  place-items: center;
  border-radius: 4px;
  color: rgba(30, 30, 30, 0.3);
  font-size: 15px;
}
.contact-social ul li a:hover {
  background: var(--clr-theme-primary);
  color: #fff;
}
.contact-map {
  height: 540px;
  width: 100%;
}
.contact-map iframe {
  width: 100%;
  height: 100%;
}

.h10_contact-title {
  font-size: 128px;
  font-weight: 700;
  color: #252839;
  -webkit-text-fill-color: transparent;
  -webkit-text-stroke: 1px #BABABA;
  line-height: 1;
  margin-bottom: 0;
  margin-right: 150px;
  width: max-content !important;
  position: relative;
  z-index: 1;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .h10_contact-title {
    font-size: 90px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .h10_contact-title {
    font-size: 90px;
    margin-right: 70px;
  }
}
@media (max-width: 767px) {
  .h10_contact-title {
    font-size: 50px;
    margin-right: auto;
    margin-left: auto;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .h10_contact-title {
    font-size: 70px;
    margin-right: auto;
    margin-left: auto;
  }
}
.h10_contact-title::before {
  content: attr(data-text);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  color: #01fe87;
  -webkit-text-fill-color: var(--clr-theme-primary-10);
  -webkit-text-stroke: 0vw transparent;
  border-right: 2px solid transparent;
  overflow: hidden;
  animation: animate 5s linear infinite;
}

@keyframes animate {
  0%, 10%, 100% {
    width: 0;
  }
  70%, 90% {
    width: 100%;
  }
}
.h10_contact-text-wrap {
  position: absolute;
  top: 100px;
  z-index: auto;
  right: 0;
}
@media (max-width: 767px) {
  .h10_contact-text-wrap {
    left: 0;
    right: 0;
  }
}
.h10_contact-text-wrap-2 {
  position: absolute;
  bottom: 60px;
}

.h10_contact-img {
  position: absolute;
  left: 15px;
  top: 0;
  right: 15px;
  z-index: -1;
}

.h10_contact-content {
  background-color: #fff;
  padding: 90px 80px 60px;
  border: 1px solid #000;
  box-shadow: 0px 10px 50px 0px rgba(30, 30, 30, 0.1);
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .h10_contact-content {
    padding: 90px 60px 60px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .h10_contact-content {
    padding: 60px 40px 30px;
  }
}
@media (max-width: 767px) {
  .h10_contact-content {
    padding: 60px 30px 30px;
  }
}
.h10_contact-content .contact-title {
  font-size: 32px;
  color: #0A0A0A;
}
@media (max-width: 767px) {
  .h10_contact-content .contact-title {
    font-size: 26px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .h10_contact-content .contact-title {
    font-size: 30px;
  }
}
.h10_contact-content .contact-form-input {
  position: relative;
  z-index: auto;
}
.h10_contact-content .contact-form-input input, .h10_contact-content .contact-form-input textarea {
  width: 100%;
  height: 60px;
  line-height: 60px;
  border: none;
  border-bottom: 1px solid rgba(10, 10, 10, 0.14);
  padding: 0 30px;
  color: rgba(10, 10, 10, 0.6);
  font-size: 16px;
}
.h10_contact-content .contact-form-input input::-webkit-input-placeholder, .h10_contact-content .contact-form-input textarea::-webkit-input-placeholder {
  color: rgba(10, 10, 10, 0.6);
  font-size: 16px;
  opacity: 1;
  -webkit-transition: all 0.3s linear 0s;
  -moz-transition: all 0.3s linear 0s;
  -ms-transition: all 0.3s linear 0s;
  -o-transition: all 0.3s linear 0s;
  transition: all 0.3s linear 0s;
}
.h10_contact-content .contact-form-input input:-moz-placeholder, .h10_contact-content .contact-form-input textarea:-moz-placeholder {
  color: rgba(10, 10, 10, 0.6);
  font-size: 16px;
  opacity: 1;
  -webkit-transition: all 0.3s linear 0s;
  -moz-transition: all 0.3s linear 0s;
  -ms-transition: all 0.3s linear 0s;
  -o-transition: all 0.3s linear 0s;
  transition: all 0.3s linear 0s;
}
.h10_contact-content .contact-form-input input::-moz-placeholder, .h10_contact-content .contact-form-input textarea::-moz-placeholder {
  color: rgba(10, 10, 10, 0.6);
  font-size: 16px;
  opacity: 1;
  -webkit-transition: all 0.3s linear 0s;
  -moz-transition: all 0.3s linear 0s;
  -ms-transition: all 0.3s linear 0s;
  -o-transition: all 0.3s linear 0s;
  transition: all 0.3s linear 0s;
}
.h10_contact-content .contact-form-input input:-ms-input-placeholder, .h10_contact-content .contact-form-input textarea:-ms-input-placeholder {
  color: rgba(10, 10, 10, 0.6);
  font-size: 16px;
  opacity: 1;
  -webkit-transition: all 0.3s linear 0s;
  -moz-transition: all 0.3s linear 0s;
  -ms-transition: all 0.3s linear 0s;
  -o-transition: all 0.3s linear 0s;
  transition: all 0.3s linear 0s;
}
.h10_contact-content .contact-form-input .inner-icon {
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  font-size: 14px;
  color: rgba(10, 10, 10, 0.6);
}
.h10_contact-content .contact-form-input .inner-icon-select {
  top: 30px;
  z-index: 99;
}
.h10_contact-content .contact-form-input textarea {
  height: 95px;
  line-height: 22px;
  padding-top: 20px;
}
.h10_contact-content .contact-form-textarea .inner-icon {
  top: 17px;
  transform: translateY(0);
}
.h10_contact-content .contact-form-submit {
  display: flex;
  align-items: center;
  gap: 40px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .h10_contact-content .contact-form-submit {
    flex-direction: column;
    gap: 20px;
    align-items: flex-start;
  }
}
.h10_contact-content .contact-form-btn .h10_contact-btn {
  padding: 0 45px;
  border-radius: 0 !important;
}
@media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .h10_contact-content .contact-form-btn {
    order: 1;
  }
}
.h10_contact-content .contact-form-condition .condition_label {
  display: block;
  position: relative;
  padding-left: 22px;
  cursor: pointer;
  font-size: 15px;
  user-select: none;
}
.h10_contact-content .contact-form-condition .condition_label input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}
.h10_contact-content .contact-form-condition .check_mark {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  height: 14px;
  width: 14px;
  background-color: transparent;
  border: 1px solid rgba(10, 10, 10, 0.2);
  border-radius: 2px;
}
.h10_contact-content .contact-form-condition .check_mark:after {
  content: "";
  position: absolute;
  display: none;
}
.h10_contact-content .contact-form-condition .condition_label input:checked ~ .check_mark:after {
  display: block;
}
.h10_contact-content .contact-form-condition .condition_label .check_mark:after {
  left: 4px;
  top: 1px;
  width: 4px;
  height: 8px;
  border: solid rgba(10, 10, 10, 0.4);
  border-width: 0 1px 1px 0;
  transform: rotate(45deg);
}

@media (max-width: 767px) {
  .row.justify-content-end.pt-110.pt-xs-60 {
    padding-top: 60px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .row.justify-content-end.pt-110.pt-xs-60 {
    padding-top: 40px;
  }
}

/*
************
*************************
26. 404
******************************************************* 
*************************************************************** */
.error-content h2 {
  font-size: 50px;
  font-weight: 700;
  margin-bottom: 27px;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .error-content h2 {
    font-size: 45px;
  }
}
@media (max-width: 767px) {
  .error-content h2 {
    font-size: 40px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .error-content h2 {
    font-size: 45px;
  }
}

/*
************
*************************
27. admission
******************************************************* 
*************************************************************** */
.h3_admission-area {
  position: relative;
  z-index: 1;
  background-color: var(--clr-color-lightPink);
  position: relative;
  z-index: 1;
}
.h3_admission-bg {
  position: absolute;
  right: 90px;
  bottom: 0;
  z-index: -1;
}
@media only screen and (min-width: 1600px) and (max-width: 1799px) {
  .h3_admission-bg {
    right: 40px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .h3_admission-bg {
    display: none;
  }
}
.h3_admission-shape-1 {
  position: absolute;
  left: 135px;
  bottom: 210px;
  z-index: -1;
  animation: animation-leftRight-1 3s linear 0s infinite alternate;
}
@media only screen and (min-width: 1600px) and (max-width: 1799px) {
  .h3_admission-shape-1 {
    left: 70px;
  }
}
@media only screen and (min-width: 1400px) and (max-width: 1599px) {
  .h3_admission-shape-1 {
    left: 20px;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .h3_admission-shape-1 {
    left: 20px;
    bottom: 170px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .h3_admission-shape-1 {
    left: 20px;
    bottom: 170px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .h3_admission-shape-1 {
    display: none;
  }
}
.h3_admission-shape-2 {
  position: absolute;
  right: 65px;
  top: 120px;
  animation: animation-upDown-2 3s linear 0s infinite alternate;
  z-index: -1;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .h3_admission-shape-2 {
    right: 30px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .h3_admission-shape-2 {
    display: none;
  }
}
.h3_admission-wrap {
  position: relative;
  z-index: 1;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .h3_admission-wrap {
    margin-right: 30px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .h3_admission-wrap {
    margin-right: 10px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .h3_admission-wrap {
    margin-right: 0;
  }
}
.h3_admission-wrap-shape-2 {
  position: absolute;
  right: 0;
  top: 0;
  animation: animation-leftRight-1 3s linear 0s infinite alternate;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .h3_admission-wrap-shape-2 {
    right: -30px;
    top: -10px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .h3_admission-wrap-shape-2 {
    right: -30px;
    top: -10px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .h3_admission-wrap-shape-2 {
    right: 0;
    top: -20px;
  }
}
.h3_admission-content {
  max-width: 500px;
}
@media only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .h3_admission-content {
    max-width: 100%;
  }
}
.h3_admission-content span {
  display: flex;
  align-items: center;
  gap: 10px;
  color: var(--clr-body-heading);
  font-size: 15px;
  font-weight: 500;
  margin-bottom: 15px;
  line-height: 1;
}
.h3_admission-content span i {
  background: linear-gradient(#20E448, #139E30);
  display: inline-grid;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  color: #fff;
  place-items: center;
  font-size: 10px;
}
.h3_admission-form {
  border-radius: 10px;
  background: #FFF;
  box-shadow: 0px 10px 60px 0px rgba(30, 30, 30, 0.1);
  padding: 45px 40px 50px;
  max-width: 440px;
}
@media only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .h3_admission-form {
    max-width: 100%;
  }
}
@media (max-width: 767px) {
  .h3_admission-form {
    padding-left: 30px;
    padding-right: 30px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .h3_admission-form {
    padding-left: 40px;
    padding-right: 40px;
  }
}
.h3_admission-form-title {
  margin-bottom: 20px;
}
.h3_admission-form-input input, .h3_admission-form-input textarea {
  width: 100%;
  border-radius: 4px;
  border: 1px solid rgba(30, 30, 30, 0.1);
  height: 40px;
  line-height: 40px;
  padding: 0 15px;
  font-size: 14px;
  color: var(--clr-body-heading);
  margin-bottom: 15px;
}
.h3_admission-form-input input::-webkit-input-placeholder, .h3_admission-form-input textarea::-webkit-input-placeholder {
  color: var(--clr-body-heading);
  font-size: 14px;
  opacity: 1;
}
.h3_admission-form-input input:-moz-placeholder, .h3_admission-form-input textarea:-moz-placeholder {
  color: var(--clr-body-heading);
  font-size: 14px;
  opacity: 1;
}
.h3_admission-form-input input::-moz-placeholder, .h3_admission-form-input textarea::-moz-placeholder {
  color: var(--clr-body-heading);
  font-size: 14px;
  opacity: 1;
}
.h3_admission-form-input input:-ms-input-placeholder, .h3_admission-form-input textarea:-ms-input-placeholder {
  color: var(--clr-body-heading);
  font-size: 14px;
  opacity: 1;
}
.h3_admission-form-input textarea {
  height: 100px;
}
.h3_admission-btn {
  height: 50px;
}
.h3_admission-btn i {
  margin-left: 8px;
}

.h6_admission-area {
  background: #F6F6F6;
}
.h6_admission-img {
  position: relative;
  z-index: 1;
}
.h6_admission-img img {
  transition: all 0.8s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  transform: scale(1);
  width: 100%;
}
.h6_admission-img:hover img {
  transform: scale(1.05);
}
.h6_admission-form {
  background: #FFF;
  box-shadow: 0px 10px 60px 0px rgba(23, 22, 28, 0.1);
  padding: 45px 40px 50px;
  max-width: 440px;
  position: absolute;
  right: 110px;
  bottom: -200px;
}
@media only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .h6_admission-form {
    max-width: 100%;
  }
}
@media (max-width: 767px) {
  .h6_admission-form {
    padding-left: 30px;
    padding-right: 30px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .h6_admission-form {
    padding-left: 40px;
    padding-right: 40px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .h6_admission-form {
    position: static;
  }
}
.h6_admission-form-title {
  margin-bottom: 20px;
}
.h6_admission-form-input input, .h6_admission-form-input textarea {
  width: 100%;
  border: 1px solid rgba(30, 30, 30, 0.1);
  height: 40px;
  line-height: 40px;
  padding: 0 15px;
  font-size: 14px;
  color: var(--clr-body-heading);
  margin-bottom: 15px;
}
.h6_admission-form-input input::-webkit-input-placeholder, .h6_admission-form-input textarea::-webkit-input-placeholder {
  color: var(--clr-body-heading);
  font-size: 14px;
  opacity: 1;
}
.h6_admission-form-input input:-moz-placeholder, .h6_admission-form-input textarea:-moz-placeholder {
  color: var(--clr-body-heading);
  font-size: 14px;
  opacity: 1;
}
.h6_admission-form-input input::-moz-placeholder, .h6_admission-form-input textarea::-moz-placeholder {
  color: var(--clr-body-heading);
  font-size: 14px;
  opacity: 1;
}
.h6_admission-form-input input:-ms-input-placeholder, .h6_admission-form-input textarea:-ms-input-placeholder {
  color: var(--clr-body-heading);
  font-size: 14px;
  opacity: 1;
}
.h6_admission-form-input textarea {
  height: 100px;
}

.h6_admission-bottom-text {
  font-size: 128px;
  font-weight: 700;
  -webkit-text-fill-color: transparent;
  -webkit-text-stroke: 1px #BABABA;
  margin-bottom: 20px;
  line-height: 1.25;
  margin-right: 100px;
  width: max-content !important;
}
@media (max-width: 767px) {
  .h6_admission-bottom-text {
    font-size: 80px;
  }
}

/*
************
*************************
28. education
******************************************************* 
*************************************************************** */
.h4_education-area {
  position: relative;
  z-index: 1;
  background-color: #36348E;
  overflow: hidden;
}
.h4_education-shape-1 {
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: -1;
}
.h4_education-shape-2 {
  position: absolute;
  right: 45%;
  top: 0;
  z-index: -1;
}
@media only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .h4_education-shape-2 {
    right: 0;
  }
}
.h4_education-img {
  position: absolute;
  right: 0;
  top: 0;
  max-width: 45%;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
@media only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .h4_education-img {
    display: none;
  }
}
.h4_education-item {
  display: flex;
  align-items: center;
  gap: 15px;
  margin-bottom: 30px;
}
.h4_education-item-icon {
  border-radius: 50px;
  border: 1px solid rgba(255, 255, 255, 0.14);
  width: 70px;
  height: 70px;
  flex-shrink: 0;
  display: grid;
  place-items: center;
}
.h4_education-item-icon i {
  font-size: 28px;
  color: #fff;
}
.h4_education-item-info h3 {
  font-size: 30px;
  font-weight: 600;
  color: #fff;
  margin-bottom: 0;
  line-height: 1;
}
.h4_education-item-info p {
  color: #C3C2DD;
  margin-bottom: 0;
}

/*
************
*************************
29. faq
******************************************************* 
*************************************************************** */
.h4_faq-img {
  margin-right: -30px;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .h4_faq-img {
    margin-right: -10px;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1399px), only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .h4_faq-img {
    margin-right: 0;
  }
}
.h4_faq-wrap {
  margin-left: 80px;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .h4_faq-wrap {
    margin-left: 30px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .h4_faq-wrap {
    margin-left: 0;
  }
}
.h4_faq-content .accordion-item {
  border: none;
  margin-bottom: 30px;
  border-radius: 4px;
}
.h4_faq-content .accordion-button {
  position: relative;
  border: 0;
  padding-left: 25px;
  padding-right: 60px;
  height: 60px;
  font-size: 18px;
  font-weight: 500;
  cursor: pointer;
  box-shadow: none;
  color: var(--clr-body-heading);
  border-radius: 6px !important;
  border: 1px solid rgba(10, 10, 10, 0.1);
  background: #FFF;
}
@media (max-width: 767px) {
  .h4_faq-content .accordion-button {
    font-size: 16px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .h4_faq-content .accordion-button {
    font-size: 18px;
  }
}
.h4_faq-content .accordion-button::after {
  display: none;
}
.h4_faq-content .accordion-button::before {
  position: absolute;
  right: 0;
  top: 0;
  content: "\f107";
  font-family: "Font Awesome 5 Pro";
  padding: 0 25px;
  font-size: 15px;
  -webkit-transition: all 0.3s linear 0s;
  -moz-transition: all 0.3s linear 0s;
  -ms-transition: all 0.3s linear 0s;
  -o-transition: all 0.3s linear 0s;
  transition: all 0.3s linear 0s;
  font-weight: 400;
  height: 60px;
  line-height: 60px;
  color: var(--clr-body-heading);
}
.h4_faq-content .accordion-button:not(.collapsed)::before {
  content: "\f107";
  transform: rotate(-180deg);
}
.h4_faq-content .accordion-button:not(.collapsed) {
  color: var(--clr-body-heading);
  background: var(--clr-theme-primary-4);
  border-color: transparent;
  border-radius: 6px 6px 0 0 !important;
}
.h4_faq-content .accordion-body {
  padding: 25px 25px;
  line-height: 1.5;
  border: 1px solid rgba(10, 10, 10, 0.1);
  border-top: 0;
  border-radius: 6px;
}
.h4_faq-content .accordion-body p {
  margin-bottom: 0;
  font-size: 15px;
  line-height: 24px;
}

.h9_faq-content .accordion-item {
  border: none;
  margin-bottom: 10px;
  border-radius: 10px;
  overflow: hidden;
}
.h9_faq-content .accordion-button {
  position: relative;
  padding-left: 25px;
  padding-right: 25px;
  font-size: 18px;
  font-weight: 500;
  cursor: pointer;
  box-shadow: none;
  color: #21242C;
  border-radius: 0px !important;
  border: none;
  background: #F5F5F5;
  display: flex;
  gap: 15px;
  align-items: center;
  height: 80px;
}
@media (max-width: 767px) {
  .h9_faq-content .accordion-button {
    font-size: 16px;
    padding-left: 20px;
    padding-right: 25px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .h9_faq-content .accordion-button {
    font-size: 18px;
    padding-left: 25px;
    padding-right: 25px;
  }
}
.h9_faq-content .accordion-button::after {
  display: none;
}
.h9_faq-content .accordion-button::before {
  position: absolute;
  right: 25px;
  top: 50%;
  transform: translateY(-50%);
  content: "+";
  font-family: "Font Awesome 5 Pro";
  -webkit-transition: all 0.3s linear 0s;
  -moz-transition: all 0.3s linear 0s;
  -ms-transition: all 0.3s linear 0s;
  -o-transition: all 0.3s linear 0s;
  transition: all 0.3s linear 0s;
  font-weight: 500;
  color: #21242C;
  width: 20px;
  height: 20px;
  font-size: 10px;
  border: 1px solid rgba(33, 36, 44, 0.2);
  border-radius: 50%;
  display: grid;
  place-items: center;
}
@media (max-width: 767px) {
  .h9_faq-content .accordion-button::before {
    right: 20px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .h9_faq-content .accordion-button::before {
    right: 25px;
  }
}
.h9_faq-content .accordion-button:not(.collapsed)::before {
  content: "\f068";
  border-color: #21242C;
}
.h9_faq-content .accordion-button img {
  width: 40px;
}
@media (max-width: 767px) {
  .h9_faq-content .accordion-button img {
    width: 30px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .h9_faq-content .accordion-button img {
    width: 40px;
  }
}
.h9_faq-content .accordion-body {
  padding: 0 115px 30px 80px;
  background: #F5F5F5;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .h9_faq-content .accordion-body {
    padding: 0 55px 30px 80px;
  }
}
@media (max-width: 767px) {
  .h9_faq-content .accordion-body {
    padding: 0 25px 30px 25px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .h9_faq-content .accordion-body {
    padding: 0 50px 30px 80px;
  }
}
.h9_faq-content .accordion-body ul {
  margin-bottom: 20px;
  line-height: 1;
  display: flex;
  flex-wrap: wrap;
  row-gap: 10px;
}
.h9_faq-content .accordion-body ul li {
  display: inline-block;
  margin-right: 25px;
  color: rgba(10, 10, 10, 0.6);
  font-size: 15px;
  font-weight: 400;
  line-height: 1;
}
@media (max-width: 767px) {
  .h9_faq-content .accordion-body ul li {
    margin-right: 15px;
  }
}
.h9_faq-content .accordion-body ul li:last-child {
  margin-right: 0;
}
.h9_faq-content .accordion-body ul li i {
  margin-right: 6px;
}
.h9_faq-content .accordion-body p {
  color: rgba(10, 10, 10, 0.6);
  font-size: 15px;
  font-weight: 400;
  line-height: 24px;
  margin-bottom: 45px;
}
.h9_faq-content .accordion-body-bottom {
  display: flex;
  align-items: center;
  gap: 35px;
}
@media (max-width: 767px) {
  .h9_faq-content .accordion-body-bottom {
    gap: 20px;
  }
}
.h9_faq-content .accordion-body-bottom span {
  color: #1865F2;
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
  display: flex;
  gap: 6px;
  align-items: center;
}
.h9_faq-content .accordion-body-bottom span del {
  color: #787878;
  font-size: 18px;
  font-weight: 500;
}
.h9_faq-content .accordion-body-bottom a {
  display: flex;
  align-items: center;
  gap: 5px;
  color: #0A0A0A;
  font-size: 15px;
  font-weight: 500;
  line-height: 24px;
}

/*
************
*************************
30. feature
******************************************************* 
*************************************************************** */
.h5_feature-wrapper {
  display: flex;
}
@media only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .h5_feature-wrapper {
    flex-wrap: wrap;
  }
}
.h5_feature-item {
  width: 33.3333333333%;
  padding: 110px 40px 105px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .h5_feature-item {
    width: 50%;
  }
}
@media (max-width: 767px) {
  .h5_feature-item {
    width: 100%;
  }
}
.h5_feature-item.first_item {
  background-color: #F5EBF5;
}
.h5_feature-item.second_item {
  background-color: #F5F2EB;
}
.h5_feature-item.third_item {
  padding: 0;
}
.h5_feature-item.third_item img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
@media only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .h5_feature-item.third_item {
    width: 100%;
  }
}
.h5_feature-item span {
  width: 40px;
  height: 40px;
  background: #fff;
  display: grid;
  place-items: center;
  border-radius: 50%;
  font-size: 16px;
  font-weight: 600;
  color: rgba(10, 10, 10, 0.4);
  margin-bottom: 13px;
}
.h5_feature-item h3 {
  font-size: 36px;
  font-weight: 600;
  margin-bottom: 20px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .h5_feature-item h3 {
    font-size: 30px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .h5_feature-item h3 {
    font-size: 32px;
  }
}
@media (max-width: 767px) {
  .h5_feature-item h3 {
    font-size: 28px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .h5_feature-item h3 {
    font-size: 30px;
  }
}
.h5_feature-item a {
  font-size: 16px;
  color: var(--clr-body-heading);
  display: flex;
  align-items: center;
  gap: 10px;
  font-weight: 500;
}
.h5_feature-item a:hover {
  color: var(--clr-theme-primary);
}

/*
************
*************************
31. video
******************************************************* 
*************************************************************** */
.h5_video-area {
  min-height: 650px;
  background-repeat: repeat;
  background-size: cover;
  background-position: center center;
  position: relative;
  z-index: 1;
  background-attachment: fixed;
}
.h5_video-content-btn {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

/*
************
*************************
32. program
******************************************************* 
*************************************************************** */
.h6_program-area {
  background-color: #f6f6f6;
}
.h6_program-item {
  position: relative;
  z-index: 1;
}
.h6_program-item-content {
  position: absolute;
  left: 0;
  bottom: 0;
  background: rgba(10, 10, 10, 0.6);
  width: 100%;
  display: flex;
  align-items: center;
  padding: 20px 30px 20px;
  justify-content: space-between;
  gap: 10px;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .h6_program-item-content {
    padding: 20px 25px 20px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .h6_program-item-content {
    flex-wrap: wrap;
    padding-bottom: 25px;
  }
}
@media (max-width: 767px) {
  .h6_program-item-content {
    flex-wrap: wrap;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .h6_program-item-content {
    flex-wrap: nowrap;
  }
}
.h6_program-item-content-info-title {
  color: #FFF;
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 10px;
}
.h6_program-item-content-info-title:hover a {
  color: var(--clr-theme-primary-6);
}
.h6_program-item-content-info-link {
  color: #FFF;
  font-size: 16px;
  font-weight: 500;
  display: flex;
  line-height: 22px;
  gap: 10px;
}
.h6_program-item-content-info-link:hover {
  color: var(--clr-theme-primary-6);
}
.h6_program-item-content-icon svg {
  color: #fff;
  width: 50px;
}
.h6_program-item-img {
  overflow: hidden;
}
.h6_program-item-img img {
  -webkit-transition: all 0.5s linear 0s;
  -moz-transition: all 0.5s linear 0s;
  -ms-transition: all 0.5s linear 0s;
  -o-transition: all 0.5s linear 0s;
  transition: all 0.5s linear 0s;
}

.h6_program-item:hover .h6_program-item-img img {
  transform: scale(1.1);
}

.h7_program-wrap {
  position: relative;
  z-index: 1;
}
@media only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .h7_program-wrap {
    padding-bottom: 90px;
  }
}
.h7_program-title {
  -webkit-text-fill-color: transparent;
  -webkit-text-stroke: 1px #C5C5C5;
  font-size: 120px;
  font-weight: 600;
  line-height: 1.12;
  text-align: center;
  margin-right: 150px;
  width: max-content !important;
}
@media (max-width: 767px) {
  .h7_program-title {
    font-size: 80px;
  }
}
.h7_program-item {
  background: #466BB8;
  padding: 46px 50px 80px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .h7_program-item {
    padding: 46px 30px 80px;
  }
}
@media (max-width: 767px) {
  .h7_program-item {
    padding: 46px 30px 80px;
  }
}
.h7_program-item-title {
  color: #fff;
  font-size: 36px;
  font-weight: 600;
  line-height: 1;
  text-transform: capitalize;
  margin-bottom: 25px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .h7_program-item-title {
    font-size: 30px;
  }
}
@media (max-width: 767px) {
  .h7_program-item-title {
    font-size: 30px;
  }
}
.h7_program-item p {
  color: #FFF;
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
  margin-bottom: 0;
}
.h7_program-item-list {
  margin-top: 32px;
  margin-bottom: 53px;
  display: flex;
  flex-direction: column;
  align-items: start;
}
.h7_program-item-list li {
  color: #FFF;
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
  text-transform: capitalize;
  border: 1px solid #fff;
  display: flex;
  padding: 6px 20px;
  gap: 10px;
  align-items: center;
}
.h7_program-item-list li:not(:last-child) {
  margin-bottom: 10px;
}
.h7_program-item-list a {
  color: #FFF;
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
  text-transform: capitalize;
  border: 1px solid #fff;
  display: flex;
  padding: 6px 20px;
  gap: 10px;
  align-items: center;
  transition: all 0.3s linear 0s;
}
.h7_program-item-list a:not(:last-child) {
  margin-bottom: 10px;
}
.h7_program-item-list a:hover {
  background-color: var(--clr-theme-primary-6);
  color: #fff;
  border-color: transparent;
}
.h7_program-item-time {
  color: #FFF;
  font-size: 15px;
  font-weight: 400;
  display: flex;
  align-items: center;
  gap: 10px;
}
.h7_program-item-time i {
  font-size: 14px;
}
.h7_program-item-list2 {
  margin-top: 25px;
  margin-bottom: 45px;
}
.h7_program-item-list2 li {
  color: #fff;
  font-size: 18px;
  font-weight: 500;
  line-height: 28px;
  text-transform: capitalize;
  margin-bottom: 10px;
  position: relative;
  padding-left: 20px;
}
.h7_program-item-list2 li:last-child {
  margin-bottom: 0;
}
.h7_program-item-list2 li::before {
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  content: "";
  background: #F6BA2A;
  width: 6px;
  height: 6px;
  border-radius: 50%;
}
.h7_program-item2 {
  background-color: #2B7258;
  padding-bottom: 100px;
}
.h7_program-more-icon {
  position: absolute;
  right: 0;
  bottom: -95px;
  bottom: 0;
  color: var(--clr-theme-primary-6);
  font-size: 26px;
  font-weight: 500;
  display: flex;
  align-items: center;
  gap: 30px;
}
.h7_program-more-icon span {
  width: 60px;
  height: 60px;
  background-color: #F6F6F6;
  display: grid;
  place-items: center;
}
.h7_program-more-icon span i {
  position: absolute;
  color: var(--clr-theme-primary-6);
  transform: rotate(45deg) translateY(0);
  -webkit-transition: all 0.3s linear 0s;
  -moz-transition: all 0.3s linear 0s;
  -ms-transition: all 0.3s linear 0s;
  -o-transition: all 0.3s linear 0s;
  transition: all 0.3s linear 0s;
}
.h7_program-more-icon span i:last-child {
  transform: rotate(45deg) translateY(100%);
  opacity: 0;
  visibility: hidden;
}
.h7_program-more-icon:hover {
  color: var(--clr-theme-primary-6);
}
.h7_program-more-icon:hover span i:last-child {
  transform: rotate(45deg) translateY(0);
  opacity: 1;
  visibility: visible;
}
.h7_program-more-icon:hover span i:first-child {
  transform: rotate(45deg) translateY(-100%);
  opacity: 0;
  visibility: hidden;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .h7_program-more-icon {
    bottom: -1px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .h7_program-more-icon {
    bottom: 0;
  }
}

.section-width.swiper .swiper-wrapper .swiper-slide {
  width: auto !important;
}

.section-width-2.swiper .swiper-wrapper .swiper-slide {
  width: auto !important;
}

/*
************
*************************
33. research
******************************************************* 
*************************************************************** */
.h6_research-img {
  position: relative;
  z-index: 1;
  padding-right: 30px;
  margin-right: 25px;
  padding-bottom: 30px;
  transition: all 0.8s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  clip-path: inset(0 0 0 0);
}
.h6_research-img::after {
  position: absolute;
  right: 0;
  bottom: 0;
  content: "";
  width: 389px;
  height: calc(100% - 50px);
  border: 1px solid #DADADA;
  z-index: -1;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px), only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .h6_research-img {
    margin-right: 0;
  }
}
.h6_research-img img {
  transition: all 0.8s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  transform: scale(1);
  width: 100%;
}
.h6_research-img:hover {
  clip-path: inset(8px 8px 8px 8px);
}
.h6_research-img:hover img {
  transform: scale(1.05);
}
.h6_research-content h4 {
  font-size: 24px;
  font-weight: 500;
  line-height: 1.4;
  margin-bottom: 25px;
}
.h6_research-content p {
  font-size: 16px;
  line-height: 26px;
  color: #666;
  margin-bottom: 0;
}
.h6_research-content-bottom {
  display: flex;
  align-items: center;
  margin-top: 50px;
}
@media (max-width: 767px) {
  .h6_research-content-bottom {
    flex-direction: column;
    align-items: flex-start;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .h6_research-content-bottom {
    flex-direction: row;
    align-items: center;
  }
}
.h6_research-content-left {
  width: 170px;
  flex-shrink: 0;
  margin-right: 20px;
}
@media (max-width: 767px) {
  .h6_research-content-left {
    margin-right: 0;
    width: 100%;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .h6_research-content-left {
    margin-right: 20px;
    width: 160px;
  }
}
.h6_research-content-left h2 {
  font-size: 80px;
  font-weight: 800;
  line-height: 1;
  margin-bottom: 7px;
  -webkit-text-fill-color: transparent;
  -webkit-text-stroke: 1px #323232;
}
.h6_research-content-left p {
  color: #565656;
  font-size: 13px;
  font-weight: 400;
  line-height: 24px;
  margin-bottom: 0;
}
.h6_research-content-right {
  flex-shrink: 0;
  width: calc(100% - 170px);
  padding-left: 45px;
  border-left: 1px solid #E7E7E7;
}
@media (max-width: 767px) {
  .h6_research-content-right {
    padding-left: 0;
    border-left: 0;
    border-top: 1px solid #E7E7E7;
    padding-top: 30px;
    margin-top: 30px;
    width: 100%;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .h6_research-content-right {
    padding-left: 30px;
    border-left: 1px solid #E7E7E7;
    border-top: 0;
    padding-top: 0;
    margin-top: 0;
    width: calc(100% - 160px);
  }
}
.h6_research-single-item {
  display: flex;
  align-items: center;
  gap: 20px;
}
.h6_research-single-item:not(:last-child) {
  margin-bottom: 35px;
}
.h6_research-bottom-text {
  font-size: 120px;
  font-weight: 600;
  line-height: 1;
  -webkit-text-fill-color: transparent;
  -webkit-text-stroke: 1px #C5C5C5;
  margin-bottom: 0;
  margin-right: 50px;
  width: max-content !important;
}
@media (max-width: 767px) {
  .h6_research-bottom-text {
    font-size: 80px;
  }
}

.single-item-icon svg {
  width: 50px;
  color: #0A0A0A;
}
.single-item-info h5 {
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 8px;
}
.single-item-info p {
  font-size: 14px;
  line-height: 24px;
  color: #666;
  margin-bottom: 0;
}

/*
************
*************************
34. tuition
******************************************************* 
*************************************************************** */
.h6_tuition-content {
  margin-right: 25px;
}
.h6_tuition-content h2 {
  color: #17161C;
  font-size: 48px;
  font-weight: 700;
  margin-bottom: 11px;
}
@media (max-width: 767px) {
  .h6_tuition-content h2 {
    font-size: 35px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .h6_tuition-content h2 {
    font-size: 40px;
  }
}
.h6_tuition-content p {
  color: #666;
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 25px;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px), (max-width: 767px) {
  .h6_tuition-content p br {
    display: none;
  }
}
.h6_tuition-content a {
  color: var(--clr-theme-primary-6);
  font-size: 16px;
  font-weight: 600;
  display: inline-flex;
  align-items: center;
  gap: 8px;
  position: relative;
  z-index: 1;
}
.h6_tuition-content a::before {
  position: absolute;
  left: 0;
  bottom: 0;
  content: "";
  width: 100%;
  height: 1px;
  background-color: var(--clr-theme-primary-6);
}
.h6_tuition-content img {
  margin-bottom: 23px;
}
.h6_tuition-wrap {
  display: flex;
  gap: 30px;
}
@media (max-width: 767px) {
  .h6_tuition-wrap {
    flex-wrap: wrap;
  }
}
.h6_tuition-item {
  flex-shrink: 0;
  width: calc(50% - 15px);
  background: #222;
  padding: 55px 45px 45px;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px), only screen and (min-width: 768px) and (max-width: 991px) {
  .h6_tuition-item {
    padding: 55px 40px 45px;
  }
}
@media (max-width: 767px) {
  .h6_tuition-item {
    width: 100%;
    padding: 55px 30px 45px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .h6_tuition-item {
    width: 100%;
    padding: 55px 40px 45px;
  }
}
.h6_tuition-item-date {
  color: #09DB12;
  font-size: 14px;
  font-weight: 600;
  display: block;
  line-height: 20px;
  margin-bottom: 5px;
}
.h6_tuition-item-title {
  color: #FFF;
  font-size: 26px;
  font-weight: 700;
  display: inline-block;
  position: relative;
  z-index: 1;
  padding-bottom: 5px;
  margin-bottom: 50px;
}
.h6_tuition-item-title::before {
  position: absolute;
  left: 0;
  bottom: 0;
  content: "";
  width: 100%;
  height: 1px;
  background: rgba(255, 255, 255, 0.14);
}
.h6_tuition-item-list {
  padding-bottom: 50px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}
.h6_tuition-item-list li {
  color: #FFF;
  font-size: 16px;
  font-weight: 500;
  line-height: 22px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
  position: relative;
  z-index: 1;
  padding-left: 16px;
}
.h6_tuition-item-list li:last-child {
  margin-bottom: 0;
}
.h6_tuition-item-list li::before {
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  content: "";
  width: 6px;
  height: 6px;
  background: var(--clr-theme-primary-6);
  border-radius: 50%;
}
.h6_tuition-item-total {
  color: #FFF;
  font-size: 16px;
  font-weight: 500;
  line-height: 22px;
  text-transform: uppercase;
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  margin-bottom: 0;
}
.h6_tuition-light {
  background-color: #F6F6F6;
}
.h6_tuition-light .h6_tuition-item-date {
  color: var(--clr-theme-primary-6);
}
.h6_tuition-light .h6_tuition-item-title,
.h6_tuition-light .h6_tuition-item-list li,
.h6_tuition-light .h6_tuition-item-total {
  color: var(--clr-body-heading);
}
.h6_tuition-light .h6_tuition-item-title::before {
  background-color: #D9D9D9;
}
.h6_tuition-light .h6_tuition-item-list {
  border-color: #D9D9D9;
}

/*
************
*************************
35. campus
******************************************************* 
*************************************************************** */
.h6_campus-area {
  position: relative;
  z-index: 1;
}
.h6_campus-wrap {
  display: grid;
  grid-template-columns: 1fr 515px 1fr;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .h6_campus-wrap {
    grid-template-columns: 1fr 450px 1fr;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px) {
  .h6_campus-wrap {
    grid-template-columns: 1fr 400px 1fr;
  }
}
@media (max-width: 767px) {
  .h6_campus-wrap {
    grid-template-columns: 100%;
  }
}
.h6_campus-title {
  font-size: 128px;
  font-weight: 700;
  -webkit-text-fill-color: transparent;
  -webkit-text-stroke: 1px #fff;
  line-height: 1;
  margin-bottom: 0;
  margin-right: 150px;
  width: max-content !important;
  opacity: 0.4;
}
@media (max-width: 767px) {
  .h6_campus-title {
    font-size: 80px;
  }
}
.h6_campus-item-1 {
  height: 630px;
  position: relative;
  z-index: 1;
}
.h6_campus-item-1 img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
@media (max-width: 767px) {
  .h6_campus-item-1 {
    height: auto;
  }
}
.h6_campus-item-3 {
  height: 630px;
  position: relative;
  z-index: 1;
}
.h6_campus-item-3 img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
@media (max-width: 767px) {
  .h6_campus-item-3 {
    height: auto;
  }
}
.h6_campus-item-2 {
  background-color: #4846AB;
}

.campus-text-wrap {
  position: absolute;
  top: 60px;
  z-index: auto;
}
.campus-text-wrap-2 {
  position: absolute;
  bottom: 60px;
}

.h6_campus-item-2 {
  padding: 40px 40px 40px;
  z-index: 2;
}
@media (max-width: 767px) {
  .h6_campus-item-2 {
    padding: 40px 30px 40px;
  }
}

.h6_campus-item-2 {
  overflow: hidden;
}
.h6_campus-item-2 img {
  width: 100%;
  margin-bottom: 35px;
  transition: all 0.8s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  clip-path: inset(0 0 0 0);
}
.h6_campus-item-2:hover img {
  clip-path: inset(8px 8px 8px 8px);
}

.h6_campus-item-2 h4 {
  color: #fff;
  font-size: 24px;
  line-height: 1.4;
  margin-bottom: 40px;
}

.h6_campus-item-2 ul li a {
  color: #FFF;
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
  text-transform: capitalize;
  border: 1px solid #fff;
  display: inline-flex;
  padding: 0 20px;
  height: 40px;
  line-height: 40px;
  gap: 10px;
  align-items: center;
}
.h6_campus-item-2 ul li a:hover {
  background-color: var(--clr-theme-primary-6);
  color: #fff;
  border-color: transparent;
}

.h6_campus-item-2 ul li:not(:last-child) {
  margin-bottom: 15px;
}

/*
************
*************************
11. about
******************************************************* 
*************************************************************** */
.h7_apply {
  position: relative;
  z-index: 1;
}
.h7_apply-title {
  -webkit-text-fill-color: transparent;
  -webkit-text-stroke: 1px #C5C5C5;
  font-size: 120px;
  font-weight: 600;
  line-height: 1.12;
  text-align: center;
  width: 5em;
  white-space: nowrap;
  overflow: hidden;
  animation: type 4s steps(120, end) infinite;
  margin: 0 auto;
}
@media (max-width: 767px) {
  .h7_apply-title {
    font-size: 80px;
  }
}
.h7_apply-wrap-btn {
  color: #0A0A0A;
  font-size: 20px;
  font-weight: 500;
  line-height: 30px;
  text-decoration-line: underline;
  display: block;
  margin-top: 70px;
}
.h7_apply-wrap-btn:hover {
  color: var(--clr-theme-primary-6);
  text-decoration: underline;
}

@keyframes type {
  from {
    width: 0;
  }
}
.h7_apply-item {
  display: flex;
  position: relative;
  z-index: 1;
  align-items: center;
  border-bottom: 1px solid #D9D9D9;
  padding: 43px 0;
}
@media (max-width: 767px) {
  .h7_apply-item {
    flex-direction: column;
    align-items: start;
    gap: 10px;
  }
}
.h7_apply-item-number {
  flex-shrink: 0;
  width: auto;
}
.h7_apply-item-number span {
  color: #000;
  font-size: 24px;
  font-weight: 600;
  text-transform: capitalize;
}
.h7_apply-item-title {
  flex-shrink: 0;
  width: calc(28% + 9px);
  text-align: center;
}
.h7_apply-item-title h4 {
  color: #000;
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 0;
}
@media (max-width: 767px) {
  .h7_apply-item-title {
    width: 100%;
    text-align: start;
  }
}
.h7_apply-item-text {
  margin-right: 100px;
  margin-left: 120px;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .h7_apply-item-text {
    margin-right: 0;
    margin-left: 40px;
  }
}
@media (max-width: 767px) {
  .h7_apply-item-text {
    margin-left: 0;
    margin-right: 0;
  }
}
.h7_apply-item-text p {
  color: #525252;
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
  margin-bottom: 0;
}
.h7_apply-item-img {
  position: absolute;
  left: 50%;
  top: 100%;
  transform: translate(-50%, -50%);
  z-index: -1;
  opacity: 0;
  -webkit-transition: all 0.3s linear 0s;
  -moz-transition: all 0.3s linear 0s;
  -ms-transition: all 0.3s linear 0s;
  -o-transition: all 0.3s linear 0s;
  transition: all 0.3s linear 0s;
}
@media (max-width: 767px) {
  .h7_apply-item-img {
    display: none;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .h7_apply-item-img {
    display: block;
  }
}

.h7_apply-item:hover .h7_apply-item-img {
  opacity: 1;
  top: 50%;
}

/*
************
*************************
11. about
******************************************************* 
*************************************************************** */
.h7_scholarship-img {
  margin-bottom: -370px;
  position: relative;
  z-index: 1;
  transition: all 0.8s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  clip-path: inset(0 0 0 0);
}
.h7_scholarship-img::before {
  position: absolute;
  left: 0;
  top: 0;
  content: "";
  width: 100%;
  height: 100%;
  background: rgba(10, 10, 10, 0.3);
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .h7_scholarship-img {
    margin-bottom: -255px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .h7_scholarship-img {
    margin-bottom: -235px;
  }
}
@media (max-width: 767px) {
  .h7_scholarship-img {
    margin-bottom: -145px;
    margin-bottom: -35%;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .h7_scholarship-img {
    margin-bottom: -150px;
  }
}
.h7_scholarship-img img {
  transition: all 0.8s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  transform: scale(1);
  width: 100%;
}
.h7_scholarship-img:hover {
  clip-path: inset(8px 8px 8px 8px);
}
.h7_scholarship-img:hover img {
  transform: scale(1.05);
}
.h7_scholarship-wrap {
  background-color: #4846AB;
  padding-top: 435px;
  padding-bottom: 110px;
  position: relative;
  z-index: auto;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .h7_scholarship-wrap {
    padding-top: 300px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .h7_scholarship-wrap {
    padding-top: 270px;
  }
}
@media (max-width: 767px) {
  .h7_scholarship-wrap {
    padding-top: 170px;
    padding-top: calc(33% + 24px);
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .h7_scholarship-wrap {
    padding-top: 240px;
  }
}

.h7_scholarship-content {
  width: 420px;
  margin-left: auto;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .h7_scholarship-content {
    width: 320px;
  }
}
@media (max-width: 767px) {
  .h7_scholarship-content {
    width: 100%;
  }
}

.h7_scholarship-content a {
  color: #FFF;
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
  display: inline-flex;
  height: 50px;
  padding: 0 30px;
  align-items: center;
  gap: 8px;
  border: 1px solid #FFF;
}
.h7_scholarship-content a:hover {
  background-color: var(--clr-theme-primary-6);
  border-color: var(--clr-theme-primary-6);
}

.h7_scholarship-content p {
  color: #EFEFEF;
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
  margin-bottom: 30px;
}

.h7_scholarship-title {
  position: absolute;
  left: 75px;
  bottom: 65px;
  z-index: 2;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .h7_scholarship-title {
    bottom: 90px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .h7_scholarship-title {
    left: 50px;
    bottom: 100px;
  }
}
@media (max-width: 767px) {
  .h7_scholarship-title {
    position: static;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .h7_scholarship-title {
    position: absolute;
    left: 15px;
    bottom: 140px;
  }
}

.h7_scholarship-title h1 {
  color: #FFF;
  font-size: 140px;
  font-weight: 800;
  line-height: 1.15;
  text-align: end;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .h7_scholarship-title h1 {
    font-size: 110px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px) {
  .h7_scholarship-title h1 {
    font-size: 85px;
    text-align: start;
  }
}
@media (max-width: 767px) {
  .h7_scholarship-title h1 {
    font-size: 54px;
    text-align: start;
  }
}
.h7_scholarship-title h1 span {
  display: block;
}

.h7_scholarship-title h1 span.scholar-bottom {
  font-size: 128px;
  font-weight: 700;
  line-height: 1.2;
  display: block;
  -webkit-text-fill-color: transparent;
  -webkit-text-stroke: 1px rgb(255, 255, 255);
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .h7_scholarship-title h1 span.scholar-bottom {
    font-size: 85px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .h7_scholarship-title h1 span.scholar-bottom {
    font-size: 70px;
  }
}
@media (max-width: 767px) {
  .h7_scholarship-title h1 span.scholar-bottom {
    font-size: 45px;
    display: inline-block;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .h7_scholarship-title h1 span.scholar-bottom {
    font-size: 60px;
    display: block;
  }
}

/*
************
*************************
15. career
******************************************************* 
*************************************************************** */
.h8_career-area {
  background-color: #F4F7FF;
}

.h8_career-item {
  border-radius: 10px;
  background: #FFF;
  box-shadow: 0px 6px 100px 0px rgba(8, 5, 33, 0.1);
  display: flex;
  align-items: center;
  gap: 35px;
  padding: 55px 40px 60px 30px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .h8_career-item {
    flex-direction: column;
  }
}
@media (max-width: 767px) {
  .h8_career-item {
    flex-direction: column;
    padding: 45px 40px 50px 30px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .h8_career-item {
    flex-direction: row;
    padding: 45px 30px 50px 30px;
    gap: 20px;
  }
}

.h8_career-item-img {
  flex-shrink: 0;
  width: 244px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px), (max-width: 767px) {
  .h8_career-item-img {
    width: 100%;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .h8_career-item-img {
    width: 224px;
  }
}

.h8_career-item-img img {
  width: 100%;
}

.h8_career-item-content span {
  color: #395BDF;
  font-size: 15px;
  font-weight: 400;
  line-height: 25px;
  margin-bottom: 5px;
  display: block;
}

.h8_career-item-content h4 {
  color: #021936;
  font-size: 20px;
  font-weight: 700;
  line-height: 1.4;
  margin-bottom: 43px;
}

.h8_career-btn {
  height: 40px;
  padding: 0 20px;
}

/*
************
*************************
14. testimonial
******************************************************* 
*************************************************************** */
.h8_instagram-wrap {
  display: flex;
  flex-wrap: wrap;
  gap: 30px;
  justify-content: center;
}
.h8_instagram-item {
  overflow: hidden;
  border-radius: 10px;
  position: relative;
  z-index: 1;
}
.h8_instagram-item::before {
  position: absolute;
  left: 0;
  top: 0;
  content: "";
  width: 100%;
  height: 100%;
  background: rgba(2, 25, 54, 0.8);
  opacity: 0;
  visibility: hidden;
  -webkit-transition: all 0.3s linear 0s;
  -moz-transition: all 0.3s linear 0s;
  -ms-transition: all 0.3s linear 0s;
  -o-transition: all 0.3s linear 0s;
  transition: all 0.3s linear 0s;
}
.h8_instagram-item-icon {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: all 0.3s linear 0s;
  -moz-transition: all 0.3s linear 0s;
  -ms-transition: all 0.3s linear 0s;
  -o-transition: all 0.3s linear 0s;
  transition: all 0.3s linear 0s;
}
.h8_instagram-item-icon svg {
  color: rgba(241, 77, 93, 0.9);
}

.h8_instagram-item:hover::before {
  opacity: 1;
  visibility: visible;
}
.h8_instagram-item:hover .h8_instagram-item-icon {
  opacity: 1;
  visibility: visible;
}

/*
************
*************************
29. faq
******************************************************* 
*************************************************************** */
.h9_choose-item {
  background: #F5F5F5;
  padding: 55px 45px 68px;
  text-align: center;
  border-radius: 20px;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .h9_choose-item {
    padding: 55px 40px 68px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .h9_choose-item {
    padding: 55px 30px 68px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .h9_choose-item {
    padding: 55px 30px 68px;
  }
}
@media (max-width: 767px) {
  .h9_choose-item {
    padding: 55px 30px 68px;
  }
}

.h9_choose-item-icon {
  margin-bottom: 50px;
}

.h9_choose-item-icon img {
  width: 85px;
  transition: all 0.3s linear 0s;
}

.h9_choose-item:hover .h9_choose-item-icon > img {
  transform: scale(1.1) translateY(-5px);
}

.h9_choose-item-title {
  color: #21242C;
  font-size: 24px;
  font-weight: 600;
  line-height: 1.2;
  margin-bottom: 19px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .h9_choose-item-title {
    font-size: 20px;
  }
}
@media (max-width: 767px) {
  .h9_choose-item-title {
    font-size: 20px;
  }
}

.h9_choose-item p {
  color: rgba(33, 36, 44, 0.7);
  font-size: 15px;
  font-weight: 400;
  line-height: 24px;
  margin-bottom: 0;
}

.h9_choose-item-big {
  border-radius: 20px;
  background: #FFF;
  box-shadow: 0px 30px 40px 0px rgba(33, 36, 44, 0.08);
  overflow: hidden;
}

.h9_choose-item-big-content {
  padding: 40px 40px 70px;
}
@media (max-width: 767px) {
  .h9_choose-item-big-content {
    padding: 40px 30px 70px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .h9_choose-item-big-content {
    padding: 40px 40px 70px;
  }
}

.h9_choose-item-big-content span {
  border-radius: 4px;
  border: 1px solid rgba(24, 101, 242, 0.1);
  background: rgba(24, 101, 242, 0.06);
  display: inline-block;
  padding: 5px 12px;
  color: #1865F2;
  font-size: 13px;
  font-weight: 500;
  line-height: 1;
  margin-bottom: 10px;
}

.h9_choose-item-big-content h3 {
  color: #21242C;
  font-size: 36px;
  font-weight: 600;
  line-height: 1.27;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .h9_choose-item-big-content h3 {
    font-size: 28px;
  }
}
@media (max-width: 767px) {
  .h9_choose-item-big-content h3 {
    font-size: 25px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .h9_choose-item-big-content h3 {
    font-size: 28px;
  }
}
.h9_choose-item-big-content h3:hover a {
  color: #1865F2;
}

.h9_choose-item-big-content p {
  color: rgba(33, 36, 44, 0.7);
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
  margin-bottom: 40px;
}

.h9_choose-item-big-img {
  overflow: hidden;
}
.h9_choose-item-big-img img {
  -webkit-transition: all 0.5s linear 0s;
  -moz-transition: all 0.5s linear 0s;
  -ms-transition: all 0.5s linear 0s;
  -o-transition: all 0.5s linear 0s;
  transition: all 0.5s linear 0s;
}

.h9_choose-item-big:hover .h9_choose-item-big-img img {
  transform: scale(1.1);
}

/*
************
*************************
05. section
******************************************************* 
*************************************************************** */
.h10_class-area {
  background: #4846AB;
  position: relative;
  z-index: 1;
}

.h10_class-content-title {
  color: #fff;
  font-size: 48px;
  font-weight: 700;
  line-height: 1.2;
  margin-bottom: 18px;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .h10_class-content-title {
    font-size: 40px;
  }
}
@media (max-width: 767px) {
  .h10_class-content-title {
    font-size: 38px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .h10_class-content-title {
    font-size: 48px;
  }
}

.h10_class-content p {
  color: #fff;
  font-size: 18px;
  font-weight: 400;
  line-height: 26px;
  margin-bottom: 37px;
}

.h10_class-content-title span {
  display: inline-block;
  padding: 5px 15px;
  position: relative;
  z-index: 1;
  color: #fff;
  font-size: 24px;
  font-weight: 700;
  border: 1px solid #fff;
  line-height: 1;
  border-radius: 4px;
  padding-left: 40px;
  margin-left: 5px;
}

.h10_class-content-title span::before {
  position: absolute;
  left: 15px;
  top: 50%;
  transform: translateY(-50%);
  content: "";
  background: #f20;
  width: 12px;
  height: 12px;
  margin-top: -6px;
  border-radius: 50%;
  animation: scaleup 1s linear 0s infinite alternate;
}

@keyframes scaleup {
  0% {
    transform: scale(0.5);
  }
  50% {
    transform: scale(1.05);
  }
  100% {
    transform: scale(1.2);
  }
}
.h10_class-shape-1 {
  position: absolute;
  left: 65px;
  top: 50%;
  animation: animation-upDown-1 3s linear 0s infinite alternate;
  transform: translateY(-50%);
}
@media only screen and (min-width: 1400px) and (max-width: 1599px) {
  .h10_class-shape-1 {
    left: 10px;
    width: 40px;
  }
}

.h10_class-shape-2 {
  position: absolute;
  top: 60px;
  left: 46%;
  transform: translateX(-50%);
  animation: animation-popup-2 1.2s linear 0s infinite alternate;
}

.h10_class-shape-3 {
  position: absolute;
  left: 42%;
  bottom: 160px;
  transform: translateX(-50%);
  animation: animation-leftRight-1 3s linear 0s infinite alternate;
}

/*
************
*************************
32. footer
******************************************************* 
*************************************************************** */
.footer-area {
  background-color: var(--clr-color-lightPurple);
}
.footer-logo {
  margin-bottom: 15px;
}

.footer-social ul {
  display: flex;
  gap: 10px;
  align-items: center;
}
.footer-social ul li a {
  display: grid;
  width: 40px;
  height: 40px;
  place-items: center;
  border-radius: 4px;
  color: rgba(30, 30, 30, 0.3);
  border: 1px solid rgba(30, 30, 30, 0.3);
  font-size: 15px;
  background: rgba(255, 255, 255, 0.08);
}
.footer-social ul li a:hover {
  background: var(--clr-theme-primary);
  color: #fff;
  border-color: transparent;
}

@media only screen and (min-width: 1600px) and (max-width: 1799px) {
  .footer-widget.ml-80 {
    margin-left: 60px;
  }
}
@media only screen and (min-width: 1400px) and (max-width: 1599px) {
  .footer-widget.ml-80 {
    margin-left: 40px;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .footer-widget.ml-80 {
    margin-left: 20px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .footer-widget.ml-80 {
    margin-left: 0;
  }
}
.footer-widget-title {
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 30px;
}
.footer-widget-list ul li {
  margin-bottom: 10px;
}
.footer-widget-list ul li:last-child {
  margin-bottom: 0;
}
.footer-widget-list ul li a {
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  color: rgba(30, 30, 30, 0.6);
}
.footer-widget-list ul li a:hover {
  color: var(--clr-theme-primary);
}

.footer-subscribe-form {
  position: relative;
  margin-bottom: 13px;
  z-index: 1;
}
.footer-subscribe-form input {
  width: 100%;
  height: 50px;
  line-height: 50px;
  padding-left: 20px;
  border-radius: 6px;
  border: 1px solid rgba(30, 30, 30, 0.1);
  padding-right: 124px;
  color: rgba(30, 30, 30, 0.4);
  background-color: transparent;
  font-size: 14px;
}
.footer-subscribe-form input::-webkit-input-placeholder {
  color: rgba(30, 30, 30, 0.4);
  font-size: 14px;
  opacity: 1;
  -webkit-transition: all 0.3s linear 0s;
  -moz-transition: all 0.3s linear 0s;
  -ms-transition: all 0.3s linear 0s;
  -o-transition: all 0.3s linear 0s;
  transition: all 0.3s linear 0s;
}
.footer-subscribe-form input:-moz-placeholder {
  color: rgba(30, 30, 30, 0.4);
  font-size: 14px;
  opacity: 1;
  -webkit-transition: all 0.3s linear 0s;
  -moz-transition: all 0.3s linear 0s;
  -ms-transition: all 0.3s linear 0s;
  -o-transition: all 0.3s linear 0s;
  transition: all 0.3s linear 0s;
}
.footer-subscribe-form input::-moz-placeholder {
  color: rgba(30, 30, 30, 0.4);
  font-size: 14px;
  opacity: 1;
  -webkit-transition: all 0.3s linear 0s;
  -moz-transition: all 0.3s linear 0s;
  -ms-transition: all 0.3s linear 0s;
  -o-transition: all 0.3s linear 0s;
  transition: all 0.3s linear 0s;
}
.footer-subscribe-form input:-ms-input-placeholder {
  color: rgba(30, 30, 30, 0.4);
  font-size: 14px;
  opacity: 1;
  -webkit-transition: all 0.3s linear 0s;
  -moz-transition: all 0.3s linear 0s;
  -ms-transition: all 0.3s linear 0s;
  -o-transition: all 0.3s linear 0s;
  transition: all 0.3s linear 0s;
}
.footer-subscribe-form button {
  position: absolute;
  right: 5px;
  top: 50%;
  transform: translateY(-50%);
  height: 40px;
  line-height: 40px;
  background: var(--clr-theme-primary);
  color: #fff;
  border: none;
  border-radius: 6px;
  font-size: 14px;
  font-weight: 600;
  padding: 0 20px;
  z-index: 1;
  overflow: hidden;
}
.footer-subscribe-form button::after {
  content: "";
  position: absolute;
  top: 10px;
  left: 0;
  width: 300px;
  height: 200px;
  background-color: #08449E;
  border-color: transparent;
  border-radius: 50%;
  transform: translate(-40px, -80px) scale(0.1);
  opacity: 0;
  z-index: -1;
  -webkit-transition: all 0.5s linear 0s;
  -moz-transition: all 0.5s linear 0s;
  -ms-transition: all 0.5s linear 0s;
  -o-transition: all 0.5s linear 0s;
  transition: all 0.5s linear 0s;
}
.footer-subscribe-form button:hover {
  color: #fff;
}
.footer-subscribe-form button:hover::after {
  opacity: 0.5;
  transform-origin: 100px 100px;
  transform: scale(1) translate(-10px, -70px);
}
.footer-subscribe-condition {
  display: flex;
  align-items: center;
  gap: 10px;
}
.footer-subscribe-condition label {
  font-size: 14px;
  font-weight: 400;
  color: rgba(30, 30, 30, 0.6);
}

.copyright-area {
  border-top: 1px solid rgba(30, 30, 30, 0.1);
  padding: 30px 0;
}
.copyright-text p {
  text-align: center;
  margin-bottom: 0;
  color: rgba(30, 30, 30, 0.5);
}

.footer-subscribe-condition .condition_label {
  display: block;
  position: relative;
  padding-left: 21px;
  cursor: pointer;
  font-size: 15px;
  user-select: none;
}
.footer-subscribe-condition .condition_label input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}
.footer-subscribe-condition .check_mark {
  position: absolute;
  top: 7px;
  left: 0;
  height: 12px;
  width: 12px;
  background-color: transparent;
  border: 1px solid rgba(30, 30, 30, 0.3);
  border-radius: 2px;
}
.footer-subscribe-condition .check_mark:after {
  content: "";
  position: absolute;
  display: none;
}
.footer-subscribe-condition .condition_label input:checked ~ .check_mark:after {
  display: block;
}
.footer-subscribe-condition .condition_label .check_mark:after {
  left: 3px;
  top: 0px;
  width: 4px;
  height: 8px;
  border: solid rgba(30, 30, 30, 0.4);
  border-width: 0 1px 1px 0;
  transform: rotate(45deg);
}

.h2_footer-area {
  background-color: #212229;
}
.h2_footer-area .footer-widget-text {
  color: rgba(255, 255, 255, 0.6);
}
.h2_footer-area .footer-social ul li a {
  color: rgba(255, 255, 255, 0.3);
  border-color: rgba(255, 255, 255, 0.14);
}
.h2_footer-area .footer-social ul li a:hover {
  color: #fff;
}
.h2_footer-area .footer-widget-list ul li a {
  color: rgba(255, 255, 255, 0.6);
}
.h2_footer-area .footer-widget-list ul li a:hover {
  color: #fff;
}
.h2_footer-area .footer-widget-title {
  color: #fff;
}
.h2_footer-area .footer-subscribe-form input {
  border-color: rgba(255, 255, 255, 0.13);
  color: rgba(255, 255, 255, 0.4);
}
.h2_footer-area .footer-subscribe-form input::-webkit-input-placeholder {
  color: rgba(255, 255, 255, 0.4);
}
.h2_footer-area .footer-subscribe-form input:-moz-placeholder {
  color: rgba(255, 255, 255, 0.4);
}
.h2_footer-area .footer-subscribe-form input::-moz-placeholder {
  color: rgba(255, 255, 255, 0.4);
}
.h2_footer-area .footer-subscribe-form input:-ms-input-placeholder {
  color: rgba(255, 255, 255, 0.4);
}
.h2_footer-area .footer-subscribe-condition label {
  color: rgba(255, 255, 255, 0.6);
}
.h2_footer-area .copyright-area {
  border-color: rgba(255, 255, 255, 0.1);
}
.h2_footer-area .copyright-text p {
  color: rgba(255, 255, 255, 0.5);
}
.h2_footer-area .footer-subscribe-condition .check_mark {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  height: 14px;
  width: 14px;
  background-color: transparent;
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 2px;
}
.h2_footer-area .footer-subscribe-condition .condition_label .check_mark:after {
  left: 4px;
  top: 1px;
  width: 4px;
  height: 8px;
  border: solid rgba(255, 255, 255, 0.4);
  border-width: 0 1px 1px 0;
  transform: rotate(45deg);
}

.h3_footer-area {
  background-color: #0A0A0A;
}
.h3_footer-area .footer-social ul {
  display: flex;
  gap: 10px;
  align-items: center;
}
.h3_footer-area .footer-social ul li a {
  background-color: rgba(255, 255, 255, 0.1);
  color: rgba(255, 255, 255, 0.6);
}
.h3_footer-area .footer-social ul li a:hover {
  background-color: var(--clr-theme-primary-3);
  color: #fff;
}
.h3_footer-area .footer-widget-text {
  color: rgba(255, 255, 255, 0.6);
}
.h3_footer-area .footer-widget-title {
  color: #fff;
}
.h3_footer-area .footer-widget-list ul li a {
  color: rgba(255, 255, 255, 0.6);
}
.h3_footer-area .footer-widget-list ul li a:hover {
  color: #fff;
}
.h3_footer-area .copyright-text p {
  color: rgba(255, 255, 255, 0.6);
}
.h3_footer-area .footer-subscribe-condition .check_mark {
  border-color: rgba(255, 255, 255, 0.6);
}
.h3_footer-area .footer-subscribe-condition .check_mark:after {
  border-color: rgba(255, 255, 255, 0.6);
}
.h3_footer-area .footer-subscribe-condition .condition_label {
  color: rgba(255, 255, 255, 0.6);
}
.h3_footer-area .footer-subscribe-form input {
  background-color: rgba(255, 255, 255, 0.1);
  color: rgba(255, 255, 255, 0.6);
}
.h3_footer-area .footer-subscribe-form input::-webkit-input-placeholder {
  color: rgba(255, 255, 255, 0.6);
}
.h3_footer-area .footer-subscribe-form input:-moz-placeholder {
  color: rgba(255, 255, 255, 0.6);
}
.h3_footer-area .footer-subscribe-form input::-moz-placeholder {
  color: rgba(255, 255, 255, 0.6);
}
.h3_footer-area .footer-subscribe-form input:-ms-input-placeholder {
  color: rgba(255, 255, 255, 0.6);
}
.h3_footer-area .footer-subscribe-form button {
  background-color: var(--clr-theme-primary-3);
}
.h3_footer-area .copyright-area {
  border-color: rgba(255, 255, 255, 0.1);
}

.h4_footer-area {
  background-color: #0A0A0A;
}
.h4_footer-area .footer-social ul {
  display: flex;
  gap: 10px;
  align-items: center;
}
.h4_footer-area .footer-social ul li a {
  background-color: rgba(255, 255, 255, 0.1);
  color: rgba(255, 255, 255, 0.6);
  border-radius: 50%;
}
.h4_footer-area .footer-social ul li a:hover {
  background-color: var(--clr-theme-primary-4);
  color: #fff;
}
.h4_footer-area .footer-widget-text {
  color: rgba(255, 255, 255, 0.6);
}
.h4_footer-area .footer-widget-title {
  color: #fff;
}
.h4_footer-area .footer-widget-list ul li a {
  color: rgba(255, 255, 255, 0.6);
}
.h4_footer-area .footer-widget-list ul li a:hover {
  color: #fff;
}
.h4_footer-area .copyright-text p {
  color: rgba(255, 255, 255, 0.6);
}
.h4_footer-area .footer-subscribe-condition .check_mark {
  border-color: rgba(255, 255, 255, 0.6);
}
.h4_footer-area .footer-subscribe-condition .check_mark:after {
  border-color: rgba(255, 255, 255, 0.6);
}
.h4_footer-area .footer-subscribe-condition .condition_label {
  color: rgba(255, 255, 255, 0.6);
}
.h4_footer-area .footer-subscribe-form input {
  background-color: rgba(255, 255, 255, 0.1);
  color: rgba(255, 255, 255, 0.6);
  border-radius: 30px;
}
.h4_footer-area .footer-subscribe-form input::-webkit-input-placeholder {
  color: rgba(255, 255, 255, 0.6);
}
.h4_footer-area .footer-subscribe-form input:-moz-placeholder {
  color: rgba(255, 255, 255, 0.6);
}
.h4_footer-area .footer-subscribe-form input::-moz-placeholder {
  color: rgba(255, 255, 255, 0.6);
}
.h4_footer-area .footer-subscribe-form input:-ms-input-placeholder {
  color: rgba(255, 255, 255, 0.6);
}
.h4_footer-area .footer-subscribe-form button {
  background-color: var(--clr-theme-primary-4);
  border-radius: 30px;
}
.h4_footer-area .copyright-area {
  border-color: rgba(255, 255, 255, 0.1);
}

.h5_footer-area {
  background-color: #0A0A0A;
}
.h5_footer-area .footer-social ul {
  display: flex;
  gap: 10px;
  align-items: center;
}
.h5_footer-area .footer-social ul li a {
  background-color: rgba(255, 255, 255, 0.1);
  color: rgba(255, 255, 255, 0.6);
  border-radius: 0;
}
.h5_footer-area .footer-social ul li a:hover {
  background-color: var(--clr-theme-primary-5);
  color: var(--clr-body-heading);
}
.h5_footer-area .footer-widget-text {
  color: rgba(255, 255, 255, 0.6);
}
.h5_footer-area .footer-widget-title {
  color: #fff;
}
.h5_footer-area .footer-widget-list ul li a {
  color: rgba(255, 255, 255, 0.6);
}
.h5_footer-area .footer-widget-list ul li a:hover {
  color: #fff;
}
.h5_footer-area .copyright-text p {
  color: rgba(255, 255, 255, 0.6);
}
.h5_footer-area .footer-subscribe-condition .check_mark {
  border-color: rgba(255, 255, 255, 0.6);
}
.h5_footer-area .footer-subscribe-condition .check_mark:after {
  border-color: rgba(255, 255, 255, 0.6);
}
.h5_footer-area .footer-subscribe-condition .condition_label {
  color: rgba(255, 255, 255, 0.6);
}
.h5_footer-area .footer-subscribe-form input {
  background-color: rgba(255, 255, 255, 0.1);
  color: rgba(255, 255, 255, 0.6);
  border-radius: 0;
}
.h5_footer-area .footer-subscribe-form input::-webkit-input-placeholder {
  color: rgba(255, 255, 255, 0.6);
}
.h5_footer-area .footer-subscribe-form input:-moz-placeholder {
  color: rgba(255, 255, 255, 0.6);
}
.h5_footer-area .footer-subscribe-form input::-moz-placeholder {
  color: rgba(255, 255, 255, 0.6);
}
.h5_footer-area .footer-subscribe-form input:-ms-input-placeholder {
  color: rgba(255, 255, 255, 0.6);
}
.h5_footer-area .footer-subscribe-form button {
  background-color: var(--clr-theme-primary-5);
  border-radius: 0;
  color: var(--clr-body-heading);
}
.h5_footer-area .copyright-area {
  border-color: rgba(255, 255, 255, 0.1);
}

.h6_footer-area {
  background-color: #F6F6F6;
}
.h6_footer-logo {
  margin-bottom: 15px;
}
.h6_footer-social ul {
  display: flex;
  gap: 10px;
  align-items: center;
}
.h6_footer-social ul li a {
  display: grid;
  width: 40px;
  height: 40px;
  place-items: center;
  border-radius: 50%;
  color: #0A0A0A;
  background-color: rgba(10, 10, 10, 0.1);
  font-size: 16px;
}
.h6_footer-social ul li a:hover {
  background: var(--clr-theme-primary-6);
  color: #fff;
  border-color: transparent;
}
.h6_footer-inner {
  width: 100%;
  display: flex;
  justify-content: center;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .h6_footer-inner {
    justify-content: flex-start;
  }
}
@media (max-width: 767px) {
  .h6_footer-inner {
    width: 50%;
    justify-content: flex-start;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .h6_footer-inner {
    width: 100%;
    justify-content: flex-start;
  }
}
@media only screen and (min-width: 1600px) and (max-width: 1799px) {
  .h6_footer-widget.ml-80 {
    margin-left: 60px;
  }
}
@media only screen and (min-width: 1400px) and (max-width: 1599px) {
  .h6_footer-widget.ml-80 {
    margin-left: 40px;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .h6_footer-widget.ml-80 {
    margin-left: 20px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .h6_footer-widget.ml-80 {
    margin-left: 0;
  }
}
@media only screen and (min-width: 1600px) and (max-width: 1799px) {
  .h6_footer-widget.mr-80 {
    margin-right: 60px;
  }
}
@media only screen and (min-width: 1400px) and (max-width: 1599px) {
  .h6_footer-widget.mr-80 {
    margin-right: 40px;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .h6_footer-widget.mr-80 {
    margin-right: 20px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .h6_footer-widget.mr-80 {
    margin-right: 0;
  }
}
.h6_footer-widget-title {
  color: #0A0A0A;
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 30px;
}
.h6_footer-widget-list ul li {
  margin-bottom: 10px;
}
.h6_footer-widget-list ul li:last-child {
  margin-bottom: 0;
}
.h6_footer-widget-list ul li a {
  font-size: 15px;
  font-weight: 400;
  line-height: 20px;
  color: rgba(10, 10, 10, 0.6);
}
.h6_footer-widget-list ul li a:hover {
  color: var(--clr-theme-primary-6);
}
.h6_footer-widget-text {
  color: rgba(10, 10, 10, 0.6);
  font-size: 15px;
  font-weight: 400;
  line-height: 24px;
  margin-bottom: 27px;
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .h6_footer-widget-text br {
    display: none;
  }
}
.h6_footer-subscribe-form {
  position: relative;
  margin-bottom: 8px;
  z-index: 1;
}
.h6_footer-subscribe-form input {
  width: 100%;
  height: 50px;
  line-height: 50px;
  padding-left: 20px;
  border: 1px solid #DDD;
  padding-right: 124px;
  color: rgba(30, 30, 30, 0.6);
  background-color: transparent;
  font-size: 14px;
}
.h6_footer-subscribe-form input::-webkit-input-placeholder {
  color: rgba(30, 30, 30, 0.4);
  font-size: 14px;
  opacity: 1;
  -webkit-transition: all 0.3s linear 0s;
  -moz-transition: all 0.3s linear 0s;
  -ms-transition: all 0.3s linear 0s;
  -o-transition: all 0.3s linear 0s;
  transition: all 0.3s linear 0s;
}
.h6_footer-subscribe-form input:-moz-placeholder {
  color: rgba(30, 30, 30, 0.4);
  font-size: 14px;
  opacity: 1;
  -webkit-transition: all 0.3s linear 0s;
  -moz-transition: all 0.3s linear 0s;
  -ms-transition: all 0.3s linear 0s;
  -o-transition: all 0.3s linear 0s;
  transition: all 0.3s linear 0s;
}
.h6_footer-subscribe-form input::-moz-placeholder {
  color: rgba(30, 30, 30, 0.4);
  font-size: 14px;
  opacity: 1;
  -webkit-transition: all 0.3s linear 0s;
  -moz-transition: all 0.3s linear 0s;
  -ms-transition: all 0.3s linear 0s;
  -o-transition: all 0.3s linear 0s;
  transition: all 0.3s linear 0s;
}
.h6_footer-subscribe-form input:-ms-input-placeholder {
  color: rgba(30, 30, 30, 0.4);
  font-size: 14px;
  opacity: 1;
  -webkit-transition: all 0.3s linear 0s;
  -moz-transition: all 0.3s linear 0s;
  -ms-transition: all 0.3s linear 0s;
  -o-transition: all 0.3s linear 0s;
  transition: all 0.3s linear 0s;
}
.h6_footer-subscribe-form button {
  position: absolute;
  right: 5px;
  top: 50%;
  transform: translateY(-50%);
  height: 40px;
  line-height: 40px;
  background: var(--clr-theme-primary-6);
  color: #fff;
  border: none;
  border-radius: 0;
  font-size: 14px;
  font-weight: 600;
  padding: 0 20px;
  z-index: 1;
  overflow: hidden;
}
.h6_footer-subscribe-form button::after {
  content: "";
  position: absolute;
  top: 10px;
  left: 0;
  width: 300px;
  height: 200px;
  background-color: #B1040E;
  border-color: transparent;
  border-radius: 50%;
  transform: translate(-40px, -80px) scale(0.1);
  opacity: 0;
  z-index: -1;
  -webkit-transition: all 0.5s linear 0s;
  -moz-transition: all 0.5s linear 0s;
  -ms-transition: all 0.5s linear 0s;
  -o-transition: all 0.5s linear 0s;
  transition: all 0.5s linear 0s;
}
.h6_footer-subscribe-form button:hover {
  color: #fff;
}
.h6_footer-subscribe-form button:hover::after {
  opacity: 0.5;
  transform-origin: 100px 100px;
  transform: scale(1) translate(-10px, -70px);
}
.h6_footer-subscribe-condition {
  display: flex;
  align-items: center;
  gap: 10px;
}
.h6_footer-subscribe-condition label {
  font-size: 14px;
  font-weight: 400;
  color: rgba(30, 30, 30, 0.6);
}
.h6_footer-subscribe-condition .condition_label {
  display: block;
  position: relative;
  padding-left: 21px;
  cursor: pointer;
  font-size: 15px;
  user-select: none;
}
.h6_footer-subscribe-condition .condition_label input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}
.h6_footer-subscribe-condition .check_mark {
  position: absolute;
  top: 7px;
  left: 0;
  height: 12px;
  width: 12px;
  background-color: transparent;
  border: 1px solid #878787;
  border-radius: 0;
}
.h6_footer-subscribe-condition .check_mark:after {
  content: "";
  position: absolute;
  display: none;
}
.h6_footer-subscribe-condition .condition_label input:checked ~ .check_mark:after {
  display: block;
}
.h6_footer-subscribe-condition .condition_label .check_mark:after {
  left: 3px;
  top: 0px;
  width: 4px;
  height: 8px;
  border: solid var(--clr-theme-primary-6);
  border-width: 0 1px 1px 0;
  transform: rotate(45deg);
}

.h6_copyright-area {
  border-top: 1px solid rgba(10, 10, 10, 0.1);
  padding: 30px 0;
}
.h6_copyright-text p {
  font-size: 15px;
  line-height: 24px;
  text-align: center;
  margin-bottom: 0;
  color: rgba(30, 30, 30, 0.5);
}

.h8_footer-area .h6_footer-subscribe-form button {
  background-color: var(--clr-theme-primary-8);
}

.h9_footer-area .h6_footer-subscribe-form button {
  background-color: var(--clr-theme-primary-9);
}

.h10_footer-area .h6_footer-subscribe-form button {
  background-color: var(--clr-theme-primary-10);
}

.course_details-author-img {
  /* Optional: set size of container */
  width: 100px;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  /* Optional: add a background color or border if needed */
  background-color: #f0f0f0;
  border-radius: 50%;
}

.round-image {
  width: 100%;
  height: 100%;
  object-fit: cover; /* ensures the image covers the entire area without distortion */
  border-radius: 50%; /* makes the image round */
}

.upcoming-assignments-section {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
}

.assignment-cards {
  display: flex;
  flex-direction: column;
}

.assignment-cards .card {
  background-color: #f8f9fa;
  padding: 20px;
  margin-bottom: 15px;
}

.assignment-cards .card-title {
  font-size: 18px;
}

.assignment-cards .badge {
  font-size: 14px;
  padding: 10px;
}
/* Main container styling */
.upload-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height:150vh;
  background: linear-gradient(to right, #74ebd5, #acb6e5);
  font-family: 'Arial', sans-serif;
}

/* Card layout for file upload form */
.upload-card {
  background-color: white;
  border-radius: 12px;
  padding: 30px;
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.1);
  text-align: center;
  width: 100%;
  max-width: 500px;
}

/* Title styling */
.upload-card h2 {
  font-size: 24px;
  margin-bottom: 20px;
  color: #333;
}

/* Form styling */
.upload-form {
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* Drag-and-drop zone */
.drop-zone {
  border: 2px dashed #007bff;
  padding: 30px;
  border-radius: 12px;
  width: 100%;
  text-align: center;
  margin-bottom: 20px;
  transition: background-color 0.3s ease;
}

.drop-zone.active {
  background-color: #f0f8ff;
}

.drop-zone:hover {
  background-color: #f0f8ff;
  cursor: pointer;
}

/* Hidden input for the file */
.file-input {
  display: none;
}

/* Label for file upload */
.file-label {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #555;
}

.file-icon {
  font-size: 40px;
  margin-bottom: 10px;
}

.file-name {
  font-size: 16px;
  color: #888;
}

/* Upload button styling */
.upload-button {
  background-color: #28a745;
  color: white;
  padding: 12px 25px;
  border: none;
  border-radius: 6px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.upload-button:hover {
  background-color: #218838;
}

/* Responsive design */
@media (max-width: 600px) {
  .upload-card {
      padding: 20px;
  }

  .upload-button {
      width: 100%;
  }
}
.assignment-list-container {
  padding: 30px;
  max-width: 1000px;
  margin: 0 auto;
  background-color: #f4f4f4;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  font-family: Arial, sans-serif;
}

.assignment-list-container h2 {
  text-align: center;
  margin-bottom: 20px;
}

.assignment-list {
  list-style: none;
  padding: 0;
}

.assignment-item {
  background-color: white;
  padding: 15px;
  margin-bottom: 10px;
  border-radius: 6px;
  cursor: pointer;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s ease;
}

.assignment-item:hover {
  background-color: #f1f1f1;
}
.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  background-color: white;
  padding: 30px;
  border-radius: 8px;
  position: relative;
  max-width: 500px;
  width: 100%;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 20px;
  cursor: pointer;
}
.assignment-details-container {
  padding: 20px;
  max-width: 600px;
  margin: 0 auto;
}

.assignment-details-container h2 {
  margin-bottom: 20px;
}

.assignment-details-container p {
  margin-bottom: 10px;
}

.close-button {
  background-color: #007bff;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin: 250px;
}

.close-button:hover {
  background-color: #0056b3;
}
.exam-results-container {
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin-top: 20px;
}

.exam-chart-container {
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.chart-title {
  font-size: 1.5rem;
  margin-bottom: 15px;
  text-align: center;
  color: #333;
}

.exam-chart {
  height: 350px;
}

.exam-card-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.exam-card, .overall-card {
  background-color: #ffffff;
  padding: 20px;
  margin-bottom: 15px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.exam-card h5, .overall-card h5 {
  font-size: 1.2rem;
  margin-bottom: 10px;
  color: #4caf50;
}

.exam-card p, .overall-card p {
  font-size: 1.5rem;
  font-weight: bold;
  margin: 0;
}
/* Container for the overall report with a stylish appearance */
.overall-report-container {
  padding: 40px;
  background: linear-gradient(135deg, #f3f4f6 0%, #ffffff 100%);
  border-radius: 15px;
  max-width: 1200px;
  margin: 30px auto;
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.1);
  font-family: 'Arial', sans-serif;
  border: 1px solid #ddd;
}

/* Title styling with a gradient text effect */
.report-title {
  text-align: center;
  font-size: 36px;
  background: -webkit-linear-gradient(45deg, #4caf50, #2196f3);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-bottom: 20px;
  font-weight: 700;
  text-transform: uppercase;
}

/* Summary heading styling */
.summary-heading {
  font-size: 28px;
  color: #333;
  margin-bottom: 20px;
  font-weight: 600;
  text-align: center;
}

/* Table styling with modern features */
.report-table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
  margin-top: 20px;
  border-radius: 10px;
  overflow: hidden;
}

.report-table thead {
  background: linear-gradient(135deg, #007bff, #00c6ff);
  color:#0a0a0a;
}

.report-table th, .report-table td {
  padding: 12px 15px;
  text-align: center;
  border-bottom: 1px solid #ddd;
  transition: background-color 0.3s, color 0.3s;
}

.report-table th {
  font-size: 18px;
  font-weight: 700;
}

.report-table td {
  font-size: 16px;
  color: #555;
}

/* Alternating row colors and hover effects */
.report-table tr:nth-child(even) {
  background-color: #f7f9fc;
}

.report-table tr:nth-child(odd) {
  background-color: #ffffff;
}

.report-table tr:hover {
  background-color: #e1e9f0;
  color: #333;
}

/* Responsive design for smaller screens */
@media (max-width: 768px) {
  .report-table th, .report-table td {
      font-size: 14px;
      padding: 10px;
  }
}
/* Container for the staff advice page */
.staff-advice-container {
    padding: 40px;
    background-color: #f9fafc;
    max-width: 1200px;
    margin: 0 auto;
    border-radius: 15px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    font-family: 'Arial', sans-serif;
}

/* Page title */
.page-title {
    text-align: center;
    font-size: 36px;
    color: #333;
    margin-bottom: 40px;
    font-weight: 700;
    text-transform: uppercase;
}

/* Cards container for displaying advice */
.advice-cards {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 20px;
}

/* Individual card for each subject */
.advice-card {
    background: linear-gradient(135deg, #f5f5f5 0%, #ffffff 100%);
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.05);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.advice-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 6px 15px rgba(0, 0, 0, 0.1);
}

/* Subject title inside the card */
.subject-title {
    font-size: 24px;
    color: #007bff;
    font-weight: bold;
    margin-bottom: 10px;
}

/* Staff advice text styling */
.staff-advice-text {
    font-size: 16px;
    color: #555;
    margin-bottom: 15px;
    line-height: 1.6;
}

/* Staff name styling */
.staff-name {
    font-size: 14px;
    font-style: italic;
    color: #888;
    text-align: right;
}

/* Responsive design for smaller screens */
@media (max-width: 768px) {
    .staff-advice-container {
        padding: 20px;
    }

    .subject-title {
        font-size: 20px;
    }

    .staff-advice-text {
        font-size: 14px;
    }
}
/* Overall container styling with gradient and shadow */
.progress-report-container {
  padding: 40px;
  background: radial-gradient(circle, #f3f4f6, #ffffff);
  border-radius: 20px;
  max-width: 1200px;
  margin: 30px auto;
  box-shadow: 0 12px 24px rgba(0, 0, 0, 0.3);
  border: 2px solid #e0e0e0;
  position: relative;
  overflow: hidden;
  font-family: 'Roboto', sans-serif;
}

/* Title with a vibrant gradient underline and text shadow */
.report-title {
  text-align: center;
  font-size: 36px;
  color: #333;
  margin-bottom: 30px;
  font-weight: 700;
  text-transform: uppercase;
  position: relative;
  padding-bottom: 20px;
  letter-spacing: 1px;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.report-title::after {
  content: '';
  display: block;
  width: 150px;
  height: 6px;
  background: linear-gradient(90deg, #4caf50, #8bc34a);
  margin: 15px auto 0;
  border-radius: 3px;
}

/* Flexbox layout for cards with wrapping */
.progress-cards {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

/* Card styling with shadow, border, and hover effects */
.progress-card {
  background: #ffffff;
  border-radius: 15px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  flex: 1 1 calc(33.333% - 20px);
  max-width: calc(33.333% - 20px);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  border: 2px solid transparent;
}

/* Animated border and scale effect on hover */
.progress-card:hover {
  transform: scale(1.05);
  box-shadow: 0 12px 24px rgba(0, 0, 0, 0.3);
  border: 2px solid #4caf50;
}

/* Card header with dynamic color and animation */
.card-header {
  background: linear-gradient(135deg, #4caf50, #8bc34a);
  color: #ffffff;
  padding: 20px;
  text-align: center;
  font-size: 24px;
  font-weight: 700;
  text-transform: uppercase;
}

/* Body with softer background and padding */
.card-body {
  padding: 20px;
  background: #f9f9f9;
  flex: 1;
  position: relative;
  transition: background-color 0.3s ease;
}

.card-body:hover {
  background-color: #f0f0f0;
}

/* Styled subject name */
.subject-name {
  margin: 0;
  font-size: 22px;
  font-weight: 600;
  color: #333;
}

/* Marks and grade with better typography */
.subject-marks, .subject-grade {
  font-size: 18px;
  color: #666;
}

.subject-marks strong, .subject-grade strong {
  color: #333;
}

/* Footer with styled button */
.card-footer {
  padding: 10px;
  background: #fafafa;
  text-align: center;
  border-top: 1px solid #e0e0e0;
}

.details-button {
  background-color: #4caf50;
  color: #fff;
  border: none;
  padding: 12px 24px;
  font-size: 16px;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.details-button:hover {
  background-color: #388e3c;
  transform: translateY(-2px);
}

/* Overall average section with distinct styling */
.overall-average {
  margin-top: 40px;
  text-align: right;
  font-size: 24px;
  color: #333;
  font-weight: 700;
}

/* Highlight color for overall average */
.overall-average h4 {
  margin: 0;
  color: #4caf50;
}

/* Responsive design for medium and small screens */
@media (max-width: 1024px) {
  .progress-card {
      flex: 1 1 calc(50% - 20px);
      max-width: calc(50% - 20px);
  }
}

@media (max-width: 768px) {
  .progress-card {
      flex: 1 1 100%;
      max-width: 100%;
  }
}
.table-custom {
  margin: 20px auto;
  width: 80%;
  border-collapse: collapse;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  overflow: hidden;
}

.table-custom thead {
  background-color: #007bff; /* Bootstrap primary color */
  color: white;
}

.table-custom th, .table-custom td {
  padding: 12px 15px;
  text-align: center;
  border: 1px solid #ddd;
}

.table-custom th {
  font-weight: bold;
  text-transform: uppercase; /* Make header text uppercase */
}

.table-custom tbody tr:nth-child(even) {
  background-color: #f9f9f9; /* Light gray for even rows */
}

.table-custom tbody tr:hover {
  background-color: #f1f1f1; /* Light gray on hover */
}

.table-custom tbody td {
  font-size: 1rem; /* Adjust font size */
}

.table-custom tbody tr td:nth-child(2) {
  color: #28a745; /* Green color for attendance percentage */
  font-weight: bold; /* Make it bold */
}
.breadcrumb-area {
  position: relative;
  padding: 60px 0;
}

.breadcrumb-shape {
  position: absolute;
  top: 20px;
  left: 0;
  z-index: 1;
}

.innerPage_event-area {
  background-color: #f9f9f9;
  padding: 60px 0;
}

.section-area {
  margin-bottom: 30px;
}

.event-item {
  border: 1px solid #e0e0e0;
  border-radius: 5px;
  overflow: hidden;
  transition: transform 0.3s;
  height: 500px; /* Set a fixed height */
}

.event-item:hover {
  transform: translateY(-5px);
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
}

.event-img img {
  width: 100%;
  height: auto;
  border-bottom: 1px solid #e0e0e0;
}

.event-content {
  padding: 15px;
}

.event-content-meta {
  font-size: 14px;
  color: #888;
  margin-bottom: 10px;
}

.event-content-title {
  font-size: 18px;
  margin-bottom: 15px;
}

.t-theme-btn {
  background-color: #007bff;
  color: #fff;
  padding: 10px 20px;
  border-radius: 5px;
  text-decoration: none;
  transition: background-color 0.3s;
}

.t-theme-btn:hover {
  background-color: #0056b3;
}
/* General Styles */
.holiday-list-area {
  background-color: #f9f9f9;
}

.holiday-table-container {
  padding: 20px;
  background: #ffffff;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.holiday-table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
}

.holiday-table th, .holiday-table td {
  padding: 15px;
  text-align: left;
  border-bottom: 1px solid #ddd;
  color: #333;
}

.holiday-table th {
  background-color: #f2f2f2;
  font-weight: 600;
}

.holiday-table td {
  font-size: 0.95rem;
}

.holiday-table tr:hover {
  background-color: #f1f1f1;
}

/* Breadcrumb */
.breadcrumb-area {
  padding: 100px 0;
  position: relative;
  background-color: #f2f2f2;
  background-position: center;
  background-size: cover;
  text-align: center;
}

.breadcrumb-content {
  color: #ffffff;
}

.breadcrumb-title {
  font-size: 2.5rem;
  margin-bottom: 10px;
}

.breadcrumb-list a {
  color: #fff;
  text-decoration: none;
}

.breadcrumb-list span {
  color: #fff;
  margin-left: 5px;
}

.breadcrumb-shape {
  position: absolute;
  bottom: 0;
  width: 100%;
}

.section-title {
  font-size: 2rem;
  color: #333;
}
